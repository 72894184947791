<div [ngClass]="['container', layout, 'skiptranslate']">
  <span class="source skiptranslate">
    {{ valueRounded | currency: sourceCurrency }}
  </span>
  <span *ngIf="targetValue !== null" class="target skiptranslate" nz-tooltip [nzTooltipTitle]="tip">
    ~{{ targetValue | currency: targetCurrency:'symbol':'1.0-2' }}
  </span>
  <span *ngIf="targetValue === null" class="target"> ~ &mdash; {{ symbols[targetCurrency] }}</span>
  <ng-template #tip>
    {{
      ('CURRENCY_HINT' | translate) +
        ' ' +
        (exchangeRate.value | number: '1.2-2') +
        symbols[targetCurrency] +
        ' / ' +
        exchangeRate.nominal +
        symbols[sourceCurrency]
    }}
    <br /><a style="color: azure; text-decoration: underline" routerLink="/shop/balance">{{
      'BALANCE.DEPOSIT' | translate
    }}</a>
  </ng-template>
</div>
