import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NzImageService } from 'ng-zorro-antd/image';

interface LotImage {
  src: string;
  thumb?: string;
}

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagesComponent implements OnInit {
  @Input() payload: any = null;

  selectedImg: LotImage = null;
  selectedImgIndex: number = null;
  imgs: LotImage[] = [];

  constructor(private gallery: NzImageService) {}

  ngOnInit(): void {
    const imgs = this.payload.Img ? Object.values(this.payload.Img) : [];
    const thumbs = this.payload.Thumbnails ? Object.values(this.payload.Thumbnails) : [];

    if (imgs.length || thumbs.length) {
      for (let i = 0; i < imgs.length; i++) {
        this.imgs.push({
          src: (imgs[i] as string)?.replace(
            'https://auctions.c.yimg.jp/',
            'https://ya-cdn.akebono.shop/',
          ),
          thumb: (thumbs[i] as string)?.replace(
            'https://auc-pctr.c.yimg.jp/',
            'https://ya-pctr-cdn.akebono.shop/',
          ),
        });
      }
    }

    console.log(this.imgs, imgs);

    this.selectedImg = this.imgs.length ? this.imgs[0] : null;
    this.selectedImgIndex = this.imgs.length ? 0 : null;
  }

  openImage(index: number) {
    this.gallery.preview(this.imgs).switchTo(index);
  }

  select(index: number): void {
    this.selectedImg = this.imgs[index];
    this.selectedImgIndex = index;
  }
}
