const domain = 'twilight.link';

export const environment = {
  production: true,
  domain,
  // paypalKey: 'AThFYn9rG1qN2Fcp0QnSKcIHDkmLJLtP6LV4lQGlyf4KQi_yM845ymxFYLF9zWUFfXz9rvYjwQjUCosN',
  paypalKey: 'ASXZOGCJ3vT-c4vkvqvzxPGNjjNQ7Z5vDvun-d9ZrzI3r6ge9txj8IJ1xfAwN1U-38djWfOnS7Qcdzxm',
  stripeKey: 'pk_test_rpKuWZImvakKhlRowFQA82ub',
  countryCheckUrl: 'https://locate.twilight.link/country',
  telegramBotName: 'AkebonoDevelopmentBot',
  sberDomain: '3dsec.sberbank.ru',
  sberKey: 't36e5q5ikf9nvu1hhibplmgfmd',

  graphql: [
    {
      clientName: 'authentication',
      endpoint: `https://api.${domain}/accounts/authentication`,
    },
    {
      clientName: 'user',
      endpoint: `https://api.${domain}/auctions/main`,
    },
    {
      clientName: 'content',
      endpoint: `https://shop.${domain}/content/shop-minimal`,
    },
    {
      clientName: 'shared-shop',
      endpoint: `https://api.${domain}/auctions/main`,
    },
    {
      clientName: 'akebono-client',
      endpoint: `https://api.${domain}/graphql/akebono/client/familiar`,
    },
    {
      clientName: 'directory',
      endpoint: `https://api.${domain}/graphql/directory/common/open`,
    },
  ],

  cognito: {
    ClientId: '7flkej10110e8bbhrdo9co0of2',
    UserPoolId: 'us-east-1_XGTHN4PBv',
  },
};
