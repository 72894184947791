import { QueryHandlingService } from '@akebono/core';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import {
  YahooFeeConfigGQL,
  YahooFeeConfigQuery,
  YahooFeeConfigQueryVariables,
} from '../graphql/user-service';

@Injectable({
  providedIn: 'root',
})
export class YahooFeeConfigService {
  private readonly _yahooFeeConfig$: Observable<YahooFeeConfigQuery['yahooFeeConfig']>;

  constructor(qhs: QueryHandlingService, yahooFeeConfigGQL: YahooFeeConfigGQL) {
    this._yahooFeeConfig$ = qhs
      .fetch<YahooFeeConfigQuery, YahooFeeConfigQueryVariables>(
        yahooFeeConfigGQL,
        {},
        'cache-first',
      )
      .data.pipe(map((data) => data.yahooFeeConfig));

    this.yahooFeeConfig$.subscribe();
  }

  get yahooFeeConfig$(): Observable<YahooFeeConfigQuery['yahooFeeConfig']> {
    return this._yahooFeeConfig$;
  }
}
