import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { DEFAULT_CURRENCY } from 'src/app/const';
import { CurrencyEnum, ExchangeRate } from 'src/app/graphql/user-service';

export type Layout = 'vertical' | 'horizontal';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyComponent implements OnChanges {
  @Input() value = 0;
  @Input() exchangeRate?: ExchangeRate = null;
  @Input() sourceCurrency: CurrencyEnum = DEFAULT_CURRENCY;
  @Input() targetCurrency: CurrencyEnum = CurrencyEnum.Rub;
  @Input() layout: Layout = 'horizontal';

  symbols = {
    [CurrencyEnum.Eur]: '€',
    [CurrencyEnum.Jpy]: '¥',
    [CurrencyEnum.Usd]: '$',
    [CurrencyEnum.Rub]: '₽',
  };

  targetValue: number | null = 0;
  valueRounded = 0;

  ngOnChanges(): void {
    const course = this.exchangeRate ? this.exchangeRate.value / this.exchangeRate.nominal : null;

    this.valueRounded = Math.round(this.value);
    this.targetValue = course !== null ? Number((this.value * course).toFixed(0)) : null;
  }
}
