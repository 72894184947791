import { NotificationService } from '@akebono/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { scrollToTop } from 'src/app/const';
import {
  CartItemCreateGQL,
  CartItemTypeEnum,
  CurrentUser,
  ExchangeRate,
  PartsSearchGQL,
} from 'src/app/graphql/user-service';
import { MutationHandlingService } from 'src/app/services/mutation-handling.service';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-parts-search',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss'],
})
export class PartsSearchComponent implements OnInit, OnDestroy {
  category: number | null = null;
  search: string | null = null;

  items: any = null;
  currentUser: CurrentUser = null;
  exchangeRate: ExchangeRate = null;

  loading = false;

  makers = [
    'toyota',
    'subaru',
    'nissan',
    'honda',
    'mazda',
    'isuzu',
    'suzuki',
    'daihatsu',
    'mitsubishi',
  ];

  filter = {
    new_flag: null,
    free_shipping_flag: null,
    kakaku_low: null,
    kakaku_high: null,
    stars_division: null,
    sort: null,
    q: null,
    page: null,
    pageSize: null,
    category: null,
    partmaker: [],
    details: [],
    model: null,
  };

  subscription: Subscription = null;

  total = 0;
  buying = {};
  authenticated = false;
  currentLang: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private searchService: SearchService,
    private partsSearchGQL: PartsSearchGQL,
    private cartItemCreate: CartItemCreateGQL,
    private mutation: MutationHandlingService,
  ) {
    this.currentLang = this.translate.currentLang;
  }

  ngOnInit(): void {
    this.searchService.currentService.next(SearchServiceEnum.Parts);
    this.route.queryParams.subscribe((params) => {
      this.filter.page = Number(params.page || 1);
      this.filter.pageSize = Number(params.per_page || 20);
      if (params.q) {
        this.filter.q = params.q;
      }

      this.unsub();
      if (this.filter.q) {
        this.fetchSearch();
      }
    });
  }

  fetchSearch(): void {
    this.loading = true;
    this.subscription = this.partsSearchGQL
      .watch({
        name: this.filter.q?.replace(/[- \/]/g, ''),
        first: this.filter.pageSize,
        offset: (this.filter.page - 1) * this.filter.pageSize,
      })
      .valueChanges.subscribe(
        (result) => {
          this.loading = false;
          console.log('Search response', result);

          if (result && result.data && result.data.autoParts) {
            this.items = result.data.autoParts.nodes;
            this.exchangeRate = result.data.exchangeRate;
            this.total = Number(result.data.autoParts.total);
            this.authenticated = Boolean(result.data.currentUser?.id);

            this.title.setTitle(this.translate.instant('SEARCH.SEARCH'));
          } else {
            this.notificationService.renderError('Empty response');
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
  }

  changePage(page: number): void {
    scrollToTop();
    this.router.navigate([], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(size: number): void {
    scrollToTop();
    this.router.navigate([], {
      queryParams: {
        per_page: size,
      },
      queryParamsHandling: 'merge',
    });
  }

  applyFilter(value: string): void {
    this.router.navigate([], {
      queryParams: {
        q: value?.trim(),
        page: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  unsub(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsub();
  }

  buy(item): void {
    if (!this.authenticated) {
      window.location.assign(
        `//id.${environment.domain}/?referrer=//shop.${environment.domain}${this.router.url}`,
      );
      return;
    }
    this.buying[item.code] = this.mutation.processingOf(
      this.cartItemCreate,
      {
        input: {
          item: {
            variant: item.maker,
            code: item.code.replace(/[- \/]/g, '').toUpperCase(),
            type: CartItemTypeEnum.AutoPart,
          },
          quantity: item.quantity,
        },
      },
      {
        successTranslationKey: 'CART.ADD_TO_CART.SUCCESS',
        failureTranslationKey: 'CART.ADD_TO_CART.FAIL',
      },
    );
  }
}
