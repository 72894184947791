<section>
  <div class="img" (click)="openImage(selectedImageIndex)">
    <img [src]="images[selectedImageIndex]" />
  </div>
  <nz-image-group class="thumbs">
    <img
      *ngFor="let img of images; index as i"
      nz-image
      class="thumb"
      (mouseover)="select(i)"
      [nzSrc]="images[i]"
      [nzPlaceholder]="thumbnails[i]"
    />
  </nz-image-group>
</section>
