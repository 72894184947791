import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RakutenCategoriesComponent } from './components/rakuten-categories';
import { RakutenItemComponent } from './components/rakuten-item';
import { RakutenSearchComponent } from './components/rakuten-search';

const routes: Routes = [
  { path: '', component: RakutenCategoriesComponent },
  { path: 'item/:code', component: RakutenItemComponent },
  { path: 'search', component: RakutenSearchComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RakutenRoutingModule {}
