<section>
  <h3 nz-typography>{{ 'AUCTION.DELIVERY_CALC.DELIVERY_CALC' | translate }}</h3>
  <form nz-row nz-form [formGroup]="form" nzLayout="vertical" nzGutter="10">
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>{{ 'AUCTION.DELIVERY_CALC.CITY' | translate }}:</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="city"
            type="text"
            [placeholder]="'AUCTION.DELIVERY_CALC.CITY_PLACEHOLDER' | translate"
            (change)="calculate()"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>{{ 'AUCTION.DELIVERY_CALC.ZIP' | translate }}:</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="zip" type="text" type="number" (change)="calculate()" />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>{{ 'AUCTION.DELIVERY_CALC.INSURANCE' | translate }}:</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="insurance"
            type="number"
            maxlength="15"
            (change)="calculate()"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>{{ 'AUCTION.DELIVERY_CALC.LENGTH' | translate }}:</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="length"
            type="number"
            maxlength="15"
            (change)="calculate()"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>{{ 'AUCTION.DELIVERY_CALC.WIDTH' | translate }}:</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="width"
            type="number"
            maxlength="15"
            (change)="calculate()"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <nz-form-item>
        <nz-form-label>{{ 'AUCTION.DELIVERY_CALC.HEIGHT' | translate }}:</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="height"
            type="number"
            maxlength="15"
            (change)="calculate()"
          />
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>

  <div nz-row class="rates" nzGutter="10">
    <div *ngIf="loading" nz-col [nzXs]="24">
      <nz-spin [nzSpinning]="loading" nzSize="large">
        <div class="payload"></div>
      </nz-spin>
    </div>
    <ng-container *ngFor="let rate of rates">
      <div *ngIf="!loading" nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6" class="stat">
        <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
          <nz-descriptions-item [nzTitle]="getRateName(rate)">
            <app-currency
              [value]="rate.price"
              [exchangeRate]="exchangeRate"
              layout="vertical"
            ></app-currency>
          </nz-descriptions-item>
        </nz-descriptions>
      </div>
    </ng-container>
  </div>
</section>
