<section>
  <div nz-row nzGutter="8">
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <app-info-row label="AUCTION.LEADER" [text]="getLeader()"></app-info-row>
      <app-info-row label="AUCTION.UNTIL_END" [text]="getEndTimer()"></app-info-row>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <app-info-row label="AUCTION.BIDS" [text]="lot.lastPayload.Bids || 0"></app-info-row>
      <app-info-row
        label="AUCTION.END"
        [text]="lot.lastPayload.EndTime.trim() | date: 'long':timezone"
      ></app-info-row>
    </div>
  </div>
  <div nz-row nzGutter="8">
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <div nz-row class="trade-control-row">
        <div *ngIf="!isAuth" class="no-auth">
          {{ 'AUCTION.NO_AUTH_TEMPLATE' | translate }}
        </div>
        <div *ngIf="isCensored()" class="censored">
          {{ 'AUCTION.CENSORED' | translate }}
        </div>
        <div *ngIf="isFinal()" class="final">
          <div>
            {{ 'AUCTION.FINISH' | translate }}<br />
            {{ 'AUCTION.FINAL_PRICE' | translate }}:<br />
          </div>
          <div>
            <app-currency
              [value]="+lot.lastPayload.Price"
              [exchangeRate]="exchangeRate"
              layout="vertical"
            ></app-currency>
          </div>
        </div>
        <div
          *ngIf="canTrade() && isAllowBid()"
          nz-col
          [nzXs]="canBidOrBuy() ? 12 : 24"
          class="current-price"
        >
          <div class="padding">
            <span>{{ 'AUCTION.CURRENT_PRICE' | translate }}:</span>
            <div class="price-container">
              <app-currency
                [value]="+lot.lastPayload.Price"
                [exchangeRate]="exchangeRate"
                layout="vertical"
              ></app-currency>
            </div>
            <app-info-row
              *ngIf="isAllowBid() && getTaxinPrice()"
              [label]="'AUCTION.PRICE_WITH_TAX' | translate"
              [text]="getTaxinPrice().toLocaleString() + ' ¥'"
            ></app-info-row>
            <span>{{ 'AUCTION.YOU_BID' | translate }}:</span>
            <input
              nz-input
              type="number"
              [(ngModel)]="userBid"
              (ngModelChange)="amountChange.emit($event)"
              (change)="validateBid()"
            />
            <label nz-checkbox [(ngModel)]="deferred">
              {{ 'AUCTION.DEFFERED_BID' | translate }}
            </label>
            <div
              nz-row
              nzJustify="space-between"
              *ngIf="isAllowBid() && lot.currentUserActiveYahooBid?.amount"
            >
              <div nz-col>{{ 'AUCTION.YOU_BID2' | translate }}:</div>
              <div nz-col>
                <app-currency
                  [value]="lot.currentUserActiveYahooBid.amount"
                  [exchangeRate]="exchangeRate"
                ></app-currency>
                <span
                  *ngIf="
                    lot.currentUserActiveYahooBid.maxAmount > lot.currentUserActiveYahooBid.amount
                  "
                >
                  &nbsp;->&nbsp;
                  <app-currency
                    [value]="lot.currentUserActiveYahooBid.maxAmount"
                    [exchangeRate]="exchangeRate"
                  ></app-currency>
                </span>
              </div>
            </div>
          </div>
          <div class="put" (click)="showConfirm(false)">
            {{ 'AUCTION.PUT' | translate }}
          </div>
        </div>
        <div *ngIf="canBidOrBuy()" nz-col [nzXs]="isAllowBid() ? 12 : 24" class="bidorbuy">
          <div class="padding">
            <span>{{ 'AUCTION.BLITZ_PRICE' | translate }}:</span>
            <div class="price-container">
              <app-currency
                [value]="+lot.lastPayload.Bidorbuy"
                [exchangeRate]="exchangeRate"
                layout="vertical"
              ></app-currency>
            </div>
            <app-info-row
              *ngIf="getTaxinBuyout()"
              [label]="'AUCTION.BLITZ_WITH_TAX' | translate"
              [text]="getTaxinBuyout().toLocaleString() + ' ¥'"
            ></app-info-row>
          </div>
          <div class="buyout" (click)="showConfirm(true)">
            {{ 'AUCTION.BUY' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
      <app-info-row label="AUCTION.CONDITION" [text]="getCondition()"></app-info-row>
      <app-info-row
        label="AUCTION.SELLER"
        [text]="getSeller()"
        [routerLink]="'/seller/' + lot.lastPayload.Seller?.Id"
      ></app-info-row>
      <app-info-row label="AUCTION.SELLER_TYPE" [text]="getStoreType()"></app-info-row>
      <app-info-row label="AUCTION.RATING" [text]="getRating()"></app-info-row>
      <app-info-row
        label="AUCTION.ADDRESS"
        [text]="lot.lastPayload.Location?.trim()"
        [href]="'https://maps.google.com/maps?q=' + lot.lastPayload.Location?.trim()"
      >
      </app-info-row>
      <app-info-row
        label="AUCTION.LOT"
        [text]="lot.lastPayload.AuctionID"
        [href]="'https://page.auctions.yahoo.co.jp/jp/auction/' + lot.lastPayload.AuctionID"
      >
      </app-info-row>
      <app-info-row label="AUCTION.AVALIABLE_COUNT" [text]="lot.lastPayload.AvailableQuantity || 0">
      </app-info-row>
      <app-info-row label="AUCTION.START_PRICE" [text]="getInitPrice() + ' ¥'"> </app-info-row>
      <app-info-row
        *ngIf="isAllowBid() && lot.bidStep > 0"
        label="AUCTION.AUCTION_STEP"
        [text]="lot.bidStep + ' ¥'"
      >
      </app-info-row>
      <app-info-row
        *ngIf="lot.lastPayload.IsAutomaticExtension"
        label="AUTO_EXT"
        [text]="lot.lastPayload.IsAutomaticExtension | uppercase | translate"
      >
      </app-info-row>
      <app-info-row
        *ngIf="lot.lastPayload.IsEarlyClosing"
        label="EARLY_CLOSING"
        [text]="lot.lastPayload.IsEarlyClosing | uppercase | translate"
      >
      </app-info-row>
    </div>
  </div>
</section>
