import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TranslateModule } from '@ngx-translate/core';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { SharedModule } from '../shared/shared.module';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { YahooAuctionsRoutingModule } from './yahoo-auctions-routing.module';
import { LotsFilterComponent } from './components/lots-filter/lots-filter.component';
import { LotsComponent } from './components/lots/lots.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryComponent } from './components/category/category.component';
import { SearchComponent } from './components/search/search.component';
import { SellerComponent } from './components/seller/seller.component';
import { AuctionComponent } from './components/auction/auction.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { InfoPanelComponent } from './components/auction/components/info-panel/info-panel.component';
import { TradePanelComponent } from './components/auction/components/trade-panel/trade-panel.component';
import { ImagesComponent } from './components/auction/components/images/images.component';
import { BidOrBuyConfirmationModalComponent } from './components/auction/components/trade-panel/bid-or-buy-confirmation-modal/bid-or-buy-confirmation-modal.component';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

@NgModule({
  declarations: [
    LotsFilterComponent,
    LotsComponent,
    CategoriesComponent,
    CategoryComponent,
    SearchComponent,
    SellerComponent,
    AuctionComponent,
    InfoPanelComponent,
    TradePanelComponent,
    ImagesComponent,
    BidOrBuyConfirmationModalComponent,
  ],
  exports: [LotsComponent, CategoriesComponent],
  imports: [
    CommonModule,
    YahooAuctionsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NzImageModule,
    NzGridModule,
    NzListModule,
    NzButtonModule,
    NzCardModule,
    NzLayoutModule,
    NzPaginationModule,
    NzInputModule,
    NzEmptyModule,
    NzTableModule,
    TranslateModule,
    NzDescriptionsModule,
    NzPopconfirmModule,
    NzSelectModule,
    NzSpinModule,
    NzRadioModule,
    SharedModule,
    NzCollapseModule,
    NzBreadCrumbModule,
    NzCheckboxModule,
    NzSwitchModule,
    NzRateModule,
    NzFormModule,
    NzInputNumberModule,
    NzTagModule,
    NzIconModule,
    NzPopoverModule,
    NzAlertModule,
    NzTabsModule,
    NzTableModule,
    NzFormModule,
    NzModalModule,
    NzTypographyModule,
  ],
})
export class YahooAuctionsModule {}
