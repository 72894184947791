export const ru = {
  HEADER: {
    LOGIN: 'Войти',
    SEARCH: 'Поиск',
    EVERYWHERE: 'Везде',
    CURRENT_CATEGORY: 'В текущей категории',
    CURRENT_SELLER: 'У текущего продавца',
    BALANCE: 'Баланс',
    UNPAID_SHIPMENTS: 'Неоплаченных посылок',
    ON_SUM: 'на сумму',
    IN_CART: 'в корзине',
  },

  FOOTER: {
    CONTACTS: 'Контакты',
    ACCEPT: 'Мы принимаем',
    ADDRESS: 'Адрес',
    PHONE: 'Телефон/WhatsApp',
    WORK_SCHEDULE_TITLE: 'Режим работы офиса',
    WORK_SCHEDULE: 'С понедельника по пятницу, с 9:00 до 18:00 японского времени',
    NEWS: 'Новости',
    SERGEY: 'Сергей',
    ANNA: 'Анна',
    PAVEL: 'Павел',
    MESSAGE: 'Служба сообщений',
    VLADIVOSTOK_ADDRESS_TITLE: 'Адрес склада во Владивостоке',
    VLADIVOSTOK_ADDRESS: `г. Владивосток, ул. Бородинская 20 (Ориентир "Автомойка", здание за автомойкой, проезд слева)<br>+7 966 289-20-05`,
  },

  MENU: {
    FAVORITES: 'Избранное',
    BIDS: 'Ставки',
    ORDERS: 'Заказы',
    BALANCE: 'Баланс',
    MESSAGES: 'Сообщения',
    SHIPMENTS: 'Отправки',
    ADDRESSES: 'Адреса доставки',
    CUSTOMS_ADDRESSES: 'Таможенная информация',
    PROFILE: 'Профиль',
    NOTIFICATIONS: 'Настройка оповещений',
    SECURITY: 'Безопасность',
    LANGUAGE: 'Язык',
    LOGOUT: 'Выйти',
    CART: 'Корзина',
  },

  CATEGORIES: {
    PARTS_SEARCH: 'Поиск запчастей',
  },

  CATEGORY: {
    SUBCATS: 'Подкатегории',
    FILTER: 'Фильтр',
  },

  LOTS: {
    LOT: 'Лот',
    SELLER: 'Продавец',
    STATE: 'Состояние',
    TO_FINISH: 'До завершения',
    BIDS: 'Ставок',
    PRICE: 'Цена',
    BLITZ_PRICE: 'Блиц цена',
    ADD_TO_FAV: 'Добавить в избранное',
    REMOVE_FROM_FAV: 'Убрать из избранного',
    NEW: 'Новый',
    USED: 'Б/У',
    UNKNOWN: 'Неизвестно',
    SECOND: 'секунда',
    SECOND_TWO: 'секунды',
    SECOND_FEW: 'секунд',
    MINUTE: 'минута',
    MINUTE_TWO: 'минуты',
    MINUTE_FEW: 'минут',
    HOUR: 'час',
    HOUR_TWO: 'часа',
    HOUR_FEW: 'часов',
    DAY: 'день',
    DAY_TWO: 'дня',
    DAY_FEW: 'дней',
    COMPLETE: 'завершено',
    FAV_SUCCESS: 'Лот успешно добавлен в избранное',
    FAV_FAIL: 'Не удалось добавить лот в избранное',
    UNFAV_SUCCESS: 'Лот успешно убран из избранного',
    UNFAV_FAIL: 'Не удалось убрать лот из избранного',
    DESCRIPTION: 'Описание',
    INFO: 'Информация',
    PLUS_JP_DELIVERY: '+ доставка по Японии (см. внутри лота)',
    PRIVATE_SELLER: 'Частное лицо',
    STORE: 'Магазин',

    FILTER: {
      APPLY: 'Применить',
      RESET: 'Сбросить',
      ONLY_NEW: 'Только новые',
      ONLY_BLITZ: 'Только с блиц ценой',
      MIN_BID: 'Минимальная ставка',
      MAX_BID: 'Максимальная ставка',
      MIN_BLITZ: 'Минимальная блиц-цена',
      MAX_BLITZ: 'Максимальная блиц-цена',
      MIN_PRICE: 'Минимальная цена',
      MAX_PRICE: 'Максимальная цена',
      SHIPPING: 'Оплата доставки',
      SHIPPING_COD: 'За счет покупателя',
      SHIPPING_SELLER: 'За счет продавца',
      SHIPPING_OPTIONS: 'Способы доставки',
      SHIPPING_ANONYM: 'Анонимная доставка',
      SHIPPING_BY_POST: 'Почтой',
      SHIPPING_NO_OPTION: 'Нет доступных опций',
      FREE_SHIPPING: 'Бесплатная доставка по Японии',
      STARS_DIVISION: 'Рейтинг',
      CATEGORY: 'Категория',
      COLOR: 'Цвет',
      BRAND: 'Бренд',
      KEYWORDS: 'Ключевые слова',
      EXCLUDE_KEYWORDS: 'Исключить слова',
      PRICE_RANGE: 'Диапазон цен',
      PRICE_TYPE: 'Тип цены',
      PRICE_TYPE_ELIGIBLE: 'Товары, на которые можно вернуть баллы',
      PRICE_TYPE_DISCOUNTED: 'Товары со скидкой',
      PRICE_TYPE_GENERAL: 'Общие товары',
      SIZE: 'Размер',
      STATUS: 'Статус',
      STATUS_SALE: 'Распродажа',
      STATUS_SOLDOUT: 'Товар закончился',
      CONDITION: {
        CONDITION: 'Состояние',
        DOESNT_MATTER: 'Не имеет значения',
        NEW: 'Только новые',
        USED: 'Бывшие в употреблении',
      },
      SELLER: {
        SELLER: 'Продавец',
        DOESNT_MATTER: 'Не имеет значения',
        STORE: 'Магазин',
        USER: 'Физ. лицо',
      },
      SORT: {
        SORT: 'Сортировка',
        DOESNT_MATTER: 'Не имеет значения',
        PRICE_UP: 'По цене вверх',
        PRICE_DOWN: 'По цене вниз',
        BLITZ_UP: 'По блиц-цене вверх',
        BLITZ_DOWN: 'По блиц-цене вниз',
        END_UP: 'По времени окончания торгов вверх',
        END_DOWN: 'По времени окончания торгов вниз',
        UPDATE_DATE: 'По-умолчанию',
        ARRIVAL_DATE: 'Новые поступления',
        CONDITION_UP: 'По состоянию вверх',
        CONDITION_DOWN: 'По состоянию вниз',
      },
    },
  },

  SEARCH: {
    SEARCH: 'Поиск',
  },

  HOME: {
    MORE: 'еще',
    ALL_CATEGORIES: 'Все категории Yahoo Auctions',
    NEWS_IN: 'Новости в',
    NEWS: 'Новости',
  },

  PARTS: {
    PARTS_SEARCH: 'Поиск запчастей',
    P_1: 'Для поиска запчастей необходимо ввести модель кузова и выбрать из списка запчасти для поиска.',
    P_2: 'Модель кузова, буквы и цифры номера кузова до тире, например:',
    VIN: 'Модель кузова',
    FIND: 'Найти',
  },

  NEWS: {
    NEWS: 'Новости',
    NAME: 'Название',
    ALL: 'Все новости',
    DATE: 'Дата публикации',
    AUCTION: 'Аукцион Yahoo',
  },

  NOT_FOUND: {
    NOT_FOUND: 'К сожалению, страница, которую вы посетили, не существует.',
    GO_HOME: 'Вернуться на главную',
  },

  AUCTION: {
    LEADER: 'Лидер',
    UNTIL_END: 'До окончания торгов',
    NO_AUTH_TEMPLATE: 'Пожалуйста, авторизируйтесь для участия в торгах.',
    CENSORED: 'В данном лоте ставки не принимаются',
    FINISH: 'Торги завершены',
    FINAL_PRICE: 'Конечная цена',
    AUTH: 'авторизируйтесь',
    BIDS: 'Ставок',
    END: 'Окончание торгов',
    CONDITION: 'Состояние',
    SELLER_TYPE: 'Тип продавца',
    NEW: 'Новый',
    USED: 'Б/У',
    SELLER: 'Продавец',
    RATING: 'Рейтинг',
    ADDRESS: 'Адрес',
    LOT: 'Лот',
    AVALIABLE_COUNT: 'Доступное количество',
    START_PRICE: 'Начальная цена',
    AUCTION_STEP: 'Шаг торгов',
    ADD_TO_FAV: 'Добавить в избранное',
    REMOVE_FROM_FAV: 'Убрать из избранного',
    ADD_FAV_SUCCESS: 'Лот успешно добавлен в избранное',
    ADD_FAV_FAIL: 'Не удалось добавить лот в избранное',
    REMOVE_FAV_SUCCESS: 'Лот успешно убран из избранного',
    REMOVE_FAV_FAIL: 'Не удалось удалить лот из избранного',
    UPDATE: 'Обновить',
    CURRENT_PRICE: 'Текущая цена',
    BLITZ_PRICE: 'Блиц-цена',
    YOU_BID: 'Ваша ставка, ¥',
    YOU_BID2: 'Ваша ставка',
    DEFFERED_BID: 'Отложенная ставка',
    BUY: 'Купить',
    PUT: 'Поставить',
    YOU: 'Вы',
    PRICE_WITH_TAX: 'Цена c налогом',
    BLITZ_WITH_TAX: 'Блиц-цена с налогом',
    VALIDATION_FAIL: 'Некоторые поля заполнены некорректно',
    BID_CREATE_SUCCESS: 'Ставка успешно создана',
    BID_UPDATE_SUCCESS: 'Ставка успешно обновлена',
    BID_CREATE_FAIL: 'Не удалось сделать ставку',
    BID_UPDATE_FAIL: 'Не удалось обновить ставку',
    LOT_BUY_SUCCESS: 'Лот успешно куплен',
    LOT_BUY_FAIL: 'Не удалось купить лот',
    PRICE_ERROR: 'Цена не может быть меньше текущей',
    CONFIRM: {
      CONFIRM_BID: 'Подтвердите ставку',
      CONFIRM_BUY: 'Подтвердите покупку',
      SUM: 'Сумма',
      DEFFERED_BID: 'Отложенная ставка',
      COUNT: 'Количество',
      COMMENT: 'Комментарий для менеджера',
      I_AGREE: 'Я согласен',
      AGREEMENT: `Внимание!

        Этот лот вызывает подозрение.
        Вы можете сделать ставку под свою ответственность, либо связаться с менеджером компании.

        Если вы делаете ставку без одобрения менеджера, вы берёте на себя ответственность оплатить товар с риском не получить данный товар или понести дополнительные расходы.
      `,
    },
    COST_CALC: {
      COST_CALC: 'Расчет стоимости',
      YOU_BID: 'Ваша ставка, ¥',
      WEIGHT: 'Вес с упаковкой, кг',
      JP_DELIVERY: 'Укажите доставку по Японии, ¥',
      SUGGESTED: 'Предположительно',
      SUGGESTED_HINT:
        'Стоимость указана приблизительная. Более точная информация обычно указывается в таблице доставки в описании лота. Стоимость необходимо смотреть до Тоямы(富山), Хокурику, Хонсю',
      RATE: 'Тариф',
      WEIGHT_PLACEHOLDER: 'Выбрать вес',
      RATE_PLACEHOLDER: 'Выбрать тариф',
      COMPANY_COMMISION: 'Комиссия компании, ¥',
      COAS_IN_JP: 'Стоимость в Японии, ¥',
      DELIVERY_TO_VDK: 'Доставка до Владивостока, ¥',
      COAST_IN_VDK: 'Cтоимость во Владивостоке, ¥',
    },
    DELIVERY_CALC: {
      DELIVERY_CALC: 'Расчёт доставки по РФ',
      CITY: 'Укажите город доставки',
      CITY_PLACEHOLDER: 'Москва',
      ZIP: 'Укажите почтовый индекс (обязательно)',
      INSURANCE: 'Укажите страховку доставки, ₽',
      LENGTH: 'Укажите длину, см (обязательно)',
      WIDTH: 'Укажите ширину, см (обязательно)',
      HEIGHT: 'Укажите высоту, см (обязательно)',
    },
    TRANSLATE: 'Перевод',
    DESCRIPTION: 'Описание',
    QA: {
      QA: 'Вопросы продавцу',
      ASK_SELLER: 'Задать вопрос продавцу',
      ASK: 'Спросить',
      QUESTION: 'Вопрос',
      SELECT_QUESTION: 'Выберите вопрос',
      QUESTION_FROM: 'Вопрос от',
      ANSWER: 'Ответ продавца',
      UNANSWERED_QUESTION: 'Вопрос ожидает ответа',
      ASK_VIA_MANAGER: 'Задать свой вопрос через консультанта',
      ENTER_QUESTION_TEXT: 'Введите текст вопроса',
      TEXT: 'Текст',
      AUTH: 'Чтобы задать вопрос необходимо авторизоваться',
      NO_TEXT: 'Заполните поле текста вопроса',
      QUESTION_SUCCESS: 'Вопрос успешно отправлен',
      QUESTION_FAIL: 'Не удалось отправить вопрос',
    },
    BIDS_HISTORY: {
      BIDS_HISTORY: 'История ставок',
      TIME: 'Время',
      LOGIN: 'Логин',
      BID: 'Ставка',
      AUCTION_START: 'Начало торгов',
      BID_CANCEL: 'Отмена ставки',
    },
  },

  PRICE: 'Цена',
  PRICE_WITH_TAX: 'Цена с налогом',
  JP_DELIVERY: 'Доставка по Японии',
  JP_DELIVERY_UNKNOWN: 'Рассчитывается после заказа',
  PRODUCT: 'Товар',

  ACTIONS: {
    ERROR: 'Ошибка',
    CONTINUE: 'Продолжить',
    CANCEL: 'Отменить',
    SAVE: 'Сохранить',
    BUY: 'Купить',
    DELETE: 'Удалить',
    ADD_TO_CART: 'Добавить в корзину',
    CREATE_ORDER: 'Создать заказ',
    ARE_YOU_SURE: 'Уверены?',
    BUY_NOW: 'Купить сейчас',
    SEND: 'Отправить',
  },
  JAPAN_COUNTRY_NAME: 'Япония',
  ERROR: 'Ошибка',
  APP: {
    MENU_TITLE: 'Меню',
    COUNTRY_CITY_HEADER: 'Япония, г. Тояма',
    MAIN_TITLE: 'Главная',
    VERIFICATION: 'Получить доступ к аукциону',
    YAHOO_AUCTIONS_TITLE: 'Аукционы Yahoo',
    SIGN_OUT_TITLE: 'Выйти',
  },
  VERIFICATION: {
    VERIFICATION: 'Верификация',
    SUBMIT: 'Отправить запрос',
    REQUEST_ACCEPTED: 'Заявка принята',
    RESET_ADDRESS: 'Сбросить адрес',
    SELECT_ADDRESS_LABEL: 'Вы можете выбрать один из Ваших адресов или указать новый',
    REQUEST_ACCEPTED_BODY: 'Ваша заявка принята, ожидайте ответа от модератора',
    LABELS: {
      PFOFILE_DATA: 'Данные профиля',
      ADDRESS_DATA: 'Ваш адрес',
      LAST_NAME: 'Фамилия',
      FIRST_NAME: 'Имя',
      MIDDLE_NAME: 'Отчество',
      EMAIL: 'E-mail',
      SKYPE: 'Скайп',
      USER_COMMENT: 'Комментарий',
      COUNTRY: 'Страна',
      CITY: 'Город',
      ZIP: 'Почтовый индекс',
      ADDRESS_1: 'Адрес',
      ADDRESS_2: 'Доп. адрес',
      ADDRESS_PHONE: 'Телефон',
      COUNTRY_HINT: 'Выберите страну',
      IMPORT_DIRECTION: 'Cтрана импорта',
    },
    ERRORS: {
      LAST_NAME: 'Фамилия',
      FIRST_NAME: 'Имя',
      EMAIL: 'E-mail',
      SKYPE: 'Skype',
      USER_COMMENT: 'Комментарий',
      COUNTRY: 'Страна',
      CITY: 'Город',
      ZIP: 'Почтовый индекс',
      ADDRESS_1: 'Адрес',
      ADDRESS_PHONE: 'Телефон',
      IMPORT_DIRECTION: 'Выберите страну импорта',
    },
  },
  IMPORT_DIRECTION: {
    RUSSIA: 'В Россию',
    OTHER: 'В другие страны',
  },
  ORDERS: {
    LIST_AKEBONO_LOTS: 'Список купленных лотов',
    LIST_ALL_OBJECTS: 'Список всех объектов',
    SHOW_ALL_OBJECTS: 'Показать все объекты',
    AUC_DATE: 'Дата аукциона',
    AUCTION: 'Аукцион',
    LOT: 'Содержимое лота',
    VESSEL: 'Судно',
    STORAGE: 'Склад',
    STATUS: 'Статус',
    OBJECT: 'Объект',
    SENDER: 'Отправитель',
    BROKER: 'Брокер',
    INVOICE: 'Инвойс',
    DOWNLOAD_INVOICE: 'Скачать инвойс',
  },
  TOTAL: 'Всего',
  BALANCE: {
    DEPOSIT: 'Пополнить баланс',
    DATE: 'Дата',
    AMOUNT: 'Сумма',
    DESCRIPTION: 'Описание',
    STATE: 'Состояние счета',
    BALANCE_DESCRIPTION: {
      BONUS_BALANCE: 'Бонусы',
      BONUS_APPLIED: 'Применено бонусов',
      TOTAL_BALANCE: 'Баланс',
      HOLD: 'Заблокировано',
    },
    FILTERS: {
      FILTERS: 'Фильтры',
      SHOW: 'Показать',
      AMOUNT_FROM: 'Сумма от',
      AMOUNT_TO: 'Сумма до',
      DATE: 'Дата операции',
      SEARCH: 'Поиск',
      APPLY: 'Применить',
      RESET: 'Сбросить',
    },
    CODES: {
      ALL: 'Все',
      DEPOSIT: 'Пополнения',
      PAY: 'Pay',
      PLACE: 'Ставки',
      RAISE: 'Повышение ставки',
      REFUND: 'Возвраты',
      UNHOLD: 'Разблокировки',
      INVOICE: 'Расходы',
    },
    DEPOSIT_TYPE: 'Способ пополнения',
    BANK_TRANSFER: 'Банковский перевод',
    BANK_TRANSFER_HINT: 'Комиссия зависит от условий Вашего банка',
    PAYPAL_HINT: 'Комиссия: {0}% + {1}¥',
    STRIPE_HINT: 'Комиссия: {0}%',
    WITH_COMISSION: 'С комиссией, примерно',
    CAN_DEPOSITE: 'Для продолжения, необходимо заполнить ФИО в профиле.',
    GO_TO_PROFILE: 'Перейти в профиль',
    PAYMENT_SUCCESS: 'Платеж прошел успешно',
    PAYMENT_FAIL: 'Не удалось пополнить счет',
    PAYMENT_INFO: 'Не работает с картами выпущенными банками РФ',
    PAYPAL: {
      PAYPAL: 'Пополнение счета через систему PayPal',
      PARAGRAPH_1:
        'Обратите внимание, что оплата данным способом доступна только зарегистрированным пользователям системы PayPal.',
      PARAGRAPH_2:
        'О том, какие преимущества Вы получите при регистрации в системе PayPal , Вы можете узнать на <a href="https://www.paypal.com/" target="_blank">официальном сайте PayPal</a>',
      PARAGRAPH_3:
        'Есть ограничения по сумме платежа. Вы можете узнать об этом подробнее <a>здесь</a>',
    },
    STRIPE: {
      STRIPE: 'Пополнение счета банковской картой',
      PARAGRAPH_1:
        'Данный способ оплаты позволяет оплачивать суммы без заведения каких-либо аккаунтов в системе Stripe.',
      PARAGRAPH_2: 'Рекомендуется для платежей с банковских карт небольшими суммами.',
      PARAGRAPH_3:
        'Обращаем Ваше внимание, что использование чужих банковских карт является преступлением и преследуется по закону.',
      SUCCESS: 'Счет успешно пополнен',
      FAIL: 'Не удалось пополнить счет',
    },
    BANK: {
      BANK: 'Пополнение счета банковским переводом',
      PARAGRAPH_1: 'Данный способ оплаты позволяет оплачивать крупные суммы.',
      PARAGRAPH_2:
        'Рекомендуется для крупных платежей большими суммами или если Ваш объем платежей в течение одного календарного месяца превышает разрешенный максимальный месячный объем платежей с банковской карты.',
      PARAGRAPH_3:
        'Срок зачисления перевода на банковский счет может составлять до 10 банковских дней.',
      INSTRUCTION: 'Инструкция',
      INSTRUCTION_HINT: 'Она поможет разобраться в заполнении банковской квитанции перевода.',
      PAYER: 'Плательщик',
      PURPOSE: 'Назначение платежа',
      PURPOCE_TEMPLATE: 'auction bidding deposit (аукционный депозит)',
      PRINT: 'Распечатать',
    },
  },

  FAVORITES: {
    FAVORITES: 'Избранное',
    ACTIVE: 'Текущие аукционы',
    END: 'Закончившиеся',

    LOTS: {
      LOT: 'Лот',
      SELLER: 'Продавец',
      STATE: 'Состояние',
      TO_FINISH: 'До завершения',
      BIDS: 'Ставок',
      PRICE: 'Цена',
      BLITZ_PRICE: 'Блиц цена',
      REMOVE_FROM_FAV: 'Убрать из избранного',
      NEW: 'Новый',
      USED: 'Б/У',
      UNKNOWN: 'Неизвестно',
      SECOND: 'секунда',
      SECOND_TWO: 'секунды',
      SECOND_FEW: 'секунд',
      MINUTE: 'минута',
      MINUTE_TWO: 'минуты',
      MINUTE_FEW: 'минут',
      HOUR: 'час',
      HOUR_TWO: 'часа',
      HOUR_FEW: 'часов',
      DAY: 'день',
      DAY_TWO: 'дня',
      DAY_FEW: 'дней',
      COMPLETE: 'завершено',
      UNFAV_SUCCESS: 'Лот успешно убран из избранного',
      UNFAV_FAIL: 'Не удалось убрать лот из избранного',
    },
  },

  BIDS: {
    BIDS: 'Ставки',
    NO_NAME: 'Нет названия',
    STATUS: 'Статус',
    BLOCKED: 'Заблокирован',
    BEFORE: 'до',
    ORDER: 'Заказ',
    CANCEL: 'Отменить',
    DELETE_SUCCESS: 'Ставка успешно отменена',
    DELETE_FAIL: 'Не удалось отменить ставку',
    BID_STATUS: {
      BEST: 'Лидирующая',
      CANCELLED: 'Отменена',
      COMPLETED: 'Завершена',
      FAILED: 'Не удалась',
      INITIAL: 'Создана',
      OVERBID: 'Перебита',
      WON: 'Победила',
      DEFERRED: 'Отложенная',
      AWAITING_MANUAL_PURCHASE: 'Ожидается покупка менеджером',
    },
  },

  YAHOO_ORDERS: {
    REQUEST: 'Оставить заявку',
    CREATED_AT: 'Дата создания',
    STATUS: 'Статус',
    PRODUCTS: 'Товаров',
    ACCRUED: 'Начислено',
    NO_NAME: 'Без названия',
    LOT: 'Лот',
    QUANTITY: 'Количество',
    TITLE: 'Название',
    STATUSES: {
      UNKNOWN: 'Неизвестен',
      initial: 'В очереди на обработку',
      negotiations: 'Переговоры с продавцом',
      arriving_jp: 'Ожидание прихода на склад',
      declarant_review_jp: 'Ожидание прихода на склад',
      stockman_review_jp: 'Ожидание прихода на склад',
      customer_review: 'На складе в Японии',
      in_shipment: 'Транспортировка',
      issued: 'Выдан',
      failed: 'Ошибка',
      canceled: 'Отменен',
      utilized: 'Утилизирован',
      in_stock_vl: 'На складе во Владивостоке',
    },
    FILTER: {
      FILTER: 'Фильтр',
      SEARCH: 'Поиск',
      SHOW: 'Показать',
      CREATED_FROM: 'Дата создания от',
      CREATED_TO: 'Дата создания до',
      APPLY: 'Применить',
      RESET: 'Сбросить',
      ALL: 'Все',
    },
  },

  ORDER_CREATE: {
    LINK: 'Адрес товара',
    TITLE: 'Наименование',
    QUANTITY: 'Количество',
    SEND: 'Отправить',
    ADD: 'Добавить позицию',
    VALIDATION_FAIL: 'Некоторые поля заполнены некорректно',
    SEND_SUCCESS: 'Заявка успешно отправлена',
    SEND_FAIL: 'Не удалось отправить заявка',
  },

  YAHOO_ORDER: {
    ITEMS: 'Товары',
    ACCRUED: 'Начисления',
    IMAGES: 'Изображения лота',
    FILES: 'Файлы',
    USE_BONUS: 'Использовать бонусы для оплаты комиссии',
    BONUS_INFO: 'Вы можете использовать бонусы для оплаты комисси каждого инвойса',
    USE_BONUS_TEMPLATE: 'У вас {0} бонусных ¥. Общая комиссия {1}. Всего применено {2}¥ бонусов.',
    BONUS_USED_TEMPLATE: 'Использовано {0} бонусов',
    COMMISSION: 'Комиссия',
    APPLY: 'Применить',
    TOTAL_JP: 'Итого по Японии',
    DELIVERY_JP: 'Оплата отправки из Японии',
    TOTAL: 'Итого по заказу',
    SEND: 'Отправить',
    COMMENT: 'Комментарий',
    TITLE: 'Название',
    JP_TITLE: 'Японское название',
    WEIGHT: 'Вес, кг',
    QUANTITY: 'Количество',
    PRICE_JP: 'Цена, ¥',
    INVOICE: 'Инвойс',
    PRICE: 'Цена',
    ITEM: 'Товар',
    TAX: 'Налог',
    BONUSES: 'Бонусы',
    APPLY_SUCCESS: 'Бонус успешно применен',
    APPLY_FAIL: 'Не удалось применить бонус',
  },

  CHATS: {
    CHATS: 'Чаты',
    POST_QA: 'Задать вопрос продавцу',
    LOT_CODE: 'Код лота',
    CHAT_WITH: 'Чат с',
    CHAT_WITH_USER: 'Чат с пользователем',
    UNANSWERED: 'Неотвеченные сообщения',
    RECENT: 'Недавние сообщения',
    UPDATE_SUCCESS: 'Сообщения успешно обновлены',
    UPDATE_FAIL: 'Не удалось обновить сообщения',
    CREATE_SUCCESS: 'Сообщение успешно создано',
    CREATE_FAIL: 'Не удалось создать сообщение',
    CLIENT: 'Клиент',
    MESSAGE: 'Сообщение',
    THEME: 'Тема',
    LAST_MESSAGE: 'Последнее сообщение',
    LAST_THEME: 'Последняя тема',
    DATE: 'Дата',
    READ: 'Прочитано',
    UNREAD: 'Не прочитано',
    EDIT: 'Редактировать',
    SEND: 'Отправить',
    SAVE: 'Сохранить',
    CANCEL: 'Отмена',
    TEMPLATE: 'Шаблон',
    DRAG: 'Щелкните или перетащите файл в эту область, чтобы прикрепить его',
    CONTENT: 'Текст сообщения',
    VALIDATION_FAIL: 'Некоторые поля заполнены неверно',
  },

  SHIPMENTS: {
    CREATE: 'Создать',
    SHIPMENT: 'Отправка',
    CREATED_AT: 'Дата создания',
    STATUS: 'Статус',
    ORDERS: 'Заказы',
    PAYED: 'Оплачено',
    TO_PAY: 'К оплате',
    NO_DEFAULT_ADDRESS:
      'Для автоматического создания отправок необходимо наличие адреса доставки, выбираемого по-умолчанию.<br />Выбрать адрес можно в настройках пользователя в разделе:',
    STATUSES: {
      packing: 'Упаковка',
      awaiting_payment: 'Ожидает оплаты',
      planing: 'Отправка',
      planed: 'Упакован',
      shipped: 'В пути',
      ready: 'Прибыл',
      issued: 'Выдан',
      failed: 'Ошибка',
      canceled: 'Отменен',
      utilized: 'Ошибка',
    },
    DEFAULT_DELIVERY_SERVICE:
      'Служба доставки для автоматически создаваемых отправок по России по-умолчанию',
  },

  SHIPMENT: {
    SHIPMENT: 'Отправка',
    COMMENT: 'Комментарий',
    ORDERS: 'Заказы',
    CHARGES: 'Начисления',
    TRACKING_NUMBER: 'Номер отслеживания',
    DELIVERY_SERVICE: 'Служба доставки',
    ISSUED: 'Отправление получено',
    ISSUED_SUCCESS: 'Действие успешно выполнено',
    ISSUED_FAIL: 'Не удалось выполнить действие',
    NAME: 'ФИО',
    ORDER: 'Заказ',
    UNSPECIFIED: 'Не указан',
    PHONE: 'Номер телефона',
    ADDRESS: {
      ADDRESS: 'Адрес',
      PASSPORT: 'Паспорт',
      CITY: 'Город',
      POSTAL_CODE: 'Почтовый индекс',
      PICKUP: 'Самовывоз',
      NO_ADDRESSES: 'Нет адресов',
      SELECT: 'Выбрать',
    },
    CUSTOMS: {
      CUSTOMS: 'Таможеная информация',
      PASSPORT_ISSUE: 'Паспорт выдан',
      SEX: 'Пол',
      REGISTRATION_ADDRESS: 'Адрес регистрации',
      INN: 'ИНН',
      FILES: 'Файлы',
      EMPTY: 'Не указана',
    },
  },

  SHIPMENT_CREATE: {
    SHIPMENT_CREATE: 'Создание отправления',
    NEXT: 'Продолжить',
    BACK: 'Назад',
    STEP: 'Шаг',
    CREATE: 'Создать',
    SELECT: 'Выбрать',
    UNSELECT: 'Убрать',
    ORDER: 'Заказ',
    DELIVERY_SERVICE: 'Служба доставки',
    RECIVE_METHOD: 'Метод получения',
    TOTAL_WEIGHT: 'Общий вес',
    KG: 'кг',
    WEIGHT: 'Вес',
    COMMENT: 'Комментарий к доставке',
    CUSTOMS: 'Выберете таможенную информацию',
    ADDRESS: 'Выберете адрес доставки',
    ADDRESS_ID: 'Адрес #{0}',
    SELECTED: 'Выбран',
    DISABLED: 'Недоступен',
    WARNING_TEXT: 'Не заполнены паспортные данные и/или ИНН',
    UPDATE: 'Заполнить',
    NAME: 'Имя',
    SELECT_DELIVERY_SERVICE: 'Выберите почтовую службу',
    VALIDATION_ERROR: 'Некоторые поля не заполнены или заполнены неверно',
    CREATE_SUCCESS: 'Отправление успешно создано',
    CREATE_FAIL: 'Не удалось создать отправление',
    STEP_1_DESCRIPTION: 'Выберите службу доставки',
    STEP_2_DESCRIPTION: 'Выберите заказы для отправки',
    STEP_3_DESCRIPTION: 'Выберите тарифы отправки товаров',
    STEP_4_DESCRIPTION: 'Выберите способ получения',
    STEP_5_DESCRIPTION: 'Подтверждение',
    METHODS: {
      pickup: 'Самовывоз',
      address: 'Доставка по адресу',
    },
  },

  ADDRESSES: {
    DELETE_SUCCESS: 'Адрес успешно удален',
    DELETE_FAIL: 'Не удалось удалить адрес',
    UPDATE_SECCESS: 'Адрес успешно обновлен',
    UPDATE_FAIL: 'Не удалось обновить адрес',
    CREATE_SUCCESS: 'Адрес успешно создан',
    CREATE_FAIL: 'Не удалось создать адрес',
    ADDRESS_CREATE: 'Создать новый адрес',
    ADDRESS_UPDATE: 'Обновить адрес',
    DELETE: 'Удалить',
    CHANGE: 'Изменить',
    CREATE: 'Создать',
    SAVE: 'Сохранить',
    ADD: 'Добавить адрес',
    SURE: 'Вы уверены?',
    FIRST_NAME: 'Имя',
    LAST_NAME: 'Фамилия',
    MIDDLE_NAME: 'Отчество',
    PASSPORT_SECTION: 'Паспортные данные для таможенного оформления',
    PASSPORT_SERIAL: 'Серия паспорта',
    PASSPORT_NO: 'Номер паспорта',
    PASSPORT_ISSUE_DATE: 'Дата выдачи',
    PASSPORT_ISSUER: 'Кем выдан',
    PASSPORT_DATEOFBIRTH: 'Дата рождения',
    COUNTRY: 'Страна',
    STATE: 'Область',
    CITY: 'Город',
    ADDRESS: 'Адрес',
    ZIP: 'Почтовый индекс',
    PHONE: 'Номер телефона',
    COUNTRY_PLACEHOLDER: 'Выберите страну',
    STATE_PLACEHOLDER: 'Выберите область',
    CITY_PLACEHOLDER: 'Выберите населённый пункт',
    INN_PLACEHOLDER: 'Введите значение',
    FROM_INVALID: 'Некоторые поля формы заполнены некорректно',
    DEFAULT: 'Адрес по-умолчанию для автоматически создаваемых отправок',
  },

  CUSTOMS_ADDRESSES: {
    DELETE: 'Удалить',
    CHANGE: 'Изменить',
    CREATE: 'Создать',
    SAVE: 'Сохранить',
    ADD: 'Добавить информацию',
    SURE: 'Вы уверены?',
    DELETE_SUCCESS: 'Информация успешно удалена',
    DELETE_FAIL: 'Не удалось удалить информация',
    UPDATE_SECCESS: 'Информация успешно обновлена',
    UPDATE_FAIL: 'Не удалось обновить информацию',
    CREATE_SUCCESS: 'Информация успешно создана',
    CREATE_FAIL: 'Не удалось создать информацию',
    ADDRESS_CREATE: 'Создать новую таможенную информацию',
    ADDRESS_UPDATE: 'Редактирование таможенной информации',
    SEX: 'Пол',
    BIRTH_DATE: 'Дата рождения',
    BIRTH_PLACE: 'Место рождения',
    PASSPORT: 'Серия номер паспорта',
    ISSUE_DATE: 'Дата выдачи паспорта',
    ISSUER: 'Кем выдан',
    REGISTRATION: 'Адрес прописки',
    INN: 'ИНН',
    FILES: 'Копия паспорта (две страницы), копия ИНН',
    MALE: 'Мужчина',
    FEMALE: 'Женщина',
  },

  PROFILE: {
    LAST_NAME: 'Фамилия',
    FIRST_NAME: 'Имя',
    MIDDLE_NAME: 'Отчество',
    SKYPE: 'Skype',
    TIMEZONE: 'Часовой пояс',
    SAVE: 'Сохранить',
    E_MAIL: 'E-mail',
    PHONE: 'Номер телефона',
    GENERAL_INFORMATION: 'Общая информация',
    ADDITIONAL_INFORMATION: 'Дополнительная информация',
    SUCCESS: 'Профиль успешно обновлен',
    FAIL: 'Не удалось обновить профиль',
    VERIFY_EMAIL: 'Подтвердить e-mail',
    VERIFY_PHONE: 'Подтвердить телефон',
    INVALID: 'Некоторые поля заполнены некорректно',
    TIMEZONE_PLACEHOLDER: 'Выберите часовой пояс',
  },

  VERIFY_MODAL: {
    PHONE: 'Подтверждение телефонного номера',
    EMAIL: 'Подтверждение почты',
    CONFIRMATION_CODE: 'Код подтверждения',
    CONFIRM: 'Подтвердить',
    SEND: 'Отправить код',
    SEND_SUCCESS: 'Код успешно отправлен',
    SEND_FAIL: 'Не удалось отправить код',
    CONFIRM_SUCCESS: 'Подтверждение прошло успешно',
    CONFIRM_FAIL: 'Не удалось получить подтверждение',
    INVALID: 'Поле код заполнено некорректно',
  },

  NOTIFICATION_SETTINGS: {
    CHANGE_STATUS: 'Изменение статуса',
    FINANCES: 'Финансы',
    ADDITIONAL_INFORMATION: 'Дополнительная информация',
    order_status: 'Заказ',
    shipment_status: 'Отправка',
    finances_incomes: 'Приходные операции',
    finances_spends: 'Расходные операции',
    finances_holds: 'Блокировка денежных средств',
    platform_news: 'Новости',
    platform_events: 'Акции',
    platform_advertising: 'Материалы рекламного характера',
    FAIL: 'Не удалось установить настройку',
    SUCCESS: 'Настройка успешно установлена',
  },

  SECURITY: {
    CHANGE_PASS: 'Изменить пароль',
    OLD_PASS: 'Старый пароль',
    NEW_PASS: 'Новый пароль',
    CONFIRM_PASS: 'Подтвердить новый пароль',
    SAVE: 'Сохранить',
    SUCESS: 'Пароль успешно сохранен',
    FAIL: 'Не удалось сохранить пароль',
    INVALID: 'Некоторые поля заполнены некорректно',
    MISMATCH: 'Пароли не совпадают',
  },

  SHOPPING: {
    SEARCH: 'Поиск',
    PRICE_FROM: 'Минимальная цена',
    PRICE_TO: 'Максимальная цена',
    SORT: 'Сортировка',
    CONDITION: 'Состояние',
    DISCOUNT: 'Скидка',
    IN_STOCK: 'Наличие',
    DM: 'Не имеет значение',
    NEW: 'Только новые',
    USED: 'Бывшие в употреблении',
    WITH_DISCOUNT: 'Со скидкой',
    WITHOUT_DISCOUNT: 'Без скидки',
    IN_STOCK_YES: 'В наличии',
    IN_STOCK_NO: 'Нет в наличии',
    SORT_SCORE_DSC: 'По убыванию рейтинга',
    SORT_REVIEW_DSC: 'По отзывам',
    SORT_PRICE_DSC: 'По убыванию цены',
    SORT_PRICE_ASC: 'По возрастанию цены',
    ORDER_CREATED: 'Заказ создан',
    ORDER_FAILED: 'Ошибка при создании заказа',
    VARIANT: 'Вариант',
  },

  CART: {
    CART: 'Корзина',
    ITEMS_IN_CART: 'В корзине {{quantity}}',
    ORDER_CREATE: {
      ORDER_CREATE: 'Оформить заказ',
      REQUEST_CREATE: 'Создать запрос',
      SUCCESS: 'Заказ успешно создан',
      FAIL: 'Не удалось создать заказ',
    },
    ADD_TO_CART: {
      SUCCESS: 'Продукт успешно добавлен в корзину',
      FAIL: 'Не удалось добавить лот в корзину',
    },
    UPDATE: {
      SUCCESS: 'Корзина успешно обновлена',
      FAIL: 'Не удалось обновить корзину',
    },
    STORES: {
      YahooProduct: 'Yahoo Shopping',
      RakutenProduct: 'Rakuten',
      AutoPart: 'Автозапчасти',
      ShopCatalogProduct: 'Супермаркет',
      MercariProduct: 'Mercari',
      MercariBeyondProduct: 'Mercari Beyond',
    },
    LABELS: {
      ITEM: 'Продукт',
      PRICE: 'Цена',
      QUANTITY: 'Количество',
    },
  },
  ORIGINAL_AUTO_PARTS: 'Оригинальные автозапчасти',
  MAKER: 'Производитель',
  CODE: 'Код',
  STATUS: 'Статус',
  SOLD: 'Продано',
  ACTIVE: 'Активно',
  SEARCH_OEM_PARTS: 'Введите номер запчасти',
  DETAILS: 'подробнее',
  SELECT_MAKER: 'Выберите производителя',
  TO_BE_DEFINED: 'по запросу',
  CURRENCY_HINT: 'Расчет произведен по курсу',
  AUTO_EXT: 'Автопродление',
  EARLY_CLOSING: 'Досрочное завершение',
  TRUE: 'Да',
  FALSE: 'Нет',
  DEFAULT: 'По умолчанию',
  SELECT: 'Выбрать',
  TEMPLATE_FIELDS: {
    WANTED_PRICE: 'Желаемая цена',
  },
  SHOP_CATALOG: 'Супермаркеты',

  SERVICES: {
    akbYahooFee: 'Akebono comission',
    akbBankFee: 'Bank comission',
    akbParcelRepack: 'Packing',
    akb400Delivery: 'AKB 400',
    akb450Delivery: 'AKB 450',
    akb1000Delivery: 'AKB Fast 1000',
    akbPersonal400Delivery: 'Personal purchases 400',
    akbWheelsDelivery: 'Wheels delivery',
    akbEnginesDelivery: 'Engine delivery',
    akbEMSDelivery: 'EMS mail',
    akbYahooOrderDelivery: 'Seller delivery payment',
    akbYahooOrderDeliverySagawa: 'In-Japan delivery. Sagawa',
    akbYahooOrderDeliveryYamato: 'In-Japan delivery. Yamato',
    akbYahooOrderDeliveryOther: 'In-Japan delivery. Other',
    akbYahooOrderPayment: 'Purchase price',
    akbOrderDeliveryGeldor: 'Jeldor',
    akbOrderDeliveryEnergy: 'Energiya',
    akbOrderDeliveryPEK: 'PEK',
    akbYahooOrderDeliveryPickup: '"Self-pickup" delivery',
    akbLargeDelivery: 'Oversized cargo',
    akbYahooOrderDeliveryFree: 'Free delivery',
    akbOrderDeliveryDelLin: 'Delovye linii',
    akb500Delivery: 'AKB 500',
    akb550Delivery: 'AKB 550',
    akb1300Delivery: 'AKB Fast',
    akbStorage: 'Paid storage',
    akbWheelsStorage: 'Paid storage. Wheels',
    akbOrderDeliveryCDEK: 'CDEK',
    akbExpressCDEK: 'CDEK Express',
    fastDelivery: 'FAST',
    akbExpress092023: 'Express - Personal purchases',
    akbYahooOrderDeliverySeino: 'In-Japan delivery. Seino',
    akbCustomsClearance: 'Customs clearance',
  },

  'Новый, неиспользованный': 'Новый, неиспользованный',
  'Почти не используется': 'Почти не используется',
  'Нет заметных царапин или грязи': 'Нет заметных царапин или грязи',
  'Есть царапины и грязь': 'Есть царапины и грязь',
  'Царапины и грязь': 'Царапины и грязь',
  'Общее плохое состояние': 'Общее плохое состояние',

  PRICE_MIN: 'Цена от',
  PRICE_MAX: 'Цена до',
  CONDITION: 'Состояние',

  'New/unused': 'Новый/не использованный',
  'Almost new': 'Почти новый',
  'No noticeable scratches or marks': 'Без заметных царапин или следов',
  'Some scratches/marks': 'Несколько царапин/пятен',
  'Scratches/marks': 'Царапины/пятна',
  'Bad condition': 'Плохое состояние',

  ON_SALE: 'Распродажа',

  'Недостаточно средств для покупки': 'Недостаточно средств для покупки',
  SELLER_TYPE: 'Тип продавца',
};
