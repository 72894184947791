<nz-content class="skiptranslate">
  <h1>Shops Catalog</h1>
  <app-top-filter [shopCatalogStores]="(itemsRef.data | async)?.shopCatalogStores"></app-top-filter>
  <h2>{{ (itemsRef.data | async)?.shopCatalogItems?.total }} Products</h2>
  <nz-list nzGrid>
    <div nz-row [nzGutter]="16">
      <div
        nz-col
        [nzXXl]="4"
        [nzXl]="4"
        [nzLg]="6"
        [nzMd]="6"
        [nzSm]="12"
        [nzXs]="24"
        *ngFor="let item of (itemsRef.data | async)?.shopCatalogItems?.nodes"
      >
        <nz-list-item>
          <nz-card [nzCover]="coverTemplate" nzBorderless>
            <h3>{{ item.title[translate.currentLang] }}</h3>
            <h4>{{ item.store.title }}</h4>
            <p>{{ item.description[translate.currentLang] }}</p>
            <div>
              <app-add-to-cart
                *ngIf="(cartRef.data | async)?.currentUser?.cartItems"
                [item]="item"
                [cartItems]="(cartRef.data | async)?.currentUser?.cartItems"
              ></app-add-to-cart>
            </div>
          </nz-card>
          <ng-template #coverTemplate>
            <img
              [alt]="item.title[translate.currentLang]"
              [nzSrc]="item.imageUrl?.replace('akebono.world/', 'akebono.world/640x480/')"
              class="product-image"
              nz-image
            />
          </ng-template>
        </nz-list-item>
      </div>
    </div>
    <nz-row nzJustify="center">
      <nz-col
        ><nz-pagination
          [nzPageIndex]="itemsRef.page | async"
          [nzPageSize]="itemsRef.pageSize | async"
          [nzTotal]="(itemsRef.data | async)?.shopCatalogItems?.total"
          (nzPageSizeChange)="changePageSize($event)"
          (nzPageIndexChange)="changePage($event)"
          nzSize="large"
        ></nz-pagination
      ></nz-col>
    </nz-row>
  </nz-list>
</nz-content>
