<nz-spin [nzSpinning]="loading" nzSize="large">
  <section class="section breadcrumbs">
    <nz-breadcrumb>
      <ng-container>
        <nz-breadcrumb-item>
          <a routerLink="/croooober">Croooober.com</a>
        </nz-breadcrumb-item>
      </ng-container>
    </nz-breadcrumb>
  </section>

  <section class="section filter skiptranslate">
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="'CATEGORY.FILTER' | translate">
        <form nz-row nz-form #f="ngForm" nzLayout="vertical" nzGutter="30">
          <div nz-col>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [ngModel]="filter.new_flag" name="new_flag">
                  {{ 'LOTS.FILTER.ONLY_NEW' | translate }}
                </label>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>{{ 'LOTS.FILTER.MIN_PRICE' | translate }}:</nz-form-label>
              <nz-form-control>
                <nz-input-group nzAddOnAfter="¥">
                  <input nz-input type="number" [ngModel]="filter.kakaku_low" name="kakaku_low" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>{{ 'LOTS.FILTER.STARS_DIVISION' | translate }}:</nz-form-label>
              <nz-form-control>
                <nz-rate [ngModel]="filter.stars_division" name="stars_division"></nz-rate>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col>
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox [ngModel]="filter.free_shipping_flag" name="free_shipping_flag">
                  {{ 'LOTS.FILTER.FREE_SHIPPING' | translate }}
                </label>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label>{{ 'LOTS.FILTER.MAX_PRICE' | translate }}:</nz-form-label>
              <nz-form-control>
                <nz-input-group nzAddOnAfter="¥">
                  <input nz-input type="number" [ngModel]="filter.kakaku_high" name="kakaku_high" />
                </nz-input-group>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col>
            <nz-form-item>
              <nz-form-label>{{ 'LOTS.FILTER.SORT.SORT' | translate }}:</nz-form-label>
              <nz-form-control>
                <nz-radio-group ngModel name="sort">
                  <label nz-radio [nzValue]="'update_date'">
                    {{ 'LOTS.FILTER.SORT.UPDATE_DATE' | translate }}
                  </label>
                  <label nz-radio [nzValue]="'price_up'">
                    {{ 'LOTS.FILTER.SORT.PRICE_UP' | translate }}
                  </label>
                  <label nz-radio [nzValue]="'price_down'">
                    {{ 'LOTS.FILTER.SORT.PRICE_DOWN' | translate }}
                  </label>
                  <label nz-radio [nzValue]="'condition_up'">
                    {{ 'LOTS.FILTER.SORT.CONDITION_UP' | translate }}
                  </label>
                  <label nz-radio [nzValue]="'condition_down'">
                    {{ 'LOTS.FILTER.SORT.CONDITION_DOWN' | translate }}
                  </label>
                  <label nz-radio [nzValue]="'arrival_date'">
                    {{ 'LOTS.FILTER.SORT.ARRIVAL_DATE' | translate }}
                  </label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col style="min-width: 300px">
            <nz-select
              nzPlaceHolder="{{ 'LOTS.FILTER.CATEGORY' | translate }}"
              [ngModel]="filter.category"
              name="category"
              style="width: 100%"
              nzDropdownClassName="skiptranslate"
              (ngModelChange)="applyFilter(f)"
              nzShowSearch
            >
              <nz-option-group *ngFor="let group of categories" [nzLabel]="group.label">
                <nz-option
                  *ngFor="let option of group.children"
                  [nzLabel]="option.label"
                  [nzValue]="option.value"
                ></nz-option>
              </nz-option-group>
            </nz-select>
            <br /><br />
            <nz-input-group nzCompact>
              <nz-select
                ngModel
                name="maker"
                nzShowSearch
                nzPlaceHolder="Car maker"
                nzDropdownClassName="skiptranslate"
                style="width: 50%"
                (ngModelChange)="onMakerChange($event)"
              >
                <nz-option
                  *ngFor="let maker of makers"
                  [nzLabel]="maker.label"
                  [nzValue]="maker.value"
                ></nz-option>
              </nz-select>
              <nz-select
                [ngModel]="filter.model"
                name="model"
                nzShowSearch
                nzPlaceHolder="Car model"
                nzDropdownClassName="skiptranslate"
                style="width: 50%"
                [nzDropdownMatchSelectWidth]="false"
                [nzDisabled]="!f.value.maker"
              >
                <nz-option
                  *ngFor="let model of models"
                  [nzLabel]="model.name"
                  [nzValue]="model"
                ></nz-option>
              </nz-select>
            </nz-input-group>
            <br />
            <nz-select
              [ngModel]="filter.partmaker"
              name="partmaker"
              nzShowSearch
              nzPlaceHolder="Parts maker"
              nzDropdownClassName="skiptranslate"
              nzMode="multiple"
              style="width: 100%"
            >
              <nz-option
                *ngFor="let maker of partmakers"
                [nzLabel]="maker.value"
                [nzValue]="maker"
              ></nz-option>
            </nz-select>
            <br /><br />
            <nz-select
              *ngIf="details.length"
              [ngModel]="filter.details"
              name="detail"
              nzShowSearch
              nzPlaceHolder="Parts details"
              nzDropdownClassName="skiptranslate"
              nzMode="multiple"
              style="width: 100%"
            >
              <nz-option
                *ngFor="let detail of details"
                [nzLabel]="detail.name"
                [nzValue]="detail"
              ></nz-option>
            </nz-select>
            <br /><br />
          </div>
        </form>
        <div class="container skiptranslate">
          <div nz-row nzGutter="20">
            <div nz-col>
              <button nz-button nzType="primary" nzSize="large" (click)="applyFilter(f)">
                {{ 'LOTS.FILTER.APPLY' | translate }}
              </button>
            </div>
            <div nz-col>
              <button nz-button nzType="danger" nzSize="large" (click)="resetFilter(f)">
                {{ 'LOTS.FILTER.RESET' | translate }}
              </button>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </section>

  <ng-template #pageTemplate let-type let-page="page">
    <a *ngIf="type === 'pre'"><</a>
    <a *ngIf="type === 'next'">></a>
    <a *ngIf="type === 'prev_5'">...</a>
    <a *ngIf="type === 'next_5'">...</a>
    <a
      *ngIf="type === 'page'"
      [routerLink]="[]"
      [queryParams]="{ page: page }"
      queryParamsHandling="merge"
      >{{ page }}</a
    >
  </ng-template>
  <ng-template #totalTemplate let-total> Total items: {{ total }} </ng-template>

  <div style="display: flex; justify-content: flex-end; margin: 20px 0px" class="skiptranslate">
    <nz-pagination
      [nzPageIndex]="filter.page"
      [nzPageSize]="filter.pageSize"
      [nzPageSizeOptions]="[20, 50, 100]"
      [nzTotal]="total"
      [nzShowTotal]="totalTemplate"
      nzShowSizeChanger
      (nzPageIndexChange)="changePage($event)"
      (nzPageSizeChange)="changePageSize($event)"
      [nzItemRender]="pageTemplate"
    ></nz-pagination>
  </div>

  <section class="section auctions">
    <div *ngIf="items">
      <nz-list nzSplit>
        <nz-list-item [nzNoFlex]="true" *ngFor="let item of items">
          <div nz-row class="body">
            <div nz-col [nzXs]="24" [nzSm]="6" [nzMd]="3" [nzLg]="3" [nzXl]="2" class="img">
              <img referrerpolicy="no-referrer" [src]="item.images && item.images[0]" />
            </div>
            <div
              nz-col
              [nzXs]="24"
              [nzSm]="18"
              [nzMd]="21"
              [nzLg]="21"
              [nzXl]="22"
              nzFlex="auto"
              class="description"
            >
              <h3 nz-typography class="ellipsis">
                <a [routerLink]="['/croooober/item', item.code]">
                  {{ item.title }}
                </a>
              </h3>
              <div nz-row nzGutter="20" class="stats">
                <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24" class="skiptranslate">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'LOTS.LOT' | translate">
                      <a [routerLink]="['/croooober/item', item.code]"> {{ item.code }} </a><br />
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.croooober.com/item/{{ item.code }}"
                      >
                        https://www.croooober.com/item/{{ item.code }}
                      </a>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div
                  *ngIf="item.price"
                  nz-col
                  [nzXl]="6"
                  [nzLg]="6"
                  [nzMd]="12"
                  [nzXs]="24"
                  class="skiptranslate price"
                >
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item
                      [nzTitle]="'LOTS.PRICE' | translate"
                      class="flex-description"
                    >
                      <div>
                        <app-currency
                          [value]="item.price"
                          [exchangeRate]="exchangeRate"
                        ></app-currency>
                        {{ 'LOTS.PLUS_JP_DELIVERY' | translate }}
                      </div>
                      <button
                        nz-button
                        nzType="primary"
                        nz-popconfirm
                        nzPopconfirmTitle="{{ 'ACTIONS.ARE_YOU_SURE' | translate }}"
                        (nzOnConfirm)="buy(item)"
                        [nzLoading]="buying[item.code] | async"
                        [disabled]="!buying[item.code] && (buying[item.code] | async)"
                      >
                        {{ 'ACTIONS.BUY' | translate }}
                      </button>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24" class="skiptranslate">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'LOTS.STATE' | translate">
                      <nz-rate [(ngModel)]="item.stars" name="rating" nzDisabled></nz-rate>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'LOTS.SELLER' | translate">
                      <a *ngIf="item.seller">
                        {{ item.seller.title }}
                      </a>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div nz-col [nzXl]="12" [nzLg]="12" [nzMd]="12" [nzXs]="24" class="">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'LOTS.DESCRIPTION' | translate">
                      {{ item.description }}
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div
                  *ngIf="item.details?.length"
                  nz-col
                  [nzXl]="12"
                  [nzLg]="12"
                  [nzMd]="12"
                  [nzXs]="24"
                  class="skiptranslate"
                >
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'LOTS.INFO' | translate">
                      <nz-table>
                        <tr *ngFor="let row of item.details">
                          <td *ngFor="let col of row">
                            {{ col }}
                          </td>
                        </tr>
                      </nz-table>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
              </div>
            </div>
          </div>
        </nz-list-item>
      </nz-list>

      <div style="display: flex; justify-content: flex-end; margin-top: 20px" class="skiptranslate">
        <nz-pagination
          [nzPageIndex]="filter.page"
          [nzPageSize]="filter.pageSize"
          [nzPageSizeOptions]="[20, 50, 100]"
          [nzTotal]="total"
          [nzShowTotal]="totalTemplate"
          nzShowSizeChanger
          (nzPageIndexChange)="changePage($event)"
          (nzPageSizeChange)="changePageSize($event)"
          [nzItemRender]="pageTemplate"
        ></nz-pagination>
      </div>
    </div>
    <div *ngIf="!items" class="empty">
      <nz-empty></nz-empty>
    </div>
  </section>
</nz-spin>
