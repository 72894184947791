import { NotificationService } from '@akebono/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Apollo, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import {
  CartItemCreateGQL,
  CartItemTypeEnum,
  CategorySearchGQL,
  CurrentUser,
  ExchangeRate,
  Service,
  ShopOrderCreateGQL,
  RakutenSearchGQL,
  RakutenSearchQuery,
  RakutenSearchQueryVariables,
} from 'src/app/graphql/user-service';
import { GoogleTranslateService } from 'src/app/services/google-translate.service';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';

interface Breadcrumb {
  name: string;
  path: string;
  params: any;
}

@Component({
  selector: 'app-rakuten-item',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss'],
})
export class RakutenItemComponent implements OnInit, OnDestroy {
  code: string = null;
  item: any = null;
  currentUser: CurrentUser = null;
  services: Service[] = [];
  crumbs: Breadcrumb[] = [];
  exchangeRate: ExchangeRate = null;

  quantity = 1;
  minQuantity = 1;
  maxQuantity = 999;
  cartItemQuantity = 0;
  variant = '';

  loading = false;
  addingToCart = false;
  ordering = false;
  categoriesFetched = false;

  queryRef: QueryRef<RakutenSearchQuery, RakutenSearchQueryVariables> = null;
  subscription: Subscription = null;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private googleTranslate: GoogleTranslateService,
    private notification: NotificationService,
    private cartItemCreateGQL: CartItemCreateGQL,
    private shopOrderCreateGQL: ShopOrderCreateGQL,
    private categoryShearchGQL: CategorySearchGQL,
    private searchService: SearchService,
    private rakutenSearchGQL: RakutenSearchGQL,
    private apollo: Apollo,
  ) {
    this.searchService.currentService.next(SearchServiceEnum.Rakuten);
    this.code = this.route.snapshot.paramMap.get('code');
    this.queryRef = this.rakutenSearchGQL.watch({
      itemCode: this.code,
      withVariants: false,
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.subscription = this.queryRef.valueChanges.subscribe(
      (result) => {
        this.loading = false;
        console.log('Shopping item response', result);

        if (result?.data?.services && result?.data?.exchangeRate && result?.data?.rakutenSearch) {
          this.item = result.data.rakutenSearch.items[0];
          this.exchangeRate = result.data.exchangeRate;
          this.services = result.data.services.nodes as unknown as Service[];
          this.currentUser = result.data.currentUser as unknown as CurrentUser;

          this.googleTranslate.translate();
          this.title.setTitle(this.item.Headline || this.item.Name);

          if (result.data.currentUser) {
            this.cartItemQuantity = result.data.currentUser.cartItems
              .filter((item) => item.subject.code === this.code)
              .reduce((sum, item) => sum + item.quantity, 0);
          }

          if (this.item.genreId && !this.categoriesFetched) {
            this.fetchCategoryPath(Number(this.item.genreId));
          }
        } else {
          this.notification.renderError('Empty response');
        }
      },
      (error) => {
        this.loading = false;
        console.log(error);
        this.notification.renderError('Error', error);
      },
    );
  }

  fetchCategoryPath(categoryId: number): void {
    const sub = this.categoryShearchGQL
      .fetch({
        categoryId,
      })
      .subscribe(
        (result) => {
          sub.unsubscribe();
          this.categoriesFetched = true;
          console.log(`Category search response for category ${categoryId}`, result);

          if (result?.data?.shopping?.categorySearch?.ResultSet) {
            const currentCategoryPath =
              result.data.shopping.categorySearch.ResultSet['0'].Result.Categories.Current.Path;

            this.crumbs = Object.keys(currentCategoryPath)
              .filter((key) => !key.startsWith('_'))
              .map((idx) => {
                return {
                  id: Number(currentCategoryPath[idx].Id),
                  parentId: Number(currentCategoryPath[idx].ParentId),
                  name: currentCategoryPath[idx].Title.Name,
                };
              })
              .filter((category) => category.parentId)
              .map((category) => {
                return {
                  name: category.name,
                  path: '/shopping/search',
                  params: { category: category.id },
                };
              });

            this.googleTranslate.translate();
          }
        },
        (error) => {
          sub.unsubscribe();
          console.log(error);
          this.notification.renderError('Error', error);
        },
      );
  }

  getImages(): string[] {
    return this.item.mediumImageUrls;
  }

  getThumbs(): string[] {
    return this.item.smallImageUrls;
  }

  update(): void {
    this.loading = true;
    this.queryRef
      .refetch({
        itemCode: this.code,
        withVariants: false,
      })
      .finally(() => {
        this.loading = false;
      });
  }

  createOrder() {
    this.ordering = true;
    this.shopOrderCreateGQL
      .mutate({
        input: {
          orderItems: [
            {
              link: this.item.Url + ' ' + this.variant,
              quantity: this.quantity,
              title: this.item.Name,
              price: Number(this.item.Price._value),
            },
          ],
        },
      })
      .subscribe(
        (result) => {
          console.log('Cart item create response', result);

          if (!result || !result.data) {
            this.ordering = false;
            this.notificationService.renderError('Empty response');
            return;
          }

          if (result.data.shopOrderCreate.error) {
            this.ordering = false;
            this.notificationService.renderError(
              'SHOPPING.ORDER_FAILED',
              result.data.shopOrderCreate.error.message || result.data.shopOrderCreate.error.code,
            );
          } else {
            this.apollo
              .use(this.cartItemCreateGQL.client)
              .getClient()
              .reFetchObservableQueries()
              .then(() => {
                this.ordering = false;
                this.notificationService.renderSuccess('SHOPPING.ORDER_CREATED');
              });
          }
        },
        (error) => {
          this.ordering = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
  }

  addToCart(): void {
    this.addingToCart = true;
    this.cartItemCreateGQL
      .mutate({
        input: {
          item: {
            code: this.code,
            type: CartItemTypeEnum.RakutenProduct,
            variant: this.variant,
          },
          quantity: this.quantity,
        },
      })
      .subscribe(
        (result) => {
          console.log('Cart item create response', result);

          if (!result || !result.data) {
            this.addingToCart = false;
            this.notificationService.renderError('Empty response');
            return;
          }

          if (result.data.cartItemCreate.error) {
            this.addingToCart = false;
            this.notificationService.renderError(
              'CART.ADD_TO_CART.FAIL',
              result.data.cartItemCreate.error.message || result.data.cartItemCreate.error.code,
            );
          } else {
            this.addingToCart = false;
            this.notificationService.renderSuccess('CART.ADD_TO_CART.SUCCESS');
          }
        },
        (error) => {
          this.addingToCart = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
