import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Params, Router } from '@angular/router';

export enum LotsSort {
  DoenstMatter = 'none',
  PriceUp = 'price_up',
  PriceDown = 'price_down',
  BlitzUp = 'blitz_up',
  BlitzDown = 'blitz_down',
  EndUp = 'end_up',
  EndDown = 'end_down',
}

export enum Usage {
  used = 2,
  new = 1,
}

export enum Seller {
  merchant = 1,
  shop = 2,
}

export interface LotsFilter {
  store: number | null;
  sort: string | null;
  order: string | null;
  itemStatus: Usage | null;
  maxBuyout: number | null;
  minBuyout: number | null;
  maxPrice: number | null;
  minPrice: number | null;
  buynow: number | null;
  new: number | null;
}

@Component({
  selector: 'app-lots-filter',
  templateUrl: './lots-filter.component.html',
  styleUrls: ['./lots-filter.component.scss'],
})
export class LotsFilterComponent implements OnInit {
  @Input() filter: LotsFilter = null;

  filterForm: FormGroup = null;

  constructor(private fb: FormBuilder, private router: Router) {}

  public static getFilterFromParams(params: Params): LotsFilter {
    return {
      ...this.sortFromLotsSortEnum(params.sort),
      new: params.onlyNew ? 1 : null,
      buynow: params.onlyBlitz ? 1 : null,
      maxPrice: Number(params.maxPrice) || null,
      minPrice: Number(params.minPrice) || null,
      maxBuyout: Number(params.maxBlitz) || null,
      minBuyout: Number(params.minBlitz) || null,
      itemStatus: this.getNumericParam(params.condition),
      store: this.getNumericParam(params.seller),
      seller: params.seller,
    };
  }

  static getNumericParam(param: any): number | null {
    if (param) {
      if (Number(param) === 0) {
        return null;
      } else {
        return Number(param);
      }
    } else {
      return null;
    }
  }

  static sortFromLotsSortEnum(sort: LotsSort): any {
    switch (sort) {
      case LotsSort.PriceUp:
        return { order: 'a', sort: 'cbids' };
      case LotsSort.BlitzUp:
        return { order: 'a', sort: 'bidorbuy' };
      case LotsSort.EndUp:
        return { order: 'a', sort: 'end' };
      case LotsSort.PriceDown:
        return { order: 'd', sort: 'cbids' };
      case LotsSort.BlitzDown:
        return { order: 'd', sort: 'bidorbuy' };
      case LotsSort.EndDown:
        return { order: 'd', sort: 'end' };
      case LotsSort.DoenstMatter:
        return { order: null, sort: null };
      default:
        return { order: null, sort: null };
    }
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      onlyNew: this.fb.control(this.filter?.new ? true : null),
      onlyBlitz: this.fb.control(this.filter?.buynow ? true : null),
      maxPrice: this.fb.control(this.filter?.maxPrice),
      minPrice: this.fb.control(this.filter?.minPrice),
      maxBlitz: this.fb.control(this.filter?.maxBuyout),
      minBlitz: this.fb.control(this.filter?.minBuyout),
      condition: this.fb.control(this.filter?.itemStatus || 0),
      seller: this.fb.control(this.filter?.store || 0),
      sort: this.fb.control(
        this.filter?.order && this.filter?.sort
          ? this.sortToLotsSortEnum(this.filter.order, this.filter.sort)
          : LotsSort.DoenstMatter,
      ),
    });
  }

  sortToLotsSortEnum(order: string, sort: string): LotsSort {
    if (order === 'a') {
      switch (sort) {
        case 'cbids':
          return LotsSort.PriceUp;
        case 'bidorbuy':
          return LotsSort.BlitzUp;
        case 'end':
          return LotsSort.EndUp;
      }
    } else {
      switch (sort) {
        case 'cbids':
          return LotsSort.PriceDown;
        case 'bidorbuy':
          return LotsSort.BlitzDown;
        case 'end':
          return LotsSort.EndDown;
      }
    }

    return LotsSort.DoenstMatter;
  }

  apply(): void {
    this.router.navigate([], {
      queryParams: {
        ...this.filterForm.value,
        page: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  reset(): void {
    this.filterForm.reset();
    this.filterForm.get('condition').setValue(0);
    this.filterForm.get('seller').setValue(0);
    this.filterForm.get('sort').setValue(LotsSort.DoenstMatter);
    this.router.navigate([], {
      queryParams: {
        onlyNew: null,
        onlyBlitz: null,
        maxPrice: null,
        minPrice: null,
        maxBlitz: null,
        minBlitz: null,
        condition: null,
        seller: null,
        sort: null,
        page: null,
      },
      queryParamsHandling: 'merge',
    });
  }
}
