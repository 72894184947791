<section class="section auctions">
  <div *ngIf="items">
    <div style="display: flex; justify-content: flex-end; margin: 20px 0px">
      <nz-radio-group
        style="margin-right: 20px"
        [ngModel]="mode"
        nzButtonStyle="solid"
        (ngModelChange)="switchMode($event)"
      >
        <label nz-radio-button nzValue="rows">
          <i nz-icon nzType="bars" nzTheme="outline"></i>
        </label>
        <label nz-radio-button nzValue="panels">
          <i nz-icon nzType="appstore" nzTheme="outline"></i>
        </label>
      </nz-radio-group>
      <div class="skiptranslate">
        <nz-pagination
          nzShowSizeChanger
          [nzPageIndex]="page"
          [nzPageSize]="pageSize"
          [nzTotal]="total"
          [nzPageSizeOptions]="[20, 50, 100]"
          (nzPageIndexChange)="changePage($event)"
          (nzPageSizeChange)="changePageSize($event)"
        ></nz-pagination>
      </div>
    </div>
    <nz-list class="rows" *ngIf="mode === 'rows'" nzBordered>
      <nz-list-item [nzNoFlex]="true" *ngFor="let item of items">
        <div nz-row class="body">
          <div nz-col [nzXs]="24" [nzSm]="6" [nzMd]="3" [nzLg]="3" [nzXl]="2" class="img">
            <img
              [src]="
                item.Image?.replace(
                  'https://auc-pctr.c.yimg.jp/',
                  'https://ya-pctr-cdn.akebono.shop/'
                )
              "
            />
          </div>
          <div
            nz-col
            [nzXs]="24"
            [nzSm]="18"
            [nzMd]="21"
            [nzLg]="21"
            [nzXl]="22"
            nzFlex="auto"
            class="description"
          >
            <h5 nz-typography class="ellipsis">
              <a [routerLink]="['/auction', item.AuctionID]" style="color: #00008b">
                {{ item.Title }}
              </a>
            </h5>
            <div nz-row nzGutter="5" class="stats skiptranslate">
              <div nz-col nzXl="4" nzXXl="4" nzXs="24" nzSm="12" nzMd="8" nzLg="5">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item [nzTitle]="'LOTS.PRICE' | translate">
                    <app-currency
                      [value]="getPrice(item)"
                      [exchangeRate]="exchangeRate"
                    ></app-currency>
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
              <div
                nz-col
                nzXl="4"
                nzXXl="4"
                nzXs="24"
                nzSm="12"
                nzMd="8"
                nzLg="5"
                class="blitz-price"
              >
                <nz-descriptions
                  *ngIf="item.BidOrBuy"
                  nzBordered
                  nzSize="small"
                  nzLayout="vertical"
                >
                  <nz-descriptions-item [nzTitle]="'LOTS.BLITZ_PRICE' | translate">
                    <app-currency
                      [value]="item.BidOrBuy"
                      [exchangeRate]="exchangeRate"
                    ></app-currency>
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
              <div nz-col nzXl="4" nzXXl="3" nzXs="24" nzSm="12" nzMd="8" nzLg="5">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item [nzTitle]="'LOTS.LOT' | translate">
                    <a [routerLink]="['/auction', item.AuctionID]">
                      {{ item.AuctionID }}
                    </a>
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
              <div nz-col nzXl="4" nzXXl="3" nzXs="24" nzSm="12" nzMd="8" nzLg="5">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item [nzTitle]="'LOTS.SELLER' | translate">
                    <a *ngIf="sellerDefined(item)" [routerLink]="['/seller', item.Seller.Id]">
                      {{ item.Seller.Id }}
                    </a>
                    <span *ngIf="!sellerDefined(item) && sellerId">
                      {{ sellerId }}
                    </span>
                    <span *ngIf="!sellerDefined(item) && !sellerId">
                      {{ 'LOTS.UNKNOWN' | translate }}
                    </span>
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
              <div nz-col nzXl="4" nzXXl="3" nzXs="24" nzSm="12" nzMd="8" nzLg="5">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item [nzTitle]="'LOTS.STATE' | translate">
                    {{ getCondition(item) | translate }}
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
              <div nz-col nzXl="4" nzXXl="3" nzXs="24" nzSm="12" nzMd="8" nzLg="5">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item [nzTitle]="'LOTS.TO_FINISH' | translate">
                    {{ getEndTime(item) }}
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
              <div nz-col nzXl="4" nzXXl="3" nzXs="24" nzSm="12" nzMd="8" nzLg="5">
                <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                  <nz-descriptions-item [nzTitle]="'LOTS.BIDS' | translate">
                    {{ item.Bids }}
                  </nz-descriptions-item>
                </nz-descriptions>
              </div>
              <div
                *ngIf="isAuth"
                nz-col
                nzXl="4"
                nzXXl="1"
                nzXs="24"
                nzSm="12"
                nzMd="8"
                nzLg="5"
                class="fav"
              >
                <button
                  *ngIf="!isFav(item)"
                  nz-button
                  nzShape="round"
                  nzSize="large"
                  (click)="fav(item.AuctionID, $event)"
                  nz-popover
                  [nzPopoverContent]="'LOTS.ADD_TO_FAV' | translate"
                >
                  <i
                    *ngIf="!favOperations[item.AuctionID]"
                    nz-icon
                    [nzType]="'star'"
                    [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'gray'"
                  ></i>
                  <i *ngIf="favOperations[item.AuctionID]" nz-icon [nzType]="'loading'"></i>
                </button>
                <button
                  *ngIf="isFav(item)"
                  nz-button
                  nzShape="round"
                  nzSize="large"
                  (click)="unfav(item.AuctionID, $event)"
                  nz-popover
                  [nzPopoverContent]="'LOTS.REMOVE_FROM_FAV' | translate"
                >
                  <i
                    *ngIf="!favOperations[item.AuctionID]"
                    nz-icon
                    [nzType]="'star'"
                    [nzTheme]="'twotone'"
                    [nzTwotoneColor]="'#eb2f96'"
                  ></i>
                  <i *ngIf="favOperations[item.AuctionID]" nz-icon [nzType]="'loading'"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nz-list-item>
    </nz-list>
    <nz-list *ngIf="mode === 'panels'" nzGrid>
      <div nz-row style="margin-top: 20px" [nzGutter]="16">
        <div
          nz-col
          [nzXXl]="3"
          [nzXl]="4"
          [nzLg]="6"
          [nzMd]="6"
          [nzSm]="12"
          [nzXs]="12"
          *ngFor="let item of items"
        >
          <nz-list-item [nzNoFlex]="true">
            <nz-card
              [nzCover]="coverTemplate"
              nzBorderless
              nzHoverable
              nzSize="small"
              routerLink="/auction/{{ item.AuctionID }}"
            >
              <ng-template #coverTemplate>
                <img
                  [alt]="item.Title"
                  referrerpolicy="no-referrer"
                  [src]="
                    item.Image?.replace(
                      'https://auc-pctr.c.yimg.jp/',
                      'https://ya-pctr-cdn.akebono.shop/'
                    )
                  "
                  class="product-image"
                />
              </ng-template>
              <div style="height: 10rem">
                <div style="position: relative; margin-bottom: 5px">
                  <div *ngIf="isAuth" style="position: absolute; bottom: 0; right: 0">
                    <button
                      *ngIf="!isFav(item)"
                      nz-button
                      nzShape="circle"
                      nzSize="large"
                      (click)="fav(item.AuctionID, $event)"
                      nz-popover
                      [nzPopoverContent]="'LOTS.ADD_TO_FAV' | translate"
                    >
                      <i
                        *ngIf="!favOperations[item.AuctionID]"
                        nz-icon
                        [nzType]="'star'"
                        [nzTheme]="'twotone'"
                        [nzTwotoneColor]="'gray'"
                      ></i>
                      <i *ngIf="favOperations[item.AuctionID]" nz-icon [nzType]="'loading'"></i>
                    </button>
                    <button
                      *ngIf="isFav(item)"
                      nz-button
                      nzShape="circle"
                      nzSize="large"
                      nzDanger
                      (click)="unfav(item.AuctionID, $event)"
                      nz-popover
                      [nzPopoverContent]="'LOTS.REMOVE_FROM_FAV' | translate"
                    >
                      <i
                        *ngIf="!favOperations[item.AuctionID]"
                        nz-icon
                        [nzType]="'star'"
                        [nzTheme]="'twotone'"
                        [nzTwotoneColor]="'#eb2f96'"
                      ></i>
                      <i *ngIf="favOperations[item.AuctionID]" nz-icon [nzType]="'loading'"></i>
                    </button>
                  </div>
                </div>
                <div nz-typography nzEllipsis [nzEllipsisRows]="2" style="height: 3rem">
                  <a [title]="item.Title" [routerLink]="['/auction', item.AuctionID]">
                    {{ item.Title }}
                  </a>
                </div>
                <div nz-row style="height: 1.6rem" nzJustify="space-between">
                  <div nz-col>
                    <a class="skiptranslate" [routerLink]="['/auction', item.AuctionID]">
                      {{ item.AuctionID }}
                    </a>
                  </div>
                  <div nz-col style="text-align: right">
                    <nz-tag
                      *ngIf="item?.Option?.NewItemIcon"
                      nzColor="lightblue"
                      style="margin: 0"
                      >{{ getCondition(item) | translate }}</nz-tag
                    >
                  </div>
                </div>
                <div nz-row class="skiptranslate">
                  <div
                    nz-col
                    nzSpan="24"
                    style="color: black; font-size: 16px; height: 1.6rem; white-space: nowrap"
                  >
                    <app-currency
                      [value]="getPrice(item)"
                      [exchangeRate]="exchangeRate"
                    ></app-currency>
                  </div>
                  <div
                    nz-col
                    nzSpan="24"
                    style="color: orange; font-size: 16px; height: 1.6rem; white-space: nowrap"
                  >
                    <app-currency
                      *ngIf="item.BidOrBuy"
                      [value]="item.BidOrBuy"
                      [exchangeRate]="exchangeRate"
                    ></app-currency>
                  </div>
                </div>
                <div nz-row class="skiptranslate" nzJustify="space-between">
                  <div nz-col>
                    <i nz-icon nzType="clock-circle" nzTheme="outline"></i> {{ getEndTime(item) }}
                  </div>
                  <div nz-col style="text-align: right">
                    <span nz-icon nzType="fund" nzTheme="outline"></span> {{ item.Bids }}
                  </div>
                </div>
              </div>
            </nz-card>
          </nz-list-item>
        </div>
      </div>
    </nz-list>
    <div class="skiptranslate" style="display: flex; justify-content: flex-end; margin-top: 20px">
      <nz-pagination
        [nzTotal]="total"
        nzShowSizeChanger
        [nzPageIndex]="page"
        [nzPageSize]="pageSize"
        [nzPageSizeOptions]="[20, 50, 100]"
        (nzPageIndexChange)="changePage($event)"
        (nzPageSizeChange)="changePageSize($event)"
      ></nz-pagination>
    </div>
  </div>
  <div *ngIf="!items" class="empty">
    <nz-empty></nz-empty>
  </div>
</section>
