<nz-page-header>
  <nz-page-header-title>
    <span class="skiptranslate">{{ 'CART.CART' | translate }}</span>
  </nz-page-header-title>
</nz-page-header>

<nz-spin [nzSpinning]="loading" nzSize="large" class="skiptranslate">
  <nz-empty *ngIf="!stores.length"></nz-empty>
  <nz-collapse *ngIf="stores.length">
    <nz-collapse-panel
      *ngFor="let store of stores"
      [nzHeader]="'CART.STORES.' + store.name | translate"
      [nzActive]="true"
    >
      <nz-list nzItemLayout="vertical">
        <nz-list-item *ngFor="let seller of store.sellers" [nzNoFlex]="true">
          <nz-list-item-meta>
            <nz-list-item-meta-title *ngIf="store.name != 'AutoPart'">
              <a
                [routerLink]="[productRouteMap[store.name], 'search']"
                [queryParams]="{ seller: seller.id }"
                class="skiptranslate"
              >
                {{ seller.name }}
              </a>
            </nz-list-item-meta-title>
            <nz-list-item-meta-title *ngIf="store.name == 'AutoPart'">
              <span class="skiptranslate">
                {{ seller.name | uppercase }}
              </span>
            </nz-list-item-meta-title>
          </nz-list-item-meta>
          <nz-table
            nzSize="small"
            [nzData]="seller.items"
            [nzFrontPagination]="false"
            [nzShowPagination]="false"
            [nzBordered]="true"
          >
            <thead>
              <tr>
                <th>{{ 'CART.LABELS.ITEM' | translate }}</th>
                <th nzWidth="100px">{{ 'CART.LABELS.PRICE' | translate }}</th>
                <th nzWidth="100px">{{ 'CART.LABELS.QUANTITY' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of seller.items">
                <td>
                  <div nz-row class="info" nzGutter="10">
                    <div
                      *ngIf="item.subject.imageUrl || item.subject.thumbnails"
                      nz-col
                      [nzXs]="24"
                      [nzSm]="6"
                      [nzMd]="3"
                      [nzLg]="3"
                      [nzXl]="2"
                      class="image"
                    >
                      <img
                        width="100"
                        [src]="item.subject.imageUrl || item.subject.thumbnails[0]"
                      />
                    </div>
                    <div
                      nz-col
                      nzFlex="auto"
                      [nzXs]="24"
                      [nzSm]="18"
                      [nzMd]="21"
                      [nzLg]="21"
                      [nzXl]="22"
                      class="text"
                    >
                      <div class="title">
                        <a
                          *ngIf="store.name != 'AutoPart'"
                          [routerLink]="[productRouteMap[store.name], 'item', item.subject.code]"
                        >
                          {{ item.subject.title }} {{ item.subject.titleJSON?.en }}
                        </a>
                        <span *ngIf="store.name == 'AutoPart'" class="skiptranslate">
                          {{ item.subject.code }} {{ item.subject.title }}
                        </span>
                        <br />
                        {{ getVariantName(item) }}
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <app-currency
                    [value]="item.subject.price * item.quantity"
                    [exchangeRate]="exchangeRate"
                    layout="vertical"
                  ></app-currency>
                </td>
                <td>
                  <div>
                    <nz-spin [nzSpinning]="updating[item.id]" nzSize="small">
                      <nz-input-number
                        [(ngModel)]="item.quantity"
                        [nzMin]="1"
                        [nzMax]="999"
                        [nzStep]="1"
                        (ngModelChange)="quantityChanges$.next(item)"
                      ></nz-input-number>
                    </nz-spin>
                  </div>
                  <div>
                    <button
                      class="delete-btn"
                      nz-button
                      nzType="primary"
                      [nzDanger]="true"
                      nzSize="small"
                      [nzLoading]="deleting[item.id]"
                      (click)="deleteItem(item)"
                    >
                      {{ 'ACTIONS.DELETE' | translate }}
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </nz-table>

          <div class="buttons">
            <button
              nz-button
              nzType="primary"
              nzSize="large"
              [nzLoading]="buing[seller.name]"
              (click)="createOrder(seller)"
            >
              {{
                (store.name != 'AutoPart'
                  ? 'CART.ORDER_CREATE.ORDER_CREATE'
                  : 'CART.ORDER_CREATE.REQUEST_CREATE'
                ) | translate
              }}
            </button>
          </div>
        </nz-list-item>
      </nz-list>
    </nz-collapse-panel>
  </nz-collapse>
</nz-spin>
