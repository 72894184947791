import { QueryHandlingService, WatchPaginatedQueryRef, WatchQueryRef } from '@akebono/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  CartItemsGQL,
  CartItemsQuery,
  CartItemsQueryVariables,
  ShopCatalogItemsFilter,
  ShopCatalogItemsGQL,
  ShopCatalogItemsQuery,
  ShopCatalogItemsQueryVariables,
  ShopCatalogItemsSort,
} from 'src/app/graphql/user-service';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListingsComponent implements OnInit {
  // stores$: ShopCatalogStore[];
  itemsRef: WatchPaginatedQueryRef<
    ShopCatalogItemsQuery,
    ShopCatalogItemsFilter,
    ShopCatalogItemsSort,
    ShopCatalogItemsQueryVariables
  >;
  cartRef: WatchQueryRef<CartItemsQuery, CartItemsQueryVariables>;

  constructor(
    private router: Router,
    private qhs: QueryHandlingService,
    private scItems: ShopCatalogItemsGQL,
    private cartItems: CartItemsGQL,
    public translate: TranslateService,
  ) {
    this.itemsRef = this.qhs.watchPaginated<
      ShopCatalogItemsQuery,
      ShopCatalogItemsFilter,
      ShopCatalogItemsSort,
      ShopCatalogItemsQueryVariables
    >(this.scItems, (params) => {
      const filter: ShopCatalogItemsFilter = {
        shopCatalogStoreId: {
          eq: params.storeId,
        },
        search: {
          eq: params.q,
        },
      };
      return filter;
    });
    this.cartRef = this.qhs.watch<CartItemsQuery, CartItemsQueryVariables>(this.cartItems);
  }

  ngOnInit(): void {}

  changePage(page: number): void {
    this.router.navigate(['.'], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize: number): void {
    this.router.navigate(['.'], {
      queryParams: {
        pageSize,
      },
      queryParamsHandling: 'merge',
    });
  }
}
