import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShoppingCategoriesComponent } from './components/shopping-categories/shopping-categories.component';
import { ShoppingItemComponent } from './components/shopping-item/shopping-item.component';
import { ShoppingSearchComponent } from './components/shopping-search/shopping-search.component';

const routes: Routes = [
  { path: '', component: ShoppingCategoriesComponent },
  { path: 'item/:code', component: ShoppingItemComponent },
  { path: 'search', component: ShoppingSearchComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class YahooShoppingRoutingModule {}
