import { Component, OnInit } from '@angular/core';
import { PARTS } from 'src/app/const';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

interface SelectedParts {
  [name: string]: boolean;
}

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss'],
})
export class PartsComponent implements OnInit {
  selectedParts: SelectedParts = {};
  categories: string[] = [];
  parts: any = PARTS;
  vin = '';

  constructor(private router: Router, private translate: TranslateService, private title: Title) {}

  ngOnInit(): void {
    this.title.setTitle(this.translate.instant('PARTS.PARTS_SEARCH'));

    Object.keys(this.parts).forEach((category) => {
      this.categories.push(category);
      this.parts[category].forEach((part) => {
        this.selectedParts[part[1].trim()] = false;
      });
    });
  }

  goHome(): void {
    this.router.navigate(['/']);
  }

  find(): void {
    const partsForSearch: string[] = Object.keys(this.selectedParts)
      .filter((part) => this.selectedParts[part])
      .map((part) => part);

    this.router.navigate(['/search'], {
      queryParams: {
        q: `${this.vin}(${partsForSearch.join(' ')})`,
      },
    });
  }
}
