<nz-spin [nzSpinning]="loading" nzSize="large">
  <div *ngIf="loading" class="loading-payload"></div>

  <section class="section breadcrumbs">
    <nz-breadcrumb>
      <ng-container>
        <nz-breadcrumb-item>
          <a routerLink="/croooober">Croooober.com</a>
        </nz-breadcrumb-item>
      </ng-container>
    </nz-breadcrumb>
  </section>

  <section class="section title">
    <h2 nz-typography>
      {{ item?.title }}
    </h2>
  </section>

  <section *ngIf="item" class="section main">
    <div nz-row nzGutter="20">
      <div nz-col [nzLg]="10" [nzXs]="24" class="images">
        <app-croober-images [images]="item.images"></app-croober-images>
      </div>
      <div nz-col [nzXs]="24" [nzLg]="14" class="trade">
        <div nz-row *ngIf="currentUser" nzJustify="space-between" class="buttons">
          <div nz-col>
            <button nz-button nzType="primary" nzShape="round" (click)="update()">
              {{ 'AUCTION.UPDATE' | translate }}
            </button>
          </div>
        </div>
        <div class="panel">
          <section>
            <div nz-row nzGutter="8">
              <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" class="skiptranslate">
                <div nz-row class="trade-control-row">
                  <div nz-col [nzXs]="24" class="bidorbuy">
                    <div class="padding">
                      <span>{{ 'PRICE' | translate }}:</span>
                      <div class="price-container">
                        <app-currency
                          [value]="+item.priceWithoutTax"
                          [exchangeRate]="exchangeRate"
                          layout="vertical"
                        ></app-currency>
                      </div>
                      <app-info-row
                        [label]="'PRICE_WITH_TAX' | translate"
                        [text]="item.price?.toLocaleString() + ' ¥'"
                      ></app-info-row>
                      <app-info-row
                        [label]="'JP_DELIVERY' | translate"
                        [text]="
                          item.shippingPrice !== null
                            ? item.shippingPrice.toLocaleString() + ' ¥'
                            : ('JP_DELIVERY_UNKNOWN' | translate)
                        "
                      ></app-info-row>
                    </div>
                    <nz-spin [nzSpinning]="buying">
                      <div
                        *ngIf="currentUser && item.status !== 'sold'"
                        class="buyout"
                        (click)="showConfirm()"
                      >
                        {{ 'AUCTION.BUY' | translate }}
                      </div>
                    </nz-spin>
                    <div *ngIf="!currentUser" class="no-auth">
                      {{ 'AUCTION.NO_AUTH_TEMPLATE' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
                <app-info-row
                  [label]="'STATUS' | translate"
                  [text]="item.status | uppercase | translate"
                ></app-info-row>
                <app-info-row label="AUCTION.CONDITION" [text]="item.stars"></app-info-row>
                <app-info-row label="AUCTION.SELLER" [text]="item.seller?.title"></app-info-row>
                <app-info-row
                  label="PRODUCT"
                  href="https://www.croooober.com/item/{{ item.code }}"
                  [text]="item.code"
                ></app-info-row>
                <app-info-row
                  *ngFor="let detail of item?.details"
                  [label]="detail.label"
                  [text]="detail.value"
                ></app-info-row>
              </div>
            </div>
          </section>
          <hr />
          <div class="calculator">
            <app-cost-calculator
              [price]="item.price"
              [jpDelivery]="item.shippingPrice"
              [taxRate]="10"
              [services]="services"
              [allowBid]="true"
              [currentUser]="currentUser"
              [exchangeRate]="exchangeRate"
              [bidStep]="50"
              #costCaclc
            >
            </app-cost-calculator>
          </div>
          <hr *ngIf="costCaclc.isRussianUser" />
          <div *ngIf="costCaclc.isRussianUser" class="delivery-calculator">
            <app-delivery-calculator
              [currentUser]="currentUser"
              [costCalc]="costCaclc"
              [exchangeRate]="exchangeRate"
            ></app-delivery-calculator>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="item" class="section info">
    {{ item.description }}
  </section>
</nz-spin>
