import { MutationHandlingServiceV2, NotificationService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
  ChatMessageCreateGQL,
  ChatMessageCreateInput,
  ChatMessageThemeEnum,
  MessageTemplate,
  PostQaMessageGQL,
  PostQaMessageInput,
} from 'src/app/graphql/user-service';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPanelComponent implements OnInit, OnChanges {
  @Input() payload: any = null;
  @Input() isAuth = false;
  @Input() messageTemplates: MessageTemplate[] = [];
  @Input() QandA: any = null;
  @Input() bidHistory: any = null;
  @Input() unasnweredQuestions: any = null;

  qas: any[] = [];
  history: any[] = [];

  selectedTemplate: MessageTemplate | string = null;
  questionText: string = null;
  templateFields = [];

  translateEnabled = true;
  sending: Observable<boolean>;

  constructor(
    private translate: TranslateService,
    private notification: NotificationService,
    private chatMessageCreateGQL: ChatMessageCreateGQL,
    private postQaMessageGQL: PostQaMessageGQL,
    private mutation: MutationHandlingServiceV2,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    if (this.QandA.ResultSet?.Result?.QandA) {
      if (Array.isArray(this.QandA.ResultSet.Result.QandA)) {
        this.qas = this.QandA.ResultSet.Result.QandA;
      } else {
        this.qas = [this.QandA.ResultSet.Result.QandA];
      }

      this.qas.sort(
        (a, b) => new Date(b.Question.Date).getTime() - new Date(a.Question.Date).getTime(),
      );
      this.unasnweredQuestions = (this.unasnweredQuestions || []).filter(
        (qa) => !this.qas.find((q) => q.Question.Comment === qa.content),
      );
    }

    if (this.bidHistory.ResultSet?.Result) {
      if (Array.isArray(this.bidHistory.ResultSet?.Result)) {
        this.history = this.bidHistory.ResultSet?.Result;
      } else {
        this.history = [this.bidHistory.ResultSet?.Result];
      }
    }
  }

  getBidPrice(bid: any): string {
    if (bid.IsCanceled === 'true') {
      return this.translate.instant('AUCTION.BIDS_HISOTRY.BID_CANCEL');
    } else {
      return `${Math.round(bid.Price).toLocaleString()}¥`;
    }
  }

  getBidder(bid: any): string {
    if (bid.Bidder) {
      return `${bid.Bidder} ${this.getRating(bid)}`;
    } else {
      return this.translate.instant('AUCTION.BIDS_HISTORY.AUCTION_START');
    }
  }

  getRating(bid: any): string {
    return bid.Rating ? `(${bid.Rating.Point})` : '';
  }

  ask(values: any): void {
    if (this.selectedTemplate === 'custom') {
      if (this.questionText) {
        const input: ChatMessageCreateInput = {
          theme: ChatMessageThemeEnum.SellerQuestion,
          content: `Здравствуйте!\nЛот: ${this.payload.AuctionID}\nВопрос:\n${this.questionText}`,
          files: [],
        };

        this.chatMessageCreate(input);
      } else {
        this.notification.renderInfo('AUCTION.QA.NO_TEXT');
      }
    } else {
      const input: PostQaMessageInput = {
        lotCode: this.payload.AuctionID,
        messageTemplateId: (this.selectedTemplate as MessageTemplate).id,
        values: JSON.stringify(values),
      };

      this.postQaMessage(input);
    }
  }

  postQaMessage(input: PostQaMessageInput): void {
    this.sending = this.mutation.mutate(
      this.postQaMessageGQL,
      { input },
      {
        failureTranslationKey: 'AUCTION.QA.QUESTION_FAIL',
        successTranslationKey: 'AUCTION.QA.QUESTION_SUCCESS',
        refetch: false,
      },
    ).loading;
  }

  chatMessageCreate(input: ChatMessageCreateInput): void {
    this.sending = this.mutation.mutate(
      this.chatMessageCreateGQL,
      { input },
      {
        failureTranslationKey: 'AUCTION.QA.QUESTION_FAIL',
        successTranslationKey: 'AUCTION.QA.QUESTION_SUCCESS',
        refetch: false,
      },
    ).loading;
  }

  onTemplateChange(template: MessageTemplate) {
    if (!template || template === null) {
      this.templateFields = [];
      return;
    }

    const rx = /(?<variable>\{(?<name>[A-z]+):(?<type>[A-z]+)\})/gm;
    let match: RegExpExecArray;
    const fields = [];

    // tslint:disable-next-line: no-conditional-assignment
    while ((match = rx.exec(template.content))) {
      fields.push(match.groups);
    }
    this.templateFields = fields;
  }

  ngOnChanges(): void {
    this.init();
  }
}
