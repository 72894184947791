<nz-row [nzGutter]="16" style="margin-bottom: 20px">
  <nz-col *ngFor="let item of shopCatalogStores">
    <img
      [src]="item.imageUrl"
      [class]="currentStoreId == item.id ? 'selected-store-image' : 'store-image'"
      (click)="filterStore(item.id)"
      style="cursor: pointer"
    />
  </nz-col>
</nz-row>
