import { AkebonoCoreModule, ApolloClientConfig, AuthenticationService } from '@akebono/core';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, registerLocaleData } from '@angular/common';
import ru from '@angular/common/locales/ru';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  AppstoreOutline,
  ArrowLeftOutline,
  ArrowRightOutline,
  BarsOutline,
  DeleteOutline,
  HeartTwoTone,
  InboxOutline,
  InstagramOutline,
  LinkOutline,
  LoginOutline,
  MinusOutline,
  PlusOutline,
  SearchOutline,
  SettingOutline,
  ShoppingCartOutline,
  StarTwoTone,
  UserOutline,
  FundOutline,
} from '@ant-design/icons-angular/icons';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { en_US, NZ_I18N, ru_RU } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationServiceModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzRateModule } from 'ng-zorro-antd/rate';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CookieService } from 'ngx-cookie-service';

import { environment } from '../environments/environment';
import { en as enLang } from '../locale/en';
import { ru as ruLang } from '../locale/ru';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import { CartComponent } from './components/cart/cart.component';
import { HomeComponent } from './components/home/home.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import userIntrospectionResult from './graphql/user-service';
import { LocationGuard } from './guards/location.guard';
import { AutopartsModule } from './modules/autoparts/autoparts.module';
import { CatalogsModule } from './modules/catalogs/catalogs.module';
import { ContentModule } from './modules/content/content.module';
import { CrooberModule } from './modules/croober/croober.module';
import { RakutenModule } from './modules/rakuten/rakuten.module';
import { SharedModule } from './modules/shared/shared.module';
import { YahooAuctionsModule } from './modules/yahoo-auctions/yahoo-auctions.module';
import { YahooShoppingModule } from './modules/yahoo-shopping/yahoo-shopping.module';
import { GoogleTranslateService } from './services/google-translate.service';
import { MutationHandlingService } from './services/mutation-handling.service';
import { SearchService } from './services/search.service';

// import { MercariModule } from './modules/mercari/mercari.module';
registerLocaleData(ru);

// @Injectable()
// class AkebonoErrorHandler extends ErrorHandler {
//   constructor(private angulartics2: Angulartics2) {
//     super();
//   }

//   handleError(error: Error) {
//     console.log(error);
//     this.angulartics2.eventTrack.next({
//       action: 'Error',
//       properties: {
//         message: error.message,
//         name: error.name,
//         stack: error.stack,
//         string: String(error),
//       },
//     });
//   }
// }

export function initGraphQLConfig(): ApolloClientConfig[] {
  return environment.graphql.map((c) => {
    if (c.clientName === 'shared-shop') {
      return { ...c, introspectionResult: userIntrospectionResult };
    }
    return c;
  });
}

const icons: IconDefinition[] = [
  InstagramOutline,
  UserOutline,
  SearchOutline,
  HeartTwoTone,
  ArrowLeftOutline,
  ArrowRightOutline,
  InboxOutline,
  ShoppingCartOutline,
  LoginOutline,
  StarTwoTone,
  DeleteOutline,
  PlusOutline,
  MinusOutline,
  BarsOutline,
  AppstoreOutline,
  SettingOutline,
  LinkOutline,
  FundOutline,
];

function localeFactory(translate: TranslateService) {
  if (!document.cookie.includes('lang')) {
    console.log('setting lang to ru');
    translate.use('ru');
    // translate.use('en');
  }
  return translate.currentLang;
}

@NgModule({
  declarations: [LayoutComponent, HomeComponent, AppComponent, NotFoundComponent, CartComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    LayoutModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),
    AkebonoCoreModule.forRoot({
      domain: environment.domain,
      cognito: environment.cognito,
      apolloClientsConfig: initGraphQLConfig as any,
    }),
    NzLayoutModule,
    NzGridModule,
    NzIconModule.forRoot(icons),
    NzButtonModule,
    NzAvatarModule,
    NzDropDownModule,
    NzMenuModule,
    NzSelectModule,
    NzInputModule,
    NzSpinModule,
    NzCardModule,
    NzCollapseModule,
    NzBreadCrumbModule,
    NzInputNumberModule,
    NzTagModule,
    NzListModule,
    NzDescriptionsModule,
    NzDividerModule,
    NzPaginationModule,
    NzFormModule,
    NzRadioModule,
    NzRateModule,
    NzCheckboxModule,
    NzPageHeaderModule,
    NzTabsModule,
    NzTableModule,
    NzResultModule,
    NzEmptyModule,
    NzModalModule,
    NzSwitchModule,
    NzPopoverModule,
    NzCascaderModule,
    NzPopconfirmModule,
    NzSkeletonModule,
    NzBadgeModule,
    NzToolTipModule,
    NzAlertModule,
    NzImageModule,
    NzDrawerModule,
    NzNotificationServiceModule,
    CatalogsModule,
    CrooberModule,
    SharedModule,
    YahooShoppingModule,
    RakutenModule,
    // MercariModule,
    AutopartsModule,
    ContentModule,
    YahooAuctionsModule,
  ],
  providers: [
    CookieService,
    AuthenticationService,
    GoogleTranslateService,
    SearchService,
    MutationHandlingService,
    LocationGuard,
    Location,
    Title,
    TranslatePipe,
    TranslateService,
    { provide: NZ_I18N, useValue: en_US },
    { provide: NZ_I18N, useValue: ru_RU },
    { provide: LOCALE_ID, useFactory: localeFactory, deps: [TranslateService] },
    // { provide: ErrorHandler, useClass: AkebonoErrorHandler },
  ],
  bootstrap: [LayoutComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    this.translate.setTranslation('en', enLang, true);
    this.translate.setTranslation('ru', ruLang, true);
    const userId = localStorage.getItem(
      `CognitoIdentityServiceProvider.${environment.cognito.ClientId}.LastAuthUser`,
    );
    // if (userId) {
    //   this.angulartics2.setUsername.next(userId);
    // }
  }
}
