import { ConfigService } from '@akebono/core';
import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, map, skip, Subscription } from 'rxjs';
import { Service } from 'src/app/graphql/user-service';

@Pipe({
  name: 'serviceTitle',
  pure: false,
})
export class ServiceTitlePipe implements PipeTransform, OnDestroy {
  private language: string;

  private readonly subscription: Subscription;

  constructor(
    cdr: ChangeDetectorRef,
    configService: ConfigService,
    private readonly translateService: TranslateService,
  ) {
    this.language = configService.getCurrentLanguage()?.localeId;
    this.subscription = configService
      .getCurrentLanguage$()
      .pipe(
        map((lang) => lang.localeId),
        distinctUntilChanged(),
        skip(1),
      )
      .subscribe((language) => {
        this.language = language;
        cdr.markForCheck();
      });
  }

  transform(service: Service): string {
    const { title, code } = service;
    const serviceCodeTranslationKey = `SERVICES.${code}`;

    if (this.language === 'ru' || !code) {
      return title;
    }

    const serviceCodeTranslation = this.translateService.instant(serviceCodeTranslationKey);
    if (serviceCodeTranslation === serviceCodeTranslationKey) {
      return title;
    } else {
      return serviceCodeTranslation;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
