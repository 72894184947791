<nz-spin [nzSpinning]="loading" nzSize="large">
  <section class="section filter skiptranslate">
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="'CATEGORY.FILTER' | translate">
        <app-lots-filter [filter]="filter"></app-lots-filter>
      </nz-collapse-panel>
    </nz-collapse>
  </section>

  <app-lots
    [items]="items"
    [favourites]="currentUser?.favourites.nodes"
    [exchangeRate]="exchangeRate"
    [sellerId]="sellerId"
    [currentTime]="currentTime"
    [page]="page"
    [pageSize]="pageSize"
    [total]="total"
  ></app-lots>
</nz-spin>
