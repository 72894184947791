<section>
  <div class="img" (click)="openImage(selectedImageIndex)">
    <img referrerpolicy="no-referrer" [src]="images[selectedImageIndex]" />
  </div>
  <nz-image-group class="thumbs">
    <img
      *ngFor="let img of images; index as i"
      referrerpolicy="no-referrer"
      class="thumb"
      (mouseover)="select(i)"
      (click)="openImage(i)"
      [src]="images[i]"
    />
  </nz-image-group>
</section>
