import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CatalogsRoutingModule } from './catalogs-routing.module';
import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { ListingsComponent } from './components/listings/listings.component';
import { TopFilterComponent } from './components/listings/top-filter/top-filter.component';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@NgModule({
  declarations: [ListingsComponent, AddToCartComponent, TopFilterComponent],
  imports: [
    CommonModule,
    CatalogsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NzImageModule,
    NzGridModule,
    NzListModule,
    NzButtonModule,
    NzCardModule,
    NzLayoutModule,
    NzPaginationModule,
    NzInputModule,
    NzEmptyModule,
  ],
})
export class CatalogsModule {}
