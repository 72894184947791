<section>
  <div class="img" (click)="openImage(selectedImgIndex)">
    <img [src]="selectedImg?.src" />
  </div>
  <nz-image-group class="thumbs">
    <img
      *ngFor="let img of imgs; index as i"
      class="thumb"
      nz-image
      (mouseover)="select(i)"
      [nzSrc]="img.src"
      [nzPlaceholder]="img.thumb"
    />
  </nz-image-group>
</section>
