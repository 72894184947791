<button
  *ngIf="!isInCart"
  nzSize="large"
  nz-button
  nzType="dashed"
  (click)="add()"
  [nzLoading]="adding$ | async"
>
  <span nz-icon nzType="plus"></span>
  Add
</button>
<div *ngIf="isInCart">
  <nz-input-group nzSize="large" nzCompact style="width: 200px">
    <button
      nzSize="large"
      nz-button
      nzType="primary"
      (click)="decrease()"
      [nzDanger]="cartItem?.quantity == 1"
      [disabled]="decreasing$ | async"
    >
      -
    </button>
    <input
      nz-input
      type="number"
      min="1"
      style="width: 70px"
      nzSize="large"
      [ngModel]="cartItem?.quantity"
      readonly
    />
    <button nzSize="large" (click)="increase()" nz-button [disabled]="increasing$ | async">
      +
    </button>
  </nz-input-group>
</div>
