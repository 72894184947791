import { NotificationService } from '@akebono/core';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import {
  CrooberItemGQL,
  CrooberItemQuery,
  CrooberItemQueryVariables,
  CrooberOrderCreateGQL,
  ExchangeRate,
  Service,
} from 'src/app/graphql/user-service';
import { GoogleTranslateService } from 'src/app/services/google-translate.service';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';

@Component({
  selector: 'app-croober-item',
  templateUrl: './croober-item.component.html',
  styleUrls: ['./croober-item.component.scss'],
})
export class CrooberItemComponent implements OnDestroy {
  item: any = null;
  loading = false;
  currentUser = null;
  services: Service[] = [];
  exchangeRate: ExchangeRate = null;
  queryRef: QueryRef<CrooberItemQuery, CrooberItemQueryVariables>;
  sub: Subscription;
  buying = false;

  constructor(
    private crooberProductGQL: CrooberItemGQL,
    private crooberOrderCreateGQL: CrooberOrderCreateGQL,
    private route: ActivatedRoute,
    private googleTranslate: GoogleTranslateService,
    private notification: NotificationService,
    private apollo: Apollo,
    private searchService: SearchService,
  ) {
    this.loading = true;
    this.queryRef = this.crooberProductGQL.watch({
      id: this.route.snapshot.params.id,
    });
    this.searchService.currentService.next(SearchServiceEnum.Croober);
    this.sub = this.queryRef.valueChanges.subscribe((result) => {
      console.log('result :', result);
      this.item = result.data.crooberProduct;
      this.currentUser = result.data.currentUser;
      this.exchangeRate = result.data.exchangeRate;
      this.services = result.data.services.nodes as unknown as Service[];
      this.loading = false;
      this.googleTranslate.translate();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  showConfirm() {
    if (this.item.shippingPrice === null) {
      this.notification.renderError('Покупка лотов с самовывозом недоступна');
      return;
    }

    this.buying = true;
    this.crooberOrderCreateGQL
      .mutate({
        input: {
          itemId: this.route.snapshot.params.id,
          quantity: 1,
          comment: '',
        },
      })
      .subscribe(
        (result) => {
          console.log('Yahoo bid create response', result);

          if (!result || !result.data) {
            this.buying = false;
            this.notification.renderError('Empty response');
            return;
          }

          if (result.data.crooberOrderCreate.error) {
            this.buying = false;
            this.notification.renderError(
              'AUCTION.LOT_BUY_FAIL',
              result.data.crooberOrderCreate.error.message ||
                result.data.crooberOrderCreate.error.code,
            );
          } else {
            this.apollo
              .use(this.crooberOrderCreateGQL.client)
              .getClient()
              .reFetchObservableQueries()
              .then(() => {
                this.buying = false;
                this.notification.renderSuccess('AUCTION.LOT_BUY_SUCCESS');
              });
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.notification.renderError('Error', error);
        },
      );
  }

  update() {
    this.loading = true;
    this.queryRef.refetch().finally(() => {
      this.loading = false;
    });
  }
}
