import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@akebono/core';
import {
  ShoppingSearchGQL,
  ExchangeRate,
  CurrentUser,
  CartItemCreateGQL,
  CartItemTypeEnum,
  CartItem,
  CartItemUpdateGQL,
  CartItemDeleteGQL,
  CartItemUpdateInput,
  CartItemDeleteInput,
  CartItemCreateInput,
  CartItemsGQL,
} from 'src/app/graphql/user-service';
import { Subscription } from 'rxjs';
import { Loadings, scrollToTop } from 'src/app/const';
import { GoogleTranslateService } from 'src/app/services/google-translate.service';
import { Apollo } from 'apollo-angular';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';
import {
  ShoppingSearchFilter,
  ShoppingSearchFilterComponent,
} from './components/shopping-search-filter/shopping-search-filter.component';

interface CartItemsByCode {
  [code: string]: CartItem;
}

@Component({
  selector: 'app-shopping-search',
  templateUrl: './shopping-search.component.html',
  styleUrls: ['./shopping-search.component.scss'],
})
export class ShoppingSearchComponent implements OnInit, OnDestroy {
  items: any = null;
  cartItems: CartItemsByCode = {};
  currentUser: CurrentUser = null;
  exchangeRate: ExchangeRate = null;

  filter: ShoppingSearchFilter = null;

  page = 1;
  pageSize = 20;
  total = 0;

  loading = false;
  isAuthenticated = false;

  adding: Loadings = {};
  increasing: Loadings = {};
  decreasing: Loadings = {};

  cartItemsQueryRef: any = null;

  subscription: Subscription = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private googleTranslate: GoogleTranslateService,
    private apollo: Apollo,
    private searchService: SearchService,
    private cartItemsGQL: CartItemsGQL,
    private shoppingSearchGQL: ShoppingSearchGQL,
    private cartItemCreateGQL: CartItemCreateGQL,
    private cartItemUpdateGQL: CartItemUpdateGQL,
    private cartItemDeleteGQL: CartItemDeleteGQL,
  ) {
    this.title.setTitle(this.translate.instant('SEARCH.SEARCH'));
  }

  ngOnInit(): void {
    this.searchService.currentService.next(SearchServiceEnum.Shopping);
    this.route.queryParams.subscribe((params) => {
      this.filter = ShoppingSearchFilterComponent.getFilter(params);
      this.page = Number(params.page || 1);
      this.pageSize = Number(params.per_page || 20);

      console.log('Filter', this.filter);
      console.log('Params', params);

      this.subscription?.unsubscribe();
      this.fetchSearch(this.filter);
    });

    this.fetchCartItems();
  }

  fetchCartItems() {
    if (!this.cartItemsQueryRef) {
      this.cartItemsQueryRef = this.cartItemsGQL.watch({});
    }
    this.subscription.add(
      this.cartItemsQueryRef.valueChanges.subscribe((result) => {
        console.log('Cart items response', result);
        this.cartItems = {};
        this.isAuthenticated = Boolean(result.data.currentUser);
        if (this.isAuthenticated) {
          result.data.currentUser.cartItems
            .filter((item) => item.subject.__typename === 'YahooProduct')
            .forEach((item) => {
              this.cartItems[item.subject.code] = item;
            });
        }
      }),
    );
  }

  fetchSearch(filter: ShoppingSearchFilter): void {
    if (!filter.query && !filter.genreCategoryId && !filter.sellerId) {
      return;
    }

    this.loading = true;
    this.increasing = {};
    this.decreasing = {};
    this.adding = {};
    this.subscription = this.shoppingSearchGQL
      .watch({
        first: this.pageSize,
        offset: (this.page - 1) * this.pageSize + 1,
        ...filter,
      })
      .valueChanges.subscribe(
        (result) => {
          this.loading = false;
          console.log('Search response', result);

          if (result?.data?.shopping?.itemSearch) {
            this.items = result.data.shopping.itemSearch.hits;
            this.exchangeRate = result.data.exchangeRate;

            this.total = Number(result.data.shopping.itemSearch.totalResultsAvailable);
            this.googleTranslate.translate();
          } else {
            this.notificationService.renderError('Empty response');
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
  }

  changePage(page: number): void {
    console.log('changing page', page);
    scrollToTop();
    this.router.navigate([], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(size: number): void {
    console.log('changing page size');
    scrollToTop();
    this.router.navigate([], {
      queryParams: {
        per_page: size,
      },
      queryParamsHandling: 'merge',
    });
  }

  addToCart(item: any): void {
    const input: CartItemCreateInput = {
      item: {
        code: item.code,
        type: CartItemTypeEnum.YahooProduct,
      },
      quantity: 1,
    };

    this.cartItemCreate(input);
  }

  increaseItemQuantity(item: any): void {
    const cartItem = this.cartItems[item.code];
    const input: CartItemUpdateInput = {
      id: cartItem.id,
      quantity: cartItem.quantity + 1,
    };

    this.cartItemUpdate(input, this.increasing);
  }

  decreaseItemQuantity(item: any): void {
    const cartItem = this.cartItems[item.code];

    if (cartItem.quantity - 1 > 0) {
      const input: CartItemUpdateInput = {
        id: cartItem.id,
        quantity: cartItem.quantity - 1,
      };

      this.cartItemUpdate(input, this.decreasing);
    } else {
      const input: CartItemDeleteInput = {
        id: cartItem.id,
      };

      this.cartItemDelete(input);
    }
  }

  cartItemCreate(input: CartItemCreateInput): void {
    this.adding[input.item.code] = true;
    this.cartItemCreateGQL.mutate({ input }).subscribe(
      (result) => {
        console.log('Cart item create response', result);

        if (!result || !result.data) {
          this.adding[input.item.code] = false;
          this.notificationService.renderError('Empty response');
          return;
        }

        if (result.data.cartItemCreate.error) {
          this.adding[input.item.code] = false;
          this.notificationService.renderError(
            'CART.ADD_TO_CART.FAIL',
            result.data.cartItemCreate.error.message || result.data.cartItemCreate.error.code,
          );
        } else {
          this.adding[input.item.code] = false;
          this.notificationService.renderSuccess('CART.ADD_TO_CART.SUCCESS');
        }
      },
      (error) => {
        this.adding[input.item.code] = false;
        console.log(error);
        this.notificationService.renderError('Error', error);
      },
    );
  }

  cartItemUpdate(input: CartItemUpdateInput, loadings: Loadings): void {
    loadings[input.id] = true;
    this.cartItemUpdateGQL.mutate({ input }).subscribe(
      (result) => {
        console.log('Cart item update response', result);

        if (!result || !result.data) {
          loadings[input.id] = false;
          this.notificationService.renderError('Empty response');
          return;
        }

        if (result.data.cartItemUpdate.error) {
          loadings[input.id] = false;
          this.notificationService.renderError(
            'CART.UPDATE.FAIL',
            result.data.cartItemUpdate.error.message || result.data.cartItemUpdate.error.code,
          );
        } else {
          loadings[input.id] = false;
          this.notificationService.renderSuccess('CART.UPDATE.SUCCESS');
        }
      },
      (error) => {
        loadings[input.id] = false;
        console.log(error);
        this.notificationService.renderError('Error', error);
      },
    );
  }

  cartItemDelete(input: CartItemDeleteInput): void {
    this.decreasing[input.id] = true;
    this.cartItemDeleteGQL.mutate({ input }).subscribe(
      (result) => {
        console.log('Cart item delete response', result);

        if (!result || !result.data) {
          this.decreasing[input.id] = false;
          this.notificationService.renderError('Empty response');
          return;
        }

        if (result.data.cartItemDelete.error) {
          this.decreasing[input.id] = false;
          this.notificationService.renderError(
            'CART.UPDATE.FAIL',
            result.data.cartItemDelete.error.message || result.data.cartItemDelete.error.code,
          );
        } else {
          this.decreasing[input.id] = false;
          this.notificationService.renderSuccess('CART.UPDATE.SUCCESS');
        }
      },
      (error) => {
        this.decreasing[input.id] = false;
        console.log(error);
        this.notificationService.renderError('Error', error);
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
