<nz-spin [nzSpinning]="loading" nzSize="large">
  <section class="section breadcrumbs">
    <nz-breadcrumb>
      <ng-container>
        <nz-breadcrumb-item>
          <a routerLink="/rakuten">Rakuten</a>
        </nz-breadcrumb-item>
      </ng-container>
    </nz-breadcrumb>
  </section>

  <section class="section filter skiptranslate">
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="'CATEGORY.FILTER' | translate">
        <app-rakuten-search-filter [initFilter]="filter"></app-rakuten-search-filter>
      </nz-collapse-panel>
    </nz-collapse>
  </section>

  <ng-template #pageTemplate let-type let-page="page">
    <a *ngIf="type === 'pre'"><</a>
    <a *ngIf="type === 'next'">></a>
    <a *ngIf="type === 'prev_5'">...</a>
    <a *ngIf="type === 'next_5'">...</a>
    <a
      *ngIf="type === 'page'"
      [routerLink]="[]"
      [queryParams]="{ page: page }"
      queryParamsHandling="merge"
      >{{ page }}</a
    >
  </ng-template>
  <ng-template #totalTemplate let-total> Total items: {{ total }} </ng-template>

  <div style="display: flex; justify-content: flex-end; margin: 20px 0px">
    <nz-pagination
      [nzPageIndex]="page"
      [nzPageSize]="pageSize"
      [nzPageSizeOptions]="[20, 50, 100]"
      [nzTotal]="total"
      [nzShowTotal]="totalTemplate"
      nzShowSizeChanger
      (nzPageIndexChange)="changePage($event)"
      (nzPageSizeChange)="changePageSize($event)"
      [nzItemRender]="pageTemplate"
    ></nz-pagination>
  </div>

  <section class="section auctions">
    <div *ngIf="items">
      <nz-list nzSplit>
        <nz-list-item [nzNoFlex]="true" *ngFor="let item of items">
          <div nz-row class="body">
            <div nz-col [nzXs]="24" [nzSm]="6" [nzMd]="3" [nzLg]="3" [nzXl]="2" class="img">
              <img [src]="item.mediumImageUrls && item.mediumImageUrls[0]" />
            </div>
            <div
              nz-col
              [nzXs]="24"
              [nzSm]="18"
              [nzMd]="21"
              [nzLg]="21"
              [nzXl]="22"
              nzFlex="auto"
              class="description"
            >
              <h3 nz-typography class="ellipsis">
                <a [routerLink]="['/rakuten/item', item.itemCode]"> {{ item.itemName }} </a>
              </h3>
              <div nz-row nzGutter="20" class="stats">
                <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24" class="skiptranslate">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'LOTS.LOT' | translate">
                      <a [routerLink]="['/rakuten/item', item.itemCode]"> {{ item.itemCode }} </a>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div
                  *ngIf="item.itemPrice"
                  nz-col
                  [nzXl]="6"
                  [nzLg]="6"
                  [nzMd]="12"
                  [nzXs]="24"
                  class="skiptranslate price"
                >
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item
                      [nzTitle]="'LOTS.PRICE' | translate"
                      class="flex-description"
                    >
                      <div>
                        <app-currency
                          [value]="item.itemPrice"
                          [exchangeRate]="exchangeRate"
                        ></app-currency>
                      </div>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24" class="skiptranslate">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'LOTS.STATE' | translate">
                      {{item.reviewAverage}}
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'LOTS.SELLER' | translate">
                      <a
                        *ngIf="item.seller"
                        class="skiptranslate"
                        routerLink="/shopping/search"
                        [queryParams]="{ seller: item.seller.path, page: null }"
                        queryParamsHandling="merge"
                      >
                        {{ item.seller.title }}
                      </a>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div nz-col [nzXl]="12" [nzLg]="12" [nzMd]="12" [nzXs]="24" class="">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'LOTS.DESCRIPTION' | translate">
                      <span *ngIf="item.Description">{{ item.itemCaption }}</span>
                      <span *ngIf="!item.Description">{{ item.itemName }}</span>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
              </div>
            </div>
          </div>
        </nz-list-item>
      </nz-list>

      <div style="display: flex; justify-content: flex-end; margin-top: 20px">
        <nz-pagination
          [nzPageIndex]="page"
          [nzPageSize]="pageSize"
          [nzPageSizeOptions]="[20, 50, 100]"
          [nzTotal]="total"
          [nzShowTotal]="totalTemplate"
          nzShowSizeChanger
          (nzPageIndexChange)="changePage($event)"
          (nzPageSizeChange)="changePageSize($event)"
          [nzItemRender]="pageTemplate"
        ></nz-pagination>
      </div>
    </div>
    <div *ngIf="!items" class="empty">
      <nz-empty></nz-empty>
    </div>
  </section>
</nz-spin>
