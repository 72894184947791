<nz-spin [nzSpinning]="loading" nzSize="large">
  <nz-page-header nzBackIcon [nzGhost]="false" (nzBack)="goToNews()">
    <nz-page-header-title>{{newsTitle}}</nz-page-header-title>
    <nz-page-header-extra>
      <div class="published-at">
        {{ news?.publishedAt | date: 'short' }}
      </div>
    </nz-page-header-extra>
    <nz-page-header-content>
      <div *ngIf="news?.image" class="news-image">
        <img [src]="news?.image" />
      </div>
      <div
        class="content"
        [innerHTML]="newsContent"
      ></div>
    </nz-page-header-content>
  </nz-page-header>
</nz-spin>
