export const PRICE_EMS = [
  {
    weight: 0.5,
    weight_unit: 'kg',
    zone1_price: 1400,
    zone1_price_currency: 'yen',
    zone2_price: 2000,
    zone2_price_currency: 'yen',
    zone3_price: 2200,
    zone3_price_currency: 'yen',
    zone4_price: 2400,
  },
  {
    weight: 0.6,
    weight_unit: 'kg',
    zone1_price: 1540,
    zone1_price_currency: 'yen',
    zone2_price: 2180,
    zone2_price_currency: 'yen',
    zone3_price: 2400,
    zone3_price_currency: 'yen',
    zone4_price: 2740,
  },
  {
    weight: 0.7,
    weight_unit: 'kg',
    zone1_price: 1680,
    zone1_price_currency: 'yen',
    zone2_price: 2360,
    zone2_price_currency: 'yen',
    zone3_price: 2600,
    zone3_price_currency: 'yen',
    zone4_price: 3080,
  },
  {
    weight: 0.8,
    weight_unit: 'kg',
    zone1_price: 1820,
    zone1_price_currency: 'yen',
    zone2_price: 2540,
    zone2_price_currency: 'yen',
    zone3_price: 2800,
    zone3_price_currency: 'yen',
    zone4_price: 3420,
  },
  {
    weight: 0.9,
    weight_unit: 'kg',
    zone1_price: 1960,
    zone1_price_currency: 'yen',
    zone2_price: 2720,
    zone2_price_currency: 'yen',
    zone3_price: 3000,
    zone3_price_currency: 'yen',
    zone4_price: 3760,
  },
  {
    weight: 1,
    weight_unit: 'kg',
    zone1_price: 2100,
    zone1_price_currency: 'yen',
    zone2_price: 2900,
    zone2_price_currency: 'yen',
    zone3_price: 3200,
    zone3_price_currency: 'yen',
    zone4_price: 4100,
  },
  {
    weight: 1.25,
    weight_unit: 'kg',
    zone1_price: 2400,
    zone1_price_currency: 'yen',
    zone2_price: 3300,
    zone2_price_currency: 'yen',
    zone3_price: 3650,
    zone3_price_currency: 'yen',
    zone4_price: 4900,
  },
  {
    weight: 1.5,
    weight_unit: 'kg',
    zone1_price: 2700,
    zone1_price_currency: 'yen',
    zone2_price: 3700,
    zone2_price_currency: 'yen',
    zone3_price: 4100,
    zone3_price_currency: 'yen',
    zone4_price: 5700,
  },
  {
    weight: 1.75,
    weight_unit: 'kg',
    zone1_price: 3000,
    zone1_price_currency: 'yen',
    zone2_price: 4100,
    zone2_price_currency: 'yen',
    zone3_price: 4550,
    zone3_price_currency: 'yen',
    zone4_price: 6500,
  },
  {
    weight: 2,
    weight_unit: 'kg',
    zone1_price: 3300,
    zone1_price_currency: 'yen',
    zone2_price: 4500,
    zone2_price_currency: 'yen',
    zone3_price: 5000,
    zone3_price_currency: 'yen',
    zone4_price: 7300,
  },
  {
    weight: 2.5,
    weight_unit: 'kg',
    zone1_price: 3800,
    zone1_price_currency: 'yen',
    zone2_price: 5200,
    zone2_price_currency: 'yen',
    zone3_price: 5800,
    zone3_price_currency: 'yen',
    zone4_price: 8800,
  },
  {
    weight: 3,
    weight_unit: 'kg',
    zone1_price: 4300,
    zone1_price_currency: 'yen',
    zone2_price: 5900,
    zone2_price_currency: 'yen',
    zone3_price: 6600,
    zone3_price_currency: 'yen',
    zone4_price: 10300,
  },
  {
    weight: 3.5,
    weight_unit: 'kg',
    zone1_price: 4800,
    zone1_price_currency: 'yen',
    zone2_price: 6600,
    zone2_price_currency: 'yen',
    zone3_price: 7400,
    zone3_price_currency: 'yen',
    zone4_price: 11800,
  },
  {
    weight: 4,
    weight_unit: 'kg',
    zone1_price: 5300,
    zone1_price_currency: 'yen',
    zone2_price: 7300,
    zone2_price_currency: 'yen',
    zone3_price: 8200,
    zone3_price_currency: 'yen',
    zone4_price: 13300,
  },
  {
    weight: 4.5,
    weight_unit: 'kg',
    zone1_price: 5800,
    zone1_price_currency: 'yen',
    zone2_price: 8000,
    zone2_price_currency: 'yen',
    zone3_price: 9000,
    zone3_price_currency: 'yen',
    zone4_price: 14800,
  },
  {
    weight: 5,
    weight_unit: 'kg',
    zone1_price: 6300,
    zone1_price_currency: 'yen',
    zone2_price: 8700,
    zone2_price_currency: 'yen',
    zone3_price: 9800,
    zone3_price_currency: 'yen',
    zone4_price: 16300,
  },
  {
    weight: 5.5,
    weight_unit: 'kg',
    zone1_price: 6800,
    zone1_price_currency: 'yen',
    zone2_price: 9400,
    zone2_price_currency: 'yen',
    zone3_price: 10600,
    zone3_price_currency: 'yen',
    zone4_price: 17800,
  },
  {
    weight: 6,
    weight_unit: 'kg',
    zone1_price: 7300,
    zone1_price_currency: 'yen',
    zone2_price: 10100,
    zone2_price_currency: 'yen',
    zone3_price: 11400,
    zone3_price_currency: 'yen',
    zone4_price: 19300,
  },
  {
    weight: 7,
    weight_unit: 'kg',
    zone1_price: 8100,
    zone1_price_currency: 'yen',
    zone2_price: 11200,
    zone2_price_currency: 'yen',
    zone3_price: 12700,
    zone3_price_currency: 'yen',
    zone4_price: 21400,
  },
  {
    weight: 8,
    weight_unit: 'kg',
    zone1_price: 8900,
    zone1_price_currency: 'yen',
    zone2_price: 12300,
    zone2_price_currency: 'yen',
    zone3_price: 14000,
    zone3_price_currency: 'yen',
    zone4_price: 23500,
  },
  {
    weight: 9,
    weight_unit: 'kg',
    zone1_price: 9700,
    zone1_price_currency: 'yen',
    zone2_price: 13400,
    zone2_price_currency: 'yen',
    zone3_price: 15300,
    zone3_price_currency: 'yen',
    zone4_price: 25600,
  },
  {
    weight: 10,
    weight_unit: 'kg',
    zone1_price: 10500,
    zone1_price_currency: 'yen',
    zone2_price: 14500,
    zone2_price_currency: 'yen',
    zone3_price: 16600,
    zone3_price_currency: 'yen',
    zone4_price: 27700,
  },
  {
    weight: 11,
    weight_unit: 'kg',
    zone1_price: 11300,
    zone1_price_currency: 'yen',
    zone2_price: 15600,
    zone2_price_currency: 'yen',
    zone3_price: 17900,
    zone3_price_currency: 'yen',
    zone4_price: 29800,
  },
  {
    weight: 12,
    weight_unit: 'kg',
    zone1_price: 12100,
    zone1_price_currency: 'yen',
    zone2_price: 16700,
    zone2_price_currency: 'yen',
    zone3_price: 19200,
    zone3_price_currency: 'yen',
    zone4_price: 31900,
  },
  {
    weight: 13,
    weight_unit: 'kg',
    zone1_price: 12900,
    zone1_price_currency: 'yen',
    zone2_price: 17800,
    zone2_price_currency: 'yen',
    zone3_price: 20500,
    zone3_price_currency: 'yen',
    zone4_price: 34000,
  },
  {
    weight: 14,
    weight_unit: 'kg',
    zone1_price: 13700,
    zone1_price_currency: 'yen',
    zone2_price: 18900,
    zone2_price_currency: 'yen',
    zone3_price: 21800,
    zone3_price_currency: 'yen',
    zone4_price: 36100,
  },
  {
    weight: 15,
    weight_unit: 'kg',
    zone1_price: 14500,
    zone1_price_currency: 'yen',
    zone2_price: 20000,
    zone2_price_currency: 'yen',
    zone3_price: 23100,
    zone3_price_currency: 'yen',
    zone4_price: 38200,
  },
  {
    weight: 16,
    weight_unit: 'kg',
    zone1_price: 15300,
    zone1_price_currency: 'yen',
    zone2_price: 21100,
    zone2_price_currency: 'yen',
    zone3_price: 24400,
    zone3_price_currency: 'yen',
    zone4_price: 40300,
  },
  {
    weight: 17,
    weight_unit: 'kg',
    zone1_price: 16100,
    zone1_price_currency: 'yen',
    zone2_price: 22200,
    zone2_price_currency: 'yen',
    zone3_price: 25700,
    zone3_price_currency: 'yen',
    zone4_price: 42400,
  },
  {
    weight: 18,
    weight_unit: 'kg',
    zone1_price: 16900,
    zone1_price_currency: 'yen',
    zone2_price: 23300,
    zone2_price_currency: 'yen',
    zone3_price: 27000,
    zone3_price_currency: 'yen',
    zone4_price: 44500,
  },
  {
    weight: 19,
    weight_unit: 'kg',
    zone1_price: 17700,
    zone1_price_currency: 'yen',
    zone2_price: 24400,
    zone2_price_currency: 'yen',
    zone3_price: 28300,
    zone3_price_currency: 'yen',
    zone4_price: 46600,
  },
  {
    weight: 20,
    weight_unit: 'kg',
    zone1_price: 18500,
    zone1_price_currency: 'yen',
    zone2_price: 25500,
    zone2_price_currency: 'yen',
    zone3_price: 29600,
    zone3_price_currency: 'yen',
    zone4_price: 48700,
  },
  {
    weight: 21,
    weight_unit: 'kg',
    zone1_price: 19300,
    zone1_price_currency: 'yen',
    zone2_price: 26600,
    zone2_price_currency: 'yen',
    zone3_price: 30900,
    zone3_price_currency: 'yen',
    zone4_price: 50800,
  },
  {
    weight: 22,
    weight_unit: 'kg',
    zone1_price: 20100,
    zone1_price_currency: 'yen',
    zone2_price: 27700,
    zone2_price_currency: 'yen',
    zone3_price: 32200,
    zone3_price_currency: 'yen',
    zone4_price: 52900,
  },
  {
    weight: 23,
    weight_unit: 'kg',
    zone1_price: 20900,
    zone1_price_currency: 'yen',
    zone2_price: 28800,
    zone2_price_currency: 'yen',
    zone3_price: 33500,
    zone3_price_currency: 'yen',
    zone4_price: 55000,
  },
  {
    weight: 24,
    weight_unit: 'kg',
    zone1_price: 21700,
    zone1_price_currency: 'yen',
    zone2_price: 29900,
    zone2_price_currency: 'yen',
    zone3_price: 34800,
    zone3_price_currency: 'yen',
    zone4_price: 57100,
  },
  {
    weight: 25,
    weight_unit: 'kg',
    zone1_price: 22500,
    zone1_price_currency: 'yen',
    zone2_price: 31000,
    zone2_price_currency: 'yen',
    zone3_price: 36100,
    zone3_price_currency: 'yen',
    zone4_price: 59200,
  },
  {
    weight: 26,
    weight_unit: 'kg',
    zone1_price: 23300,
    zone1_price_currency: 'yen',
    zone2_price: 32100,
    zone2_price_currency: 'yen',
    zone3_price: 37400,
    zone3_price_currency: 'yen',
    zone4_price: 61300,
  },
  {
    weight: 27,
    weight_unit: 'kg',
    zone1_price: 24100,
    zone1_price_currency: 'yen',
    zone2_price: 33200,
    zone2_price_currency: 'yen',
    zone3_price: 38700,
    zone3_price_currency: 'yen',
    zone4_price: 63400,
  },
  {
    weight: 28,
    weight_unit: 'kg',
    zone1_price: 24900,
    zone1_price_currency: 'yen',
    zone2_price: 34300,
    zone2_price_currency: 'yen',
    zone3_price: 40000,
    zone3_price_currency: 'yen',
    zone4_price: 65500,
  },
  {
    weight: 29,
    weight_unit: 'kg',
    zone1_price: 25700,
    zone1_price_currency: 'yen',
    zone2_price: 35400,
    zone2_price_currency: 'yen',
    zone3_price: 41300,
    zone3_price_currency: 'yen',
    zone4_price: 67600,
  },
  {
    weight: 30,
    weight_unit: 'kg',
    zone1_price: 26500,
    zone1_price_currency: 'yen',
    zone2_price: 36500,
    zone2_price_currency: 'yen',
    zone3_price: 42600,
    zone3_price_currency: 'yen',
    zone4_price: 69700,
  },
];
