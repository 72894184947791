<nz-spin [nzSpinning]="loading" nzSize="large">
  <div class="container skiptranslate">
    <div nz-row nzType="flex" [nzGutter]="[15, 15]">
      <ng-container *ngFor="let category of categories">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="8" [nzXl]="6">
          <nz-card [nzTitle]="title" [nzBodyStyle]="{ heigth: '100%' }">
            <ng-template #title>
              <a
                *ngIf="category.code && !category.searchQuery"
                routerLink="/category/{{ category.code }}"
              >
                {{ category.title }}
              </a>
              <a
                *ngIf="category.useSearch"
                routerLink="{{ getPathname(category.searchQuery) }}"
                [queryParams]="getQueryParams(category.searchQuery)"
              >
                {{ category.title }}
              </a>
              <span *ngIf="!category.code && !category.searchQuery">
                {{ category.title }}
              </span>
            </ng-template>

            <div class="body">
              <ng-container *ngFor="let subcat of category.children">
                <span class="tag">
                  <a
                    *ngIf="subcat.code && !subcat.searchQuery"
                    routerLink="/category/{{ subcat.code }}"
                  >
                    {{ subcat.title }}
                  </a>
                  <a
                    *ngIf="subcat.useSearch"
                    routerLink="{{ getPathname(subcat.searchQuery) }}"
                    [queryParams]="getQueryParams(subcat.searchQuery)"
                  >
                    {{ subcat.title }}
                  </a>
                </span>
              </ng-container>
            </div>
            <!-- 26318 - auto category; 26322 - parts category -->
            <div
              *ngIf="category.code === '26318' || category.code === '26422'"
              class="parts-search"
            >
              <a [routerLink]="['/parts']">
                <i nz-icon nzType="search" nzTheme="outline"></i>
                {{ 'CATEGORIES.PARTS_SEARCH' | translate }}
              </a>
            </div>
          </nz-card>
        </div>
      </ng-container>
    </div>
  </div>
</nz-spin>
