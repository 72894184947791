import { Component, OnInit, Input } from '@angular/core';
import {
  RussianDeliveryCalculationCreateGQL,
  CurrentUser,
  RussianDeliveryCalculationCreateInput,
  ExchangeRate,
  AuctionQuery,
} from 'src/app/graphql/user-service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CostCalculatorComponent } from '../cost-calculator/cost-calculator.component';

@Component({
  selector: 'app-delivery-calculator',
  templateUrl: './delivery-calculator.component.html',
  styleUrls: ['./delivery-calculator.component.scss'],
})
export class DeliveryCalculatorComponent implements OnInit {
  @Input() currentUser: AuctionQuery['currentUser'] = null;
  @Input() costCalc: CostCalculatorComponent = null;
  @Input() exchangeRate: ExchangeRate = null;

  form: FormGroup = null;

  rates: any = null;

  loading = false;

  constructor(
    private fb: FormBuilder,
    private russianDeliveryCalculationCreateGQL: RussianDeliveryCalculationCreateGQL,
  ) {}

  ngOnInit(): void {
    const address = this.currentUser?.addresses?.find((a) => a.default);
    this.form = this.fb.group({
      city: this.fb.control(address?.city),
      zip: this.fb.control(address?.zip),
      insurance: this.fb.control(0),
      length: this.fb.control(null),
      width: this.fb.control(null),
      height: this.fb.control(null),
    });
  }

  calculate(): void {
    console.log('calculating');
    const input: RussianDeliveryCalculationCreateInput = {
      toCity: this.form.value.city?.trim(),
      weight: this.costCalc.selectedWeight,
      strah: this.form.value.insurance,
      zip: Number(this.form.value.zip),
      hg: this.form.value.height,
      ln: this.form.value.length,
      wd: this.form.value.width,
    };

    if (
      input.toCity &&
      input.weight &&
      input.strah &&
      input.zip &&
      input.hg &&
      input.ln &&
      input.wd
    ) {
      this.loading = true;
      this.russianDeliveryCalculationCreateGQL.mutate({ input }).subscribe(
        (result) => {
          this.loading = false;
          console.log('Russian delivery calculation create response', result);
          if (
            result &&
            result.data &&
            result.data.russianDeliveryCalculationCreate.calculation.rsp &&
            result.data.russianDeliveryCalculationCreate.calculation.rsp.stat === '1'
          ) {
            this.rates =
              (result.data.russianDeliveryCalculationCreate.calculation.rsp?.tarif as any[]) || [];
            this.rates.sort((a, b) => {
              if (a.company > b.company) {
                return -1;
              }
              if (b.company > a.company) {
                return 1;
              }
              return 0;
            });
          }
        },
        (error) => {
          console.log(error);
        },
      );
    }

    console.log(input);
  }

  getRateName(rate: any): string {
    return `${rate.company} ${rate.name}`.trim();
  }
}
