import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A valid DateTime, transported as a iso8601 string */
  DateTime: any;
  /** A valid JSON object */
  JSON: any;
  /** A secure, escaped string! */
  SecureString: any;
};

export type Account = {
  __typename?: 'Account';
  currency: Scalars['String'];
  identifier: IdentifierEnum;
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  countryIso?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  passportDateOfBirth?: Maybe<Scalars['String']>;
  passportDateOfIssue?: Maybe<Scalars['String']>;
  passportIssuer?: Maybe<Scalars['String']>;
  passportNo?: Maybe<Scalars['String']>;
  passportSerial?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  shopCdekTariffPriceId?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AddressCreate */
export type AddressCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  countryIso: Scalars['SecureString'];
  city: Scalars['SecureString'];
  zip: Scalars['SecureString'];
  state: Scalars['SecureString'];
  address1: Scalars['SecureString'];
  address2?: Maybe<Scalars['SecureString']>;
  phone?: Maybe<Scalars['SecureString']>;
  comment?: Maybe<Scalars['SecureString']>;
  default: Scalars['Boolean'];
  firstName?: Maybe<Scalars['SecureString']>;
  middleName?: Maybe<Scalars['SecureString']>;
  lastName?: Maybe<Scalars['SecureString']>;
  passportNo?: Maybe<Scalars['SecureString']>;
  passportSerial?: Maybe<Scalars['SecureString']>;
  passportIssuer?: Maybe<Scalars['SecureString']>;
  passportDateOfIssue?: Maybe<Scalars['SecureString']>;
  passportDateOfBirth?: Maybe<Scalars['SecureString']>;
  tin?: Maybe<Scalars['SecureString']>;
};

/** Autogenerated return type of AddressCreate. */
export type AddressCreatePayload = {
  __typename?: 'AddressCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of AddressDelete */
export type AddressDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of AddressDelete. */
export type AddressDeletePayload = {
  __typename?: 'AddressDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of AddressUpdate */
export type AddressUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  countryIso: Scalars['SecureString'];
  city: Scalars['SecureString'];
  zip: Scalars['SecureString'];
  state: Scalars['SecureString'];
  address1: Scalars['SecureString'];
  address2?: Maybe<Scalars['SecureString']>;
  phone?: Maybe<Scalars['SecureString']>;
  comment?: Maybe<Scalars['SecureString']>;
  default: Scalars['Boolean'];
  firstName?: Maybe<Scalars['SecureString']>;
  middleName?: Maybe<Scalars['SecureString']>;
  lastName?: Maybe<Scalars['SecureString']>;
  passportNo?: Maybe<Scalars['SecureString']>;
  passportSerial?: Maybe<Scalars['SecureString']>;
  passportIssuer?: Maybe<Scalars['SecureString']>;
  passportDateOfIssue?: Maybe<Scalars['SecureString']>;
  passportDateOfBirth?: Maybe<Scalars['SecureString']>;
  tin?: Maybe<Scalars['SecureString']>;
};

/** Autogenerated return type of AddressUpdate. */
export type AddressUpdatePayload = {
  __typename?: 'AddressUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type AmountFilter = {
  eq?: Maybe<Scalars['Float']>;
  neq?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
};

export enum AttributeName {
  Email = 'email',
  PhoneNumber = 'phone_number'
}

export type Auctions = {
  __typename?: 'Auctions';
  auctionItem?: Maybe<Scalars['JSON']>;
  bidHistory?: Maybe<Scalars['JSON']>;
  bidHistoryDetail?: Maybe<Scalars['JSON']>;
  categoryLeaf?: Maybe<Scalars['JSON']>;
  categoryTree?: Maybe<Scalars['JSON']>;
  search?: Maybe<Scalars['JSON']>;
  sellingList?: Maybe<Scalars['JSON']>;
  showQandA?: Maybe<Scalars['JSON']>;
  showRating?: Maybe<Scalars['JSON']>;
};


export type AuctionsAuctionItemArgs = {
  auctionID?: Maybe<Scalars['SecureString']>;
};


export type AuctionsBidHistoryArgs = {
  auctionID?: Maybe<Scalars['SecureString']>;
  page?: Maybe<Scalars['Int']>;
};


export type AuctionsBidHistoryDetailArgs = {
  auctionID?: Maybe<Scalars['SecureString']>;
  page?: Maybe<Scalars['Int']>;
};


export type AuctionsCategoryLeafArgs = {
  category?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['Int']>;
  aucminprice?: Maybe<Scalars['Int']>;
  aucmaxprice?: Maybe<Scalars['Int']>;
  aucminBidorbuyPrice?: Maybe<Scalars['Int']>;
  aucmaxBidorbuyPrice?: Maybe<Scalars['Int']>;
  locCd?: Maybe<Scalars['Int']>;
  easypayment?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
  freeshipping?: Maybe<Scalars['Int']>;
  wrappingicon?: Maybe<Scalars['Int']>;
  buynow?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['Int']>;
  attn?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['Int']>;
  giftIcon?: Maybe<Scalars['Int']>;
  itemStatus?: Maybe<Scalars['Int']>;
  offer?: Maybe<Scalars['Int']>;
  adf?: Maybe<Scalars['Int']>;
  minCharity?: Maybe<Scalars['Int']>;
  maxCharity?: Maybe<Scalars['Int']>;
  minAffiliate?: Maybe<Scalars['Int']>;
  maxAffiliate?: Maybe<Scalars['Int']>;
  timebuf?: Maybe<Scalars['Int']>;
  ranking?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
};


export type AuctionsCategoryTreeArgs = {
  category?: Maybe<Scalars['String']>;
  adf?: Maybe<Scalars['Int']>;
};


export type AuctionsSearchArgs = {
  query: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['Int']>;
  aucminprice?: Maybe<Scalars['Int']>;
  aucmaxprice?: Maybe<Scalars['Int']>;
  aucminBidorbuyPrice?: Maybe<Scalars['Int']>;
  aucmaxBidorbuyPrice?: Maybe<Scalars['Int']>;
  locCd?: Maybe<Scalars['Int']>;
  easypayment?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
  freeshipping?: Maybe<Scalars['Int']>;
  wrappingicon?: Maybe<Scalars['Int']>;
  buynow?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['Int']>;
  attn?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['Int']>;
  giftIcon?: Maybe<Scalars['Int']>;
  itemStatus?: Maybe<Scalars['Int']>;
  offer?: Maybe<Scalars['Int']>;
  adf?: Maybe<Scalars['Int']>;
  minCharity?: Maybe<Scalars['Int']>;
  maxCharity?: Maybe<Scalars['Int']>;
  minAffiliate?: Maybe<Scalars['Int']>;
  maxAffiliate?: Maybe<Scalars['Int']>;
  timebuf?: Maybe<Scalars['Int']>;
  ranking?: Maybe<Scalars['String']>;
  seller?: Maybe<Scalars['String']>;
  f?: Maybe<Scalars['String']>;
};


export type AuctionsSellingListArgs = {
  sellerID?: Maybe<Scalars['SecureString']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  store?: Maybe<Scalars['Int']>;
  aucminprice?: Maybe<Scalars['Int']>;
  aucmaxprice?: Maybe<Scalars['Int']>;
  aucminBidorbuyPrice?: Maybe<Scalars['Int']>;
  aucmaxBidorbuyPrice?: Maybe<Scalars['Int']>;
  locCd?: Maybe<Scalars['Int']>;
  easypayment?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
  freeshipping?: Maybe<Scalars['Int']>;
  wrappingicon?: Maybe<Scalars['Int']>;
  buynow?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['Int']>;
  attn?: Maybe<Scalars['Int']>;
  point?: Maybe<Scalars['Int']>;
  giftIcon?: Maybe<Scalars['Int']>;
  itemStatus?: Maybe<Scalars['Int']>;
  offer?: Maybe<Scalars['Int']>;
};


export type AuctionsShowQandAArgs = {
  auctionID?: Maybe<Scalars['SecureString']>;
};


export type AuctionsShowRatingArgs = {
  id?: Maybe<Scalars['SecureString']>;
  auctionID?: Maybe<Scalars['SecureString']>;
  author?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  results?: Maybe<Scalars['Int']>;
};

export type AuctionsMainShipmentOrdersFilter = {
  search?: Maybe<SearchFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  orderStatus?: Maybe<OrderStatusFilter>;
};

export type AuctionsMainShipmentOrdersSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type AuctionsMainShipmentsFilter = {
  createdAt?: Maybe<CreatedAtFilter>;
  shipmentStatus?: Maybe<ShipmentStatusFilter>;
  shipmentCartrackTripId?: Maybe<ShipmentCartrackTripIdFilter>;
};

export type AuctionsMainShipmentsSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type AutoPart = {
  __typename?: 'AutoPart';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  maker?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};


export type AutoPartTitleArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type AutoPartOrder = OrderInterface & {
  __typename?: 'AutoPartOrder';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryService?: Maybe<Service>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesSummary?: Maybe<OrderInvoicesSummary>;
  metadata?: Maybe<Scalars['JSON']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderItemsCount?: Maybe<Scalars['Int']>;
  orderItemsTotalPrice?: Maybe<Scalars['Float']>;
  orderItemsTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  shipmentInfo?: Maybe<ShipmentInfo>;
  status?: Maybe<OrderStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<TrailEvent>>;
};


export type AutoPartOrderTitleArgs = {
  lang: Scalars['String'];
};

export type AutoPartPaginatedList = {
  __typename?: 'AutoPartPaginatedList';
  nodes: Array<AutoPart>;
  total: Scalars['Int'];
};

export type AutoPartsFilter = {
  code?: Maybe<CodeFilter>;
};

export type CartItem = {
  __typename?: 'CartItem';
  id?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
  subject?: Maybe<CartItemSubjectUnion>;
  variant?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CartItemCreate */
export type CartItemCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  item: CartItemInput;
  quantity: Scalars['Int'];
};

/** Autogenerated return type of CartItemCreate. */
export type CartItemCreatePayload = {
  __typename?: 'CartItemCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentUser?: Maybe<CurrentUser>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of CartItemDelete */
export type CartItemDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CartItemDelete. */
export type CartItemDeletePayload = {
  __typename?: 'CartItemDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentUser?: Maybe<CurrentUser>;
  error?: Maybe<Error>;
};

export type CartItemInput = {
  type: CartItemTypeEnum;
  code: Scalars['String'];
  variant?: Maybe<Scalars['String']>;
};

export type CartItemSubjectUnion = AutoPart | MercariBeyondProduct | MercariProduct | RakutenProduct | ShopCatalogProduct | YahooProduct;

export enum CartItemTypeEnum {
  YahooProduct = 'YahooProduct',
  RakutenProduct = 'RakutenProduct',
  AutoPart = 'AutoPart',
  ShopCatalogProduct = 'ShopCatalogProduct',
  MercariProduct = 'MercariProduct',
  MercariBeyondProduct = 'MercariBeyondProduct'
}

/** Autogenerated input type of CartItemUpdate */
export type CartItemUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  quantity: Scalars['Int'];
};

/** Autogenerated return type of CartItemUpdate. */
export type CartItemUpdatePayload = {
  __typename?: 'CartItemUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentUser?: Maybe<CurrentUser>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of CartItemsOrder */
export type CartItemsOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
};

/** Autogenerated return type of CartItemsOrder. */
export type CartItemsOrderPayload = {
  __typename?: 'CartItemsOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  shopOrder?: Maybe<ManualOrder>;
};

export type CatalogOrder = OrderInterface & {
  __typename?: 'CatalogOrder';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryService?: Maybe<Service>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesSummary?: Maybe<OrderInvoicesSummary>;
  metadata?: Maybe<Scalars['JSON']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderItemsCount?: Maybe<Scalars['Int']>;
  orderItemsTotalPrice?: Maybe<Scalars['Float']>;
  orderItemsTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  shipmentInfo?: Maybe<ShipmentInfo>;
  status?: Maybe<OrderStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<TrailEvent>>;
};


export type CatalogOrderTitleArgs = {
  lang: Scalars['String'];
};

export type Category = {
  __typename?: 'Category';
  children?: Maybe<Array<CategoryChild>>;
  code?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isPopular: Scalars['Boolean'];
  searchQuery?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  useSearch: Scalars['Boolean'];
  weight?: Maybe<Scalars['Int']>;
};


export type CategoryTitleArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type CategoryChild = {
  __typename?: 'CategoryChild';
  code?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isPopular: Scalars['Boolean'];
  searchQuery?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  useSearch: Scalars['Boolean'];
  weight?: Maybe<Scalars['Int']>;
};


export type CategoryChildTitleArgs = {
  lang?: Maybe<Scalars['String']>;
};

export type ChatMessage = {
  __typename?: 'ChatMessage';
  author?: Maybe<UserInfo>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  isRead?: Maybe<Scalars['Boolean']>;
  theme?: Maybe<ChatMessageThemeEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserInfo>;
};

/** Autogenerated input type of ChatMessageCreate */
export type ChatMessageCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  theme: ChatMessageThemeEnum;
  content: Scalars['SecureString'];
  files: Array<S3FileInput>;
};

/** Autogenerated return type of ChatMessageCreate. */
export type ChatMessageCreatePayload = {
  __typename?: 'ChatMessageCreatePayload';
  chatMessage?: Maybe<ChatMessage>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  currentUser?: Maybe<CurrentUser>;
  error?: Maybe<Error>;
};

export type ChatMessagePaginatedList = {
  __typename?: 'ChatMessagePaginatedList';
  nodes: Array<ChatMessage>;
  total: Scalars['Int'];
};

export enum ChatMessageThemeEnum {
  FinancialQuestion = 'financial_question',
  SellerQuestion = 'seller_question',
  GeneralQuestion = 'general_question',
  ShippingQuestion = 'shipping_question',
  PaymentMessage = 'payment_message'
}

/** Autogenerated input type of ChatMessagesUpdate */
export type ChatMessagesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  ids: Array<Scalars['ID']>;
  isRead: Scalars['Boolean'];
};

/** Autogenerated return type of ChatMessagesUpdate. */
export type ChatMessagesUpdatePayload = {
  __typename?: 'ChatMessagesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type CodeFilter = {
  eq?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  alpha2: Scalars['String'];
  name: Scalars['String'];
};

export type CreatedAtFilter = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
};

export type CreatedAtSort = {
  direction?: Maybe<Scalars['String']>;
};

export type CrooberOrder = OrderInterface & {
  __typename?: 'CrooberOrder';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  crooberProduct?: Maybe<CrooberProduct>;
  deliveryService?: Maybe<Service>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesSummary?: Maybe<OrderInvoicesSummary>;
  metadata?: Maybe<Scalars['JSON']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderItemsCount?: Maybe<Scalars['Int']>;
  orderItemsTotalPrice?: Maybe<Scalars['Float']>;
  orderItemsTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  serviceOrderNum?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  shipmentInfo?: Maybe<ShipmentInfo>;
  status?: Maybe<OrderStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<TrailEvent>>;
};


export type CrooberOrderTitleArgs = {
  lang: Scalars['String'];
};

/** Autogenerated input type of CrooberOrderCreate */
export type CrooberOrderCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['String'];
  quantity: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CrooberOrderCreate. */
export type CrooberOrderCreatePayload = {
  __typename?: 'CrooberOrderCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  crooberOrder?: Maybe<CrooberOrder>;
  error?: Maybe<Error>;
};

export type CrooberProduct = {
  __typename?: 'CrooberProduct';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSON']>;
  fullPath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['String']>>;
  lastPayload?: Maybe<Scalars['JSON']>;
  path?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  priceWithoutTax?: Maybe<Scalars['Int']>;
  seller?: Maybe<CrooberSeller>;
  shippingPrice?: Maybe<Scalars['Int']>;
  stars?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type CrooberSearch = {
  __typename?: 'CrooberSearch';
  products?: Maybe<Array<CrooberProduct>>;
  total?: Maybe<Scalars['Int']>;
};

export type CrooberSeller = {
  __typename?: 'CrooberSeller';
  path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum Currency {
  Jpy = 'JPY',
  Usd = 'USD'
}

export enum CurrencyEnum {
  /** Japan Yen */
  Jpy = 'JPY',
  /** US Dollar */
  Usd = 'USD',
  /** Euro */
  Eur = 'EUR',
  /** Russian Ruble */
  Rub = 'RUB'
}

export type CurrencyInfo = {
  __typename?: 'CurrencyInfo';
  buy?: Maybe<Scalars['Float']>;
  centralBank?: Maybe<Scalars['Float']>;
  currency: CurrencyEnum;
  date: Scalars['DateTime'];
  nominal: Scalars['Float'];
  sell?: Maybe<Scalars['Float']>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  address?: Maybe<Address>;
  addresses?: Maybe<Array<Address>>;
  appliedBonuses?: Maybe<Scalars['Int']>;
  autoShipmentEnabled: Scalars['Boolean'];
  bidsBalance?: Maybe<Scalars['Int']>;
  bonusBalance?: Maybe<Scalars['Int']>;
  cartItems?: Maybe<Array<CartItem>>;
  cartrackTripIds?: Maybe<Array<Scalars['ID']>>;
  chatMessages?: Maybe<ChatMessagePaginatedList>;
  countryIso?: Maybe<Scalars['String']>;
  creditBalance?: Maybe<Scalars['Int']>;
  creditLimit?: Maybe<Scalars['Int']>;
  customsAddress?: Maybe<CustomsAddress>;
  customsAddresses?: Maybe<Array<CustomsAddress>>;
  debt?: Maybe<Scalars['Int']>;
  defaultOrderDeliveryServiceId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  favourites?: Maybe<FavouritePaginatedList>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  guid?: Maybe<Scalars['ID']>;
  hasCartrackUserAssigned: Scalars['Boolean'];
  hasNotAcceptedAgreements?: Maybe<Scalars['Boolean']>;
  hasPermission?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  notificationSettings?: Maybe<NotificationSettings>;
  notifications?: Maybe<Array<Notification>>;
  order?: Maybe<OrderUnion>;
  orderItem?: Maybe<OrderItem>;
  orderItems?: Maybe<OrderItemPaginatedList>;
  orders?: Maybe<OrderUnionPaginatedList>;
  ownBalance?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Array<Permission>>;
  personalAccountStartingPage?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneVerified: Scalars['Boolean'];
  shipment?: Maybe<Shipment>;
  shipments?: Maybe<ShipmentPaginatedList>;
  skype?: Maybe<Scalars['String']>;
  telegramChatId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  timezoneOffset?: Maybe<Scalars['String']>;
  totalBalance?: Maybe<Scalars['Int']>;
  transactions?: Maybe<TransactionPaginatedList>;
  unpaidShipmentsTotalAmount: Money;
  unpaidShipmentsTotalCount: Scalars['Int'];
  usdBalance?: Maybe<Scalars['Float']>;
  username?: Maybe<Scalars['String']>;
  yahooBids?: Maybe<YahooBidPaginatedList>;
  yahooLots?: Maybe<YahooLotPaginatedList>;
};


export type CurrentUserAddressArgs = {
  id: Scalars['ID'];
};


export type CurrentUserChatMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CurrentUserChatMessagesFilter>;
  sort?: Maybe<CurrentUserChatMessagesSort>;
};


export type CurrentUserCustomsAddressArgs = {
  id: Scalars['ID'];
};


export type CurrentUserFavouritesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CurrentUserFavouritesFilter>;
  sort?: Maybe<CurrentUserFavouritesSort>;
};


export type CurrentUserHasPermissionArgs = {
  code: Scalars['String'];
};


export type CurrentUserOrderArgs = {
  id: Scalars['ID'];
};


export type CurrentUserOrderItemArgs = {
  id: Scalars['ID'];
};


export type CurrentUserOrderItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CurrentUserOrderItemsFilter>;
};


export type CurrentUserOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CurrentUserOrdersFilter>;
  sort?: Maybe<CurrentUserOrdersSort>;
};


export type CurrentUserShipmentArgs = {
  id: Scalars['ID'];
};


export type CurrentUserShipmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<AuctionsMainShipmentsFilter>;
  sort?: Maybe<AuctionsMainShipmentsSort>;
};


export type CurrentUserTransactionsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CurrentUserTransactionsFilter>;
  sort?: Maybe<CurrentUserTransactionsSort>;
};


export type CurrentUserYahooBidsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CurrentUserYahooBidsFilter>;
  sort?: Maybe<CurrentUserYahooBidsSort>;
};


export type CurrentUserYahooLotsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<CurrentUserYahooLotsFilter>;
  sort?: Maybe<CurrentUserYahooLotsSort>;
};

export type CurrentUserChatMessagesFilter = {
  search?: Maybe<SearchFilter>;
  isRead?: Maybe<IsReadFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  ownMessages?: Maybe<OwnMessagesFilter>;
};

export type CurrentUserChatMessagesSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type CurrentUserFavouritesFilter = {
  yahooLot?: Maybe<YahooLotFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  yahooLotEndTime?: Maybe<YahooLotEndTimeFilter>;
};

export type CurrentUserFavouritesSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
  yahooLotEndTime?: Maybe<YahooLotEndTimeSort>;
};

export type CurrentUserOrderItemsFilter = {
  orderItemOrderStatus?: Maybe<OrderItemOrderStatusFilter>;
};

export type CurrentUserOrdersFilter = {
  search?: Maybe<SearchFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  orderStatus?: Maybe<OrderStatusFilter>;
  orderCartrackTripId?: Maybe<OrderCartrackTripIdFilter>;
};

export type CurrentUserOrdersSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type CurrentUserTransactionsFilter = {
  createdAt?: Maybe<CreatedAtFilter>;
  amount?: Maybe<AmountFilter>;
  transactionCode?: Maybe<TransactionCodeFilter>;
  search?: Maybe<SearchFilter>;
};

export type CurrentUserTransactionsSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type CurrentUserYahooBidsFilter = {
  yahooLot?: Maybe<YahooLotFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
};

export type CurrentUserYahooBidsSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type CurrentUserYahooLotsFilter = {
  code?: Maybe<CodeFilter>;
};

export type CurrentUserYahooLotsSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type CustomsAddress = {
  __typename?: 'CustomsAddress';
  birthdate?: Maybe<Scalars['DateTime']>;
  birthplace?: Maybe<Scalars['String']>;
  default?: Maybe<Scalars['Boolean']>;
  files?: Maybe<Array<S3File>>;
  firstNameLatin?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  inn?: Maybe<Scalars['String']>;
  innImage?: Maybe<Scalars['String']>;
  lastNameLatin?: Maybe<Scalars['String']>;
  middleNameLatin?: Maybe<Scalars['String']>;
  passport?: Maybe<Scalars['String']>;
  passportImage1?: Maybe<Scalars['String']>;
  passportImage2?: Maybe<Scalars['String']>;
  passportIssueDate?: Maybe<Scalars['DateTime']>;
  passportIssuer?: Maybe<Scalars['String']>;
  registrationAddress?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of CustomsAddressCreate */
export type CustomsAddressCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  firstNameLatin: Scalars['SecureString'];
  middleNameLatin: Scalars['SecureString'];
  lastNameLatin: Scalars['SecureString'];
  birthdate: Scalars['DateTime'];
  birthplace: Scalars['SecureString'];
  sex: Scalars['SecureString'];
  inn: Scalars['SecureString'];
  passport: Scalars['SecureString'];
  passportIssuer: Scalars['SecureString'];
  passportIssueDate: Scalars['DateTime'];
  registrationAddress: Scalars['SecureString'];
  passportImage1?: Maybe<Scalars['SecureString']>;
  passportImage2?: Maybe<Scalars['SecureString']>;
  innImage?: Maybe<Scalars['SecureString']>;
  files: Array<S3FileInput>;
  default: Scalars['Boolean'];
};

/** Autogenerated return type of CustomsAddressCreate. */
export type CustomsAddressCreatePayload = {
  __typename?: 'CustomsAddressCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of CustomsAddressDelete */
export type CustomsAddressDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of CustomsAddressDelete. */
export type CustomsAddressDeletePayload = {
  __typename?: 'CustomsAddressDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of CustomsAddressUpdate */
export type CustomsAddressUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  firstNameLatin: Scalars['SecureString'];
  middleNameLatin: Scalars['SecureString'];
  lastNameLatin: Scalars['SecureString'];
  birthdate: Scalars['DateTime'];
  birthplace: Scalars['SecureString'];
  sex: Scalars['SecureString'];
  inn: Scalars['SecureString'];
  passport: Scalars['SecureString'];
  passportIssuer: Scalars['SecureString'];
  passportIssueDate: Scalars['DateTime'];
  registrationAddress: Scalars['SecureString'];
  passportImage1?: Maybe<Scalars['SecureString']>;
  passportImage2?: Maybe<Scalars['SecureString']>;
  innImage?: Maybe<Scalars['SecureString']>;
  files?: Maybe<Array<S3FileInput>>;
  default: Scalars['Boolean'];
};

/** Autogenerated return type of CustomsAddressUpdate. */
export type CustomsAddressUpdatePayload = {
  __typename?: 'CustomsAddressUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type DetailUnion = AutoPartOrder | CatalogOrder | CrooberOrder | Invoice | ManualOrder | MercariOrder | PaymentSystemEvent | RakutenOrder | YahooBid | YahooOrder | YahooShoppingOrder;

export type Error = {
  __typename?: 'Error';
  backtrace?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  payload: Scalars['JSON'];
  subjectId: Scalars['ID'];
  subjectType: Scalars['String'];
  type: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  date: Scalars['DateTime'];
  nominal: Scalars['Float'];
  value: Scalars['Float'];
};

export type Favourite = {
  __typename?: 'Favourite';
  id?: Maybe<Scalars['ID']>;
  yahooLot: YahooLot;
};


export type FavouriteYahooLotArgs = {
  refresh?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of FavouriteCreate */
export type FavouriteCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  auctionId: Scalars['SecureString'];
};

/** Autogenerated return type of FavouriteCreate. */
export type FavouriteCreatePayload = {
  __typename?: 'FavouriteCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  favourite?: Maybe<Favourite>;
};

/** Autogenerated input type of FavouriteDelete */
export type FavouriteDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of FavouriteDelete. */
export type FavouriteDeletePayload = {
  __typename?: 'FavouriteDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type FavouritePaginatedList = {
  __typename?: 'FavouritePaginatedList';
  nodes: Array<Favourite>;
  total: Scalars['Int'];
};

export type GroupSettingInput = {
  name: GroupSettingName;
  medium: SettingMedium;
  value: Scalars['Boolean'];
};

export enum GroupSettingName {
  OrderStatus = 'order_status',
  ShipmentStatus = 'shipment_status',
  YahooBidCanceled = 'yahoo_bid_canceled',
  YahooBidOverbid = 'yahoo_bid_overbid',
  YahooBidWon = 'yahoo_bid_won',
  FinancesIncomes = 'finances_incomes',
  FinancesSpends = 'finances_spends'
}

export enum IdentifierEnum {
  Auctions = 'auctions',
  AuctionsUsd = 'auctions_usd',
  Paypal = 'paypal',
  PaypalUsd = 'paypal_usd',
  Stripe = 'stripe',
  StripeUsd = 'stripe_usd',
  Altecar = 'altecar',
  AltecarUsd = 'altecar_usd',
  Akebono = 'akebono',
  AkebonoCredit = 'akebono_credit',
  AkebonoCash = 'akebono_cash',
  AkebonoAmex = 'akebono_amex',
  AkebonoFutaba = 'akebono_futaba',
  YahooHold = 'yahoo_hold',
  YahooAuctions = 'yahoo_auctions',
  Shops = 'shops',
  Bank = 'bank',
  BankUsd = 'bank_usd',
  PaypalManual = 'paypal_manual',
  PaypalManualUsd = 'paypal_manual_usd',
  Cash = 'cash',
  CashUsd = 'cash_usd',
  Nayahe = 'nayahe',
  NayaheUsd = 'nayahe_usd',
  CreditManual = 'credit_manual',
  CreditManualUsd = 'credit_manual_usd',
  AkebonoBonus = 'akebono_bonus',
  Bonus = 'bonus',
  BonusHold = 'bonus_hold'
}

export type Invoice = {
  __typename?: 'Invoice';
  amount: Money;
  amountPaid: Money;
  createdAt?: Maybe<Scalars['DateTime']>;
  currency?: Maybe<Currency>;
  dueTo?: Maybe<Scalars['DateTime']>;
  events: Array<Event>;
  id?: Maybe<Scalars['ID']>;
  internalCode: Scalars['String'];
  invoiceLines?: Maybe<Array<InvoiceLine>>;
  isPayed: Scalars['Boolean'];
  metadata?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  status: InvoiceStatus;
  subject?: Maybe<InvoiceSubjectUnion>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceInfo = {
  __typename?: 'InvoiceInfo';
  bonusAmount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  internalCode: Scalars['String'];
  status: InvoiceStatus;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  invoice?: Maybe<InvoiceInfo>;
  invoiceLineTaxes: Array<InvoiceLineTax>;
  orderItem: OrderItem;
  quantity: Scalars['Int'];
  service: Service;
  title: Scalars['String'];
  totalPrice: Money;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type InvoiceLineTax = {
  __typename?: 'InvoiceLineTax';
  amount: Money;
  id?: Maybe<Scalars['ID']>;
  included: Scalars['Boolean'];
  tax: Tax;
};

export enum InvoiceStatus {
  Draft = 'draft',
  Sent = 'sent',
  Viewed = 'viewed',
  Paid = 'paid',
  Partial = 'partial',
  DraftPartial = 'draft_partial',
  Disputed = 'disputed',
  Pending = 'pending',
  Canceled = 'canceled'
}

export type InvoiceSubjectUnion = AutoPartOrder | CatalogOrder | CrooberOrder | ManualOrder | MercariOrder | RakutenOrder | Shipment | YahooOrder | YahooShoppingOrder;

export type InvoiceUpdateInput = {
  id: Scalars['ID'];
  bonusAmount: Scalars['Int'];
};

/** Autogenerated input type of InvoicesUpdate */
export type InvoicesUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  updates: Array<InvoiceUpdateInput>;
};

/** Autogenerated return type of InvoicesUpdate. */
export type InvoicesUpdatePayload = {
  __typename?: 'InvoicesUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type IsReadFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type ManualOrder = OrderInterface & {
  __typename?: 'ManualOrder';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryService?: Maybe<Service>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesSummary?: Maybe<OrderInvoicesSummary>;
  metadata?: Maybe<Scalars['JSON']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderItemsCount?: Maybe<Scalars['Int']>;
  orderItemsTotalPrice?: Maybe<Scalars['Float']>;
  orderItemsTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  shipmentInfo?: Maybe<ShipmentInfo>;
  status?: Maybe<OrderStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<TrailEvent>>;
};


export type ManualOrderTitleArgs = {
  lang: Scalars['String'];
};

export type MercariBeyondProduct = {
  __typename?: 'MercariBeyondProduct';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['String']>>;
  lastPayload?: Maybe<Scalars['JSON']>;
  price?: Maybe<Scalars['Int']>;
  thumbnails?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MercariOrder = OrderInterface & {
  __typename?: 'MercariOrder';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryService?: Maybe<Service>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesSummary?: Maybe<OrderInvoicesSummary>;
  metadata?: Maybe<Scalars['JSON']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderItemsCount?: Maybe<Scalars['Int']>;
  orderItemsTotalPrice?: Maybe<Scalars['Float']>;
  orderItemsTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  shipmentInfo?: Maybe<ShipmentInfo>;
  status?: Maybe<OrderStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<TrailEvent>>;
};


export type MercariOrderTitleArgs = {
  lang: Scalars['String'];
};

export type MercariProduct = {
  __typename?: 'MercariProduct';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['String']>>;
  lastPayload?: Maybe<Scalars['JSON']>;
  price?: Maybe<Scalars['Int']>;
  thumbnails?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type MessageTemplate = {
  __typename?: 'MessageTemplate';
  content?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  language?: Maybe<MessageTemplateLanguageEnum>;
  templateType?: Maybe<MessageTemplateTypeEnum>;
  title?: Maybe<Scalars['String']>;
};

export enum MessageTemplateLanguageEnum {
  Ru = 'ru',
  En = 'en'
}

export enum MessageTemplateTypeEnum {
  Yahoo = 'yahoo',
  Chat = 'chat',
  Lot = 'lot'
}

export type Money = {
  __typename?: 'Money';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addressCreate?: Maybe<AddressCreatePayload>;
  addressDelete?: Maybe<AddressDeletePayload>;
  addressUpdate?: Maybe<AddressUpdatePayload>;
  cartItemCreate?: Maybe<CartItemCreatePayload>;
  cartItemDelete?: Maybe<CartItemDeletePayload>;
  cartItemUpdate?: Maybe<CartItemUpdatePayload>;
  cartItemsOrder?: Maybe<CartItemsOrderPayload>;
  chatMessageCreate?: Maybe<ChatMessageCreatePayload>;
  chatMessagesUpdate?: Maybe<ChatMessagesUpdatePayload>;
  crooberOrderCreate?: Maybe<CrooberOrderCreatePayload>;
  customsAddressCreate?: Maybe<CustomsAddressCreatePayload>;
  customsAddressDelete?: Maybe<CustomsAddressDeletePayload>;
  customsAddressUpdate?: Maybe<CustomsAddressUpdatePayload>;
  favouriteCreate?: Maybe<FavouriteCreatePayload>;
  favouriteDelete?: Maybe<FavouriteDeletePayload>;
  invoicesUpdate?: Maybe<InvoicesUpdatePayload>;
  notificationSettingsGroupUpdate?: Maybe<NotificationSettingsGroupUpdatePayload>;
  notificationSettingsUpdate?: Maybe<NotificationSettingsUpdatePayload>;
  notificationUpdate?: Maybe<NotificationUpdatePayload>;
  postQaMessage?: Maybe<PostQaMessagePayload>;
  presignedPostCreate?: Maybe<PresignedPostCreatePayload>;
  reportPayment?: Maybe<ReportPaymentPayload>;
  russianDeliveryCalculationCreate?: Maybe<RussianDeliveryCalculationCreatePayload>;
  sberDeposit?: Maybe<SberDepositPayload>;
  shipmentCreate?: Maybe<ShipmentCreatePayload>;
  shipmentIssued?: Maybe<ShipmentIssuedPayload>;
  shopCatalogItemCreate?: Maybe<ShopCatalogItemCreatePayload>;
  shopOrderCreate?: Maybe<ShopOrderCreatePayload>;
  shopOrderDelete?: Maybe<ShopOrderDeletePayload>;
  shopOrderUpdate?: Maybe<ShopOrderUpdatePayload>;
  stripeDeposit?: Maybe<StripeDepositPayload>;
  telegramSubscriptionCreate?: Maybe<TelegramSubscriptionCreatePayload>;
  telegramSubscriptionDelete?: Maybe<TelegramSubscriptionDeletePayload>;
  userAttributeUpdate?: Maybe<UserAttributeUpdatePayload>;
  userAttributeVerificationCodeCreate?: Maybe<UserAttributeVerificationCodeCreatePayload>;
  userAttributeVerify?: Maybe<UserAttributeVerifyPayload>;
  userDetailsUpdate?: Maybe<UserDetailsUpdatePayload>;
  userDisable?: Maybe<UserDisablePayload>;
  userPasswordUpdate?: Maybe<UserPasswordUpdatePayload>;
  yahooBidCreate?: Maybe<YahooBidCreatePayload>;
  yahooBidDelete?: Maybe<YahooBidDeletePayload>;
  yahooBidUpdate?: Maybe<YahooBidUpdatePayload>;
  yahooOrderDelete?: Maybe<YahooOrderDeletePayload>;
};


export type MutationAddressCreateArgs = {
  input: AddressCreateInput;
};


export type MutationAddressDeleteArgs = {
  input: AddressDeleteInput;
};


export type MutationAddressUpdateArgs = {
  input: AddressUpdateInput;
};


export type MutationCartItemCreateArgs = {
  input: CartItemCreateInput;
};


export type MutationCartItemDeleteArgs = {
  input: CartItemDeleteInput;
};


export type MutationCartItemUpdateArgs = {
  input: CartItemUpdateInput;
};


export type MutationCartItemsOrderArgs = {
  input: CartItemsOrderInput;
};


export type MutationChatMessageCreateArgs = {
  input: ChatMessageCreateInput;
};


export type MutationChatMessagesUpdateArgs = {
  input: ChatMessagesUpdateInput;
};


export type MutationCrooberOrderCreateArgs = {
  input: CrooberOrderCreateInput;
};


export type MutationCustomsAddressCreateArgs = {
  input: CustomsAddressCreateInput;
};


export type MutationCustomsAddressDeleteArgs = {
  input: CustomsAddressDeleteInput;
};


export type MutationCustomsAddressUpdateArgs = {
  input: CustomsAddressUpdateInput;
};


export type MutationFavouriteCreateArgs = {
  input: FavouriteCreateInput;
};


export type MutationFavouriteDeleteArgs = {
  input: FavouriteDeleteInput;
};


export type MutationInvoicesUpdateArgs = {
  input: InvoicesUpdateInput;
};


export type MutationNotificationSettingsGroupUpdateArgs = {
  input: NotificationSettingsGroupUpdateInput;
};


export type MutationNotificationSettingsUpdateArgs = {
  input: NotificationSettingsUpdateInput;
};


export type MutationNotificationUpdateArgs = {
  input: NotificationUpdateInput;
};


export type MutationPostQaMessageArgs = {
  input: PostQaMessageInput;
};


export type MutationPresignedPostCreateArgs = {
  input: PresignedPostCreateInput;
};


export type MutationReportPaymentArgs = {
  input: ReportPaymentInput;
};


export type MutationRussianDeliveryCalculationCreateArgs = {
  input: RussianDeliveryCalculationCreateInput;
};


export type MutationSberDepositArgs = {
  input: SberDepositInput;
};


export type MutationShipmentCreateArgs = {
  input: ShipmentCreateInput;
};


export type MutationShipmentIssuedArgs = {
  input: ShipmentIssuedInput;
};


export type MutationShopCatalogItemCreateArgs = {
  input: ShopCatalogItemCreateInput;
};


export type MutationShopOrderCreateArgs = {
  input: ShopOrderCreateInput;
};


export type MutationShopOrderDeleteArgs = {
  input: ShopOrderDeleteInput;
};


export type MutationShopOrderUpdateArgs = {
  input: ShopOrderUpdateInput;
};


export type MutationStripeDepositArgs = {
  input: StripeDepositInput;
};


export type MutationTelegramSubscriptionCreateArgs = {
  input: TelegramSubscriptionCreateInput;
};


export type MutationTelegramSubscriptionDeleteArgs = {
  input: TelegramSubscriptionDeleteInput;
};


export type MutationUserAttributeUpdateArgs = {
  input: UserAttributeUpdateInput;
};


export type MutationUserAttributeVerificationCodeCreateArgs = {
  input: UserAttributeVerificationCodeCreateInput;
};


export type MutationUserAttributeVerifyArgs = {
  input: UserAttributeVerifyInput;
};


export type MutationUserDetailsUpdateArgs = {
  input: UserDetailsUpdateInput;
};


export type MutationUserDisableArgs = {
  input: UserDisableInput;
};


export type MutationUserPasswordUpdateArgs = {
  input: UserPasswordUpdateInput;
};


export type MutationYahooBidCreateArgs = {
  input: YahooBidCreateInput;
};


export type MutationYahooBidDeleteArgs = {
  input: YahooBidDeleteInput;
};


export type MutationYahooBidUpdateArgs = {
  input: YahooBidUpdateInput;
};


export type MutationYahooOrderDeleteArgs = {
  input: YahooOrderDeleteInput;
};

export type Notification = {
  __typename?: 'Notification';
  id?: Maybe<Scalars['ID']>;
};

export type NotificationSettings = {
  __typename?: 'NotificationSettings';
  purchasedSmsAmount: Scalars['Int'];
  setting: Scalars['Boolean'];
  settings: Scalars['JSON'];
};


export type NotificationSettingsSettingArgs = {
  name: GroupSettingName;
  medium: SettingMedium;
};

/** Autogenerated input type of NotificationSettingsGroupUpdate */
export type NotificationSettingsGroupUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  settings: Array<GroupSettingInput>;
};

/** Autogenerated return type of NotificationSettingsGroupUpdate. */
export type NotificationSettingsGroupUpdatePayload = {
  __typename?: 'NotificationSettingsGroupUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of NotificationSettingsUpdate */
export type NotificationSettingsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  settings: Array<SettingInput>;
};

/** Autogenerated return type of NotificationSettingsUpdate. */
export type NotificationSettingsUpdatePayload = {
  __typename?: 'NotificationSettingsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of NotificationUpdate */
export type NotificationUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of NotificationUpdate. */
export type NotificationUpdatePayload = {
  __typename?: 'NotificationUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type OrderCartrackTripIdFilter = {
  eq?: Maybe<Scalars['ID']>;
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  auctionId: Scalars['ID'];
  id: Scalars['ID'];
  images?: Maybe<Array<Scalars['String']>>;
  internalCode: Scalars['String'];
  status: OrderStatus;
};

export type OrderInterface = {
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryService?: Maybe<Service>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesSummary?: Maybe<OrderInvoicesSummary>;
  metadata?: Maybe<Scalars['JSON']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderItemsCount?: Maybe<Scalars['Int']>;
  orderItemsTotalPrice?: Maybe<Scalars['Float']>;
  orderItemsTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  shipmentInfo?: Maybe<ShipmentInfo>;
  status?: Maybe<OrderStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<TrailEvent>>;
};


export type OrderInterfaceTitleArgs = {
  lang: Scalars['String'];
};

export type OrderInvoicesSummary = {
  __typename?: 'OrderInvoicesSummary';
  export?: Maybe<Money>;
  japan?: Maybe<Money>;
  total?: Maybe<Money>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  availableDeliveryServiceIds?: Maybe<Array<Scalars['ID']>>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryService?: Maybe<Service>;
  freeQuantity: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  internalCode: Scalars['String'];
  invoiceLines?: Maybe<Array<InvoiceLine>>;
  link?: Maybe<Scalars['String']>;
  orderInfo?: Maybe<OrderInfo>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  subject?: Maybe<OrderItemSubjectUnion>;
  title?: Maybe<Scalars['String']>;
  totalWeight?: Maybe<Scalars['Float']>;
  trackingNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type OrderItemTitleArgs = {
  lang: Scalars['String'];
};

export type OrderItemInput = {
  id: Scalars['ID'];
  deliveryServiceId: Scalars['ID'];
};

export type OrderItemOrderStatusFilter = {
  eq?: Maybe<Array<OrderStatus>>;
};

export type OrderItemPaginatedList = {
  __typename?: 'OrderItemPaginatedList';
  nodes: Array<OrderItem>;
  total: Scalars['Int'];
};

export type OrderItemSubjectUnion = AutoPart | MercariBeyondProduct | MercariProduct | RakutenProduct | ShopCatalogProduct | YahooProduct;

export enum OrderStatus {
  Initial = 'initial',
  Negotiations = 'negotiations',
  ArrivingJp = 'arriving_jp',
  DeclarantReviewJp = 'declarant_review_jp',
  StockmanReviewJp = 'stockman_review_jp',
  CustomerReview = 'customer_review',
  InShipment = 'in_shipment',
  Issued = 'issued',
  Failed = 'failed',
  Canceled = 'canceled',
  Utilized = 'utilized',
  InStockVl = 'in_stock_vl',
  PassedToTransportingCompany = 'passed_to_transporting_company',
  Shipped = 'shipped',
  Affirmation = 'affirmation',
  InitialAffirmative = 'initial_affirmative',
  ToBeUtilized = 'to_be_utilized'
}

export type OrderStatusFilter = {
  eq?: Maybe<Array<OrderStatus>>;
};

export type OrderUnion = AutoPartOrder | CatalogOrder | CrooberOrder | ManualOrder | MercariOrder | RakutenOrder | YahooOrder | YahooShoppingOrder;

export type OrderUnionPaginatedList = {
  __typename?: 'OrderUnionPaginatedList';
  nodes: Array<OrderUnion>;
  total: Scalars['Int'];
};

export type OwnMessagesFilter = {
  eq?: Maybe<Scalars['Boolean']>;
};

export type PaymentSystemEvent = {
  __typename?: 'PaymentSystemEvent';
  eventType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  payload?: Maybe<Scalars['JSON']>;
};

export type Permission = {
  __typename?: 'Permission';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of PostQAMessage */
export type PostQaMessageInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  values?: Maybe<Scalars['JSON']>;
  messageTemplateId: Scalars['ID'];
  lotCode: Scalars['String'];
};

/** Autogenerated return type of PostQAMessage. */
export type PostQaMessagePayload = {
  __typename?: 'PostQAMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export enum PreferredLang {
  Ru = 'ru',
  En = 'en'
}

/** Autogenerated input type of PresignedPostCreate */
export type PresignedPostCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  contentType: Scalars['String'];
};

/** Autogenerated return type of PresignedPostCreate. */
export type PresignedPostCreatePayload = {
  __typename?: 'PresignedPostCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  fields?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  altecarExchangeRate?: Maybe<ExchangeRate>;
  atbExchangeRate?: Maybe<CurrencyInfo>;
  auctions?: Maybe<Auctions>;
  autoParts?: Maybe<AutoPartPaginatedList>;
  cdekTariffCities: Array<Scalars['String']>;
  cdekTariffStates: Array<Scalars['String']>;
  countries: Array<Country>;
  countryBlocked: Scalars['Boolean'];
  crooberCarTypes?: Maybe<Scalars['JSON']>;
  crooberCategories?: Maybe<Array<Category>>;
  crooberCategory?: Maybe<Category>;
  crooberPartmakers?: Maybe<Scalars['JSON']>;
  crooberProduct?: Maybe<CrooberProduct>;
  crooberSearch?: Maybe<CrooberSearch>;
  currentTime?: Maybe<Scalars['DateTime']>;
  currentUser?: Maybe<CurrentUser>;
  deliveryServices?: Maybe<Array<Service>>;
  exchangeRate: ExchangeRate;
  khanbankExchangeRate?: Maybe<CurrencyInfo>;
  maybankExchangeRate: CurrencyInfo;
  mercariGetItem?: Maybe<Scalars['JSON']>;
  mercariItemBrands?: Maybe<Scalars['JSON']>;
  mercariItemCategories?: Maybe<Scalars['JSON']>;
  mercariItemColors?: Maybe<Scalars['JSON']>;
  mercariItemConditions?: Maybe<Scalars['JSON']>;
  mercariItemSizes?: Maybe<Scalars['JSON']>;
  mercariSearchItems?: Maybe<Scalars['JSON']>;
  messageTemplates: Array<MessageTemplate>;
  primbankExchangeRate?: Maybe<CurrencyInfo>;
  pskbExchangeRate?: Maybe<CurrencyInfo>;
  rakutenCategories?: Maybe<Array<Category>>;
  rakutenCategory?: Maybe<Category>;
  rakutenSearch?: Maybe<RakutenSearch>;
  sberDepositEnabled: Scalars['Boolean'];
  sberDepositMax: Scalars['Int'];
  sberDepositMin: Scalars['Int'];
  service?: Maybe<Service>;
  services?: Maybe<ServicePaginatedList>;
  shopCatalogItems?: Maybe<ShopCatalogProductPaginatedList>;
  shopCatalogStores?: Maybe<Array<ShopCatalogStore>>;
  shopping?: Maybe<Shopping>;
  states: Array<State>;
  stripeExchangeRate?: Maybe<ExchangeRate>;
  timezone: Array<TimeZone>;
  yahooAuctionsCategories?: Maybe<Array<Category>>;
  yahooAuctionsCategory?: Maybe<Category>;
  yahooFeeConfig: YahooFeeConfig;
  yahooLot?: Maybe<YahooLot>;
  yahooShoppingCategories?: Maybe<Array<Category>>;
  yahooShoppingCategory?: Maybe<Category>;
};


export type QueryAltecarExchangeRateArgs = {
  iso?: Maybe<CurrencyEnum>;
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryAtbExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryAutoPartsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<AutoPartsFilter>;
};


export type QueryCdekTariffCitiesArgs = {
  state: Scalars['String'];
};


export type QueryCrooberCarTypesArgs = {
  makerId?: Maybe<Scalars['String']>;
};


export type QueryCrooberCategoryArgs = {
  code: Scalars['String'];
};


export type QueryCrooberPartmakersArgs = {
  categoryId?: Maybe<Scalars['String']>;
};


export type QueryCrooberProductArgs = {
  id: Scalars['String'];
};


export type QueryCrooberSearchArgs = {
  path: Scalars['String'];
};


export type QueryExchangeRateArgs = {
  iso: CurrencyEnum;
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryKhanbankExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryMaybankExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryMercariGetItemArgs = {
  itemId: Scalars['String'];
  itemType: Scalars['String'];
};


export type QueryMercariItemSizesArgs = {
  groupId?: Maybe<Scalars['String']>;
};


export type QueryMercariSearchItemsArgs = {
  keyword?: Maybe<Scalars['String']>;
  brandId?: Maybe<Array<Scalars['Int']>>;
  categoryId?: Maybe<Array<Scalars['Int']>>;
  colorId?: Maybe<Array<Scalars['Int']>>;
  excludeKeyword?: Maybe<Scalars['String']>;
  itemConditionId?: Maybe<Array<Scalars['Int']>>;
  order?: Maybe<Scalars['String']>;
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  priceType?: Maybe<Array<Scalars['String']>>;
  shippingMethod?: Maybe<Array<Scalars['String']>>;
  shippingPayerId?: Maybe<Array<Scalars['Int']>>;
  sizeId?: Maybe<Array<Scalars['String']>>;
  sort?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Scalars['String']>>;
  pageToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
};


export type QueryMessageTemplatesArgs = {
  language?: Maybe<MessageTemplateLanguageEnum>;
};


export type QueryPrimbankExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryPskbExchangeRateArgs = {
  iso: CurrencyEnum;
};


export type QueryRakutenCategoryArgs = {
  code: Scalars['String'];
};


export type QueryRakutenSearchArgs = {
  keyword?: Maybe<Scalars['String']>;
  genreId?: Maybe<Scalars['String']>;
  shopCode?: Maybe<Scalars['String']>;
  itemCode?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  availability?: Maybe<Scalars['Int']>;
};


export type QueryServiceArgs = {
  serviceCode: Scalars['String'];
};


export type QueryServicesArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<ServicesFilter>;
  sort?: Maybe<ServicesSort>;
};


export type QueryShopCatalogItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<ShopCatalogItemsFilter>;
  sort?: Maybe<ShopCatalogItemsSort>;
};


export type QueryStatesArgs = {
  countryIso: Scalars['String'];
};


export type QueryStripeExchangeRateArgs = {
  iso?: Maybe<CurrencyEnum>;
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryYahooAuctionsCategoryArgs = {
  code: Scalars['String'];
};


export type QueryYahooLotArgs = {
  code: Scalars['String'];
};


export type QueryYahooShoppingCategoryArgs = {
  code: Scalars['String'];
};

export type RakutenApiItem = {
  __typename?: 'RakutenAPIItem';
  itemCaption?: Maybe<Scalars['String']>;
  itemCode?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  itemPrice?: Maybe<Scalars['Int']>;
  itemUrl?: Maybe<Scalars['String']>;
  mediumImageUrls?: Maybe<Array<Scalars['String']>>;
  reviewAverage?: Maybe<Scalars['Float']>;
  seller?: Maybe<RakutenSeller>;
  smallImageUrls?: Maybe<Array<Scalars['String']>>;
  variants?: Maybe<Scalars['JSON']>;
};

export type RakutenOrder = OrderInterface & {
  __typename?: 'RakutenOrder';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryService?: Maybe<Service>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesSummary?: Maybe<OrderInvoicesSummary>;
  metadata?: Maybe<Scalars['JSON']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderItemsCount?: Maybe<Scalars['Int']>;
  orderItemsTotalPrice?: Maybe<Scalars['Float']>;
  orderItemsTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  shipmentInfo?: Maybe<ShipmentInfo>;
  status?: Maybe<OrderStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<TrailEvent>>;
};


export type RakutenOrderTitleArgs = {
  lang: Scalars['String'];
};

export type RakutenProduct = {
  __typename?: 'RakutenProduct';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['String']>>;
  lastPayload?: Maybe<Scalars['JSON']>;
  price?: Maybe<Scalars['Int']>;
  thumbnails?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type RakutenSearch = {
  __typename?: 'RakutenSearch';
  count?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<RakutenApiItem>>;
};

export type RakutenSeller = {
  __typename?: 'RakutenSeller';
  path?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ReportPayment */
export type ReportPaymentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  payerFullName: Scalars['String'];
  amount: Scalars['Int'];
  comment: Scalars['String'];
  files: Array<S3FileInput>;
};

/** Autogenerated return type of ReportPayment. */
export type ReportPaymentPayload = {
  __typename?: 'ReportPaymentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of RussianDeliveryCalculationCreate */
export type RussianDeliveryCalculationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  toCity: Scalars['String'];
  weight: Scalars['Float'];
  strah: Scalars['Float'];
  ln?: Maybe<Scalars['Float']>;
  wd?: Maybe<Scalars['Float']>;
  hg?: Maybe<Scalars['Float']>;
  zip?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of RussianDeliveryCalculationCreate. */
export type RussianDeliveryCalculationCreatePayload = {
  __typename?: 'RussianDeliveryCalculationCreatePayload';
  calculation?: Maybe<Scalars['JSON']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type S3File = {
  __typename?: 'S3File';
  filename?: Maybe<Scalars['String']>;
  fullPath?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  key?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
};

export type S3FileInput = {
  key: Scalars['String'];
  filename: Scalars['String'];
};

/** Autogenerated input type of SberDeposit */
export type SberDepositInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency: CurrencyEnum;
};

/** Autogenerated return type of SberDeposit. */
export type SberDepositPayload = {
  __typename?: 'SberDepositPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  formUrl?: Maybe<Scalars['String']>;
};

export type SearchFilter = {
  eq?: Maybe<Scalars['String']>;
};

export type Service = {
  __typename?: 'Service';
  code: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  price: Money;
  provider?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
};

export type ServicePaginatedList = {
  __typename?: 'ServicePaginatedList';
  nodes: Array<Service>;
  total: Scalars['Int'];
};

export enum ServiceTypeEnum {
  OrderDeliveryService = 'OrderDeliveryService',
  OrderItemDeliveryService = 'OrderItemDeliveryService',
  OrderService = 'OrderService',
  UserService = 'UserService'
}

export type ServiceTypeFilter = {
  eq?: Maybe<ServiceTypeEnum>;
};

export type ServicesFilter = {
  serviceType?: Maybe<ServiceTypeFilter>;
};

export type ServicesSort = {
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type SettingInput = {
  name: SettingName;
  medium: SettingMedium;
  value: Scalars['Boolean'];
};

export enum SettingMedium {
  System = 'system',
  Email = 'email',
  Sms = 'sms',
  Telegram = 'telegram'
}

export enum SettingName {
  AcquiringEnabled = 'AcquiringEnabled'
}

export type Shipment = {
  __typename?: 'Shipment';
  address?: Maybe<Address>;
  cartrackTripId?: Maybe<Scalars['ID']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customsAddress?: Maybe<CustomsAddress>;
  id: Scalars['ID'];
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  orders?: Maybe<OrderUnionPaginatedList>;
  ordersCount?: Maybe<Scalars['Int']>;
  ordersTotalPrice?: Maybe<Scalars['Float']>;
  ordersTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  status?: Maybe<ShipmentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ShipmentOrdersArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<AuctionsMainShipmentOrdersFilter>;
  sort?: Maybe<AuctionsMainShipmentOrdersSort>;
};

export type ShipmentCartrackTripIdFilter = {
  eq?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of ShipmentCreate */
export type ShipmentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  deliveryServiceId?: Maybe<Scalars['ID']>;
  addressId?: Maybe<Scalars['ID']>;
  customsAddressId?: Maybe<Scalars['ID']>;
  orderItems: Array<OrderItemInput>;
  orderIds: Array<Scalars['ID']>;
  comment?: Maybe<Scalars['SecureString']>;
};

/** Autogenerated return type of ShipmentCreate. */
export type ShipmentCreatePayload = {
  __typename?: 'ShipmentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type ShipmentInfo = {
  __typename?: 'ShipmentInfo';
  cartrackTripId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  internalCode: Scalars['String'];
  ordersCount?: Maybe<Scalars['Int']>;
  ordersTotalPrice?: Maybe<Scalars['Float']>;
  ordersTotalWeight?: Maybe<Scalars['Float']>;
  status: ShipmentStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** Autogenerated input type of ShipmentIssued */
export type ShipmentIssuedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of ShipmentIssued. */
export type ShipmentIssuedPayload = {
  __typename?: 'ShipmentIssuedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type ShipmentPaginatedList = {
  __typename?: 'ShipmentPaginatedList';
  nodes: Array<Shipment>;
  total: Scalars['Int'];
};

export enum ShipmentStatus {
  Packing = 'packing',
  AwaitingPayment = 'awaiting_payment',
  Planing = 'planing',
  Planed = 'planed',
  Shipped = 'shipped',
  Ready = 'ready',
  Issued = 'issued',
  Failed = 'failed',
  Canceled = 'canceled',
  Utilized = 'utilized',
  PassedToTransportingCompany = 'passed_to_transporting_company'
}

export type ShipmentStatusFilter = {
  eq?: Maybe<Array<ShipmentStatus>>;
};

/** Autogenerated input type of ShopCatalogItemCreate */
export type ShopCatalogItemCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  title: Scalars['JSON'];
  description: Scalars['JSON'];
  storeId: Scalars['ID'];
};

/** Autogenerated return type of ShopCatalogItemCreate. */
export type ShopCatalogItemCreatePayload = {
  __typename?: 'ShopCatalogItemCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type ShopCatalogItemsFilter = {
  search?: Maybe<SearchFilter>;
  createdAt?: Maybe<CreatedAtFilter>;
  shopCatalogStoreId?: Maybe<ShopCatalogStoreIdFilter>;
};

export type ShopCatalogItemsSort = {
  rank?: Maybe<SortRankSort>;
  createdAt?: Maybe<CreatedAtSort>;
  updatedAt?: Maybe<UpdatedAtSort>;
};

export type ShopCatalogProduct = {
  __typename?: 'ShopCatalogProduct';
  categoryId?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  sortRank?: Maybe<Scalars['Int']>;
  store?: Maybe<ShopCatalogStore>;
  storeId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopCatalogProductPaginatedList = {
  __typename?: 'ShopCatalogProductPaginatedList';
  nodes: Array<ShopCatalogProduct>;
  total: Scalars['Int'];
};

export type ShopCatalogStore = {
  __typename?: 'ShopCatalogStore';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['JSON']>;
  id?: Maybe<Scalars['ID']>;
  imageUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ShopCatalogStoreIdFilter = {
  eq?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated input type of ShopOrderCreate */
export type ShopOrderCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  orderItems: Array<ShopOrderItemInput>;
};

/** Autogenerated return type of ShopOrderCreate. */
export type ShopOrderCreatePayload = {
  __typename?: 'ShopOrderCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  shopOrder?: Maybe<ManualOrder>;
};

/** Autogenerated input type of ShopOrderDelete */
export type ShopOrderDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  flagAgreement: Scalars['Boolean'];
  flagNoRefund: Scalars['Boolean'];
  flagPossibleCharges: Scalars['Boolean'];
};

/** Autogenerated return type of ShopOrderDelete. */
export type ShopOrderDeletePayload = {
  __typename?: 'ShopOrderDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type ShopOrderItemInput = {
  title: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of ShopOrderUpdate */
export type ShopOrderUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  confirm: Scalars['Boolean'];
};

/** Autogenerated return type of ShopOrderUpdate. */
export type ShopOrderUpdatePayload = {
  __typename?: 'ShopOrderUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type Shopping = {
  __typename?: 'Shopping';
  categoryRanking?: Maybe<Scalars['JSON']>;
  categorySearch?: Maybe<Scalars['JSON']>;
  eventSearch?: Maybe<Scalars['JSON']>;
  getModule?: Maybe<Scalars['JSON']>;
  itemLookup?: Maybe<Scalars['JSON']>;
  itemSearch?: Maybe<Scalars['JSON']>;
  queryRanking?: Maybe<Scalars['JSON']>;
  reviewSearch?: Maybe<Scalars['JSON']>;
};


export type ShoppingCategoryRankingArgs = {
  affiliateType?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  generation?: Maybe<Scalars['Int']>;
  period?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};


export type ShoppingCategorySearchArgs = {
  affiliateType?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['Int']>;
};


export type ShoppingEventSearchArgs = {
  affiliateType?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['Int']>;
  eventType?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  hits?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ShoppingGetModuleArgs = {
  affiliateType?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['String']>;
};


export type ShoppingItemLookupArgs = {
  affiliateType?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['ID']>;
  itemcode?: Maybe<Scalars['String']>;
  responsegroup?: Maybe<Scalars['String']>;
  imageSize?: Maybe<Scalars['String']>;
  license?: Maybe<Scalars['String']>;
};


export type ShoppingItemSearchArgs = {
  affiliateType?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  janCode?: Maybe<Scalars['Int']>;
  isbn?: Maybe<Scalars['Int']>;
  imageSize?: Maybe<Scalars['Int']>;
  genreCategoryId?: Maybe<Scalars['Int']>;
  sellerId?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['String']>;
  priceFrom?: Maybe<Scalars['Int']>;
  priceTo?: Maybe<Scalars['Int']>;
  affiliateFrom?: Maybe<Scalars['Int']>;
  affiliateTo?: Maybe<Scalars['Int']>;
  preorder?: Maybe<Scalars['Boolean']>;
  results?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  inStock?: Maybe<Scalars['Boolean']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  shipping?: Maybe<Scalars['Int']>;
  userRank?: Maybe<Scalars['String']>;
  saleStartFrom?: Maybe<Scalars['Int']>;
  saleStartTo?: Maybe<Scalars['Int']>;
  saleEndFrom?: Maybe<Scalars['Int']>;
  saleEndTo?: Maybe<Scalars['Int']>;
  deliveryArea?: Maybe<Scalars['String']>;
  deliveryDay?: Maybe<Scalars['Int']>;
  deliveryDeadline?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
};


export type ShoppingQueryRankingArgs = {
  affiliateType?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  hits?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};


export type ShoppingReviewSearchArgs = {
  affiliateType?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['Int']>;
  jan?: Maybe<Scalars['Int']>;
  personId?: Maybe<Scalars['Int']>;
  storeId?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  results?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
};

export type SortRankSort = {
  direction?: Maybe<Scalars['String']>;
};

export type State = {
  __typename?: 'State';
  alpha2: Scalars['String'];
  name: Scalars['String'];
};

/** Autogenerated input type of StripeDeposit */
export type StripeDepositInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  currency: CurrencyEnum;
  token: Scalars['String'];
};

/** Autogenerated return type of StripeDeposit. */
export type StripeDepositPayload = {
  __typename?: 'StripeDepositPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type Tax = {
  __typename?: 'Tax';
  code: Scalars['String'];
  id: Scalars['ID'];
  percentage: Scalars['Float'];
  title: Scalars['String'];
};

/** Autogenerated input type of TelegramSubscriptionCreate */
export type TelegramSubscriptionCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TelegramSubscriptionCreate. */
export type TelegramSubscriptionCreatePayload = {
  __typename?: 'TelegramSubscriptionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  token?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TelegramSubscriptionDelete */
export type TelegramSubscriptionDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of TelegramSubscriptionDelete. */
export type TelegramSubscriptionDeletePayload = {
  __typename?: 'TelegramSubscriptionDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TrailEvent = {
  __typename?: 'TrailEvent';
  author?: Maybe<TrailEventAuthor>;
  changeset?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  reason?: Maybe<Scalars['String']>;
  versionNumber?: Maybe<Scalars['Int']>;
};

export type TrailEventAuthor = {
  __typename?: 'TrailEventAuthor';
  countryIso?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  username?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  account: Account;
  amount: Money;
  balance: Money;
  code: TransactionCode;
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  detail?: Maybe<DetailUnion>;
  id?: Maybe<Scalars['ID']>;
  metadata?: Maybe<Scalars['JSON']>;
  partnerAccount: Account;
  updatedAt: Scalars['DateTime'];
};

export enum TransactionCode {
  Deposit = 'deposit',
  Refund = 'refund',
  Place = 'place',
  Raise = 'raise',
  Unhold = 'unhold',
  Invoice = 'invoice',
  DeliveryReserveHold = 'delivery_reserve_hold',
  DeliveryReserveUnhold = 'delivery_reserve_unhold',
  Apply = 'apply',
  Reset = 'reset'
}

export type TransactionCodeFilter = {
  eq?: Maybe<Array<TransactionCode>>;
};

export type TransactionPaginatedList = {
  __typename?: 'TransactionPaginatedList';
  nodes: Array<Transaction>;
  total: Scalars['Int'];
};

export type UpdatedAtSort = {
  direction?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UserAttributeUpdate */
export type UserAttributeUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: AttributeName;
  value: Scalars['SecureString'];
};

/** Autogenerated return type of UserAttributeUpdate. */
export type UserAttributeUpdatePayload = {
  __typename?: 'UserAttributeUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of UserAttributeVerificationCodeCreate */
export type UserAttributeVerificationCodeCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: AttributeName;
};

/** Autogenerated return type of UserAttributeVerificationCodeCreate. */
export type UserAttributeVerificationCodeCreatePayload = {
  __typename?: 'UserAttributeVerificationCodeCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of UserAttributeVerify */
export type UserAttributeVerifyInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  name: AttributeName;
  code: Scalars['SecureString'];
};

/** Autogenerated return type of UserAttributeVerify. */
export type UserAttributeVerifyPayload = {
  __typename?: 'UserAttributeVerifyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of UserDetailsUpdate */
export type UserDetailsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['SecureString']>;
  middleName?: Maybe<Scalars['SecureString']>;
  lastName?: Maybe<Scalars['SecureString']>;
  fullName?: Maybe<Scalars['SecureString']>;
  skype?: Maybe<Scalars['SecureString']>;
  timezone?: Maybe<Scalars['SecureString']>;
  preferredLang?: Maybe<PreferredLang>;
  autoShipmentEnabled?: Maybe<Scalars['Boolean']>;
  defaultOrderDeliveryServiceId?: Maybe<Scalars['ID']>;
  countryIso?: Maybe<Scalars['SecureString']>;
  personalAccountStartingPage?: Maybe<Scalars['SecureString']>;
};

/** Autogenerated return type of UserDetailsUpdate. */
export type UserDetailsUpdatePayload = {
  __typename?: 'UserDetailsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

/** Autogenerated input type of UserDisable */
export type UserDisableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  disableAgreement: Scalars['Boolean'];
  understandConsequences: Scalars['Boolean'];
  confirm: Scalars['Boolean'];
};

/** Autogenerated return type of UserDisable. */
export type UserDisablePayload = {
  __typename?: 'UserDisablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of UserPasswordUpdate */
export type UserPasswordUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  oldPassword: Scalars['SecureString'];
  newPassword: Scalars['SecureString'];
};

/** Autogenerated return type of UserPasswordUpdate. */
export type UserPasswordUpdatePayload = {
  __typename?: 'UserPasswordUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type YahooBid = {
  __typename?: 'YahooBid';
  amount?: Maybe<Scalars['Float']>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isDeferred?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  reservedAmount?: Maybe<Scalars['Int']>;
  status?: Maybe<YahooBidStatusEnum>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  winAmount?: Maybe<Scalars['Float']>;
  yahooLotCode?: Maybe<Scalars['String']>;
  yahooLotId?: Maybe<Scalars['ID']>;
  yahooOrderId?: Maybe<Scalars['ID']>;
};

/** Autogenerated input type of YahooBidCreate */
export type YahooBidCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  auctionId: Scalars['String'];
  amount: Scalars['Int'];
  quantity: Scalars['Int'];
  quantityPolicy: Scalars['String'];
  isBuyNow: Scalars['Boolean'];
  isDeferred: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of YahooBidCreate. */
export type YahooBidCreatePayload = {
  __typename?: 'YahooBidCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  yahooBid?: Maybe<YahooBid>;
};

/** Autogenerated input type of YahooBidDelete */
export type YahooBidDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of YahooBidDelete. */
export type YahooBidDeletePayload = {
  __typename?: 'YahooBidDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  yahooBid?: Maybe<YahooBid>;
};

export type YahooBidPaginatedList = {
  __typename?: 'YahooBidPaginatedList';
  nodes: Array<YahooBid>;
  total: Scalars['Int'];
};

export enum YahooBidStatusEnum {
  Initial = 'initial',
  Best = 'best',
  Failed = 'failed',
  Overbid = 'overbid',
  Cancelled = 'cancelled',
  Won = 'won',
  Completed = 'completed',
  Deferred = 'deferred',
  Indeterminate = 'indeterminate',
  AwaitingManualPurchase = 'awaiting_manual_purchase'
}

/** Autogenerated input type of YahooBidUpdate */
export type YahooBidUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  amount: Scalars['Int'];
};

/** Autogenerated return type of YahooBidUpdate. */
export type YahooBidUpdatePayload = {
  __typename?: 'YahooBidUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
  yahooBid?: Maybe<YahooBid>;
};

export type YahooFeeConfig = {
  __typename?: 'YahooFeeConfig';
  exchangeRatePercent: Scalars['Float'];
  maximum: Scalars['Float'];
  minimum: Scalars['Float'];
  percent: Scalars['Float'];
  personTaxRate: Scalars['Float'];
};

export type YahooLot = {
  __typename?: 'YahooLot';
  bidStep: Scalars['Int'];
  currentUserActiveYahooBid?: Maybe<YahooBid>;
  currentUserYahooBids: Array<YahooBid>;
  endTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['String']>>;
  isBiddable: Scalars['Boolean'];
  isCategoryBlocked: Scalars['Boolean'];
  isCensored: Scalars['Boolean'];
  isLeader: Scalars['Boolean'];
  isLeaderKnown: Scalars['Boolean'];
  lastPayload?: Maybe<Scalars['JSON']>;
  orderId?: Maybe<Scalars['ID']>;
  shipmentPrice?: Maybe<Scalars['Int']>;
  thumbnails?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  yahooQuestions: Array<YahooQuestion>;
};

export type YahooLotEndTimeFilter = {
  eq?: Maybe<Scalars['DateTime']>;
  neq?: Maybe<Scalars['DateTime']>;
  lt?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
};

export type YahooLotEndTimeSort = {
  direction?: Maybe<Scalars['String']>;
};

export type YahooLotFilter = {
  code?: Maybe<Scalars['String']>;
};

export type YahooLotPaginatedList = {
  __typename?: 'YahooLotPaginatedList';
  nodes: Array<YahooLot>;
  total: Scalars['Int'];
};

export type YahooOrder = OrderInterface & {
  __typename?: 'YahooOrder';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryService?: Maybe<Service>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesSummary?: Maybe<OrderInvoicesSummary>;
  metadata?: Maybe<Scalars['JSON']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderItemsCount?: Maybe<Scalars['Int']>;
  orderItemsTotalPrice?: Maybe<Scalars['Float']>;
  orderItemsTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  shipmentInfo?: Maybe<ShipmentInfo>;
  status?: Maybe<OrderStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<TrailEvent>>;
  yahooBid?: Maybe<YahooBid>;
  yahooLot?: Maybe<YahooLot>;
};


export type YahooOrderTitleArgs = {
  lang: Scalars['String'];
};

/** Autogenerated input type of YahooOrderDelete */
export type YahooOrderDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  flagAgreement: Scalars['Boolean'];
  flagNoRefund: Scalars['Boolean'];
  flagPossibleCharges: Scalars['Boolean'];
};

/** Autogenerated return type of YahooOrderDelete. */
export type YahooOrderDeletePayload = {
  __typename?: 'YahooOrderDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  error?: Maybe<Error>;
};

export type YahooProduct = {
  __typename?: 'YahooProduct';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Scalars['String']>>;
  lastPayload?: Maybe<Scalars['JSON']>;
  price?: Maybe<Scalars['Int']>;
  thumbnails?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type YahooQuestion = {
  __typename?: 'YahooQuestion';
  answered?: Maybe<Scalars['Boolean']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type YahooShoppingOrder = OrderInterface & {
  __typename?: 'YahooShoppingOrder';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryService?: Maybe<Service>;
  files?: Maybe<Array<S3File>>;
  id?: Maybe<Scalars['ID']>;
  internalCode?: Maybe<Scalars['String']>;
  invoices?: Maybe<Array<Invoice>>;
  invoicesSummary?: Maybe<OrderInvoicesSummary>;
  metadata?: Maybe<Scalars['JSON']>;
  orderItems?: Maybe<Array<OrderItem>>;
  orderItemsCount?: Maybe<Scalars['Int']>;
  orderItemsTotalPrice?: Maybe<Scalars['Float']>;
  orderItemsTotalWeight?: Maybe<Scalars['Float']>;
  ruTrackingNumber?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['ID']>;
  shipmentInfo?: Maybe<ShipmentInfo>;
  status?: Maybe<OrderStatus>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['ID']>;
  versions?: Maybe<Array<TrailEvent>>;
};


export type YahooShoppingOrderTitleArgs = {
  lang: Scalars['String'];
};

export type CartItemCreateMutationVariables = Exact<{
  input: CartItemCreateInput;
}>;


export type CartItemCreateMutation = { __typename?: 'Mutation', cartItemCreate?: Maybe<{ __typename?: 'CartItemCreatePayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, cartItems?: Maybe<Array<{ __typename?: 'CartItem', id?: Maybe<string>, quantity?: Maybe<number>, variant?: Maybe<string>, subject?: Maybe<{ __typename: 'AutoPart', id?: Maybe<string>, code?: Maybe<string>, price?: Maybe<number>, title?: Maybe<string>, maker?: Maybe<string> } | { __typename: 'MercariBeyondProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'MercariProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'RakutenProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> } | { __typename: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string>, imageUrl?: Maybe<string>, price?: Maybe<number>, titleJSON?: Maybe<any>, store?: Maybe<{ __typename?: 'ShopCatalogStore', id?: Maybe<string>, title?: Maybe<string> }> } | { __typename: 'YahooProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> }> }>> }> }> };

export type CartItemDeleteMutationVariables = Exact<{
  input: CartItemDeleteInput;
}>;


export type CartItemDeleteMutation = { __typename?: 'Mutation', cartItemDelete?: Maybe<{ __typename?: 'CartItemDeletePayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, cartItems?: Maybe<Array<{ __typename?: 'CartItem', id?: Maybe<string>, quantity?: Maybe<number>, variant?: Maybe<string>, subject?: Maybe<{ __typename: 'AutoPart', id?: Maybe<string>, code?: Maybe<string>, price?: Maybe<number>, title?: Maybe<string>, maker?: Maybe<string> } | { __typename: 'MercariBeyondProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'MercariProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'RakutenProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> } | { __typename: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string>, imageUrl?: Maybe<string>, price?: Maybe<number>, titleJSON?: Maybe<any>, store?: Maybe<{ __typename?: 'ShopCatalogStore', id?: Maybe<string>, title?: Maybe<string> }> } | { __typename: 'YahooProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> }> }>> }> }> };

export type CartItemUpdateMutationVariables = Exact<{
  input: CartItemUpdateInput;
}>;


export type CartItemUpdateMutation = { __typename?: 'Mutation', cartItemUpdate?: Maybe<{ __typename?: 'CartItemUpdatePayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, cartItems?: Maybe<Array<{ __typename?: 'CartItem', id?: Maybe<string>, quantity?: Maybe<number>, variant?: Maybe<string>, subject?: Maybe<{ __typename: 'AutoPart', id?: Maybe<string>, code?: Maybe<string>, price?: Maybe<number>, title?: Maybe<string>, maker?: Maybe<string> } | { __typename: 'MercariBeyondProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'MercariProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'RakutenProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> } | { __typename: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string>, imageUrl?: Maybe<string>, price?: Maybe<number>, titleJSON?: Maybe<any>, store?: Maybe<{ __typename?: 'ShopCatalogStore', id?: Maybe<string>, title?: Maybe<string> }> } | { __typename: 'YahooProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> }> }>> }> }> };

export type CartItemsOrderMutationVariables = Exact<{
  input: CartItemsOrderInput;
}>;


export type CartItemsOrderMutation = { __typename?: 'Mutation', cartItemsOrder?: Maybe<{ __typename?: 'CartItemsOrderPayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type ChatMessageCreateMutationVariables = Exact<{
  input: ChatMessageCreateInput;
}>;


export type ChatMessageCreateMutation = { __typename?: 'Mutation', chatMessageCreate?: Maybe<{ __typename?: 'ChatMessageCreatePayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type CrooberOrderCreateMutationVariables = Exact<{
  input: CrooberOrderCreateInput;
}>;


export type CrooberOrderCreateMutation = { __typename?: 'Mutation', crooberOrderCreate?: Maybe<{ __typename?: 'CrooberOrderCreatePayload', error?: Maybe<{ __typename?: 'Error', backtrace?: Maybe<string>, code?: Maybe<string>, message?: Maybe<string> }> }> };

export type FavouriteCreateMutationVariables = Exact<{
  input: FavouriteCreateInput;
}>;


export type FavouriteCreateMutation = { __typename?: 'Mutation', favouriteCreate?: Maybe<{ __typename?: 'FavouriteCreatePayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }>, favourite?: Maybe<{ __typename?: 'Favourite', id?: Maybe<string> }> }> };

export type FavouriteDeleteMutationVariables = Exact<{
  input: FavouriteDeleteInput;
}>;


export type FavouriteDeleteMutation = { __typename?: 'Mutation', favouriteDelete?: Maybe<{ __typename?: 'FavouriteDeletePayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type PostQaMessageMutationVariables = Exact<{
  input: PostQaMessageInput;
}>;


export type PostQaMessageMutation = { __typename?: 'Mutation', postQaMessage?: Maybe<{ __typename?: 'PostQAMessagePayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type RussianDeliveryCalculationCreateMutationVariables = Exact<{
  input: RussianDeliveryCalculationCreateInput;
}>;


export type RussianDeliveryCalculationCreateMutation = { __typename?: 'Mutation', russianDeliveryCalculationCreate?: Maybe<{ __typename?: 'RussianDeliveryCalculationCreatePayload', calculation?: Maybe<any>, error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type ShopOrderCreateMutationVariables = Exact<{
  input: ShopOrderCreateInput;
}>;


export type ShopOrderCreateMutation = { __typename?: 'Mutation', shopOrderCreate?: Maybe<{ __typename?: 'ShopOrderCreatePayload', error?: Maybe<{ __typename?: 'Error', code?: Maybe<string>, message?: Maybe<string> }> }> };

export type YahooBidCreateMutationVariables = Exact<{
  input: YahooBidCreateInput;
}>;


export type YahooBidCreateMutation = { __typename?: 'Mutation', yahooBidCreate?: Maybe<{ __typename?: 'YahooBidCreatePayload', error?: Maybe<{ __typename?: 'Error', backtrace?: Maybe<string>, code?: Maybe<string>, message?: Maybe<string> }> }> };

export type YahooBidUpdateMutationVariables = Exact<{
  input: YahooBidUpdateInput;
}>;


export type YahooBidUpdateMutation = { __typename?: 'Mutation', yahooBidUpdate?: Maybe<{ __typename?: 'YahooBidUpdatePayload', error?: Maybe<{ __typename?: 'Error', backtrace?: Maybe<string>, code?: Maybe<string>, message?: Maybe<string> }> }> };

export type AuctionQueryVariables = Exact<{
  auctionId: Scalars['String'];
  secureAuctionId: Scalars['SecureString'];
  withShipmentPrice: Scalars['Boolean'];
  messageTemplateLanguage: MessageTemplateLanguageEnum;
}>;


export type AuctionQuery = { __typename?: 'Query', currentTime?: Maybe<any>, auctions?: Maybe<{ __typename?: 'Auctions', showQandA?: Maybe<any>, bidHistoryDetail?: Maybe<any> }>, messageTemplates: Array<{ __typename?: 'MessageTemplate', id?: Maybe<string>, title?: Maybe<string>, content?: Maybe<string> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, email?: Maybe<string>, countryIso?: Maybe<string>, totalBalance?: Maybe<number>, fullName?: Maybe<string>, username?: Maybe<string>, timezone?: Maybe<string>, timezoneOffset?: Maybe<string>, addresses?: Maybe<Array<{ __typename?: 'Address', id?: Maybe<string>, zip?: Maybe<string>, city?: Maybe<string>, state?: Maybe<string>, default?: Maybe<boolean>, country?: Maybe<{ __typename?: 'Country', name: string }> }>>, favourites?: Maybe<{ __typename?: 'FavouritePaginatedList', total: number, nodes: Array<{ __typename?: 'Favourite', id?: Maybe<string>, yahooLot: { __typename?: 'YahooLot', id?: Maybe<string> } }> }> }>, yahooLot?: Maybe<{ __typename?: 'YahooLot', isBiddable: boolean, isCensored: boolean, isCategoryBlocked: boolean, lastPayload?: Maybe<any>, isLeaderKnown: boolean, isLeader: boolean, bidStep: number, shipmentPrice?: Maybe<number>, currentUserActiveYahooBid?: Maybe<{ __typename?: 'YahooBid', amount?: Maybe<number>, maxAmount?: Maybe<number>, status?: Maybe<YahooBidStatusEnum>, id?: Maybe<string> }>, yahooQuestions: Array<{ __typename?: 'YahooQuestion', id?: Maybe<string>, content?: Maybe<string>, createdAt?: Maybe<any> }> }>, services?: Maybe<{ __typename?: 'ServicePaginatedList', nodes: Array<{ __typename?: 'Service', id: string, code: string, title: string, provider?: Maybe<string>, price: { __typename?: 'Money', amount?: Maybe<number>, currency?: Maybe<string> } }> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }> };

export type PartsSearchQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type PartsSearchQuery = { __typename?: 'Query', autoParts?: Maybe<{ __typename?: 'AutoPartPaginatedList', total: number, nodes: Array<{ __typename?: 'AutoPart', id?: Maybe<string>, code?: Maybe<string>, price?: Maybe<number>, maker?: Maybe<string>, titleEN?: Maybe<string>, titleRU?: Maybe<string> }> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }> };

export type CartItemsFragment = { __typename?: 'CurrentUser', cartItems?: Maybe<Array<{ __typename?: 'CartItem', id?: Maybe<string>, quantity?: Maybe<number>, variant?: Maybe<string>, subject?: Maybe<{ __typename: 'AutoPart', id?: Maybe<string>, code?: Maybe<string>, price?: Maybe<number>, title?: Maybe<string>, maker?: Maybe<string> } | { __typename: 'MercariBeyondProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'MercariProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'RakutenProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> } | { __typename: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string>, imageUrl?: Maybe<string>, price?: Maybe<number>, titleJSON?: Maybe<any>, store?: Maybe<{ __typename?: 'ShopCatalogStore', id?: Maybe<string>, title?: Maybe<string> }> } | { __typename: 'YahooProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> }> }>> };

export type CartItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type CartItemsQuery = { __typename?: 'Query', currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, cartItems?: Maybe<Array<{ __typename?: 'CartItem', id?: Maybe<string>, quantity?: Maybe<number>, variant?: Maybe<string>, subject?: Maybe<{ __typename: 'AutoPart', id?: Maybe<string>, code?: Maybe<string>, price?: Maybe<number>, title?: Maybe<string>, maker?: Maybe<string> } | { __typename: 'MercariBeyondProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'MercariProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'RakutenProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> } | { __typename: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string>, imageUrl?: Maybe<string>, price?: Maybe<number>, titleJSON?: Maybe<any>, store?: Maybe<{ __typename?: 'ShopCatalogStore', id?: Maybe<string>, title?: Maybe<string> }> } | { __typename: 'YahooProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> }> }>> }> };

export type CartQueryVariables = Exact<{ [key: string]: never; }>;


export type CartQuery = { __typename?: 'Query', currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, cartItems?: Maybe<Array<{ __typename?: 'CartItem', id?: Maybe<string>, quantity?: Maybe<number>, variant?: Maybe<string>, subject?: Maybe<{ __typename: 'AutoPart', id?: Maybe<string>, code?: Maybe<string>, price?: Maybe<number>, title?: Maybe<string>, maker?: Maybe<string> } | { __typename: 'MercariBeyondProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'MercariProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<string>, images?: Maybe<Array<string>>, thumbnails?: Maybe<Array<string>>, price?: Maybe<number>, lastPayload?: Maybe<any> } | { __typename: 'RakutenProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> } | { __typename: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string>, imageUrl?: Maybe<string>, price?: Maybe<number>, titleJSON?: Maybe<any>, store?: Maybe<{ __typename?: 'ShopCatalogStore', id?: Maybe<string>, title?: Maybe<string> }> } | { __typename: 'YahooProduct', id?: Maybe<string>, code?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, price?: Maybe<number>, thumbnails?: Maybe<Array<string>>, title?: Maybe<string>, url?: Maybe<string> }> }>> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }> };

export type CategoriesQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type CategoriesQuery = { __typename?: 'Query', yahooAuctionsCategories?: Maybe<Array<{ __typename?: 'Category', code?: Maybe<string>, isPopular: boolean, title?: Maybe<string>, weight?: Maybe<number>, searchQuery?: Maybe<string>, useSearch: boolean, children?: Maybe<Array<{ __typename?: 'CategoryChild', code?: Maybe<string>, isPopular: boolean, title?: Maybe<string>, weight?: Maybe<number>, searchQuery?: Maybe<string>, useSearch: boolean }>> }>> };

export type CategorySearchQueryVariables = Exact<{
  categoryId: Scalars['Int'];
}>;


export type CategorySearchQuery = { __typename?: 'Query', shopping?: Maybe<{ __typename?: 'Shopping', categorySearch?: Maybe<any> }> };

export type CategoryQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  minBuyout?: Maybe<Scalars['Int']>;
  maxBuyout?: Maybe<Scalars['Int']>;
  store?: Maybe<Scalars['Int']>;
  itemStatus?: Maybe<Scalars['Int']>;
  buynow?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
}>;


export type CategoryQuery = { __typename?: 'Query', currentTime?: Maybe<any>, yahooAuctionsCategory?: Maybe<{ __typename?: 'Category', title?: Maybe<string>, children?: Maybe<Array<{ __typename?: 'CategoryChild', code?: Maybe<string>, title?: Maybe<string> }>> }>, auctions?: Maybe<{ __typename?: 'Auctions', categoryLeaf?: Maybe<any> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, favourites?: Maybe<{ __typename?: 'FavouritePaginatedList', nodes: Array<{ __typename?: 'Favourite', id?: Maybe<string>, yahooLot: { __typename?: 'YahooLot', id?: Maybe<string> } }> }> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }> };

export type CrooberCarTypesQueryVariables = Exact<{
  makerId: Scalars['String'];
}>;


export type CrooberCarTypesQuery = { __typename?: 'Query', crooberCarTypes?: Maybe<any> };

export type CrooberCategoriesQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type CrooberCategoriesQuery = { __typename?: 'Query', crooberCategories?: Maybe<Array<{ __typename?: 'Category', useSearch: boolean, title?: Maybe<string>, code?: Maybe<string>, children?: Maybe<Array<{ __typename?: 'CategoryChild', useSearch: boolean, title?: Maybe<string>, image?: Maybe<string>, code?: Maybe<string> }>> }>> };

export type CrooberItemQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CrooberItemQuery = { __typename?: 'Query', crooberProduct?: Maybe<{ __typename?: 'CrooberProduct', code?: Maybe<string>, fullPath?: Maybe<string>, images?: Maybe<Array<string>>, shippingPrice?: Maybe<number>, description?: Maybe<string>, details?: Maybe<any>, path?: Maybe<string>, price?: Maybe<number>, priceWithoutTax?: Maybe<number>, status?: Maybe<string>, stars?: Maybe<number>, title?: Maybe<string>, seller?: Maybe<{ __typename?: 'CrooberSeller', path?: Maybe<string>, title?: Maybe<string> }> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }>, services?: Maybe<{ __typename?: 'ServicePaginatedList', nodes: Array<{ __typename?: 'Service', id: string, code: string, title: string, provider?: Maybe<string>, price: { __typename?: 'Money', amount?: Maybe<number>, currency?: Maybe<string> } }> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, email?: Maybe<string>, countryIso?: Maybe<string> }> };

export type CrooberSearchQueryVariables = Exact<{
  path: Scalars['String'];
  categoryId: Scalars['String'];
}>;


export type CrooberSearchQuery = { __typename?: 'Query', crooberPartmakers?: Maybe<any>, crooberSearch?: Maybe<{ __typename?: 'CrooberSearch', total?: Maybe<number>, products?: Maybe<Array<{ __typename?: 'CrooberProduct', code?: Maybe<string>, fullPath?: Maybe<string>, images?: Maybe<Array<string>>, lastPayload?: Maybe<any>, path?: Maybe<string>, price?: Maybe<number>, description?: Maybe<string>, details?: Maybe<any>, stars?: Maybe<number>, title?: Maybe<string>, seller?: Maybe<{ __typename?: 'CrooberSeller', path?: Maybe<string>, title?: Maybe<string> }> }>> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }> };

export type HeaderQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type HeaderQuery = { __typename?: 'Query', countryBlocked: boolean, yahooAuctionsCategories?: Maybe<Array<{ __typename?: 'Category', code?: Maybe<string>, title?: Maybe<string> }>>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, fullName?: Maybe<string>, username?: Maybe<string>, email?: Maybe<string>, totalBalance?: Maybe<number>, shipments?: Maybe<{ __typename?: 'ShipmentPaginatedList', nodes: Array<{ __typename?: 'Shipment', invoices?: Maybe<Array<{ __typename?: 'Invoice', amount: { __typename?: 'Money', amount?: Maybe<number> } }>> }> }> }> };

export type MercariBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type MercariBrandsQuery = { __typename?: 'Query', mercariItemBrands?: Maybe<any> };

export type MercariCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type MercariCategoriesQuery = { __typename?: 'Query', mercariItemCategories?: Maybe<any> };

export type MercariFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type MercariFilterQuery = { __typename?: 'Query', mercariItemConditions?: Maybe<any>, mercariItemColors?: Maybe<any>, mercariItemSizes?: Maybe<any> };

export type MercariItemQueryVariables = Exact<{
  id: Scalars['String'];
  type: Scalars['String'];
}>;


export type MercariItemQuery = { __typename?: 'Query', mercariGetItem?: Maybe<any>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, email?: Maybe<string>, totalBalance?: Maybe<number>, fullName?: Maybe<string>, username?: Maybe<string>, timezone?: Maybe<string>, countryIso?: Maybe<string>, timezoneOffset?: Maybe<string>, addresses?: Maybe<Array<{ __typename?: 'Address', id?: Maybe<string>, zip?: Maybe<string>, city?: Maybe<string>, state?: Maybe<string>, default?: Maybe<boolean>, country?: Maybe<{ __typename?: 'Country', name: string }> }>>, cartItems?: Maybe<Array<{ __typename?: 'CartItem', id?: Maybe<string>, quantity?: Maybe<number>, subject?: Maybe<{ __typename?: 'AutoPart', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'MercariBeyondProduct' } | { __typename?: 'MercariProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'RakutenProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'YahooProduct', id?: Maybe<string>, code?: Maybe<string> }> }>> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }>, services?: Maybe<{ __typename?: 'ServicePaginatedList', nodes: Array<{ __typename?: 'Service', id: string, code: string, title: string, provider?: Maybe<string>, price: { __typename?: 'Money', amount?: Maybe<number>, currency?: Maybe<string> } }> }> };

export type MercariSearchQueryVariables = Exact<{
  keyword: Scalars['String'];
  brandId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  categoryId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  colorId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  excludeKeyword?: Maybe<Scalars['String']>;
  itemConditionId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  priceMax?: Maybe<Scalars['Int']>;
  priceMin?: Maybe<Scalars['Int']>;
  priceType?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  shippingMethod?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  shippingPayerId?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  sizeId?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  status?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  nextPageToken?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
}>;


export type MercariSearchQuery = { __typename?: 'Query', mercariSearchItems?: Maybe<any>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }> };

export type RakutenCategoriesQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type RakutenCategoriesQuery = { __typename?: 'Query', rakutenCategories?: Maybe<Array<{ __typename?: 'Category', code?: Maybe<string>, isPopular: boolean, title?: Maybe<string>, weight?: Maybe<number>, searchQuery?: Maybe<string>, useSearch: boolean, children?: Maybe<Array<{ __typename?: 'CategoryChild', code?: Maybe<string>, isPopular: boolean, title?: Maybe<string>, weight?: Maybe<number>, searchQuery?: Maybe<string>, useSearch: boolean }>> }>> };

export type RakutenSearchQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
  genreId?: Maybe<Scalars['String']>;
  shopCode?: Maybe<Scalars['String']>;
  itemCode?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['String']>;
  availability?: Maybe<Scalars['Int']>;
  withVariants: Scalars['Boolean'];
}>;


export type RakutenSearchQuery = { __typename?: 'Query', rakutenSearch?: Maybe<{ __typename?: 'RakutenSearch', count?: Maybe<number>, items?: Maybe<Array<{ __typename?: 'RakutenAPIItem', itemCaption?: Maybe<string>, itemCode?: Maybe<string>, itemName?: Maybe<string>, itemPrice?: Maybe<number>, itemUrl?: Maybe<string>, mediumImageUrls?: Maybe<Array<string>>, reviewAverage?: Maybe<number>, smallImageUrls?: Maybe<Array<string>>, variants?: Maybe<any>, seller?: Maybe<{ __typename?: 'RakutenSeller', path?: Maybe<string>, title?: Maybe<string> }> }>> }>, services?: Maybe<{ __typename?: 'ServicePaginatedList', nodes: Array<{ __typename?: 'Service', id: string, code: string, title: string, provider?: Maybe<string>, price: { __typename?: 'Money', amount?: Maybe<number>, currency?: Maybe<string> } }> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, countryIso?: Maybe<string>, cartItems?: Maybe<Array<{ __typename?: 'CartItem', id?: Maybe<string>, quantity?: Maybe<number>, subject?: Maybe<{ __typename?: 'AutoPart', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'MercariBeyondProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'MercariProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'RakutenProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'YahooProduct', id?: Maybe<string>, code?: Maybe<string> }> }>> }> };

export type SearchQueryVariables = Exact<{
  search: Scalars['String'];
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  minBuyout?: Maybe<Scalars['Int']>;
  maxBuyout?: Maybe<Scalars['Int']>;
  store?: Maybe<Scalars['Int']>;
  itemStatus?: Maybe<Scalars['Int']>;
  buynow?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['Int']>;
  seller?: Maybe<Scalars['String']>;
}>;


export type SearchQuery = { __typename?: 'Query', currentTime?: Maybe<any>, auctions?: Maybe<{ __typename?: 'Auctions', search?: Maybe<any> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, favourites?: Maybe<{ __typename?: 'FavouritePaginatedList', nodes: Array<{ __typename?: 'Favourite', id?: Maybe<string>, yahooLot: { __typename?: 'YahooLot', id?: Maybe<string> } }> }> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }> };

export type SellerQueryVariables = Exact<{
  sellerId: Scalars['SecureString'];
  sort?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  minBuyout?: Maybe<Scalars['Int']>;
  maxBuyout?: Maybe<Scalars['Int']>;
  store?: Maybe<Scalars['Int']>;
  itemStatus?: Maybe<Scalars['Int']>;
  buynow?: Maybe<Scalars['Int']>;
  new?: Maybe<Scalars['Int']>;
}>;


export type SellerQuery = { __typename?: 'Query', currentTime?: Maybe<any>, auctions?: Maybe<{ __typename?: 'Auctions', sellingList?: Maybe<any> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, favourites?: Maybe<{ __typename?: 'FavouritePaginatedList', nodes: Array<{ __typename?: 'Favourite', id?: Maybe<string>, yahooLot: { __typename?: 'YahooLot', id?: Maybe<string> } }> }> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }> };

export type ShopCatalogItemsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  filter?: Maybe<ShopCatalogItemsFilter>;
  sort?: Maybe<ShopCatalogItemsSort>;
}>;


export type ShopCatalogItemsQuery = { __typename?: 'Query', shopCatalogItems?: Maybe<{ __typename?: 'ShopCatalogProductPaginatedList', total: number, nodes: Array<{ __typename?: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string>, title?: Maybe<any>, description?: Maybe<any>, imageUrl?: Maybe<string>, price?: Maybe<number>, store?: Maybe<{ __typename?: 'ShopCatalogStore', id?: Maybe<string>, title?: Maybe<string> }> }> }>, shopCatalogStores?: Maybe<Array<{ __typename?: 'ShopCatalogStore', id?: Maybe<string>, title?: Maybe<string>, imageUrl?: Maybe<string> }>> };

export type ShopCatalogStoresQueryVariables = Exact<{ [key: string]: never; }>;


export type ShopCatalogStoresQuery = { __typename?: 'Query', shopCatalogStores?: Maybe<Array<{ __typename?: 'ShopCatalogStore', id?: Maybe<string>, title?: Maybe<string>, imageUrl?: Maybe<string> }>> };

export type ShoppingCategoriesQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type ShoppingCategoriesQuery = { __typename?: 'Query', yahooShoppingCategories?: Maybe<Array<{ __typename?: 'Category', code?: Maybe<string>, isPopular: boolean, title?: Maybe<string>, weight?: Maybe<number>, searchQuery?: Maybe<string>, useSearch: boolean, children?: Maybe<Array<{ __typename?: 'CategoryChild', code?: Maybe<string>, isPopular: boolean, title?: Maybe<string>, weight?: Maybe<number>, searchQuery?: Maybe<string>, useSearch: boolean }>> }>> };

export type ShoppingItemQueryVariables = Exact<{
  code: Scalars['String'];
  imageSize?: Maybe<Scalars['String']>;
  responsegroup?: Maybe<Scalars['String']>;
}>;


export type ShoppingItemQuery = { __typename?: 'Query', shopping?: Maybe<{ __typename?: 'Shopping', itemLookup?: Maybe<any> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }>, exchangeRateEur: { __typename?: 'ExchangeRate', nominal: number, value: number, date: any }, services?: Maybe<{ __typename?: 'ServicePaginatedList', nodes: Array<{ __typename?: 'Service', id: string, code: string, title: string, provider?: Maybe<string>, price: { __typename?: 'Money', amount?: Maybe<number>, currency?: Maybe<string> } }> }>, currentUser?: Maybe<{ __typename?: 'CurrentUser', id?: Maybe<string>, countryIso?: Maybe<string>, cartItems?: Maybe<Array<{ __typename?: 'CartItem', id?: Maybe<string>, quantity?: Maybe<number>, subject?: Maybe<{ __typename?: 'AutoPart', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'MercariBeyondProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'MercariProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'RakutenProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'ShopCatalogProduct', id?: Maybe<string>, code?: Maybe<string> } | { __typename?: 'YahooProduct', id?: Maybe<string>, code?: Maybe<string> }> }>> }> };

export type ShoppingSearchQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
  genreCategoryId?: Maybe<Scalars['Int']>;
  priceFrom?: Maybe<Scalars['Int']>;
  priceTo?: Maybe<Scalars['Int']>;
  isDiscounted?: Maybe<Scalars['Boolean']>;
  inStock?: Maybe<Scalars['Boolean']>;
  sellerId?: Maybe<Scalars['String']>;
}>;


export type ShoppingSearchQuery = { __typename?: 'Query', shopping?: Maybe<{ __typename?: 'Shopping', itemSearch?: Maybe<any> }>, exchangeRate?: Maybe<{ __typename?: 'ExchangeRate', nominal: number, value: number, date: any }> };

export type YahooFeeConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type YahooFeeConfigQuery = { __typename?: 'Query', yahooFeeConfig: { __typename?: 'YahooFeeConfig', percent: number, minimum: number, maximum: number, personTaxRate: number } };

export const CartItemsFragmentDoc = gql`
    fragment cartItems on CurrentUser {
  cartItems {
    id
    quantity
    variant
    subject {
      __typename
      ... on YahooProduct {
        id
        code
        images
        lastPayload
        price
        thumbnails
        title
        url
      }
      ... on RakutenProduct {
        id
        code
        images
        lastPayload
        price
        thumbnails
        title
        url
      }
      ... on AutoPart {
        id
        code
        price
        title(lang: "en")
        maker
      }
      ... on ShopCatalogProduct {
        id
        code
        titleJSON: title
        imageUrl
        price
        store {
          id
          title
        }
      }
      ... on MercariProduct {
        id
        code
        title
        images
        thumbnails
        price
        lastPayload
      }
      ... on MercariBeyondProduct {
        id
        code
        title
        images
        thumbnails
        price
        lastPayload
      }
    }
  }
}
    `;
export const CartItemCreateDocument = gql`
    mutation cartItemCreate($input: CartItemCreateInput!) {
  cartItemCreate(input: $input) {
    error {
      code
      message
    }
    currentUser {
      id
      ...cartItems
    }
  }
}
    ${CartItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartItemCreateGQL extends Apollo.Mutation<CartItemCreateMutation, CartItemCreateMutationVariables> {
    document = CartItemCreateDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartItemDeleteDocument = gql`
    mutation cartItemDelete($input: CartItemDeleteInput!) {
  cartItemDelete(input: $input) {
    error {
      code
      message
    }
    currentUser {
      id
      ...cartItems
    }
  }
}
    ${CartItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartItemDeleteGQL extends Apollo.Mutation<CartItemDeleteMutation, CartItemDeleteMutationVariables> {
    document = CartItemDeleteDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartItemUpdateDocument = gql`
    mutation cartItemUpdate($input: CartItemUpdateInput!) {
  cartItemUpdate(input: $input) {
    error {
      code
      message
    }
    currentUser {
      id
      ...cartItems
    }
  }
}
    ${CartItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartItemUpdateGQL extends Apollo.Mutation<CartItemUpdateMutation, CartItemUpdateMutationVariables> {
    document = CartItemUpdateDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartItemsOrderDocument = gql`
    mutation cartItemsOrder($input: CartItemsOrderInput!) {
  cartItemsOrder(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CartItemsOrderGQL extends Apollo.Mutation<CartItemsOrderMutation, CartItemsOrderMutationVariables> {
    document = CartItemsOrderDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ChatMessageCreateDocument = gql`
    mutation chatMessageCreate($input: ChatMessageCreateInput!) {
  chatMessageCreate(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ChatMessageCreateGQL extends Apollo.Mutation<ChatMessageCreateMutation, ChatMessageCreateMutationVariables> {
    document = ChatMessageCreateDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CrooberOrderCreateDocument = gql`
    mutation crooberOrderCreate($input: CrooberOrderCreateInput!) {
  crooberOrderCreate(input: $input) {
    error {
      backtrace
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CrooberOrderCreateGQL extends Apollo.Mutation<CrooberOrderCreateMutation, CrooberOrderCreateMutationVariables> {
    document = CrooberOrderCreateDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FavouriteCreateDocument = gql`
    mutation favouriteCreate($input: FavouriteCreateInput!) {
  favouriteCreate(input: $input) {
    error {
      code
      message
    }
    favourite {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FavouriteCreateGQL extends Apollo.Mutation<FavouriteCreateMutation, FavouriteCreateMutationVariables> {
    document = FavouriteCreateDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FavouriteDeleteDocument = gql`
    mutation favouriteDelete($input: FavouriteDeleteInput!) {
  favouriteDelete(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FavouriteDeleteGQL extends Apollo.Mutation<FavouriteDeleteMutation, FavouriteDeleteMutationVariables> {
    document = FavouriteDeleteDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PostQaMessageDocument = gql`
    mutation postQaMessage($input: PostQAMessageInput!) {
  postQaMessage(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PostQaMessageGQL extends Apollo.Mutation<PostQaMessageMutation, PostQaMessageMutationVariables> {
    document = PostQaMessageDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RussianDeliveryCalculationCreateDocument = gql`
    mutation russianDeliveryCalculationCreate($input: RussianDeliveryCalculationCreateInput!) {
  russianDeliveryCalculationCreate(input: $input) {
    error {
      code
      message
    }
    calculation
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RussianDeliveryCalculationCreateGQL extends Apollo.Mutation<RussianDeliveryCalculationCreateMutation, RussianDeliveryCalculationCreateMutationVariables> {
    document = RussianDeliveryCalculationCreateDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShopOrderCreateDocument = gql`
    mutation shopOrderCreate($input: ShopOrderCreateInput!) {
  shopOrderCreate(input: $input) {
    error {
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShopOrderCreateGQL extends Apollo.Mutation<ShopOrderCreateMutation, ShopOrderCreateMutationVariables> {
    document = ShopOrderCreateDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const YahooBidCreateDocument = gql`
    mutation yahooBidCreate($input: YahooBidCreateInput!) {
  yahooBidCreate(input: $input) {
    error {
      backtrace
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class YahooBidCreateGQL extends Apollo.Mutation<YahooBidCreateMutation, YahooBidCreateMutationVariables> {
    document = YahooBidCreateDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const YahooBidUpdateDocument = gql`
    mutation yahooBidUpdate($input: YahooBidUpdateInput!) {
  yahooBidUpdate(input: $input) {
    error {
      backtrace
      code
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class YahooBidUpdateGQL extends Apollo.Mutation<YahooBidUpdateMutation, YahooBidUpdateMutationVariables> {
    document = YahooBidUpdateDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AuctionDocument = gql`
    query auction($auctionId: String!, $secureAuctionId: SecureString!, $withShipmentPrice: Boolean!, $messageTemplateLanguage: MessageTemplateLanguageEnum!) {
  auctions {
    showQandA(auctionID: $secureAuctionId)
    bidHistoryDetail(auctionID: $secureAuctionId, page: 1)
  }
  messageTemplates(language: $messageTemplateLanguage) {
    id
    title
    content
  }
  currentUser {
    id
    email
    countryIso
    totalBalance
    fullName
    username
    timezone
    timezoneOffset
    addresses {
      id
      zip
      city
      state
      country {
        name
      }
      default
    }
    favourites(filter: {yahooLot: {code: $auctionId}}) {
      nodes {
        id
        yahooLot(refresh: false) {
          id
        }
      }
      total
    }
  }
  yahooLot(code: $auctionId) {
    isBiddable
    isCensored
    isCategoryBlocked
    lastPayload
    isLeaderKnown
    isLeader
    bidStep
    shipmentPrice @include(if: $withShipmentPrice)
    currentUserActiveYahooBid {
      amount
      maxAmount
      status
      id
    }
    yahooQuestions {
      id
      content
      createdAt
    }
  }
  services(filter: {serviceType: {eq: OrderItemDeliveryService}}) {
    nodes {
      id
      code
      title
      provider
      price {
        amount
        currency
      }
    }
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
  currentTime
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AuctionGQL extends Apollo.Query<AuctionQuery, AuctionQueryVariables> {
    document = AuctionDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PartsSearchDocument = gql`
    query partsSearch($name: String, $first: Int, $offset: Int) {
  autoParts(first: $first, offset: $offset, filter: {code: {eq: $name}}) {
    nodes {
      id
      code
      price
      titleEN: title(lang: "en")
      titleRU: title(lang: "ru")
      maker
    }
    total
  }
  currentUser {
    id
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PartsSearchGQL extends Apollo.Query<PartsSearchQuery, PartsSearchQueryVariables> {
    document = PartsSearchDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartItemsDocument = gql`
    query cartItems {
  currentUser {
    id
    ...cartItems
  }
}
    ${CartItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartItemsGQL extends Apollo.Query<CartItemsQuery, CartItemsQueryVariables> {
    document = CartItemsDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CartDocument = gql`
    query cart {
  currentUser {
    id
    ...cartItems
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
}
    ${CartItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CartGQL extends Apollo.Query<CartQuery, CartQueryVariables> {
    document = CartDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CategoriesDocument = gql`
    query categories($lang: String) {
  yahooAuctionsCategories {
    code
    isPopular
    title(lang: $lang)
    weight
    searchQuery
    useSearch
    children {
      code
      isPopular
      title(lang: $lang)
      weight
      searchQuery
      useSearch
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoriesGQL extends Apollo.Query<CategoriesQuery, CategoriesQueryVariables> {
    document = CategoriesDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CategorySearchDocument = gql`
    query categorySearch($categoryId: Int!) {
  shopping {
    categorySearch(categoryId: $categoryId)
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategorySearchGQL extends Apollo.Query<CategorySearchQuery, CategorySearchQueryVariables> {
    document = CategorySearchDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CategoryDocument = gql`
    query category($lang: String, $code: String!, $sort: String, $order: String, $offset: Int, $pageSize: Int, $minPrice: Int, $maxPrice: Int, $minBuyout: Int, $maxBuyout: Int, $store: Int, $itemStatus: Int, $buynow: Int, $new: Int) {
  yahooAuctionsCategory(code: $code) {
    title(lang: $lang)
    children {
      code
      title(lang: $lang)
    }
  }
  auctions {
    categoryLeaf(
      category: $code
      offset: $offset
      pageSize: $pageSize
      sort: $sort
      order: $order
      aucminprice: $minPrice
      aucmaxprice: $maxPrice
      aucminBidorbuyPrice: $minBuyout
      aucmaxBidorbuyPrice: $maxBuyout
      store: $store
      itemStatus: $itemStatus
      buynow: $buynow
      new: $new
    )
  }
  currentUser {
    id
    favourites {
      nodes {
        id
        yahooLot(refresh: false) {
          id
        }
      }
    }
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
  currentTime
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CategoryGQL extends Apollo.Query<CategoryQuery, CategoryQueryVariables> {
    document = CategoryDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CrooberCarTypesDocument = gql`
    query crooberCarTypes($makerId: String!) {
  crooberCarTypes(makerId: $makerId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CrooberCarTypesGQL extends Apollo.Query<CrooberCarTypesQuery, CrooberCarTypesQueryVariables> {
    document = CrooberCarTypesDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CrooberCategoriesDocument = gql`
    query crooberCategories($lang: String) {
  crooberCategories {
    code: searchQuery
    useSearch
    title(lang: $lang)
    children {
      code: searchQuery
      useSearch
      title(lang: $lang)
      image
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CrooberCategoriesGQL extends Apollo.Query<CrooberCategoriesQuery, CrooberCategoriesQueryVariables> {
    document = CrooberCategoriesDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CrooberItemDocument = gql`
    query crooberItem($id: String!) {
  crooberProduct(id: $id) {
    code
    fullPath
    images
    shippingPrice
    description
    details
    path
    price
    priceWithoutTax
    status
    seller {
      path
      title
    }
    stars
    title
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
  services(filter: {serviceType: {eq: OrderItemDeliveryService}}) {
    nodes {
      id
      code
      title
      provider
      price {
        amount
        currency
      }
    }
  }
  currentUser {
    id
    email
    countryIso
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CrooberItemGQL extends Apollo.Query<CrooberItemQuery, CrooberItemQueryVariables> {
    document = CrooberItemDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CrooberSearchDocument = gql`
    query crooberSearch($path: String!, $categoryId: String!) {
  crooberSearch(path: $path) {
    products {
      code
      fullPath
      images
      lastPayload
      path
      price
      description
      details
      seller {
        path
        title
      }
      stars
      title
    }
    total
  }
  crooberPartmakers(categoryId: $categoryId)
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CrooberSearchGQL extends Apollo.Query<CrooberSearchQuery, CrooberSearchQueryVariables> {
    document = CrooberSearchDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const HeaderDocument = gql`
    query header($lang: String) {
  yahooAuctionsCategories {
    code
    title(lang: $lang)
  }
  countryBlocked
  currentUser {
    id
    fullName
    username
    email
    totalBalance
    shipments(filter: {shipmentStatus: {eq: awaiting_payment}}) {
      nodes {
        invoices {
          amount {
            amount
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class HeaderGQL extends Apollo.Query<HeaderQuery, HeaderQueryVariables> {
    document = HeaderDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MercariBrandsDocument = gql`
    query mercariBrands {
  mercariItemBrands
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MercariBrandsGQL extends Apollo.Query<MercariBrandsQuery, MercariBrandsQueryVariables> {
    document = MercariBrandsDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MercariCategoriesDocument = gql`
    query mercariCategories {
  mercariItemCategories
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MercariCategoriesGQL extends Apollo.Query<MercariCategoriesQuery, MercariCategoriesQueryVariables> {
    document = MercariCategoriesDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MercariFilterDocument = gql`
    query mercariFilter {
  mercariItemConditions
  mercariItemColors
  mercariItemSizes
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MercariFilterGQL extends Apollo.Query<MercariFilterQuery, MercariFilterQueryVariables> {
    document = MercariFilterDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MercariItemDocument = gql`
    query mercariItem($id: String!, $type: String!) {
  mercariGetItem(itemId: $id, itemType: $type)
  currentUser {
    id
    email
    totalBalance
    fullName
    username
    timezone
    countryIso
    timezoneOffset
    addresses {
      id
      zip
      city
      state
      country {
        name
      }
      default
    }
    cartItems {
      id
      quantity
      subject {
        ... on YahooProduct {
          id
          code
        }
        ... on RakutenProduct {
          id
          code
        }
        ... on AutoPart {
          id
          code
        }
        ... on ShopCatalogProduct {
          id
          code
        }
        ... on MercariProduct {
          id
          code
        }
      }
    }
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
  services(filter: {serviceType: {eq: OrderItemDeliveryService}}) {
    nodes {
      id
      code
      title
      provider
      price {
        amount
        currency
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MercariItemGQL extends Apollo.Query<MercariItemQuery, MercariItemQueryVariables> {
    document = MercariItemDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MercariSearchDocument = gql`
    query mercariSearch($keyword: String!, $brandId: [Int!], $categoryId: [Int!], $colorId: [Int!], $excludeKeyword: String, $itemConditionId: [Int!], $order: String, $priceMax: Int, $priceMin: Int, $priceType: [String!], $shippingMethod: [String!], $shippingPayerId: [Int!], $sizeId: [String!], $sort: String, $status: [String!], $nextPageToken: String, $pageSize: Int) {
  mercariSearchItems(
    keyword: $keyword
    brandId: $brandId
    categoryId: $categoryId
    colorId: $colorId
    excludeKeyword: $excludeKeyword
    itemConditionId: $itemConditionId
    order: $order
    priceMax: $priceMax
    priceMin: $priceMin
    priceType: $priceType
    shippingMethod: $shippingMethod
    shippingPayerId: $shippingPayerId
    sizeId: $sizeId
    sort: $sort
    status: $status
    pageToken: $nextPageToken
    pageSize: $pageSize
  )
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MercariSearchGQL extends Apollo.Query<MercariSearchQuery, MercariSearchQueryVariables> {
    document = MercariSearchDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RakutenCategoriesDocument = gql`
    query rakutenCategories($lang: String) {
  rakutenCategories {
    code
    isPopular
    title(lang: $lang)
    weight
    searchQuery
    useSearch
    children {
      code
      isPopular
      title(lang: $lang)
      weight
      searchQuery
      useSearch
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RakutenCategoriesGQL extends Apollo.Query<RakutenCategoriesQuery, RakutenCategoriesQueryVariables> {
    document = RakutenCategoriesDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RakutenSearchDocument = gql`
    query rakutenSearch($query: String, $genreId: String, $shopCode: String, $itemCode: String, $page: Int, $minPrice: Int, $maxPrice: Int, $sort: String, $availability: Int, $withVariants: Boolean!) {
  rakutenSearch(
    keyword: $query
    genreId: $genreId
    shopCode: $shopCode
    itemCode: $itemCode
    page: $page
    minPrice: $minPrice
    maxPrice: $maxPrice
    sort: $sort
    availability: $availability
  ) {
    count
    items {
      itemCaption
      itemCode
      itemName
      itemPrice
      itemUrl
      mediumImageUrls
      reviewAverage
      seller {
        path
        title
      }
      smallImageUrls
      variants @include(if: $withVariants)
    }
  }
  services(filter: {serviceType: {eq: OrderItemDeliveryService}}) {
    nodes {
      id
      code
      title
      provider
      price {
        amount
        currency
      }
    }
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
  currentUser {
    id
    countryIso
    cartItems {
      id
      quantity
      subject {
        ... on YahooProduct {
          id
          code
        }
        ... on RakutenProduct {
          id
          code
        }
        ... on AutoPart {
          id
          code
        }
        ... on ShopCatalogProduct {
          id
          code
        }
        ... on MercariProduct {
          id
          code
        }
        ... on MercariBeyondProduct {
          id
          code
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RakutenSearchGQL extends Apollo.Query<RakutenSearchQuery, RakutenSearchQueryVariables> {
    document = RakutenSearchDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchDocument = gql`
    query search($search: String!, $sort: String, $order: String, $offset: Int, $pageSize: Int, $minPrice: Int, $maxPrice: Int, $minBuyout: Int, $maxBuyout: Int, $store: Int, $itemStatus: Int, $buynow: Int, $new: Int, $category: Int, $seller: String) {
  auctions {
    search(
      query: $search
      offset: $offset
      pageSize: $pageSize
      sort: $sort
      order: $order
      aucminprice: $minPrice
      aucmaxprice: $maxPrice
      aucminBidorbuyPrice: $minBuyout
      aucmaxBidorbuyPrice: $maxBuyout
      store: $store
      itemStatus: $itemStatus
      buynow: $buynow
      new: $new
      seller: $seller
      category: $category
    )
  }
  currentUser {
    id
    favourites {
      nodes {
        id
        yahooLot(refresh: false) {
          id
        }
      }
    }
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
  currentTime
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchGQL extends Apollo.Query<SearchQuery, SearchQueryVariables> {
    document = SearchDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SellerDocument = gql`
    query seller($sellerId: SecureString!, $sort: String, $order: String, $offset: Int, $pageSize: Int, $minPrice: Int, $maxPrice: Int, $minBuyout: Int, $maxBuyout: Int, $store: Int, $itemStatus: Int, $buynow: Int, $new: Int) {
  auctions {
    sellingList(
      sellerID: $sellerId
      offset: $offset
      pageSize: $pageSize
      sort: $sort
      order: $order
      aucminprice: $minPrice
      aucmaxprice: $maxPrice
      aucminBidorbuyPrice: $minBuyout
      aucmaxBidorbuyPrice: $maxBuyout
      store: $store
      itemStatus: $itemStatus
      buynow: $buynow
      new: $new
    )
  }
  currentUser {
    id
    favourites {
      nodes {
        id
        yahooLot(refresh: false) {
          id
        }
      }
    }
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
  currentTime
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SellerGQL extends Apollo.Query<SellerQuery, SellerQueryVariables> {
    document = SellerDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShopCatalogItemsDocument = gql`
    query shopCatalogItems($first: Int, $offset: Int, $filter: ShopCatalogItemsFilter, $sort: ShopCatalogItemsSort) {
  shopCatalogItems(first: $first, offset: $offset, filter: $filter, sort: $sort) {
    total
    nodes {
      id
      code
      title
      description
      imageUrl
      price
      store {
        id
        title
      }
    }
  }
  shopCatalogStores {
    id
    title
    imageUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShopCatalogItemsGQL extends Apollo.Query<ShopCatalogItemsQuery, ShopCatalogItemsQueryVariables> {
    document = ShopCatalogItemsDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShopCatalogStoresDocument = gql`
    query shopCatalogStores {
  shopCatalogStores {
    id
    title
    imageUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShopCatalogStoresGQL extends Apollo.Query<ShopCatalogStoresQuery, ShopCatalogStoresQueryVariables> {
    document = ShopCatalogStoresDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShoppingCategoriesDocument = gql`
    query shoppingCategories($lang: String) {
  yahooShoppingCategories {
    code
    isPopular
    title(lang: $lang)
    weight
    searchQuery
    useSearch
    children {
      code
      isPopular
      title(lang: $lang)
      weight
      searchQuery
      useSearch
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShoppingCategoriesGQL extends Apollo.Query<ShoppingCategoriesQuery, ShoppingCategoriesQueryVariables> {
    document = ShoppingCategoriesDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShoppingItemDocument = gql`
    query shoppingItem($code: String!, $imageSize: String, $responsegroup: String) {
  shopping {
    itemLookup(
      itemcode: $code
      imageSize: $imageSize
      responsegroup: $responsegroup
    )
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
  exchangeRateEur: exchangeRate(iso: EUR) {
    nominal
    value
    date
  }
  services(filter: {serviceType: {eq: OrderItemDeliveryService}}) {
    nodes {
      id
      code
      title
      provider
      price {
        amount
        currency
      }
    }
  }
  currentUser {
    id
    countryIso
    cartItems {
      id
      quantity
      subject {
        ... on YahooProduct {
          id
          code
        }
        ... on RakutenProduct {
          id
          code
        }
        ... on AutoPart {
          id
          code
        }
        ... on ShopCatalogProduct {
          id
          code
        }
        ... on MercariProduct {
          id
          code
        }
        ... on MercariBeyondProduct {
          id
          code
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShoppingItemGQL extends Apollo.Query<ShoppingItemQuery, ShoppingItemQueryVariables> {
    document = ShoppingItemDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShoppingSearchDocument = gql`
    query shoppingSearch($offset: Int, $first: Int, $query: String, $condition: String, $sort: String, $genreCategoryId: Int, $priceFrom: Int, $priceTo: Int, $isDiscounted: Boolean, $inStock: Boolean, $sellerId: String) {
  shopping {
    itemSearch(
      start: $offset
      results: $first
      query: $query
      condition: $condition
      sort: $sort
      genreCategoryId: $genreCategoryId
      priceFrom: $priceFrom
      priceTo: $priceTo
      isDiscounted: $isDiscounted
      inStock: $inStock
      sellerId: $sellerId
    )
  }
  exchangeRate: altecarExchangeRate(iso: JPY) {
    nominal
    value
    date
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShoppingSearchGQL extends Apollo.Query<ShoppingSearchQuery, ShoppingSearchQueryVariables> {
    document = ShoppingSearchDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const YahooFeeConfigDocument = gql`
    query yahooFeeConfig {
  yahooFeeConfig {
    percent
    minimum
    maximum
    personTaxRate
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class YahooFeeConfigGQL extends Apollo.Query<YahooFeeConfigQuery, YahooFeeConfigQueryVariables> {
    document = YahooFeeConfigDocument;
    client = 'user';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "CartItemSubjectUnion": [
      "AutoPart",
      "MercariBeyondProduct",
      "MercariProduct",
      "RakutenProduct",
      "ShopCatalogProduct",
      "YahooProduct"
    ],
    "DetailUnion": [
      "AutoPartOrder",
      "CatalogOrder",
      "CrooberOrder",
      "Invoice",
      "ManualOrder",
      "MercariOrder",
      "PaymentSystemEvent",
      "RakutenOrder",
      "YahooBid",
      "YahooOrder",
      "YahooShoppingOrder"
    ],
    "InvoiceSubjectUnion": [
      "AutoPartOrder",
      "CatalogOrder",
      "CrooberOrder",
      "ManualOrder",
      "MercariOrder",
      "RakutenOrder",
      "Shipment",
      "YahooOrder",
      "YahooShoppingOrder"
    ],
    "OrderInterface": [
      "AutoPartOrder",
      "CatalogOrder",
      "CrooberOrder",
      "ManualOrder",
      "MercariOrder",
      "RakutenOrder",
      "YahooOrder",
      "YahooShoppingOrder"
    ],
    "OrderItemSubjectUnion": [
      "AutoPart",
      "MercariBeyondProduct",
      "MercariProduct",
      "RakutenProduct",
      "ShopCatalogProduct",
      "YahooProduct"
    ],
    "OrderUnion": [
      "AutoPartOrder",
      "CatalogOrder",
      "CrooberOrder",
      "ManualOrder",
      "MercariOrder",
      "RakutenOrder",
      "YahooOrder",
      "YahooShoppingOrder"
    ]
  }
};
      export default result;
    