import { NotificationService } from '@akebono/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs';
import { Loadings } from 'src/app/const';
import {
  CartGQL,
  CartItem,
  CartItemDeleteGQL,
  CartItemDeleteInput,
  CartItemsOrderGQL,
  CartItemsOrderInput,
  CartItemUpdateGQL,
  CartItemUpdateInput,
  ExchangeRate,
  ShopCatalogProduct,
} from 'src/app/graphql/user-service';
import { GoogleTranslateService } from 'src/app/services/google-translate.service';

interface Seller {
  id: string;
  name: string;
  items: CartItem[];
}

interface Store {
  name: string;
  sellers: Seller[];
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit, OnDestroy {
  stores: Store[] = [];
  exchangeRate: ExchangeRate = null;

  loading = false;
  updating: Loadings = {};
  deleting: Loadings = {};
  buing: Loadings = {};

  quantityChanges$ = new BehaviorSubject({});
  subscription: Subscription = null;

  productRouteMap = {
    YahooProduct: '/shopping',
    RakutenProduct: '/rakuten',
    MercariProduct: '/mercari',
    MercariBeyondProduct: '/mercari',
    // AutoPart: '/original-autoparts',
  };

  constructor(
    private apollo: Apollo,
    private notificationService: NotificationService,
    private googleTranslate: GoogleTranslateService,
    private cartGQL: CartGQL,
    private cartItemUpdateGQL: CartItemUpdateGQL,
    private cartItemDeleteGQL: CartItemDeleteGQL,
    private cartItemsOrderGQL: CartItemsOrderGQL,
  ) {}

  ngOnInit(): void {
    this.fetchCart();

    this.quantityChanges$.pipe(debounceTime(1000)).subscribe((change: CartItem) => {
      if (change && Object.keys(change).length) {
        const input: CartItemUpdateInput = {
          id: change.id,
          quantity: change.quantity,
        };

        this.cartItemUpdate(input);
      }
    });
  }

  fetchCart(): void {
    this.loading = true;
    this.subscription = this.cartGQL.watch().valueChanges.subscribe(
      (result) => {
        this.loading = false;
        console.log('Cart response', result);

        if (result?.data?.currentUser) {
          this.exchangeRate = result.data.exchangeRate;
          this.stores = this.getStores(result.data.currentUser.cartItems);
          this.googleTranslate.translate();
          console.log('Stores', this.stores);
        } else {
          this.notificationService.renderError('Empty response');
        }
      },
      (error) => {
        this.loading = false;
        console.log(error);
        this.notificationService.renderError('Error', error);
      },
    );
  }

  getStores(cartItems: CartItem[]): Store[] {
    const stores = cartItems
      .map((item) => item.subject.__typename)
      .filter((name, index, self) => self.indexOf(name) === index)
      .map((name) => {
        return {
          name,
          sellers: this.getSellers(name, cartItems),
        };
      });

    return stores;
  }

  getSellers(storeName: string, cartItems: CartItem[]): Seller[] {
    const sellers: { [key: string]: Seller } = {};
    cartItems
      .filter((item) => item.subject.__typename === storeName)
      .forEach((item) => {
        let subStoreName = null;
        let subStoreId = null;
        switch (storeName) {
          case 'YahooProduct':
            subStoreName = (item.subject as any).lastPayload.Store.Name;
            subStoreId = (item.subject as any).lastPayload.Store.Id;
            break;
          case 'RakutenProduct':
            subStoreName = (item.subject as any).lastPayload.shopName;
            subStoreId = (item.subject as any).lastPayload.shopCode;
            break;
          case 'MercariProduct':
            subStoreName = (item.subject as any).lastPayload.data.seller.name;
            subStoreId = (item.subject as any).lastPayload.data.seller.id;
            break;
          case 'MercariBeyondProduct':
            subStoreName = (item.subject as any).lastPayload.productDetail.shop.displayName;
            subStoreId = (item.subject as any).lastPayload.productDetail.shop.name;
            break;
          case 'AutoPart':
            subStoreName = (item.subject as any).maker;
            subStoreId = (item.subject as any).maker;
            break;
          case 'ShopCatalogProduct':
            subStoreName = (item.subject as ShopCatalogProduct).store.title;
            subStoreId = (item.subject as ShopCatalogProduct).store.id;
            break;
          default:
            break;
        }
        if (!sellers[subStoreId]) {
          sellers[subStoreId] = {
            id: subStoreId,
            name: subStoreName,
            items: this.getItems(storeName, subStoreName, cartItems),
          };
        }
      });

    return Object.values(sellers);
  }

  getItems(storeName: string, sellerName: string, cartItems: CartItem[]): CartItem[] {
    const items: CartItem[] = cartItems
      .filter((item) => item.subject.__typename === storeName)
      .filter((item) => {
        if (item.subject.__typename === 'YahooProduct') {
          return item.subject.lastPayload.Store?.Name === sellerName;
        } else if (item.subject.__typename === 'RakutenProduct') {
          return item.subject.lastPayload.shopName === sellerName;
        } else if (item.subject.__typename === 'MercariProduct') {
          return item.subject.lastPayload.data.seller.name === sellerName;
        } else if (item.subject.__typename === 'MercariBeyondProduct') {
          return item.subject.lastPayload.productDetail.shop.displayName === sellerName;
        } else if (item.subject.__typename === 'AutoPart') {
          return item.subject.maker === sellerName;
        } else if (item.subject.__typename === 'ShopCatalogProduct') {
          return item.subject.store.title === sellerName;
        }
        return false;
      });

    return items.map((i) => ({
      ...i,
      quantity: i.quantity,
    }));
  }

  getVariantName(item: CartItem) {
    if (item.subject.__typename === 'YahooProduct' && item.variant && item.variant !== '') {
      const variant: any = Object.values((item.subject as any).lastPayload.Inventories).find(
        (v: any) => item.variant === v.SubCode,
      );

      console.log(variant, item.variant, item);

      return variant?.Order[0].Name + ' ' + variant?.Order[0].Value;
    } else if (
      item.subject.__typename === 'MercariBeyondProduct' &&
      item.variant &&
      item.variant !== ''
    ) {
      const variant: any = (item.subject as any).lastPayload.productDetail.variants.find(
        (v: any) => item.variant === v.variantId,
      );

      console.log(variant, item.variant, item);

      return variant?.displayName;
    }

    return null;
  }

  deleteItem(item: CartItem): void {
    const input: CartItemDeleteInput = {
      id: item.id,
    };

    this.cartItemDelete(input);
  }

  createOrder(seller: Seller): void {
    const input: CartItemsOrderInput = {
      ids: seller.items.map((i) => i.id),
    };

    this.cartItemsOrder(input, seller.name);
  }

  cartItemUpdate(input: CartItemUpdateInput): void {
    this.updating[input.id] = true;
    this.cartItemUpdateGQL.mutate({ input }).subscribe(
      (result) => {
        console.log('Cart item update response', result);

        if (!result || !result.data) {
          this.updating[input.id] = false;
          this.notificationService.renderError('Empty response');
          return;
        }

        if (result.data.cartItemUpdate.error) {
          this.updating[input.id] = false;
          this.notificationService.renderError(
            'CART.UPDATE.FAIL',
            result.data.cartItemUpdate.error.message || result.data.cartItemUpdate.error.code,
          );
        } else {
          this.updating[input.id] = false;
          this.notificationService.renderSuccess('CART.UPDATE.SUCCESS');
        }
      },
      (error) => {
        this.updating[input.id] = false;
        console.log(error);
        this.notificationService.renderError('Error', error);
      },
    );
  }

  cartItemDelete(input: CartItemDeleteInput): void {
    this.deleting[input.id] = true;
    this.cartItemDeleteGQL.mutate({ input }).subscribe(
      (result) => {
        console.log('Cart item delete response', result);

        if (!result || !result.data) {
          this.deleting[input.id] = false;
          this.notificationService.renderError('Empty response');
          return;
        }

        if (result.data.cartItemDelete.error) {
          this.deleting[input.id] = false;
          this.notificationService.renderError(
            'CART.UPDATE.FAIL',
            result.data.cartItemDelete.error.message || result.data.cartItemDelete.error.code,
          );
        } else {
          this.deleting[input.id] = false;
          this.notificationService.renderSuccess('CART.UPDATE.SUCCESS');
        }
      },
      (error) => {
        this.deleting[input.id] = false;
        console.log(error);
        this.notificationService.renderError('Error', error);
      },
    );
  }

  cartItemsOrder(input: CartItemsOrderInput, sellerName: string): void {
    this.buing[sellerName] = true;
    this.cartItemsOrderGQL.mutate({ input }).subscribe(
      (result) => {
        console.log('Shop order create response', result);

        if (!result?.data) {
          this.buing[sellerName] = false;
          this.notificationService.renderError('Empty response');
          return;
        }

        if (result.data.cartItemsOrder.error) {
          this.buing[sellerName] = false;
          this.notificationService.renderError(
            'CART.ORDER_CREATE.FAIL',
            result.data.cartItemsOrder.error.message || result.data.cartItemsOrder.error.code,
          );
        } else {
          this.apollo
            .use(this.cartGQL.client)
            .client.reFetchObservableQueries()
            .then(() => {
              this.buing[sellerName] = false;
              this.notificationService.renderSuccess('CART.ORDER_CREATE.SUCCESS');
            });
        }
      },
      (error) => {
        this.buing[sellerName] = true;
        console.log(error);
        this.notificationService.renderError('Error', error);
      },
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
