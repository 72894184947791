<nz-spin [nzSpinning]="queryRef.loading | async" nzSize="large">
  <section class="news">
    <a
      *ngFor="let news of (queryRef.data | async)?.news?.items; index as i"
      [routerLink]="['/news', news.id]"
      [class]="'link ' + (i == 0 && rowLimit ? 'first' : '')"
    >
      <div class="shadow"></div>
      <img [src]="news.image || '/assets/images/logo-sphere.png'" />
      <div class="date">
        {{ news.publishedAt | date: 'dd.MM.yyyy' }}
      </div>
    </a>
  </section>

  <section *ngIf="showPagination" nz-row nzJustify="end" class="section pagination">
    <div nz-col>
      <nz-pagination
        [nzPageIndex]="page"
        [nzPageSize]="pageSize"
        [nzTotal]="(queryRef.data | async)?.news?.totalCount || 1000"
        (nzPageIndexChange)="changePage($event)"
      ></nz-pagination>
    </div>
  </section>
</nz-spin>
