<div *ngIf="filterForm" class="container skiptranslate">
  <form nz-row nz-form [formGroup]="filterForm" nzLayout="vertical" nzGutter="30">
    <div nz-col>
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="onlyNew">
            {{ 'LOTS.FILTER.ONLY_NEW' | translate }}
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>{{ 'LOTS.FILTER.MIN_BID' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-input-group nzAddOnAfter="¥">
            <input nz-input type="number" formControlName="minPrice" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>{{ 'LOTS.FILTER.MIN_BLITZ' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-input-group nzAddOnAfter="¥">
            <input nz-input type="number" formControlName="minBlitz" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>{{ 'LOTS.FILTER.CONDITION.CONDITION' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="condition">
            <label nz-radio [nzValue]="0">
              {{ 'LOTS.FILTER.CONDITION.DOESNT_MATTER' | translate }}
            </label>
            <label nz-radio [nzValue]="1">
              {{ 'LOTS.FILTER.CONDITION.NEW' | translate }}
            </label>
            <label nz-radio [nzValue]="2">
              {{ 'LOTS.FILTER.CONDITION.USED' | translate }}
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col>
      <nz-form-item>
        <nz-form-control>
          <label nz-checkbox formControlName="onlyBlitz">
            {{ 'LOTS.FILTER.ONLY_BLITZ' | translate }}
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>{{ 'LOTS.FILTER.MAX_BID' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-input-group nzAddOnAfter="¥">
            <input nz-input type="number" formControlName="maxPrice" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>{{ 'LOTS.FILTER.MAX_BLITZ' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-input-group nzAddOnAfter="¥">
            <input nz-input type="number" formControlName="maxBlitz" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>{{ 'LOTS.FILTER.SELLER.SELLER' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="seller">
            <label nz-radio [nzValue]="0">
              {{ 'LOTS.FILTER.SELLER.DOESNT_MATTER' | translate }}
            </label>
            <label nz-radio [nzValue]="1">
              {{ 'LOTS.FILTER.SELLER.STORE' | translate }}
            </label>
            <label nz-radio [nzValue]="2">
              {{ 'LOTS.FILTER.SELLER.USER' | translate }}
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col>
      <nz-form-item>
        <nz-form-label>{{ 'LOTS.FILTER.SORT.SORT' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="sort">
            <label nz-radio [nzValue]="'none'">
              {{ 'LOTS.FILTER.SORT.DOESNT_MATTER' | translate }}
            </label>
            <label nz-radio [nzValue]="'price_up'">
              {{ 'LOTS.FILTER.SORT.PRICE_UP' | translate }}
            </label>
            <label nz-radio [nzValue]="'price_down'">
              {{ 'LOTS.FILTER.SORT.PRICE_DOWN' | translate }}
            </label>
            <label nz-radio [nzValue]="'blitz_up'">
              {{ 'LOTS.FILTER.SORT.BLITZ_UP' | translate }}
            </label>
            <label nz-radio [nzValue]="'blitz_down'">
              {{ 'LOTS.FILTER.SORT.BLITZ_DOWN' | translate }}
            </label>
            <label nz-radio [nzValue]="'end_up'">
              {{ 'LOTS.FILTER.SORT.END_UP' | translate }}
            </label>
            <label nz-radio [nzValue]="'end_down'">
              {{ 'LOTS.FILTER.SORT.END_DOWN' | translate }}
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
  <div nz-row nzGutter="20">
    <div nz-col>
      <button nz-button nzType="primary" nzSize="large" (click)="apply()">
        {{ 'LOTS.FILTER.APPLY' | translate }}
      </button>
    </div>
    <div nz-col>
      <button nz-button nzType="primary" nzDanger nzSize="large" (click)="reset()">
        {{ 'LOTS.FILTER.RESET' | translate }}
      </button>
    </div>
  </div>
</div>
