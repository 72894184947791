<nz-spin [nzSpinning]="loading" nzSize="large">
  <section class="section breadcrumbs skiptranslate">
    <nz-breadcrumb>
      <ng-container>
        <nz-breadcrumb-item>
          <a routerLink="/original-parts">{{'ORIGINAL_AUTO_PARTS'|translate}}</a>
        </nz-breadcrumb-item>
      </ng-container>
    </nz-breadcrumb>
  </section>

  <section class="section filter skiptranslate">
    <nz-collapse style="margin-bottom: 20px">
      <nz-collapse-panel nzHeader="Список запчастей, запрещенных для вывоза из Японии">
        <ul data-indent-level="1" style="columns: 2">
          <li>
            <p data-renderer-start-pos="234">Блоки управления</p>
          </li>
          <li>
            <p data-renderer-start-pos="255">Прокладки</p>
          </li>
          <li>
            <p data-renderer-start-pos="270">Подшипники</p>
          </li>
          <li>
            <p data-renderer-start-pos="284">Радиаторы – охлаждения, кондиционера</p>
          </li>
          <li>
            <p data-renderer-start-pos="324">Сальники</p>
          </li>
          <li>
            <p data-renderer-start-pos="336">Камеры</p>
          </li>
          <li>
            <p data-renderer-start-pos="346">Навигации</p>
          </li>
          <li>
            <p data-renderer-start-pos="359">GPS</p>
          </li>
          <li>
            <p data-renderer-start-pos="366">
              Система зажигания, включая стартера, генераторы, свечи зажигания, бронепровода,
              проводка<br />
              двигателя и салона (коса) и тд.
            </p>
          </li>
          <li>
            <p data-renderer-start-pos="490">
              Запчасти для бензиновых двигателей (включая валы, шестеренки, заслонки и т.д.)
            </p>
          </li>
          <li>
            <p data-renderer-start-pos="572">Фильтра – масляные, воздушные, топливные</p>
          </li>
          <li>
            <p data-renderer-start-pos="616">
              Оптика вся - фары, стопы, поворотники, противотуманные фары и т.д.
            </p>
          </li>
          <li>
            <p data-renderer-start-pos="686">
              Радиоприемники, используемые в автомобилях (то есть все автомагнитолы)
            </p>
          </li>
          <li>
            <p data-renderer-start-pos="760">Насосы – масляные, топливные, водяные и т.д.</p>
          </li>
          <li>
            <p data-renderer-start-pos="808">Пружины для автомобилей любые</p>
          </li>
          <li>
            <p data-renderer-start-pos="841">Стекла автомобильные</p>
          </li>
          <li>
            <p data-renderer-start-pos="865">Сиденья автомобильные</p>
          </li>
          <li>
            <p data-renderer-start-pos="890">Замки для автомобилей</p>
          </li>
          <li>
            <p data-renderer-start-pos="915">Автомобильные ключи</p>
          </li>
          <li>
            <p data-renderer-start-pos="938">
              Разнообразные трубки: резиновые, пластиковые, металлические
            </p>
          </li>
          <li>
            <p data-renderer-start-pos="1001">Приводные ремни, в том числе ремни ГРМ</p>
          </li>
          <li>
            <p data-renderer-start-pos="1043">Покрышки автомобильные</p>
          </li>
          <li>
            <p data-renderer-start-pos="1069">
              Металлические кронштейны, крепежи и аналогичные запчасти
            </p>
          </li>
          <li>
            <p data-renderer-start-pos="1129">Тормозные колодки</p>
          </li>
          <li>
            <p data-renderer-start-pos="1150">Сцепление</p>
          </li>
        </ul>
      </nz-collapse-panel>
    </nz-collapse>
    <form #f="ngForm" (ngSubmit)="applyFilter(f.value.q)">
      <h3>{{'SEARCH_OEM_PARTS'|translate}}</h3>
      <nz-input-group nzCompact nzSize="large">
        <input
          nz-input
          [ngModel]="filter?.q"
          name="q"
          style="max-width: calc(100% - 48px)"
          placeholder="52129-22090"
        />
        <button nzSize="large" nz-button nzType="primary">
          <i nz-icon nzType="search" nzTheme="outline"></i>
        </button>
      </nz-input-group>
    </form>
  </section>

  <ng-template #pageTemplate let-type let-page="page">
    <a *ngIf="type === 'pre'"><</a>
    <a *ngIf="type === 'next'">></a>
    <a *ngIf="type === 'prev_5'">...</a>
    <a *ngIf="type === 'next_5'">...</a>
    <a
      *ngIf="type === 'page'"
      [routerLink]="[]"
      [queryParams]="{ page: page }"
      queryParamsHandling="merge"
      >{{ page }}</a
    >
  </ng-template>
  <ng-template #totalTemplate let-total> Total items: {{ total }} </ng-template>

  <div style="display: flex; justify-content: flex-end; margin: 20px 0px">
    <nz-pagination
      [nzPageIndex]="filter.page"
      [nzPageSize]="filter.pageSize"
      [nzPageSizeOptions]="[20, 50, 100]"
      [nzTotal]="total"
      [nzShowTotal]="totalTemplate"
      nzShowSizeChanger
      (nzPageIndexChange)="changePage($event)"
      (nzPageSizeChange)="changePageSize($event)"
      [nzItemRender]="pageTemplate"
    ></nz-pagination>
  </div>

  <section class="section auctions skiptranslate">
    <div *ngIf="items">
      <nz-list nzSplit>
        <nz-list-item [nzNoFlex]="true" *ngFor="let item of items">
          <div nz-row class="body">
            <div
              nz-col
              [nzXs]="24"
              [nzSm]="18"
              [nzMd]="21"
              [nzLg]="21"
              [nzXl]="22"
              nzFlex="auto"
              class="description"
            >
              <h3 nz-typography class="ellipsis skiptranslate">
                {{ item['title' + (currentLang | uppercase)] || item.titleEN }}
              </h3>
              <div nz-row nzGutter="20" class="stats">
                <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item [nzTitle]="'MAKER' | translate">
                      <span *ngIf="item.maker" class="skiptranslate">
                        {{ item.maker | uppercase }}
                      </span>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24" class="skiptranslate">
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item class="skiptranslate" [nzTitle]="'CODE' | translate">
                      {{ item.code }}
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div
                  *ngIf="item.price || item.price === 0"
                  nz-col
                  [nzXl]="6"
                  [nzLg]="6"
                  [nzMd]="12"
                  [nzXs]="24"
                  class="skiptranslate price"
                >
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item
                      [nzTitle]="'LOTS.PRICE' | translate"
                      class="flex-description skiptranslate"
                    >
                      <div>
                        <app-currency
                          [value]="item.price"
                          [exchangeRate]="exchangeRate"
                        ></app-currency>
                      </div>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
                <div
                  nz-col
                  [nzXl]="6"
                  [nzLg]="6"
                  [nzMd]="12"
                  [nzXs]="24"
                  class="skiptranslate price"
                >
                  <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                    <nz-descriptions-item
                      [nzTitle]="'ORDER_CREATE.QUANTITY' | translate"
                      class="flex-description"
                    >
                      <form #f="ngForm">
                        <input
                          nz-input
                          style="max-width: 100px"
                          [ngModel]="1"
                          type="number"
                          name="quantity"
                        />
                        <input [ngModel]="item.code" type="hidden" name="code" />
                        <input [ngModel]="item.maker" type="hidden" name="maker" />
                        &nbsp;
                        <button
                          nz-button
                          nzType="primary"
                          (click)="buy(f.value)"
                          [nzLoading]="buying[item.code] | async"
                        >
                          {{ 'ACTIONS.ADD_TO_CART' | translate }}
                        </button>
                      </form>
                    </nz-descriptions-item>
                  </nz-descriptions>
                </div>
              </div>
            </div>
          </div>
        </nz-list-item>
      </nz-list>

      <div style="display: flex; justify-content: flex-end; margin-top: 20px">
        <nz-pagination
          [nzPageIndex]="filter.page"
          [nzPageSize]="filter.pageSize"
          [nzPageSizeOptions]="[20, 50, 100]"
          [nzTotal]="total"
          [nzShowTotal]="totalTemplate"
          nzShowSizeChanger
          (nzPageIndexChange)="changePage($event)"
          (nzPageSizeChange)="changePageSize($event)"
          [nzItemRender]="pageTemplate"
        ></nz-pagination>
      </div>
    </div>
    <div *ngIf="items && !items.length" class="empty">
      <!-- <nz-empty></nz-empty> -->
      <form #f="ngForm" nz-row class="body">
        <div
          nz-col
          [nzXs]="24"
          [nzSm]="18"
          [nzMd]="21"
          [nzLg]="21"
          [nzXl]="22"
          nzFlex="auto"
          class="description"
        >
          <div nz-row nzGutter="20" class="stats skiptranslate">
            <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24">
              <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                <nz-descriptions-item [nzTitle]="'MAKER' | translate">
                  <span>
                    <nz-select
                      ngModel
                      name="maker"
                      [nzPlaceHolder]="'SELECT_MAKER' | translate"
                      nzDropdownClassName="skiptranslate"
                      required
                    >
                      <nz-option
                        *ngFor="let maker of makers"
                        [nzLabel]="maker | uppercase"
                        [nzValue]="maker"
                      ></nz-option>
                    </nz-select>
                  </span>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
            <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24" class="skiptranslate">
              <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                <nz-descriptions-item [nzTitle]="'CODE' | translate">
                  {{ filter.q | uppercase }}
                  <input [ngModel]="filter.q" type="hidden" name="code" />
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
            <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24" class="skiptranslate price">
              <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                <nz-descriptions-item [nzTitle]="'LOTS.PRICE' | translate" class="flex-description">
                  <div>{{ 'TO_BE_DEFINED' | translate }}</div>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
            <div nz-col [nzXl]="6" [nzLg]="6" [nzMd]="12" [nzXs]="24" class="skiptranslate price">
              <nz-descriptions nzBordered nzSize="small" nzLayout="vertical">
                <nz-descriptions-item
                  [nzTitle]="'ORDER_CREATE.QUANTITY' | translate"
                  class="flex-description"
                >
                  <input nz-input [ngModel]="1" type="number" name="quantity" />
                  <button
                    nz-button
                    nzType="primary"
                    (click)="f.valid && buy(f.value)"
                    [nzLoading]="buying[filter.q] | async"
                  >
                    {{ 'ACTIONS.ADD_TO_CART' | translate }}
                  </button>
                </nz-descriptions-item>
              </nz-descriptions>
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</nz-spin>
