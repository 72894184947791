<nz-row [nzGutter]="[10, 10]" class="skiptranslate">
  <nz-col>
    <a nz-button [routerLink]="['/categories']">
      {{ 'HOME.ALL_CATEGORIES' | translate }}
    </a>
  </nz-col>
  <!-- <nz-col>
    <a nz-button [routerLink]="['/shopping']"> Yahoo Shopping </a>
  </nz-col> -->
  <nz-col>
    <a nz-button [routerLink]="['/croooober']"> Crooober.com </a>
  </nz-col>
  <nz-col>
    <a nz-button [routerLink]="['/original-parts']">
      {{ 'ORIGINAL_AUTO_PARTS' | translate }}
    </a>
  </nz-col>
  <!-- <nz-col>
    <a nz-button [routerLink]="['/rakuten']"> Rakuten </a>
  </nz-col> -->
  <!-- <nz-col>
    <a nz-button [routerLink]="['/mercari/search']"> Mercari </a>
  </nz-col> -->
  <nz-col *akbCheck="['world.akebono.shop.shop-catalog']">
    <a nz-button [routerLink]="['/shop-catalog']">
      {{ 'SHOP_CATALOG' | translate }}
    </a>
  </nz-col>
</nz-row>

<section class="news">
  <!-- <div class="desktop">
    <div nz-row nzJustify="space-between" nzAlign="middle">
      <div nz-col>
        <a nz-button nzShape="round" routerLink="news">
          {{ 'NEWS.ALL' | translate }}
          <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
        </a>
      </div>
      <div nz-col>
        <div class="telega">
          {{ 'HOME.NEWS_IN' | translate }}
          <img src="/assets/telegram.svg" alt="telegram-icon" />
          <a href="https://t.me/akebonoshop"> @akebonoshop </a>
        </div>
      </div>
    </div>
    <app-news-tile [rowLimit]="2" [pageSize]="9" [showPagination]="false"></app-news-tile>
  </div>
  <div class="mobile">
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="'HOME.NEWS' | translate">
        <app-news-tile [rowLimit]="2" [pageSize]="9" [showPagination]="false"></app-news-tile>
        <div nz-row nzJustify="space-between" nzAlign="middle" [nzGutter]="[10, 10]">
          <div nz-col>
            <a nz-button nzShape="round" routerLink="news">
              {{ 'NEWS.ALL' | translate }}
              <i nz-icon nzType="arrow-right" nzTheme="outline"></i>
            </a>
          </div>
          <div nz-col>
            <div class="telega">
              {{ 'HOME.NEWS_IN' | translate }}
              <img src="/assets/telegram.svg" alt="telegram-icon" />
              <a href="https://t.me/akebonoshop"> @akebonoshop </a>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </div> -->

  <div class="telega">
    {{ 'HOME.NEWS_IN' | translate }}
    <img src="/assets/telegram.svg" alt="telegram-icon" />
    <a href="https://t.me/akebonoshop"> @akebonoshop </a>
  </div>
</section>

<section class="categories">
  <nz-row style="margin-top: 20px">
    <nz-col [nzSpan]="24">
      <app-categories [onlyPopular]="true"></app-categories>
    </nz-col>
  </nz-row>
</section>
