import { ru } from './ru';

export const en: typeof ru = {
  HEADER: {
    LOGIN: 'Login',
    SEARCH: 'Search',
    EVERYWHERE: 'Everywhere',
    CURRENT_CATEGORY: 'In current category',
    CURRENT_SELLER: 'From the current seller',
    BALANCE: 'Balance',
    UNPAID_SHIPMENTS: 'Unpaid shipments',
    ON_SUM: 'on sum',
    IN_CART: 'in cart',
  },

  FOOTER: {
    CONTACTS: 'Contacts',
    ACCEPT: 'We accept',
    ADDRESS: 'Address',
    PHONE: 'Phone/WhatsApp',
    WORK_SCHEDULE_TITLE: 'Work schedule',
    WORK_SCHEDULE: 'From Monday to Friday, from 9:00 to 18:00 Japanese time',
    NEWS: 'News',
    SERGEY: 'Sergey',
    ANNA: 'Anna',
    PAVEL: 'Pavel',
    MESSAGE: 'Message service',
    VLADIVOSTOK_ADDRESS_TITLE: 'Vladivostok stock address',
    VLADIVOSTOK_ADDRESS: `Vladivostok, Borodinskaya str. 20 (Landmark "Car wash", building behind the car wash, passage on the left)<br>+7 966 289-20-05`,
  },

  MENU: {
    FAVORITES: 'Favorites',
    BIDS: 'Bids',
    ORDERS: 'Orders',
    BALANCE: 'Balance',
    MESSAGES: 'Messages',
    SHIPMENTS: 'Shipments',
    ADDRESSES: 'Addesses',
    CUSTOMS_ADDRESSES: 'Customs addresses',
    PROFILE: 'Profile',
    NOTIFICATIONS: 'Notifications',
    SECURITY: 'Security',
    LANGUAGE: 'Language',
    LOGOUT: 'Log out',
    CART: 'Cart',
  },

  CATEGORIES: {
    PARTS_SEARCH: 'Parts search',
  },

  CATEGORY: {
    SUBCATS: 'Subcategories',
    FILTER: 'Filter',
  },

  LOTS: {
    LOT: 'Lot',
    SELLER: 'Seller',
    STATE: 'Condition',
    TO_FINISH: 'Remaining time',
    BIDS: 'Bids',
    PRICE: 'Price',
    BLITZ_PRICE: 'Blitz price',
    ADD_TO_FAV: 'Add to favorite',
    REMOVE_FROM_FAV: 'Remove from favorite',
    NEW: 'New',
    USED: 'Used',
    UNKNOWN: 'Unknown',
    SECOND: 'second',
    SECOND_TWO: 'seconds',
    SECOND_FEW: 'seconds',
    MINUTE: 'minute',
    MINUTE_TWO: 'minutes',
    MINUTE_FEW: 'minutes',
    HOUR: 'hour',
    HOUR_TWO: 'hours',
    HOUR_FEW: 'hours',
    DAY: 'day',
    DAY_TWO: 'days',
    DAY_FEW: 'days',
    COMPLETE: 'complete',
    FAV_SUCCESS: 'Lot was successfully added to favorites',
    FAV_FAIL: 'Failed to add a lot to favorites',
    UNFAV_SUCCESS: 'The lot was successfully removed from favorites',
    UNFAV_FAIL: 'Failed to remove a lot from favorites',
    DESCRIPTION: 'Description',
    INFO: 'Info',
    PLUS_JP_DELIVERY: '+ delivery through Japan (see at product page)',
    PRIVATE_SELLER: 'Private seller',
    STORE: 'Store',

    FILTER: {
      APPLY: 'Apply',
      RESET: 'Reset',
      ONLY_NEW: 'Only new',
      ONLY_BLITZ: 'Only with blitz-price',
      MIN_BID: 'Minimum bid',
      MAX_BID: 'Maximum bid',
      MIN_BLITZ: 'Minimum blitz price',
      MAX_BLITZ: 'Maximum blitz price',
      MIN_PRICE: 'Minimum price',
      MAX_PRICE: 'Maximum price',
      SHIPPING: 'Shipping',
      SHIPPING_COD: 'Buyers burden',
      SHIPPING_SELLER: 'Paid by seller',
      SHIPPING_OPTIONS: 'Shipping service',
      SHIPPING_ANONYM: 'Anonymous delivery',
      SHIPPING_BY_POST: 'Post office',
      SHIPPING_NO_OPTION: 'No options available',
      FREE_SHIPPING: 'Free shipping in Japan',
      STARS_DIVISION: 'Rating',
      CATEGORY: 'Category',
      COLOR: 'Color',
      BRAND: 'Brand',
      KEYWORDS: 'Keywords',
      EXCLUDE_KEYWORDS: 'Exclude keywords',
      PRICE_RANGE: 'Price range',
      PRICE_TYPE: 'Price type',
      PRICE_TYPE_ELIGIBLE: 'Products eligible for point back',
      PRICE_TYPE_DISCOUNTED: 'Discounted products',
      PRICE_TYPE_GENERAL: 'General merchandise',
      SIZE: 'Size',
      STATUS: 'Status',
      STATUS_SALE: 'Sale',
      STATUS_SOLDOUT: 'Sold out',
      CONDITION: {
        CONDITION: 'Condition',
        DOESNT_MATTER: 'Doesnt matter',
        NEW: 'New',
        USED: 'Used',
      },
      SELLER: {
        SELLER: 'Seller',
        DOESNT_MATTER: 'Doesnt matter',
        STORE: 'Store',
        USER: 'User',
      },
      SORT: {
        SORT: 'Sort',
        DOESNT_MATTER: 'Doesnt matter',
        PRICE_UP: 'Price up',
        PRICE_DOWN: 'Price down',
        BLITZ_UP: 'Blitz-price up',
        BLITZ_DOWN: 'Blitz-price down',
        END_UP: 'Time of the end of trading up',
        END_DOWN: 'Time of the end of trading down',
        UPDATE_DATE: 'Default',
        ARRIVAL_DATE: 'New arrival',
        CONDITION_UP: 'Condition up',
        CONDITION_DOWN: 'Condition down',
      },
    },
  },

  SEARCH: {
    SEARCH: 'Search',
  },

  HOME: {
    MORE: 'more',
    ALL_CATEGORIES: 'Yahoo Auctions categories',
    NEWS_IN: 'News in',
    NEWS: 'News',
  },

  PARTS: {
    PARTS_SEARCH: 'Parts search',
    P_1: 'To search for spare parts, enter the body model and select the spare parts to search from the list.',
    P_2: 'Body model, letters and numbers of the body number up to a dash, for example:',
    VIN: 'Body model',
    FIND: 'Find',
  },

  NEWS: {
    NEWS: 'News',
    NAME: 'Name',
    ALL: 'All news',
    DATE: 'Date of publication',
    AUCTION: 'Auction Yahoo',
  },

  NOT_FOUND: {
    NOT_FOUND: 'Sorry, the page you visited does not exist.',
    GO_HOME: 'Go to home page',
  },

  AUCTION: {
    LEADER: 'Leader',
    UNTIL_END: 'Until the end of the auction',
    NO_AUTH_TEMPLATE: 'Please log in to participate in the auction.',
    CENSORED: 'No bids are accepted in this lot',
    AUTH: 'log in',
    FINISH: 'The auction is completed',
    FINAL_PRICE: 'Final price',
    BIDS: 'Bids',
    END: 'The end of the auction',
    CONDITION: 'Condition',
    NEW: 'New',
    USED: 'Used',
    SELLER: 'Seller',
    RATING: 'Rating',
    ADDRESS: 'Address',
    SELLER_TYPE: 'Seller type',
    LOT: 'Lot',
    AVALIABLE_COUNT: 'Available quantity',
    START_PRICE: 'Initial price',
    AUCTION_STEP: 'The step of auction',
    ADD_TO_FAV: 'Add to favorites',
    REMOVE_FROM_FAV: 'Remove from favorites',
    ADD_FAV_SUCCESS: 'Lot was successfully added to favorites',
    ADD_FAV_FAIL: 'Failed to add a lot to favorites',
    REMOVE_FAV_SUCCESS: 'The lot was successfully removed from favorites',
    REMOVE_FAV_FAIL: 'I couldnt delete a lot from my favorites',
    UPDATE: 'Update',
    CURRENT_PRICE: 'Current price',
    BLITZ_PRICE: 'Blitz price',
    YOU_BID: 'Your bid, ¥',
    YOU_BID2: 'Your bid',
    DEFFERED_BID: 'Deferred bid',
    BUY: 'Buy',
    PUT: 'Put',
    YOU: 'You',
    PRICE_WITH_TAX: 'Price with tax',
    BLITZ_WITH_TAX: 'Blitz-price with tax',
    VALIDATION_FAIL: 'Some fields are filled in incorrectly',
    BID_CREATE_SUCCESS: 'The bid was created successfully',
    BID_UPDATE_SUCCESS: 'Bid was successfully updated',
    BID_CREATE_FAIL: 'Failed to place a bid',
    BID_UPDATE_FAIL: 'Failed to update a bid',
    LOT_BUY_SUCCESS: 'Lot successfully purchased',
    LOT_BUY_FAIL: 'Failed to buy',
    PRICE_ERROR: 'The price cannot be less than the current one',
    CONFIRM: {
      CONFIRM_BID: 'Confirm your bid',
      CONFIRM_BUY: 'Confirm your purchase',
      SUM: 'Sum',
      DEFFERED_BID: 'The amount',
      COUNT: 'Quantity',
      COMMENT: 'Comments for the Manager',
      I_AGREE: 'I agree',
      AGREEMENT: `Attention!
        This lot is suspicious.
        You can make a bet on your own responsibility, or contact the company manager.
        If you place a bid without a manager's approval, you are responsible for paying for the item at the risk of not receiving the item or incurring additional costs.
      `,
    },
    COST_CALC: {
      COST_CALC: 'Cost calculation',
      YOU_BID: 'Your bid, ¥',
      WEIGHT: 'Weight with packaging, kg',
      JP_DELIVERY: 'Specify delivery in Japan, ¥',
      SUGGESTED: 'Suggested',
      SUGGESTED_HINT:
        'This is suggested price. Real price could be listed in table in lot description. Consider price for delivery to Toyama (富山), Hokuriku, Honsu',
      RATE: 'Rate',
      WEIGHT_PLACEHOLDER: 'Choose a weight',
      RATE_PLACEHOLDER: 'Choose a pricing plan',
      COMPANY_COMMISION: 'Commission of the company, ¥',
      COAS_IN_JP: 'Price in Japan, ¥',
      DELIVERY_TO_VDK: 'Delivery to Vladivostok, ¥',
      COAST_IN_VDK: 'Cost in Vladivostok, ¥',
    },
    DELIVERY_CALC: {
      DELIVERY_CALC: 'Calculation of delivery in Russia',
      CITY: 'Specify the delivery city',
      CITY_PLACEHOLDER: 'Moscow',
      ZIP: 'Enter your postal code (required)',
      INSURANCE: 'Specify the delivery insurance, ₽',
      LENGTH: 'Specify the length, cm (required)',
      WIDTH: 'Specify the width, cm (required)',
      HEIGHT: 'Specify the height, cm (required)',
    },
    TRANSLATE: 'Translation',
    DESCRIPTION: 'Description',
    QA: {
      QA: 'Questions to the seller',
      ASK_SELLER: 'Ask the seller a question',
      ASK: 'Ask',
      QUESTION: 'Question',
      SELECT_QUESTION: 'Select a question',
      QUESTION_FROM: 'Question from',
      ANSWER: 'Answer',
      ASK_VIA_MANAGER: 'Ask your question through a consultant',
      ENTER_QUESTION_TEXT: 'Enter question text',
      TEXT: 'Text',
      AUTH: 'To ask a question you must log in',
      NO_TEXT: 'Fill in the question text field',
      QUESTION_SUCCESS: 'The question was sent successfully',
      QUESTION_FAIL: 'Failed to send a question',
      UNANSWERED_QUESTION: 'Awaiting answer',
    },
    BIDS_HISTORY: {
      BIDS_HISTORY: 'Bid history',
      TIME: 'Time',
      LOGIN: 'Login',
      BID: 'Bid',
      AUCTION_START: 'Start of trading',
      BID_CANCEL: 'Canceling a bid',
    },
  },

  PRICE: 'Price',
  PRICE_WITH_TAX: 'Price with tax',
  JP_DELIVERY: 'Delivery in Japan',
  JP_DELIVERY_UNKNOWN: 'Calculated after order',
  PRODUCT: 'Product',

  ACTIONS: {
    ERROR: 'Error',
    CONTINUE: 'Continue',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    BUY: 'Buy',
    DELETE: 'Delete',
    ADD_TO_CART: 'Add to cart',
    CREATE_ORDER: 'Create order',
    ARE_YOU_SURE: 'Are you sure?',
    BUY_NOW: 'Buy now',
    SEND: 'Send',
  },
  JAPAN_COUNTRY_NAME: 'Japan',
  ERROR: 'Error',
  APP: {
    MENU_TITLE: 'Menu',
    COUNTRY_CITY_HEADER: 'Japan, Toyama',
    MAIN_TITLE: 'Home',
    VERIFICATION: 'Get access to auction',
    YAHOO_AUCTIONS_TITLE: 'Yahoo auctions',
    SIGN_OUT_TITLE: 'Sign out',
  },
  VERIFICATION: {
    VERIFICATION: 'Verification',
    SUBMIT: 'Отправить запрос',
    RESET_ADDRESS: 'Сбросить адрес',
    SELECT_ADDRESS_LABEL: 'Вы можете выбрать один из Ваших адресов или указать новый',
    REQUEST_ACCEPTED: 'Заявка принята',
    REQUEST_ACCEPTED_BODY: 'Ваша заявка принята, ожидайте ответа от модератора',
    LABELS: {
      PFOFILE_DATA: 'Данные профиля',
      ADDRESS_DATA: 'Ваш адрес',
      LAST_NAME: 'Фамилия',
      FIRST_NAME: 'Имя',
      MIDDLE_NAME: 'Отчество',
      EMAIL: 'E-mail',
      SKYPE: 'Скайп',
      USER_COMMENT: 'Комментарий',
      COUNTRY: 'Страна',
      CITY: 'Город',
      ZIP: 'Почтовый индекс',
      ADDRESS_1: 'Адрес',
      ADDRESS_2: 'Доп. адрес',
      ADDRESS_PHONE: 'Телефон',
      COUNTRY_HINT: 'Select a country',
      IMPORT_DIRECTION: 'Cтрана импорта',
    },
    ERRORS: {
      LAST_NAME: 'Фамилия',
      FIRST_NAME: 'Имя',
      EMAIL: 'E-mail',
      SKYPE: 'Skype',
      USER_COMMENT: 'Комментарий',
      COUNTRY: 'Страна',
      CITY: 'Город',
      ZIP: 'Почтовый индекс',
      ADDRESS_1: 'Адрес',
      ADDRESS_PHONE: 'Телефон',
      IMPORT_DIRECTION: 'Выберите страну импорта',
    },
  },
  IMPORT_DIRECTION: {
    RUSSIA: 'В Россию',
    OTHER: 'В другие страны',
  },
  ORDERS: {
    LIST_AKEBONO_LOTS: 'List of purchased lots',
    LIST_ALL_OBJECTS: 'List all object',
    SHOW_ALL_OBJECTS: 'Show all objects',
    AUC_DATE: 'Date auction',
    AUCTION: 'Auction',
    LOT: 'Lot',
    VESSEL: 'Vessel',
    STORAGE: 'Storage',
    STATUS: 'Status',
    OBJECT: 'Object',
    SENDER: 'Sender',
    BROKER: 'Broker',
    INVOICE: 'Invoice',
    DOWNLOAD_INVOICE: 'Download invoice',
  },
  TOTAL: 'Всего',
  BALANCE: {
    DEPOSIT: 'Deposit',
    DATE: 'Date',
    AMOUNT: 'Amount',
    DESCRIPTION: 'Description',
    STATE: 'State',
    BALANCE_DESCRIPTION: {
      BONUS_BALANCE: 'Bonus balance',
      BONUS_APPLIED: 'Bonuses applied',
      TOTAL_BALANCE: 'Total balance',
      HOLD: 'Hold',
    },
    FILTERS: {
      FILTERS: 'Filters',
      SHOW: 'Show',
      AMOUNT_FROM: 'Amount from',
      AMOUNT_TO: 'Amount to',
      DATE: 'Operation date',
      SEARCH: 'Search',
      APPLY: 'Apply',
      RESET: 'Reset',
    },
    CODES: {
      ALL: 'All',
      DEPOSIT: 'Deposit',
      PAY: 'Pay',
      PLACE: 'Bids',
      RAISE: 'Raise autobid maximum',
      REFUND: 'Refunds',
      UNHOLD: 'Unhold',
      INVOICE: 'Expenses',
    },
    DEPOSIT_TYPE: 'Deposit type',
    BANK_TRANSFER: 'Bank transfer',
    BANK_TRANSFER_HINT: 'The Commission fee depends on your Banks terms and conditions',
    PAYPAL_HINT: 'Commission: {0}% + {1}¥',
    STRIPE_HINT: 'Commission: {0}%',
    WITH_COMISSION: 'With comission',
    CAN_DEPOSITE: 'To continue, you must fill in your full name in your profile.',
    GO_TO_PROFILE: 'Go to profile',
    PAYMENT_SUCCESS: 'Payment was successful',
    PAYMENT_FAIL: 'Failed to top up your account',
    PAYMENT_INFO: 'Does not work with card issued by banks in Russian Federation',
    PAYPAL: {
      PAYPAL: 'Adding funds to your account via PayPal',
      PARAGRAPH_1:
        'Please note that this payment method is only available to registered PayPal users.',
      PARAGRAPH_2:
        'You can find out what benefits you will get when registering with PayPal on the <a href="https://www.paypal.com/" target="_blank">official PayPal website</a>',
      PARAGRAPH_3:
        'There are limits on the payment amount. You can find out more about this <a>here</a>',
    },
    STRIPE: {
      STRIPE: 'Topping up your account with a Bank card',
      PARAGRAPH_1:
        'This payment method allows you to pay amounts without creating any accounts in the Stripe system.',
      PARAGRAPH_2: 'It is recommended for payments with Bank cards in small amounts.',
      PARAGRAPH_3:
        'Please note that using someone elses Bank cards is a crime and is punishable by law.',
      SUCCESS: 'The account was successfully topped up',
      FAIL: 'Failed to top up your account',
    },
    BANK: {
      BANK: 'Adding funds to your account via Bank transfer',
      PARAGRAPH_1: 'This method of payment allows you to pay large sums of money.',
      PARAGRAPH_2:
        'It is recommended for large payments with large amounts, or if Your payment volume for one calendar month exceeds the allowed maximum monthly amount of payments from a Bank card.',
      PARAGRAPH_3:
        'The term for crediting a transfer to a Bank account can be up to 10 banking days.',
      INSTRUCTION: 'Instruction',
      INSTRUCTION_HINT: 'It will help you understand how to fill out a Bank transfer receipt.',
      PAYER: 'Payer',
      PURPOSE: 'Purpose of payment',
      PURPOCE_TEMPLATE: 'auction bidding deposit (аукционный депозит)',
      PRINT: 'Print out',
    },
  },

  FAVORITES: {
    FAVORITES: 'Favorites',
    ACTIVE: 'Active auctions',
    END: 'Ended auctions',

    LOTS: {
      LOT: 'Lot',
      SELLER: 'Seller',
      STATE: 'Condition',
      TO_FINISH: 'Before the end',
      BIDS: 'Bids',
      PRICE: 'Price',
      BLITZ_PRICE: 'Blitz price',
      REMOVE_FROM_FAV: 'Remove from favorite',
      NEW: 'New',
      USED: 'Used',
      UNKNOWN: 'Unknown',
      SECOND: 'second',
      SECOND_TWO: 'seconds',
      SECOND_FEW: 'seconds',
      MINUTE: 'minute',
      MINUTE_TWO: 'minutes',
      MINUTE_FEW: 'minutes',
      HOUR: 'hour',
      HOUR_TWO: 'hours',
      HOUR_FEW: 'hours',
      DAY: 'day',
      DAY_TWO: 'days',
      DAY_FEW: 'days',
      COMPLETE: 'complete',
      UNFAV_SUCCESS: 'The lot was successfully removed from favorites',
      UNFAV_FAIL: 'Failed to remove a lot from favorites',
    },
  },

  BIDS: {
    BIDS: 'Bids',
    NO_NAME: 'No name',
    STATUS: 'Status',
    BLOCKED: 'Blocked',
    BEFORE: 'before',
    ORDER: 'Order',
    CANCEL: 'Cancel',
    DELETE_SUCCESS: 'Bid was successfully cancelled',
    DELETE_FAIL: 'Failed to cancel bid',
    BID_STATUS: {
      BEST: 'Lead',
      CANCELLED: 'Cancelled',
      COMPLETED: 'Completed',
      FAILED: 'Failed',
      INITIAL: 'Created',
      OVERBID: 'Overbid',
      WON: 'Won',
      DEFERRED: 'Deferred',
      AWAITING_MANUAL_PURCHASE: 'Awaiting manual purchase',
    },
  },

  YAHOO_ORDERS: {
    REQUEST: 'Leave a request',
    CREATED_AT: 'Creation date',
    STATUS: 'Status',
    PRODUCTS: 'Products',
    ACCRUED: 'Accrued',
    NO_NAME: 'No name',
    LOT: 'Lot',
    QUANTITY: 'Quantity',
    TITLE: 'Title',
    STATUSES: {
      UNKNOWN: 'Unknown',
      initial: 'In the processing queue',
      negotiations: 'Negotiations with the seller',
      arriving_jp: 'Waiting for arrival at the warehouse',
      declarant_review_jp: 'Waiting for arrival at the warehouse',
      stockman_review_jp: 'Waiting for arrival at the warehouse',
      customer_review: 'In stock in Japan',
      in_shipment: 'Transportation',
      issued: 'Issued',
      failed: 'Error',
      canceled: 'Canceled',
      utilized: 'Utilized',
      in_stock_vl: 'In a warehouse in Vladivostok',
    },
    FILTER: {
      FILTER: 'Filter',
      SEARCH: 'Search',
      SHOW: 'Show',
      CREATED_FROM: 'Creation date from',
      CREATED_TO: 'Creation date to',
      APPLY: 'Apply',
      RESET: 'Reset',
      ALL: 'Все',
    },
  },

  ORDER_CREATE: {
    LINK: 'Product address',
    TITLE: 'Name',
    QUANTITY: 'Quantity',
    SEND: 'Send',
    ADD: 'Add item',
    VALIDATION_FAIL: 'Some fields are filled in incorrectly',
    SEND_SUCCESS: 'The request was sent successfully',
    SEND_FAIL: 'The request could not be sent',
  },

  YAHOO_ORDER: {
    ITEMS: 'Items',
    ACCRUED: 'Charges',
    IMAGES: 'Lot images',
    FILES: 'Files',
    USE_BONUS: 'Use bonuses to pay Commission',
    BONUS_INFO: 'You can use bonuses to pay the Commission for each invoice',
    USE_BONUS_TEMPLATE:
      'You have {0} bonus points. The total Commission is {1}. Total applied {2}¥ bonuses.',
    BONUS_USED_TEMPLATE: 'Used {0} bonus',
    COMMISSION: 'Commission',
    APPLY: 'Apply',
    TOTAL_JP: 'Total for Japan',
    DELIVERY_JP: 'Payment for sending from Japan',
    TOTAL: 'Total on order',
    SEND: 'Send',
    COMMENT: 'Comment',
    TITLE: 'Name',
    JP_TITLE: 'Japanese name',
    WEIGHT: 'Weight, kg',
    QUANTITY: 'Quantity',
    PRICE_JP: 'Price, ¥',
    INVOICE: 'Invoice',
    PRICE: 'Price',
    ITEM: 'Item',
    TAX: 'Tax',
    BONUSES: 'Bonuses',
    APPLY_SUCCESS: 'Bonus successfully applied',
    APPLY_FAIL: 'Failed to apply the bonus',
  },

  CHATS: {
    CHATS: 'Chats',
    CHAT_WITH: 'Chat with',
    POST_QA: 'Ask the seller a question',
    LOT_CODE: 'Lot code',
    CHAT_WITH_USER: 'Chat with user',
    UNANSWERED: 'Unanswered messages',
    RECENT: 'Recent messages',
    UPDATE_SUCCESS: 'Messages update successfully',
    UPDATE_FAIL: 'Fail to update messages',
    CREATE_SUCCESS: 'Message created successfully',
    CREATE_FAIL: 'Fail to create message',
    CLIENT: 'Client',
    MESSAGE: 'Message',
    THEME: 'Theme',
    LAST_MESSAGE: 'Last message',
    LAST_THEME: 'Last theme',
    DATE: 'Date',
    READ: 'Read',
    UNREAD: 'Unread',
    EDIT: 'Edit',
    SEND: 'Send',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    TEMPLATE: 'Template',
    DRAG: 'Click or drag file to this area to upload',
    CONTENT: 'Content',
    VALIDATION_FAIL: 'Some fields are filled in incorrectly',
  },

  SHIPMENTS: {
    CREATE: 'Create',
    SHIPMENT: 'Shipment',
    CREATED_AT: 'Created at',
    STATUS: 'Status',
    ORDERS: 'Orders',
    PAYED: 'Paid',
    TO_PAY: 'For payment',
    NO_DEFAULT_ADDRESS:
      'For automatic shipments, you need to choose default delivery address.<br />You can select the address in the user settings under:',
    STATUSES: {
      packing: 'Packaging',
      awaiting_payment: 'Waiting for payment',
      planing: 'Planing',
      planed: 'Packed',
      shipped: 'In the way',
      ready: 'Arrived',
      issued: 'Issued',
      failed: 'Error',
      canceled: 'Canceled',
      utilized: 'Utilized',
    },
    DEFAULT_DELIVERY_SERVICE: 'Default delivery service for automatic shipments',
  },

  SHIPMENT: {
    SHIPMENT: 'Shipment',
    COMMENT: 'Comment',
    ORDERS: 'Orders',
    CHARGES: 'Charges',
    TRACKING_NUMBER: 'Tracking number',
    DELIVERY_SERVICE: 'Delivery service',
    ISSUED: 'Shipment received',
    ISSUED_SUCCESS: 'The action was completed successfully',
    ISSUED_FAIL: 'The action failed',
    NAME: 'Full name',
    ORDER: 'Order',
    UNSPECIFIED: 'Unspecified',
    PHONE: 'Phone number',
    ADDRESS: {
      ADDRESS: 'Address',
      PASSPORT: 'Passport',
      CITY: 'City',
      POSTAL_CODE: 'Postal code',
      PICKUP: 'Pickup',
      NO_ADDRESSES: 'No addresses',
      SELECT: 'Select',
    },
    CUSTOMS: {
      CUSTOMS: 'Customs info',
      PASSPORT_ISSUE: 'Passport issue date',
      SEX: 'Sex',
      REGISTRATION_ADDRESS: 'Registration address',
      INN: 'INN',
      FILES: 'Files',
      EMPTY: 'Unspecified',
    },
  },

  SHIPMENT_CREATE: {
    SHIPMENT_CREATE: 'Creating a shipment',
    NEXT: 'Continue',
    BACK: 'Back',
    STEP: 'Step',
    CREATE: 'Create',
    SELECT: 'Select',
    UNSELECT: 'Unselect',
    ORDER: 'Order',
    DELIVERY_SERVICE: 'Delivery service',
    RECIVE_METHOD: 'Method of receiving',
    TOTAL_WEIGHT: 'Total weight',
    KG: 'kg',
    WEIGHT: 'Weight',
    COMMENT: 'Comments on delivery',
    CUSTOMS: 'Select customs information',
    ADDRESS: 'Select the delivery address',
    ADDRESS_ID: 'Addres #{0}',
    SELECTED: 'Selected',
    DISABLED: 'Unavailable',
    WARNING_TEXT: 'INN or passport data is missing',
    UPDATE: 'Update',
    NAME: 'Name',
    SELECT_DELIVERY_SERVICE: 'Select a delivery service',
    CREATE_SUCCESS: 'The shipment was created successfully',
    VALIDATION_ERROR: 'Some fields are not filled in or are filled in incorrectly',
    CREATE_FAIL: 'Failed to create a shipment',
    STEP_1_DESCRIPTION: 'Choose a delivery service',
    STEP_2_DESCRIPTION: 'Select orders to send',
    STEP_3_DESCRIPTION: 'Select the rates for sending products',
    STEP_4_DESCRIPTION: 'Select the method to get it',
    STEP_5_DESCRIPTION: 'Confirmation',
    METHODS: {
      pickup: 'Pickup',
      address: 'Delivery to the address',
    },
  },

  ADDRESSES: {
    DELETE_SUCCESS: 'The address was successfully deleted',
    DELETE_FAIL: 'Unable to delete address',
    UPDATE_SECCESS: 'The address was updated successfully',
    UPDATE_FAIL: 'Failed to update address',
    CREATE_SUCCESS: 'The address was created successfully',
    CREATE_FAIL: 'Failed to create an address',
    ADDRESS_CREATE: 'Create new address',
    ADDRESS_UPDATE: 'Update address',
    DELETE: 'Delete',
    CHANGE: 'Edit',
    CREATE: 'Create',
    SAVE: 'Save',
    ADD: 'Add address',
    SURE: 'Are u sure?',
    FIRST_NAME: 'First name',
    LAST_NAME: 'Last name',
    MIDDLE_NAME: 'Middle name',
    PASSPORT_SECTION: 'Passport data for customs clearance (RU)',
    PASSPORT_SERIAL: 'Passport series',
    PASSPORT_NO: 'Passport number',
    PASSPORT_ISSUE_DATE: 'Passport issue date',
    PASSPORT_ISSUER: 'Passport issuer',
    PASSPORT_DATEOFBIRTH: 'Date of birth',
    COUNTRY: 'Country',
    STATE: 'State',
    CITY: 'City',
    ADDRESS: 'Address',
    ZIP: 'Zip code',
    PHONE: 'Phone number',
    COUNTRY_PLACEHOLDER: 'Select country',
    STATE_PLACEHOLDER: 'Select or enter state',
    CITY_PLACEHOLDER: 'Select or enter city',
    INN_PLACEHOLDER: 'Input value',
    FROM_INVALID: 'Some form fields are filled in incorrectly',
    DEFAULT: 'Default address for automatic shipments',
  },

  CUSTOMS_ADDRESSES: {
    DELETE: 'Delete',
    CHANGE: 'Edit',
    CREATE: 'Create',
    SAVE: 'Save',
    ADD: 'Add address',
    SURE: 'Are u sure?',
    DELETE_SUCCESS: 'Information was successfully deleted',
    DELETE_FAIL: 'Information could not be deleted',
    UPDATE_SECCESS: 'Information was updated successfully',
    UPDATE_FAIL: 'Failed to update information',
    CREATE_SUCCESS: 'Information was created successfully',
    CREATE_FAIL: 'Failed to create information',
    ADDRESS_CREATE: 'To create a new customs information',
    ADDRESS_UPDATE: 'Editing customs information',
    SEX: 'Sex',
    BIRTH_DATE: 'Date of birth',
    BIRTH_PLACE: 'Place of birth',
    PASSPORT: 'The serial number of the passport',
    ISSUE_DATE: 'Date of passport issue',
    ISSUER: 'Passport issuer',
    REGISTRATION: 'Registration address',
    INN: 'INN',
    FILES: 'A copy of the passport (two pages), a copy of the INN',
    MALE: 'Male',
    FEMALE: 'Female',
  },

  PROFILE: {
    LAST_NAME: 'Last name',
    FIRST_NAME: 'First name',
    MIDDLE_NAME: 'Middle name',
    SKYPE: 'Skype',
    TIMEZONE: 'Time zone',
    SAVE: 'Save',
    E_MAIL: 'E-mail',
    PHONE: 'Phone number',
    GENERAL_INFORMATION: 'General information',
    ADDITIONAL_INFORMATION: 'Additional information',
    SUCCESS: 'The profile has been successfully updated',
    FAIL: 'Failed to update the profile',
    VERIFY_EMAIL: 'Verify e-mail',
    VERIFY_PHONE: 'Verify phone',
    INVALID: 'Some fields are filled in incorrectly',
    TIMEZONE_PLACEHOLDER: 'Select timezone',
  },

  VERIFY_MODAL: {
    PHONE: 'Phone number confirmation',
    EMAIL: 'Email confirmation',
    CONFIRMATION_CODE: 'Confirmation code',
    CONFIRM: 'Confirm',
    SEND: 'Send a code',
    SEND_SUCCESS: 'Code sent successfully',
    SEND_FAIL: 'Failed to send the code',
    CONFIRM_SUCCESS: 'Confirmation was successful',
    CONFIRM_FAIL: 'Couldnt get confirmation',
    INVALID: 'The code field is filled in incorrectly',
  },

  NOTIFICATION_SETTINGS: {
    CHANGE_STATUS: 'Status change',
    FINANCES: 'Finance',
    ADDITIONAL_INFORMATION: 'Additional information',
    order_status: 'Order',
    shipment_status: 'Shipment',
    finances_incomes: 'Incoming operations',
    finances_spends: 'Expenditure operations',
    finances_holds: 'Blocking funds',
    platform_news: 'News',
    platform_events: 'Stocks',
    platform_advertising: 'Advertising materials',
    FAIL: 'Failed to install setup',
    SUCCESS: 'The setting was successfully installed',
  },

  SECURITY: {
    CHANGE_PASS: 'Change password',
    OLD_PASS: 'Old password',
    NEW_PASS: 'New password',
    CONFIRM_PASS: 'Confirm new password',
    SAVE: 'Save',
    SUCESS: 'The password is saved successfully',
    FAIL: 'I couldnt save my password',
    INVALID: 'Some fields are filled in incorrectly',
    MISMATCH: 'Passwords dont match',
  },

  SHOPPING: {
    SEARCH: 'Search',
    PRICE_FROM: 'Minimum price',
    PRICE_TO: 'Maximum price',
    SORT: 'Sort',
    CONDITION: 'Condition',
    DISCOUNT: 'Discount',
    IN_STOCK: 'In stock',
    DM: 'Doesnt matter',
    NEW: 'Only new',
    USED: 'Only used',
    WITH_DISCOUNT: 'With discount',
    WITHOUT_DISCOUNT: 'Without discount',
    IN_STOCK_YES: 'In stock',
    IN_STOCK_NO: 'Out of stock',
    SORT_SCORE_DSC: 'In descending order of rating',
    SORT_REVIEW_DSC: 'On reviews',
    SORT_PRICE_DSC: 'Descending price',
    SORT_PRICE_ASC: 'Ascending price',
    ORDER_CREATED: 'Order created',
    ORDER_FAILED: 'Order failed',
    VARIANT: 'Variant',
  },

  CART: {
    CART: 'Shopping cart',
    ITEMS_IN_CART: 'In shopping cart {{quantity}}',
    ORDER_CREATE: {
      ORDER_CREATE: 'Create order',
      REQUEST_CREATE: 'Create request',
      SUCCESS: 'The order was created successfully',
      FAIL: 'Failed to create order',
    },
    ADD_TO_CART: {
      SUCCESS: 'Item added to cart successfully',
      FAIL: 'Failed to add item to cart',
    },
    UPDATE: {
      SUCCESS: 'Shopping cart updated successfully',
      FAIL: 'Failed to update the shopping cart',
    },
    STORES: {
      YahooProduct: 'Yahoo Shopping',
      RakutenProduct: 'Rakuten',
      AutoPart: 'Auto parts',
      ShopCatalogProduct: 'Supermarkets',
      MercariProduct: 'Mercari',
      MercariBeyondProduct: 'Mercari Beyond',
    },
    LABELS: {
      ITEM: 'Product',
      PRICE: 'Price',
      QUANTITY: 'Quantity',
    },
  },
  ORIGINAL_AUTO_PARTS: 'Original Auto Parts',
  MAKER: 'Maker',
  CODE: 'Code',
  STATUS: 'Status',
  SOLD: 'Sold',
  ACTIVE: 'Active',
  SEARCH_OEM_PARTS: 'Input part number',
  DETAILS: 'details',
  SELECT_MAKER: 'Choose maker',
  TO_BE_DEFINED: 'by request',
  CURRENCY_HINT: 'By rate',
  AUTO_EXT: 'Automatic extension',
  EARLY_CLOSING: 'Early closing',
  TRUE: 'Yes',
  FALSE: 'No',
  DEFAULT: 'Default',
  SELECT: 'Select',
  TEMPLATE_FIELDS: {
    WANTED_PRICE: 'Wanted price',
  },
  SHOP_CATALOG: 'Supermarkets',

  SERVICES: {
    akbYahooFee: 'Akebono comission',
    akbBankFee: 'Bank comission',
    akbParcelRepack: 'Packing',
    akb400Delivery: 'AKB 400',
    akb450Delivery: 'AKB 450',
    akb1000Delivery: 'AKB Fast 1000',
    akbPersonal400Delivery: 'Personal purchases 400',
    akbWheelsDelivery: 'Wheels delivery',
    akbEnginesDelivery: 'Engine delivery',
    akbEMSDelivery: 'EMS mail',
    akbYahooOrderDelivery: 'Seller delivery payment',
    akbYahooOrderDeliverySagawa: 'In-Japan delivery. Sagawa',
    akbYahooOrderDeliveryYamato: 'In-Japan delivery. Yamato',
    akbYahooOrderDeliveryOther: 'In-Japan delivery. Other',
    akbYahooOrderPayment: 'Purchase price',
    akbOrderDeliveryGeldor: 'Jeldor',
    akbOrderDeliveryEnergy: 'Energiya',
    akbOrderDeliveryPEK: 'PEK',
    akbYahooOrderDeliveryPickup: '"Self-pickup" delivery',
    akbLargeDelivery: 'Oversized cargo',
    akbYahooOrderDeliveryFree: 'Free delivery',
    akbOrderDeliveryDelLin: 'Delovye linii',
    akb500Delivery: 'AKB 500',
    akb550Delivery: 'AKB 550',
    akb1300Delivery: 'AKB Fast',
    akbStorage: 'Paid storage',
    akbWheelsStorage: 'Paid storage. Wheels',
    akbOrderDeliveryCDEK: 'CDEK',
    akbExpressCDEK: 'CDEK Express',
    fastDelivery: 'FAST',
    akbExpress092023: 'Express - Personal purchases',
    akbYahooOrderDeliverySeino: 'In-Japan delivery. Seino',
    akbCustomsClearance: 'Customs clearance',
  },

  'Новый, неиспользованный': 'New, unused',
  'Почти не используется': 'Almost unused',
  'Нет заметных царапин или грязи': 'No visible scratches or dirt',
  'Есть царапины и грязь': 'Visible scratches or dirt',
  'Царапины и грязь': 'Scratches and dirt',
  'Общее плохое состояние': 'Overall bad condition',

  PRICE_MIN: 'Min price',
  PRICE_MAX: 'Max price',
  CONDITION: 'Condition',

  'New/unused': 'New/unused',
  'Almost new': 'Almost new',
  'No noticeable scratches or marks': 'No noticeable scratches or marks',
  'Some scratches/marks': 'Some scratches/marks',
  'Scratches/marks': 'Scratches/marks',
  'Bad condition': 'Bad condition',

  ON_SALE: 'On sale',

  'Недостаточно средств для покупки': 'Insufficient funds',
  SELLER_TYPE: 'Seller type',
};
