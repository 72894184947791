<main class="skiptranslate">
  <nz-alert *ngIf="data.isBlocked" nzType="warning" [nzMessage]="agreement"></nz-alert>
  <ng-template #agreement>
    <span style="white-space: pre-line; font-weight: bold">
      {{ 'AUCTION.CONFIRM.AGREEMENT' | translate }}
    </span>
  </ng-template>
  <form nz-form [formGroup]="form" nzLayout="vertical">
    <nz-form-item *ngIf="data.isBlocked">
      <nz-form-control>
        <label nz-checkbox formControlName="agree">
          {{ 'AUCTION.CONFIRM.I_AGREE' | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired>{{ 'AUCTION.CONFIRM.SUM' | translate }}:</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="price" type="number" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="!data.isBuying">
      <nz-form-control>
        <label nz-checkbox formControlName="deferred">
          {{ 'AUCTION.DEFFERED_BID' | translate }}
        </label>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label nzRequired>{{ 'AUCTION.CONFIRM.COUNT' | translate }}:</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="quantity" type="number" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>{{ 'AUCTION.CONFIRM.COMMENT' | translate }}:</nz-form-label>
      <nz-form-control>
        <textarea nz-input rows="4" formControlName="message"></textarea>
      </nz-form-control>
    </nz-form-item>
  </form>
</main>

<div *nzModalFooter>
  <div nz-row nzJustify="space-around">
    <div nz-col>
      <button
        nz-button
        [disabled]="isBlocked && !form.value.agree"
        [nzLoading]="loading | async"
        nzSize="large"
        nzType="primary"
        (click)="trade()"
      >
        {{ (isBuying ? 'AUCTION.BUY' : 'AUCTION.PUT') | translate }}
      </button>
    </div>
  </div>
</div>
