import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

export enum RakutenSearchSort {
  ScoreDsc = '-score',
  ReviewDsc = '-review_count',
  PriceDsc = '-price',
  PriceAsc = '+price',
}

export interface RakutenSearchFilter {
  minPrice: number | null;
  maxPrice: number | null;
  query: string | null;
  genreId: string | null;
  sort: string | null;
  availability: number | null;
  shopCode: string | null;
}

@Component({
  selector: 'app-rakuten-search-filter',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss'],
})
export class RakutenSearchFilterComponent implements OnInit {
  @Input() initFilter: RakutenSearchFilter = null;

  form: FormGroup = null;

  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder) {
    this.initFilterForm();
  }

  public static getFilter(params: Params): RakutenSearchFilter {
    return {
      query: params.q || undefined,
      sort: params.sort || undefined,
      shopCode: params.seller || undefined,
      genreId: params.category || undefined,
      maxPrice: this.paramToNumber(params.priceTo),
      minPrice: this.paramToNumber(params.priceFrom),
      availability: this.paramToNumber(params.inStock),
    };
  }

  private static paramToBoolean(param: string | null): boolean | null {
    if (param) {
      return param === 'true';
    }

    return null;
  }

  private static paramToNumber(value: string, defaultValue: number | null = null): number | null {
    const result = Number(value);
    return !isNaN(result) ? result : defaultValue;
  }

  ngOnInit(): void {
    this.initFilterForm(this.initFilter);
  }

  initFilterForm(filter?: RakutenSearchFilter): void {
    this.form = this.fb.group({
      sort: this.fb.control(filter?.sort || RakutenSearchSort.ScoreDsc),
      priceFrom: this.fb.control(filter?.minPrice || null),
      priceTo: this.fb.control(filter?.maxPrice || null),
      inStock: this.fb.control(filter?.availability || 'default'),
    });
  }

  reset(): void {
    const mainFilters = this.getMainFilters();
    this.form.reset();
    this.initFilterForm();

    this.router.navigate([], {
      queryParams: {
        ...mainFilters,
      },
    });
  }

  apply(): void {
    const query = this.compileQury();
    const mainFilters = this.getMainFilters();

    this.router.navigate([], {
      queryParams: {
        ...query,
        ...mainFilters,
      },
    });
  }

  getMainFilters(): any {
    return {
      q: this.route.snapshot.queryParams.q,
      seller: this.route.snapshot.queryParams.seller,
      category: this.route.snapshot.queryParams.category,
      pageSize: this.route.snapshot.queryParams.pageSize,
    };
  }

  compileQury(): any {
    const query = {};
    const value = this.form.value;

    Object.keys(value)
      .filter((key) => value[key] !== 'default' && value[key] !== '-score')
      .forEach((key) => (query[key] = value[key]));

    return query;
  }
}
