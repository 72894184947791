<nz-page-header (nzBack)="goHome()" nzBackIcon [nzGhost]="false">
  <nz-page-header-title>{{ 'PARTS.PARTS_SEARCH' | translate }}</nz-page-header-title>
  <nz-page-header-content>
    <section class="section header">
      <p>
        {{ 'PARTS.P_1' | translate }}
        <br />
        {{ 'PARTS.P_2' | translate }}
      </p>
      <p>
        ZZT260 ALLION
        <br />
        ZVW30 PRIUS
      </p>
      <p>
        <span>{{ 'PARTS.VIN' | translate }}:</span><br />
        <input nz-input nzSize="large" [(ngModel)]="vin" />
      </p>
    </section>

    <section class="section parts">
      <nz-tabset nzSize="large">
        <nz-tab *ngFor="let category of categories" [nzTitle]="category">
          <div nz-row>
            <div
              *ngFor="let part of parts[category]"
              nz-col
              [nzXl]="6"
              [nzLg]="8"
              [nzMd]="12"
              [nzSm]="12"
              [nzXs]="24"
            >
              <label [(ngModel)]="selectedParts[part[1].trim()]" nz-checkbox>
                {{ part[0] }}
              </label>
            </div>
          </div>
        </nz-tab>
      </nz-tabset>
      <button nz-button nzType="primary" nzSize="large" (click)="find()">
        {{ 'PARTS.FIND' | translate }}
      </button>
    </section>
  </nz-page-header-content>
</nz-page-header>
