import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PartsSearchComponent } from './components/parts-search';
import { PartsComponent } from './components/parts/parts.component';

export const routes: Routes = [
  { path: 'original-parts', component: PartsSearchComponent },
  { path: 'parts', component: PartsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AutopartsRoutingModule {}
