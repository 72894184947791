<section nz-row>
  <form nz-form nz-row [formGroup]="form" nzLayout="vertical" nzGutter="30">
    <div nz-col>
      <nz-form-item>
        <nz-form-label>{{ 'SHOPPING.PRICE_FROM' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-input-group nzAddOnAfter="¥">
            <input nz-input type="number" formControlName="priceFrom" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>{{ 'SHOPPING.DISCOUNT' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="discounted">
            <label nz-radio nzValue="default">
              {{ 'SHOPPING.DM' | translate }}
            </label>
            <label nz-radio [nzValue]="true">
              {{ 'SHOPPING.WITH_DISCOUNT' | translate }}
            </label>
            <label nz-radio [nzValue]="false">
              {{ 'SHOPPING.WITHOUT_DISCOUNT' | translate }}
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col>
      <nz-form-item>
        <nz-form-label>{{ 'SHOPPING.PRICE_TO' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-input-group nzAddOnAfter="¥">
            <input nz-input type="number" formControlName="priceTo" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>{{ 'SHOPPING.IN_STOCK' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="inStock">
            <label nz-radio nzValue="default">
              {{ 'SHOPPING.DM' | translate }}
            </label>
            <label nz-radio [nzValue]="true">
              {{ 'SHOPPING.IN_STOCK_YES' | translate }}
            </label>
            <label nz-radio [nzValue]="false">
              {{ 'SHOPPING.IN_STOCK_NO' | translate }}
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col>
      <nz-form-item>
        <nz-form-label>{{ 'SHOPPING.CONDITION' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="condition">
            <label nz-radio nzValue="default">
              {{ 'SHOPPING.DM' | translate }}
            </label>
            <label nz-radio nzValue="new">
              {{ 'SHOPPING.NEW' | translate }}
            </label>
            <label nz-radio nzValue="used">
              {{ 'SHOPPING.USED' | translate }}
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>{{ 'SHOPPING.SORT' | translate }}:</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="sort">
            <label nz-radio nzValue="-score">
              {{ 'SHOPPING.SORT_SCORE_DSC' | translate }}
            </label>
            <label nz-radio nzValue="-review_count">
              {{ 'SHOPPING.SORT_REVIEW_DSC' | translate }}
            </label>
            <label nz-radio nzValue="-price">
              {{ 'SHOPPING.SORT_PRICE_DSC' | translate }}
            </label>
            <label nz-radio nzValue="+price">
              {{ 'SHOPPING.SORT_PRICE_ASC' | translate }}
            </label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>
    </div>
  </form>
</section>
<section nz-row nzGutter="20">
  <div nz-col>
    <button nz-button nzType="primary" nzSize="large" (click)="apply()">
      {{ 'LOTS.FILTER.APPLY' | translate }}
    </button>
  </div>
  <div nz-col>
    <button nz-button nzType="primary" nzDanger nzSize="large" (click)="reset()">
      {{ 'LOTS.FILTER.RESET' | translate }}
    </button>
  </div>
</section>
