<nz-spin [nzSpinning]="loading" nzSize="large">
  <section class="section breadcrumbs">
    <nz-breadcrumb>
      <nz-breadcrumb-item>
        <a routerLink="/shopping">Yahoo Shopping</a>
      </nz-breadcrumb-item>
      <ng-container *ngFor="let crumb of crumbs">
        <nz-breadcrumb-item>
          <a [routerLink]="crumb.path" [queryParams]="crumb.params">
            {{ crumb.name }}
          </a>
        </nz-breadcrumb-item>
      </ng-container>
    </nz-breadcrumb>
  </section>

  <div *ngIf="loading && !item" class="loading-payload"></div>

  <section class="section title">
    <h2 *ngIf="item?.Headline" nz-typography>
      {{ item?.Headline }}
    </h2>
    <h2 *ngIf="!item?.Headline" nz-typography>
      {{ item?.Name }}
    </h2>
  </section>

  <section *ngIf="item" class="section main">
    <div nz-row nzGutter="20">
      <div nz-col [nzLg]="10" [nzXs]="24" class="images">
        <app-shopping-images
          [images]="getImages()"
          [thumbnails]="getThumbs()"
        ></app-shopping-images>
      </div>
      <div nz-col [nzXs]="24" [nzLg]="14">
        <div nz-row *ngIf="currentUser" nzJustify="space-between" class="buttons">
          <div nz-col>
            <button nz-button nzType="primary" nzShape="round" (click)="update()">
              {{ 'AUCTION.UPDATE' | translate }}
            </button>
          </div>
        </div>
        <div class="panel">
          <div nz-row nzJustify="center" class="price-row skiptranslate">
            <div nz-col class="price-container">
              <div>
                {{ 'PRICE' | translate }}
              </div>
              <div class="price">
                <app-currency
                  [value]="+item.Price._value"
                  [exchangeRate]="exchangeRate"
                  layout="horizontal"
                ></app-currency>
              </div>
            </div>
          </div>
          <div class="description">
            <nz-table
              class="description-table"
              [nzData]="[1]"
              nzSize="small"
              [nzShowPagination]="false"
              [nzFrontPagination]="false"
            >
              <tbody>
                <tr>
                  <td>{{ 'PRICE_WITH_TAX' | translate }}</td>
                  <td class="bold">
                    <app-currency
                      [value]="+item.Price._value + 0.1 * +item.Price._value"
                      [exchangeRate]="exchangeRate"
                      layout="horizontal"
                    ></app-currency>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'AUCTION.CONDITION' | translate }}</td>
                  <td>{{ item.Condition }}</td>
                </tr>
                <tr class="skiptranslate">
                  <td>{{ 'PRODUCT' | translate }}</td>
                  <td>
                    <a [href]="item.Url">{{ code }}</a>
                  </td>
                </tr>
                <tr class="skiptranslate">
                  <td>{{ 'AUCTION.SELLER' | translate }}</td>
                  <td>
                    <a routerLink="/shopping/search" [queryParams]="{ seller: item.Store?.Id }">{{
                      item.Store?.Name
                    }}</a>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'JP_DELIVERY' | translate }}</td>
                  <td>
                    <div>{{ item.Shipping.Name }}</div>
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td>{{ 'AUCTION.RATING' | translate }}</td>
                  <td>
                    <nz-rate [ngModel]="+item.Review.Rate" nzSize="small" nzDisabled></nz-rate>
                  </td>
                </tr>
                <tr *ngIf="currentUser">
                  <td>{{ 'ORDER_CREATE.QUANTITY' | translate }}</td>
                  <td>
                    <nz-input-number
                      [(ngModel)]="quantity"
                      [nzMin]="minQuantity"
                      [nzMax]="maxQuantity"
                      [nzStep]="1"
                    ></nz-input-number>
                  </td>
                </tr>
                <tr *ngIf="item.Inventories['1']">
                  <td>{{ 'SHOPPING.VARIANT' | translate }}</td>
                  <td style="text-align: left; display: flex">
                    <div style="display: flex; flex: 1"></div>
                    <nz-radio-group [(ngModel)]="variant" nzSize="large">
                      <ng-container *ngFor="let variant of item.Inventories | keyvalue">
                        <label
                          *ngIf="variant.value.SubCode !== undefined"
                          nz-radio
                          [nzValue]="variant.value.SubCode"
                          [nzDisabled]="variant.value.Availability !== 'instock'"
                        >
                          <ng-container *ngFor="let option of variant.value.Order | keyvalue">
                            {{ option.value.Name }} {{ option.value.Value }}
                          </ng-container> </label
                        ><br />
                      </ng-container>
                    </nz-radio-group>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>
          <div class="trade skiptranslate">
            <div class="buttons" *ngIf="currentUser">
              <button
                nz-button
                [nzLoading]="addingToCart"
                [disabled]="ordering || (item.Inventories['1'] && !variant)"
                nzType="primary"
                nzSize="large"
                (click)="addToCart()"
              >
                {{ 'ACTIONS.ADD_TO_CART' | translate }}
              </button>
              <button
                nz-button
                [nzLoading]="ordering"
                [disabled]="addingToCart || (item.Inventories['1'] && !variant)"
                (click)="createOrder()"
                nzType="default"
                nzSize="large"
                class="buy-now"
              >
                {{ 'ACTIONS.BUY_NOW' | translate }}
              </button>
              <div *ngIf="cartItemQuantity" class="in-cart">
                <a nz-button nzType="default" nzShape="round" [routerLink]="['/cart']">
                  <i nz-icon nzType="shopping-cart" nzTheme="outline"></i>
                  {{ 'CART.ITEMS_IN_CART' | translate: { quantity: cartItemQuantity } }}
                </a>
              </div>
            </div>
            <div *ngIf="!currentUser" class="no-auth">
              {{ 'AUCTION.NO_AUTH_TEMPLATE' | translate }}
            </div>
          </div>
          <hr class="panel-divider" />
          <div class="calculator">
            <app-cost-calculator
              [price]="item.Price._value"
              [taxRate]="10"
              [services]="services"
              [allowBid]="true"
              [currentUser]="currentUser"
              [exchangeRate]="exchangeRate"
              [bidStep]="50"
              #costCaclc
            >
            </app-cost-calculator>
          </div>
          <hr *ngIf="costCaclc.isRussianUser" />
          <div *ngIf="costCaclc.isRussianUser" class="delivery-calculator">
            <app-delivery-calculator
              [currentUser]="currentUser"
              [costCalc]="costCaclc"
              [exchangeRate]="exchangeRate"
            ></app-delivery-calculator>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="item" class="section info">
    <div
      *ngIf="item.Description"
      style="width: max-content; max-width: 100%; margin: auto"
      [innerHTML]="item.SpAdditional + '<br>' + item.Description"
    ></div>
    <span *ngIf="!item.Description">{{ item.Name }}</span>
  </section>
</nz-spin>
