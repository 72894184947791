import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(private title: Title, private search: SearchService) {
    this.search.currentYahooCategory.next(null);
    this.search.currentService.next(SearchServiceEnum.Yahoo);
  }

  ngOnInit(): void {
    this.title.setTitle('Akebono Shop');
  }
}
