import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NewsComponent } from './components/news/news.component';
import { NewsEntryComponent } from './components/news-entry/news-entry.component';
import { ArticleComponent } from './components/article/article.component';

export const routes: Routes = [
  { path: 'news', component: NewsComponent },
  { path: 'news/:id', component: NewsEntryComponent },
  { path: 'support/:article', component: ArticleComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentRoutingModule {}
