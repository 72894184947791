import { NotificationService } from '@akebono/core';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { AuctionQuery, ExchangeRate } from 'src/app/graphql/user-service';

import { BidOrBuyConfirmationModalComponent } from './bid-or-buy-confirmation-modal/bid-or-buy-confirmation-modal.component';

interface DateDiff {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
}

@Component({
  selector: 'app-trade-panel',
  templateUrl: './trade-panel.component.html',
  styleUrls: ['./trade-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TradePanelComponent implements OnInit {
  @Input() lot: AuctionQuery['yahooLot'] = null;
  @Input() isAuth = false;
  @Input() exchangeRate: ExchangeRate = null;
  @Input() timezone: string | null = null;
  @Input() currentTime: string | null = null;

  @Output() amountChange = new EventEmitter<number>();

  userBid = 0;
  deferred = false;

  loading: Observable<boolean>;

  constructor(
    private translate: TranslateService,
    private modalService: NzModalService,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.userBid = Number(this.lot.lastPayload.Price) + Number(this.lot.bidStep || 0);
  }

  getCondition(): string {
    if (this.lot?.lastPayload?.ItemStatus?.Condition) {
      if (this.lot.lastPayload.ItemStatus.Condition === 'new') {
        return this.translate.instant('LOTS.NEW');
      } else {
        return this.translate.instant('LOTS.USED');
      }
    } else {
      return this.translate.instant('LOTS.UNKNOWN');
    }
  }

  getSeller(): string {
    if (this.lot.lastPayload.Seller?.Id) {
      return this.lot.lastPayload.Seller.Id;
    }

    return this.translate.instant('LOTS.UNKNOWN');
  }

  getStoreType(): boolean {
    if (this.lot.lastPayload.Option) {
      return this.translate.instant(
        this.lot.lastPayload.Option.StoreIcon ? 'LOTS.STORE' : 'LOTS.PRIVATE_SELLER',
      );
    }

    return this.translate.instant('LOTS.UNKNOWN');
  }

  getInitPrice(): string {
    const priceStr = this.lot.lastPayload.Initprice?.trim();
    if (priceStr) {
      return Number(Number(priceStr).toFixed()).toLocaleString();
    }

    return '';
  }

  isAllowBid(): boolean {
    return (
      !this.lot.lastPayload.Bidorbuy || this.lot.lastPayload.Price !== this.lot.lastPayload.Bidorbuy
    );
  }

  getRating(): string {
    if (
      this.lot.lastPayload.Seller?.Rating?.TotalNormalRating &&
      this.lot.lastPayload.Seller?.Rating?.Point
    ) {
      const total = Number(this.lot.lastPayload.Seller.Rating.Point);
      const good = Number(this.lot.lastPayload.Seller.Rating.TotalNormalRating);

      return `+${good}% / ${total}`;
    } else {
      return '';
    }
  }

  canBidOrBuy(): boolean {
    return (
      this.isAuth &&
      this.lot.isBiddable &&
      this.lot.lastPayload.AvailableQuantity &&
      this.lot.lastPayload.Price &&
      (!this.lot.isCensored || (this.lot.isCensored && !this.lot.isCategoryBlocked)) &&
      this.lot.lastPayload.Bidorbuy
    );
  }

  canTrade(): boolean {
    return (
      this.isAuth &&
      this.lot.isBiddable &&
      this.lot.lastPayload.AvailableQuantity &&
      this.lot.lastPayload.Price &&
      (!this.lot.isCensored || (this.lot.isCensored && !this.lot.isCategoryBlocked))
    );
  }

  isCensored(): boolean {
    return (
      this.isAuth &&
      this.lot.lastPayload.AvailableQuantity === null &&
      this.lot.lastPayload.Price === null &&
      this.lot.isCensored
    );
  }

  isFinal(): boolean {
    return this.lot.lastPayload.Status !== 'open';
    // return (
    //   this.isAuth &&
    //   this.lot.isCensored &&
    //   (!this.lot.isBiddable ||
    //     this.lot.lastPayload.AvailableQuantity === null ||
    //     this.lot.lastPayload.Price === null)
    // );
  }

  getLeader(): string {
    if (this.lot.isLeader) {
      return this.translate.instant('AUCTION.YOU');
    } else {
      if (
        this.lot.lastPayload.HighestBidders &&
        this.lot.lastPayload.HighestBidders.Bidder &&
        this.lot.lastPayload.HighestBidders.Bidder.Id
      ) {
        return this.lot.lastPayload.HighestBidders.Bidder.Id;
      }
    }

    return this.translate.instant('LOTS.UNKNOWN');
  }

  getTaxinPrice(): number | null {
    if (this.lot.lastPayload.TaxinPrice) {
      return Number(Number(this.lot.lastPayload.TaxinPrice).toFixed());
    }

    return null;
  }

  getTaxinBuyout(): number | null {
    if (this.lot.lastPayload.TaxinBidorbuy) {
      return Number(Number(this.lot.lastPayload.TaxinBidorbuy).toFixed());
    }

    return null;
  }

  getEndTimer(): string {
    if (this.lot.lastPayload.EndTime) {
      const endTime = new Date(this.lot.lastPayload.EndTime.trim());
      const now = this.currentTime ? new Date(this.currentTime) : new Date();

      if (now.getTime() > endTime.getTime()) {
        return this.translate.instant('LOTS.COMPLETE');
      } else {
        const diff = this.getDateDiff(endTime, now);

        if (diff.days !== 0) {
          return this.compileEndTimeString(diff.days, ['LOTS.DAY', 'LOTS.DAY_TWO', 'LOTS.DAY_FEW']);
        }

        if (diff.hours !== 0) {
          return this.compileEndTimeString(diff.hours, [
            'LOTS.HOUR',
            'LOTS.HOUR_TWO',
            'LOTS.HOUR_FEW',
          ]);
        }

        if (diff.minutes !== 0) {
          return this.compileEndTimeString(diff.minutes, [
            'LOTS.MINUTE',
            'LOTS.MINUTE_TWO',
            'LOTS.MINUTE_FEW',
          ]);
        }

        if (diff.seconds !== 0) {
          return this.compileEndTimeString(diff.seconds, [
            'LOTS.SECOND',
            'LOTS.SECOND_TWO',
            'LOTS.SECOND_FEW',
          ]);
        }

        return '';
      }
    } else {
      return '';
    }
  }

  getPrice(item: any): string | null {
    if (item.CurrentPrice) {
      return item.CurrentPrice;
    } else if (item.Price) {
      return item.Price;
    } else {
      return null;
    }
  }

  getDateDiff(first: Date, second: Date): DateDiff {
    const diff = first.getTime() - second.getTime();
    return {
      seconds: Math.floor((diff / 1000) % 60),
      minutes: Math.floor((diff / (1000 * 60)) % 60),
      hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
    };
  }

  compileEndTimeString(end: number, titles: string[]): string {
    // https://realadmin.ru/coding/sklonenie-na-javascript.html
    const title =
      titles[end % 100 > 4 && end % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][end % 10 < 5 ? end % 10 : 5]];
    return `${end} ${this.translate.instant(title)}`;
  }

  showConfirm(isBuy: boolean): void {
    if (isBuy) {
      this.openConfirmModal(this.lot.lastPayload.Bidorbuy, this.deferred, isBuy);
    } else {
      this.openConfirmModal(this.userBid, this.deferred, isBuy);
    }
  }

  private openConfirmModal(amount: number | string, deferred: boolean, isBuying: boolean): void {
    this.modalService.create({
      nzTitle: this.translate.instant(
        isBuying ? 'AUCTION.CONFIRM.CONFIRM_BUY' : 'AUCTION.CONFIRM.CONFIRM_BID',
      ),
      nzContent: BidOrBuyConfirmationModalComponent,
      nzWidth: '350px',
      nzData: {
        amount,
        isBuying,
        deferred,
        isBlocked: this.lot.isCensored && !this.lot.isCategoryBlocked,
        auctionId: this.lot.lastPayload.AuctionID,
        activeBid: this.lot.currentUserActiveYahooBid,
      },
    });
  }

  validateBid(): void {
    if (this.userBid < Number(this.getPrice(this.lot.lastPayload))) {
      this.userBid = Number(this.lot.lastPayload.Price);
      this.notification.renderWarning('AUCTION.PRICE_ERROR');
    }
  }
}
