import { Component, Input, OnInit } from '@angular/core';
import { NzImage, NzImageService } from 'ng-zorro-antd/image';

@Component({
  selector: 'app-shopping-images',
  templateUrl: './shopping-images.component.html',
  styleUrls: ['./shopping-images.component.scss'],
})
export class ShoppingImagesComponent implements OnInit {
  @Input() selectedImageIndex: number = null;
  @Input() images: string[] = [];
  @Input() thumbnails: string[] = [];

  galleryImages: NzImage[];

  constructor(private gallery: NzImageService) {}

  ngOnInit() {
    if (this.images.length) {
      this.selectedImageIndex = this.images.length ? 0 : null;
      this.galleryImages = this.images.map((x) => ({
        src: x,
      }));
    }
  }

  openImage(index: number) {
    this.gallery.preview(this.galleryImages).switchTo(index);
  }

  select(index: number): void {
    this.selectedImageIndex = index;
  }
}
