import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuctionComponent } from './components/auction/auction.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryComponent } from './components/category/category.component';
import { SearchComponent } from './components/search/search.component';
import { SellerComponent } from './components/seller/seller.component';

export const routes: Routes = [
  { path: 'categories', component: CategoriesComponent },
  { path: 'category/:code', component: CategoryComponent },
  { path: 'search', component: SearchComponent },
  { path: 'seller/:id', component: SellerComponent },
  { path: 'auction/:auctionId', component: AuctionComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class YahooAuctionsRoutingModule {}
