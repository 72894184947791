import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum SearchServiceEnum {
  Yahoo = 'yahoo',
  YahooCurrent = 'currentYahoo',
  YahooSellerCurrent = 'yahooSellerCurrent',
  Croober = 'croober',
  Shopping = 'shopping',
  Rakuten = 'rakuten',
  Parts = 'parts',
  Catalog = 'catalog',
  Mercari = 'mercari',
}

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  currentSeller = new BehaviorSubject<string | null>(null);
  currentYahooCategory = new BehaviorSubject<string | null>(null);
  currentService = new BehaviorSubject<SearchServiceEnum | null>(null);
}
