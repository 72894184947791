import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CartComponent } from './components/cart/cart.component';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PERMISSION_SHOP_JAPAN } from './const';
import { LocationGuard } from './guards/location.guard';
import { routes as autopartsRoutes } from './modules/autoparts/autoparts-routing.module';
import { routes as contentRoutes } from './modules/content/content-routing.module';
import { routes as yahooAuctionsRoutes } from './modules/yahoo-auctions/yahoo-auctions-routing.module';

const routes: Routes = [
  {
    path: '',
    canActivate: [LocationGuard],
    children: [
      { path: '', component: HomeComponent },
      {
        path: 'croooober',
        loadChildren: () => import('./modules/croober/croober.module').then((m) => m.CrooberModule),
      },
      {
        path: 'shopping',
        loadChildren: () =>
          import('./modules/yahoo-shopping/yahoo-shopping.module').then(
            (m) => m.YahooShoppingModule,
          ),
      },
      {
        path: 'rakuten',
        loadChildren: () => import('./modules/rakuten/rakuten.module').then((m) => m.RakutenModule),
      },
      // {
      //   path: 'mercari',
      //   loadChildren: () => import('./modules/mercari/mercari.module').then((m) => m.MercariModule),
      // },
      // {
      //   path: 'shop',
      //   loadChildren: () => import('./modules/shop/shop.module').then((m) => m.ShopModule),
      // },
      {
        path: 'shop-catalog',
        loadChildren: () =>
          import('./modules/catalogs/catalogs.module').then((m) => m.CatalogsModule),
      },
      ...yahooAuctionsRoutes,
      ...contentRoutes,
      ...autopartsRoutes,
      { path: 'cart', component: CartComponent },
      { path: '404', component: NotFoundComponent },
    ],
    data: {
      permissions: [PERMISSION_SHOP_JAPAN],
      doRedirect: true,
    },
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
