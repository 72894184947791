import { NotificationService } from '@akebono/core';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DefaultUrlSerializer, PRIMARY_OUTLET } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Category, RakutenCategoriesGQL } from 'src/app/graphql/user-service';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';

@Component({
  selector: 'app-rakuten-categories',
  templateUrl: './template.html',
  styleUrls: ['./styles.scss'],
})
export class RakutenCategoriesComponent implements OnInit, OnDestroy {
  @Input() onlyPopular = false;

  categories: Category[] = [];

  loading = false;

  subscription: Subscription = null;
  urlSerializer = new DefaultUrlSerializer();

  constructor(
    private translate: TranslateService,
    private notificationService: NotificationService,
    private categoriesGQL: RakutenCategoriesGQL,
    private searchService: SearchService,
  ) {}

  ngOnInit(): void {
    this.searchService.currentService.next(SearchServiceEnum.Rakuten);
    this.loading = true;
    this.subscription = this.categoriesGQL
      .watch(
        {
          lang: this.translate.currentLang,
        },
        {
          fetchPolicy: 'cache-first',
        },
      )
      .valueChanges.subscribe(
        (result) => {
          this.loading = false;
          console.log('Categories response', result);

          if (result && result.data && result.data.rakutenCategories) {
            if (this.onlyPopular) {
              this.categories = result.data.rakutenCategories.filter((c) => c.isPopular);

              this.categories.forEach((category) => {
                category.children = category.children.filter((c) => c.isPopular);
              });
            } else {
              this.categories = result.data.rakutenCategories.filter(
                (c) => c.code || (c.useSearch && c.searchQuery),
              );
            }
          } else {
            this.notificationService.renderError('Empty response');
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
  }

  getPathname(path) {
    return this.urlSerializer.parse(path).root.children[PRIMARY_OUTLET].segments.join('/');
  }

  getQueryParams(path) {
    return this.urlSerializer.parse(path).queryParams;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
