import { FormGroup } from '@angular/forms';

import { CurrencyEnum, ExchangeRate } from './graphql/user-service';

export const DEFAULT_LANG = 'ru';

export const DEFAULT_CURRENCY = CurrencyEnum.Jpy;

export const COMPANY_COMISSION_RATE = 0.1;

export const PERMISSION_SHOP_JAPAN = 'world.akebono.shop.japan';
export const PERMISSION_SHOP_MANAGER = 'world.akebono.shop.manager';
export const PERMISSION_SHOP_STOCKMAN = 'world.akebono.shop.stockman';
export const CROOBER_IMAGES_PROXY = 'https://extimg.akebono.world';

export const YAHOO_SHOPPING_LAGE_IMAGE_BASE_URL = 'https://item-shopping.c.yimg.jp/i/l/';

export const EMPTY_EXCHANGE_RATE: ExchangeRate = {
  nominal: 0,
  value: 0,
  date: 0,
};

export const RUSSIAN_CUSTOMS_CLEARANCE_INFO = {
  priceLimitEur: 200,
  percentageOfExcess: 0.15,
};

export interface Loadings {
  [id: string]: boolean;
}

export function validateAllFormFields(form: FormGroup) {
  form.markAllAsTouched();

  // tslint:disable-next-line:forin
  for (const i in form.controls) {
    form.controls[i].markAsDirty();
    form.controls[i].updateValueAndValidity();
  }
}

export function scrollToTop(): void {
  const scrollToTopInterval = window.setInterval(() => {
    const pos = window.pageYOffset;
    if (pos > 0) {
      window.scrollTo(0, pos - 80);
    } else {
      window.clearInterval(scrollToTopInterval);
    }
  }, 16);
}

export const PARTS: any = {
  Электрика: [
    ['антенна', 'アンテナ'],
    ['блок предохранителей', 'ヒューズボックス'],
    ['блоки розжига ксенона', 'バラストバーナー'],
    ['вакуумметр', 'バキュームメーター'],
    ['вольтметр', '電圧計'],
    ['генератор', 'オルタネーター'],
    ['датчик  температуры масла', '油温計'],
    ['датчик давления масла', '油圧計'],
    ['датчик давления топлива', '燃圧計'],
    ['датчик охлаждения', '水温計'],
    ['датчик температурывыхлопных газов', '排気温センサー'],
    ['датчик угла поворота', 'クランク角センサー '],
    ['динамики', 'スピーカー '],
    ['загрузочный диск навигатора', 'ナビロム'],
    ['звуковой сигнал', 'ホーン'],
    ['инвертер', 'インバーター'],
    ['испаритель', 'エバポレーター'],
    ['камера', 'カメラ'],
    ['компрессор кондиционера', 'エアコンコンプレッサー'],
    ['компьютер', 'コンピューター'],
    ['компьютер АКПП', ' ミッションコンピューター'],
    ['компьютер двигателя', 'エンジンコンピューター'],
    ['компьютер подушек безопасности', 'エアバッグコンピューター'],
    ['компьютер ABS', 'ＡＢＳコンピューター カセット'],
    ['кондиционер', 'エアコン'],
    ['лампочки ксенон', 'HIDバーナー'],
    ['моторчик дворников', 'ワイパーモーター'],
    ['моторчик печки', 'ブロアモーター'],
    ['панель кондиционера', 'エアコンスイッチ'],
    ['переключатель', 'スイッチ'],
    ['переключатель зеркал', 'ミラースイッチ'],
    ['печка', 'ヒーター'],
    ['радиатор кондеционера', 'コンデンサー'],
    ['расходомер воздуха', 'エアフロセンサー'],
    ['резистор', 'レジスター'],
    ['свеча зажигания', 'プラグ '],
    ['сенсор', 'センサー'],
    ['спидометр', 'スピードメーター'],
    ['стартер', 'セルモーター'],
    ['таймер', 'タイマー'],
    ['тахометр', 'タコメーター'],
    ['ТВ/навигация', 'ナビ'],
    ['тумблер комбинированный', 'コンビネーションスイッチ'],
    ['тумблерсвет.сигнализации', 'ハザードスイッチ'],
    ['турбометр', 'ターボ計'],
    ['уклономер', 'クライノメーター'],
    ['управление стеклоподъемников', 'パワーウィンドスイッチ'],
    ['усилитель', 'アンプ'],
    ['электрический вентилятор', '電動ファン'],
    ['электропроводка', 'ハーネス'],
    ['Aудио.CD.DVD.player', 'プレヤー '],
  ],
  Ходовка: [
    ['главный тормозной цилиндр', 'ブレーキマスター'],
    ['гидроусилитель руля', 'パワステギアボックス '],
    ['ступица', 'ナックルハブ'],
    ['привод', 'ドライブシャフト'],
    ['проставки для дисков', 'ワイドトレッドスペーサー'],
    ['суппорт', 'キャリパー'],
    ['насос гидроусилителя руля', 'パワステポンプ'],
    ['тормозной диск', 'デスクローター'],
  ],
  Подвеска: [
    ['амортизатор', 'ショックアブソーバ'],
    ['балка ДВС', 'エンジンメンバー'],
    ['балка моста', 'アクスルビーム'],
    ['верхний рычаг', 'アッパーアーム'],
    ['компрессор пневмoподвески', 'エアサスコンプレッサー'],
    ['наконечник тяги', 'タイロッドエンド'],
    ['нижний рычаг', 'ロアーアーム'],
    ['пневмоподвеска', 'エアサス'],
    ['подвеска', 'サスペンション'],
    ['пружина', 'スプリング '],
    ['стабилизатор', 'スタビライザー'],
    ['стойка', 'ストラット'],
    ['тяга', 'テンションロッド'],
  ],
  Кузов: [
    [' ручка двери', 'ドアハンドル'],
    [' спойлер', 'スポイラー'],
    ['амортизатор багажника', 'トランクダンパー'],
    ['амортизатор задней двери', 'バックドアダンパー'],
    ['амортизатор капота', 'ボンネットダンパー'],
    ['багажник', 'トランク'],
    ['бампер', 'バンパー'],
    ['боковое зеркало', 'ドアミラー'],
    ['брызговик', 'マッドガード'],
    ['ветровик', 'バイザー'],
    ['воздухозаборник', 'ダクト'],
    ['габарит', 'コーナーランプ '],
    ['дверь', 'ドア'],
    ['дворник', 'ワイパーアーム'],
    ['держатель запаски', 'スペアタイヤキャリア'],
    ['дополнительный габарит', 'コナーランプ'],
    ['жесткость бампера', 'ホースメント'],
    ['задняя панель', 'バックパネル'],
    ['задняя торцевая дверь', 'バックドア'],
    ['защита', 'ガード'],
    ['защитная решётка', 'グリルガード'],
    ['зеркало на крыле', 'フェンダーミラー'],
    ['капот', 'ボンネット'],
    ['крылo', 'フェンダー'],
    ['крышка топливного бака', 'フューエルリッド'],
    ['люк', 'サンルーフ'],
    ['молдинг', 'モールディング'],
    ['нижняя защита двс', 'アンダーカバー'],
    ['обшивка салона', '内張り'],
    ['петли капота', 'ボンネットヒンジ'],
    ['петля двери', 'ドアヒンジ '],
    ['повторитель поворота', 'ウィンカー'],
    ['подкрылок пластиковый', 'フェンダーライナー'],
    ['подножка', 'サイドステップ '],
    ['противотуманная фара', 'フォグランプ'],
    ['рамка радиатора "телевизор"', 'コアサポート '],
    ['рейлинг крыши', 'ルーフレール'],
    ['решетка радиатора', 'グリル'],
    ['стекло', 'ガラス'],
    ['стекло двери', 'ドアガラス'],
    ['стекло форточки', '三角窓ガラス'],
    ['стоп-сигнал', 'テールランプ'],
    ['уплотнительная резинка двери', 'ウェザーストリップ '],
    ['фара', 'ヘッドライト'],
    ['фаркопом', 'ヒッチメンバー'],
    ['форсунка стеклоомывателя', 'ウォッシャーノズル'],
    ['чехол запасного колеса', 'スペアタイヤカバー'],
    ['эмблема', 'エンブレム'],
  ],
  Салон: [
    ['багажный поддон', 'ラゲッジトレイ'],
    ['бардачок', 'グローブボックス'],
    ['зеркало заднего вида', 'ルームミラー'],
    ['коврик', 'フロアマット '],
    ['ковровое покрытие', 'カーペット'],
    ['кожух рулевой колонки', 'コラムカバー'],
    ['консоль', 'コンソール '],
    ['направляющие сидений', 'シートレール'],
    ['обшивка потолка', '天張り '],
    ['панель "торпеда"', 'ダッシュボード '],
    ['панель приборов', 'メーターパネル'],
    ['педаль акселератора', 'アクセルペダル'],
    ['педаль сцепления', 'クラッチペダル'],
    ['педаль тормоза', 'ブレーキペダル '],
    ['пепельница', '灰皿'],
    ['подголовник', 'ヘッドレスト'],
    ['решетка динамика', 'スピーカーグリル'],
    ['руль', 'ステアリングホイール'],
    ['ручной тормоз', 'サイドブレーキ '],
    ['рычаг переключения передач', 'シフトレバー '],
    ['свет в салоне', 'ルームランプ '],
    ['сиденье', 'シート'],
    ['центральная консоль', 'センターコンソール '],
    ['чехол коробки передач', 'シフトブーツ'],
    ['шторка багажника', 'トノカバー '],
  ],
  Трансмиссия: [
    ['автоматическая коробка передач', 'オートマチックミッション'],
    ['гидротрансформатор "маховик"', 'トルクコンバーター'],
    ['дифференциал', 'デフ'],
    ['карданный вал', 'プロペラシャフト'],
    ['колесный колпак', 'ホイールキャップ'],
    ['механическая  коробка передач', 'マニアルミッション'],
    ['раздаточная коробка', 'トランスファー'],
    ['редуктор', 'デフ'],
    ['сцепление', 'クラッチ'],
    ['трансмиссия', 'ミッション'],
  ],
  Двигатель: [
    ['бачок омывателя', 'ウォッシャータンク'],
    ['бачок радиатора', 'ラジエターリザーバータンク モーター'],
    ['блок  цилиндров', 'シリンダーブロック'],
    ['водяная помпа', 'ウォーターポンプ'],
    ['воздушный фильтр', 'エアクリーナー'],
    ['впускной коллектор', 'インマニ '],
    ['выпускной коллектор', 'エキマニ'],
    ['глушитель, выхлоп', 'マフラー'],
    ['двигатель', 'エンジン'],
    ['дроссельная заслонка', 'スロットル'],
    ['зажигание', 'インジェクション'],
    ['инжектора', 'インジェクタ '],
    ['интеркулер', 'インタークーラー'],
    ['карбюратор ', 'キャブレータ '],
    ['коленчатый вал', 'クランクシャフト'],
    ['масляный радиатор', 'オイルクーラー  '],
    ['нагнетатель механический', 'スーパーチャージャー '],
    ['радиатор', 'ラジエター '],
    ['распределитель зажигания', 'ディストリビューター'],
    ['ремень', 'ベルト'],
    ['стартер', 'セルモ－ター'],
    ['ТНВД', '噴射ポンプ'],
    ['топливный бак', '燃料タンク'],
    ['топливный насос', 'フューエルポンプ'],
    ['топливный насос', '燃料ポンプ'],
    ['турбина', 'タービン'],
    ['фильтр', 'フィルタ'],
    ['электропроводка двигателя', 'エンジンハーネス'],
  ],
};
