import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from '@akebono/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LocationGuard implements CanActivate {
  constructor(private authService: AuthenticationService, private httpClient: HttpClient) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const permissions = route.data.permissions || [];
    const doRedirect = route.data.doRedirect || false;

    return true;
    // return this.httpClient
    //   .get(environment.countryCheckUrl)
    //   .pipe(
    //     timeout(5000),
    //     catchError(() => {
    //       return of(null);
    //     }),
    //   )
    //   .toPromise()
    //   .then((res) => {
    //     if (!res || res instanceof HttpErrorResponse || res.country !== 'JP') {
    //       console.log(res);
    //       return true;
    //     }

    //     return this.authService.checkPermissions(permissions, doRedirect);
    //   });
  }
}
