import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CrooberCategoriesGQL } from 'src/app/graphql/user-service';
import { NotificationService } from '@akebono/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTranslateService } from 'src/app/services/google-translate.service';
import { DefaultUrlSerializer, PRIMARY_OUTLET } from '@angular/router';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';

@Component({
  selector: 'app-croober-categories',
  templateUrl: './croober-categories.component.html',
  styleUrls: ['./croober-categories.component.scss'],
})
export class CrooberCategoriesComponent implements OnInit, OnDestroy {
  @Input() onlyPopular = false;

  categories: any[] = [];

  loading = false;

  urlSerializer = new DefaultUrlSerializer();

  subscription: Subscription = null;

  constructor(
    private translate: TranslateService,
    private googleTranslate: GoogleTranslateService,
    private notificationService: NotificationService,
    private crooberCategoriesGQL: CrooberCategoriesGQL,
    private searchService: SearchService,
  ) {
    this.searchService.currentService.next(SearchServiceEnum.Croober);
  }

  ngOnInit(): void {
    this.loading = true;
    this.subscription = this.crooberCategoriesGQL
      .watch(
        {
          lang: this.translate.currentLang,
        },
        {
          fetchPolicy: 'cache-first',
        },
      )
      .valueChanges.subscribe(
        (result) => {
          this.loading = false;
          console.log('Categories response', result);

          if (result && result.data && result.data.crooberCategories) {
            this.categories = result.data.crooberCategories;
            this.googleTranslate.translate();
          } else {
            this.notificationService.renderError('Empty response');
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getPathname(path) {
    if (path) {
      return this.urlSerializer.parse(path).root.children[PRIMARY_OUTLET].segments.join('/');
    }
    return null;
  }

  getQueryParams(path) {
    if (path) {
      return this.urlSerializer.parse(path).queryParams;
    }
    return null;
  }
}
