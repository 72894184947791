const domain = 'akebono.world';

export const environment = {
  production: true,
  domain,
  paypalKey: 'ATSEIOt13ZzNgJtIF56ntMwtxRfp7UB-YvV4M4mQwwYDn4aj2aNlOCbVUVNdInjJRKYpU0cro-io9ld4',
  stripeKey: 'pk_live_tcYkGD6d8uyTVEKSXWoO2t8f001nf0ASxn',
  countryCheckUrl: 'https://locate.akebono.world/country',
  telegramBotName: 'AkebonoBot',
  sberDomain: 'securepayments.sberbank.ru',
  sberKey: '', // 'juue95061gqu40eeira8eegc5a',

  graphql: [
    {
      clientName: 'authentication',
      endpoint: `https://api.${domain}/accounts/authentication`,
    },
    {
      clientName: 'user',
      endpoint: `https://api.${domain}/auctions/main`,
    },
    {
      clientName: 'content',
      endpoint: `https://shop.${domain}/content/shop-minimal`,
    },
    {
      clientName: 'shared-shop',
      endpoint: `https://api.${domain}/auctions/main`,
    },
    {
      clientName: 'akebono-client',
      endpoint: `https://api.${domain}/graphql/akebono/client/familiar`,
    },
    {
      clientName: 'directory',
      endpoint: `https://api.${domain}/graphql/directory/common/open`,
    },
  ],

  cognito: {
    ClientId: '4hg2ordk36hq7o63in3c7msjmr',
    UserPoolId: 'us-east-1_aH7NyGzZz',
  },
};
