import { QueryHandlingService } from '@akebono/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, combineLatest, map, switchMap, tap } from 'rxjs';
import { CategoryGQL, CategoryQuery, CategoryQueryVariables } from 'src/app/graphql/user-service';
import { SearchService } from 'src/app/services/search.service';

import { LotsFilter, LotsFilterComponent } from '../lots-filter/lots-filter.component';

interface Breadcrumb {
  name: string;
  path: string;
}

interface CategoryData {
  category: CategoryQuery['yahooAuctionsCategory'];
  exchangeRate: CategoryQuery['exchangeRate'];
  currentUser: CategoryQuery['currentUser'];
  currentTime: CategoryQuery['currentTime'];
  total: number;
  auctions: any;
}

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryComponent {
  code = '';

  categoryData: Observable<CategoryData>;

  breadcrumbs: Breadcrumb[] = [];

  filter: LotsFilter = null;

  page = 1;
  total = 0;
  pageSize = 20;

  untranslated = false;
  loadingSubject = new BehaviorSubject(false);
  get loading() {
    return this.loadingSubject.asObservable();
  }

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    private router: Router,
    private translate: TranslateService,
    private categoryGQL: CategoryGQL,
    private search: SearchService,
    private qhs: QueryHandlingService,
  ) {
    this.categoryData = combineLatest([this.route.params, this.route.queryParams]).pipe(
      tap(() => {
        this.loadingSubject.next(true);
      }),
      switchMap(([params, queryParams]) => {
        this.code = params.code;
        this.search.currentYahooCategory.next(this.code);

        this.page = Number(queryParams.page) || 1;
        this.pageSize = Number(queryParams.pageSize) || this.pageSize;

        this.filter = LotsFilterComponent.getFilterFromParams(queryParams);

        const category = this.qhs.watch<CategoryQuery, CategoryQueryVariables>(this.categoryGQL, {
          code: this.code,
          pageSize: this.pageSize,
          offset: (this.page - 1) * this.pageSize || 1,
          lang: this.translate.currentLang,
          ...this.filter,
        });
        return category.data;
      }),
      map((result) => {
        if (!result.auctions.categoryLeaf?.ResultSet['@attributes']) {
          this.router.navigate(['404']);
          return;
        }

        this.breadcrumbs = this.getBreadcrumbs(result.auctions.categoryLeaf.ResultSet.Result);
        this.title.setTitle(result.yahooAuctionsCategory?.title);

        this.untranslated = true;

        return {
          category: result.yahooAuctionsCategory,
          exchangeRate: result.exchangeRate,
          currentUser: result.currentUser,
          currentTime: result.currentTime,
          total: Number(
            result.auctions.categoryLeaf.ResultSet['@attributes']?.totalResultsAvailable,
          ),
          auctions: result.auctions.categoryLeaf.ResultSet.Result,
        };
      }),
      tap(() => {
        this.loadingSubject.next(false);
      }),
    );
  }

  getBreadcrumbs(data: CategoryData['auctions']): Breadcrumb[] {
    console.log(data);
    const crumbs: Breadcrumb[] = [];
    const names = data.CategoryPath.split('>');
    const ids = data.CategoryIdPath.split(',');

    for (let i = 1; i < names.length && i < ids.length; i++) {
      crumbs.push({
        name: names[i],
        path: `/category/${ids[i]}`,
      });
    }

    return crumbs;
  }
}
