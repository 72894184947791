import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  constructor(private title: Title, private router: Router, private translate: TranslateService) {}

  ngOnInit(): void {
    this.title.setTitle(this.translate.instant('NEWS.NEWS'));
  }

  goHome(): void {
    this.router.navigate(['/']);
  }
}
