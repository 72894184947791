<nz-spin [nzSpinning]="loading" nzSize="large">
  <div class="container skiptranslate">
    <div nz-row nzType="flex" [nzGutter]="[15, 15]">
      <ng-container *ngFor="let category of categories">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="8" [nzXl]="6">
          <nz-card [nzTitle]="title" [nzBodyStyle]="{ heigth: '100%' }">
            <ng-template #title>
              <a
                *ngIf="category.code; else simpleTitle"
                routerLink="/croooober/{{ category.code }}"
              >
                {{ category.title }}
              </a>
            </ng-template>
            <ng-template #simpleTitle>
              {{ category.title }}
            </ng-template>

            <div class="body">
              <ng-container *ngFor="let subcat of category.children">
                <div class="root-tag">
                  <a
                    routerLink="/croooober/{{ getPathname(subcat.code) }}"
                    [queryParams]="getQueryParams(subcat.code)"
                    style="display: flex; align-items: center; justify-content: space-between"
                  >
                    <span>{{ subcat.title }}</span>
                    <img src="{{ subcat.image }}" width="100" />
                  </a>
                </div>
                <br /><br />
              </ng-container>
            </div>
          </nz-card>
        </div>
      </ng-container>
    </div>
  </div>
</nz-spin>
