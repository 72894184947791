import { Input, Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { environment as envStaging } from 'src/environments/environment.staging';
import {
  News,
  NewsGQL,
  NewsQuery,
  NewsQueryVariables,
  SortEnumType,
} from 'src/app/graphql/content-service';
import { QueryHandlingService, WatchQueryRef } from '@akebono/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { scrollToTop } from 'src/app/const';

@Component({
  selector: 'app-news-tile',
  templateUrl: './news-tile.component.html',
  styleUrls: ['./news-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsTileComponent implements OnInit {
  @Input() showPagination = true;
  @Input() pageSize = 18;
  @Input() rowLimit: number = null;

  news: News[] = [];

  gridRowLimits: any = {};

  loading = false;
  domain = environment.production ? environment.domain : envStaging.domain;

  page = 1;
  total = 0;

  queryRef: WatchQueryRef<NewsQuery, NewsQueryVariables>;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private newsGQL: NewsGQL,
    private qhs: QueryHandlingService,
  ) {}

  ngOnInit() {
    this.page = this.route.snapshot.queryParams.page || 1;
    this.route.queryParams.subscribe((params) => {
      this.page = params.page || 1;
      const variables = {
        take: this.pageSize,
        skip: (this.page - 1) * this.pageSize,
        where: { site: { domain: { eq: `shop.${this.domain}` } } },
        order: {
          createdAt: SortEnumType.Desc,
        },
      };
      if (!this.queryRef) {
        this.queryRef = this.qhs.watch(this.newsGQL, variables, 'cache-first');
      } else {
        this.queryRef.refetch(variables, { triggerLoading: true });
      }
    });
  }

  changePage(page: number): void {
    this.router
      .navigate([], {
        queryParams: {
          page,
        },
        queryParamsHandling: 'merge',
      })
      .then(() => scrollToTop());
  }
}
