import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ArticleGQL, Article } from 'src/app/graphql/content-service';
import { environment } from 'src/environments/environment';
import { environment as envStaging } from 'src/environments/environment.staging';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
})
export class ArticleComponent implements OnInit {
  page = '';
  article: Article['title' | 'content' | 'publishedAt'] = null;

  loading = false;

  domain = environment.production ? environment.domain : envStaging.domain;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // private title: Title,
    public translate: TranslateService,
    private articleGQL: ArticleGQL,
  ) { }

  ngOnInit(): void {
    this.route.url.subscribe((urlParts) => {
      this.page = urlParts.slice(-1).pop().toString();

      this.fetchArticle();
    });
  }

  fetchArticle(): void {
    this.loading = true;
    const sub = this.articleGQL.fetch({
      where: {
        page: { eq: `/${this.page}` },
        site: { domain: { eq: `shop.${this.domain}`, } }
      }
    }).subscribe(
      (result) => {
        sub.unsubscribe();
        this.loading = false;

        if (result && result.data && result.data.article) {
          this.article = result.data.article;
        } else {
          this.router.navigate(['/404']);
        }
      },
      (error) => {
        this.loading = false;
        sub.unsubscribe();
        console.log(error);
        this.router.navigate(['/404']);
      },
    );
  }

  goHome(): void {
    this.router.navigate(['/']);
  }
}
