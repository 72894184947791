import { NotificationService } from '@akebono/core';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { Loadings, scrollToTop } from 'src/app/const';
import {
  ExchangeRate,
  Favourite,
  FavouriteCreateGQL,
  FavouriteDeleteGQL,
} from 'src/app/graphql/user-service';
import { GoogleTranslateService } from 'src/app/services/google-translate.service';

interface DateDiff {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
}

export enum LotsViewMode {
  Rows = 'rows',
  Panels = 'panels',
}

@Component({
  selector: 'app-lots',
  templateUrl: './lots.component.html',
  styleUrls: ['./lots.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LotsComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() items: any | any[] = [];
  @Input() exchangeRate: ExchangeRate = null;
  @Input() favourites: Favourite[] | null = [];
  @Input() sellerId: string | null = null;
  @Input() currentTime: any = null;
  @Input() mode: LotsViewMode;
  @Input() page: number = null;
  @Input() pageSize: number = null;
  @Input() total: number = null;

  isAuth = false;
  componentInited = false;
  untranslated = false;

  favOperations: Loadings = {};

  constructor(
    private apollo: Apollo,
    private router: Router,
    private translate: TranslateService,
    private notificationService: NotificationService,
    private favouriteCreateGQL: FavouriteCreateGQL,
    private favouriteDeleteGQL: FavouriteDeleteGQL,
    private googleTranslate: GoogleTranslateService,
  ) {
    this.mode = (localStorage.getItem('listViewMode') as LotsViewMode) || LotsViewMode.Rows;
  }

  ngOnInit(): void {
    console.log('lots init');
    this.isAuth = Boolean(this.favourites);

    if (this.items && !Array.isArray(this.items)) {
      this.items = [this.items];
    }
    this.untranslated = true;

    this.componentInited = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && this.componentInited) {
      if (changes.items.currentValue && !Array.isArray(changes.items.currentValue)) {
        this.items = [changes.items.currentValue];
      }
      this.untranslated = true;
    }
    if (changes.currentTime) {
      this.currentTime = changes.currentTime.currentValue;
    }
    if (changes.favourites) {
      this.favourites = changes.favourites.currentValue;
      this.isAuth = Boolean(this.favourites);
    }
  }

  ngAfterViewChecked() {
    if (this.untranslated) {
      this.untranslated = false;
      this.googleTranslate.translate();
    }
  }

  sellerDefined(item: any): boolean {
    return item.Seller && item.Seller.Id;
  }

  getCondition(item: any): string {
    if (item.Option) {
      if (item.Option.NewItemIcon) {
        return 'LOTS.NEW';
      } else {
        return 'LOTS.USED';
      }
    } else {
      return 'LOTS.UNKNOWN';
    }
  }

  getEndTime(item: any): string {
    if (item.EndTime) {
      const endTime = new Date(item.EndTime.trim());
      const now = this.currentTime ? new Date(this.currentTime) : new Date();

      if (now.getTime() > endTime.getTime()) {
        return this.translate.instant('LOTS.COMPLETE');
      } else {
        const diff = this.getDateDiff(endTime, now);

        if (diff.days !== 0) {
          return this.compileEndTimeString(diff.days, ['LOTS.DAY', 'LOTS.DAY_TWO', 'LOTS.DAY_FEW']);
        }

        if (diff.hours !== 0) {
          return this.compileEndTimeString(diff.hours, [
            'LOTS.HOUR',
            'LOTS.HOUR_TWO',
            'LOTS.HOUR_FEW',
          ]);
        }

        if (diff.minutes !== 0) {
          return this.compileEndTimeString(diff.minutes, [
            'LOTS.MINUTE',
            'LOTS.MINUTE_TWO',
            'LOTS.MINUTE_FEW',
          ]);
        }

        if (diff.seconds !== 0) {
          return this.compileEndTimeString(diff.seconds, [
            'LOTS.SECOND',
            'LOTS.SECOND_TWO',
            'LOTS.SECOND_FEW',
          ]);
        }

        return '';
      }
    } else {
      return '';
    }
  }

  getPrice(item: any): number | null {
    if (item.CurrentPrice) {
      return item.CurrentPrice;
    } else if (item.Price) {
      return item.Price;
    } else {
      return null;
    }
  }

  getDateDiff(first: Date, second: Date): DateDiff {
    const diff = first.getTime() - second.getTime();
    return {
      seconds: Math.floor((diff / 1000) % 60),
      minutes: Math.floor((diff / (1000 * 60)) % 60),
      hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
    };
  }

  changePage(page: number): void {
    scrollToTop();
    this.router.navigate([], {
      queryParams: {
        page,
      },
      queryParamsHandling: 'merge',
    });
  }

  changePageSize(pageSize: number): void {
    this.pageSize = pageSize;
    this.router.navigate([], {
      queryParams: {
        pageSize,
        page: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  compileEndTimeString(end: number, titles: string[]): string {
    // https://realadmin.ru/coding/sklonenie-na-javascript.html
    const title =
      titles[end % 100 > 4 && end % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][end % 10 < 5 ? end % 10 : 5]];
    return `${end} ${this.translate.instant(title)}`;
  }

  isFav(item: any): boolean {
    return this.favourites.map((fav) => fav.yahooLot.id).includes(item.AuctionID);
  }

  switchMode(mode: string) {
    this.mode = mode as LotsViewMode;
    localStorage.setItem('listViewMode', this.mode);
    this.googleTranslate.translate();
  }

  getFavId(auctionId: string): string {
    return this.favourites?.find((fav) => fav.yahooLot.id === auctionId).id;
  }

  fav(auctionId: string, event: Event): boolean {
    event.stopPropagation();

    this.favOperations[auctionId] = true;
    this.favouriteCreateGQL
      .mutate({
        input: {
          auctionId,
        },
      })
      .subscribe(
        (result) => {
          console.log('Favourite create response', result);

          if (!result || !result.data) {
            this.favOperations[auctionId] = false;
            this.notificationService.renderError('Empty response');
            return;
          }

          if (result.data.favouriteCreate.error) {
            this.favOperations[auctionId] = false;
            this.notificationService.renderError(
              'LOTS.FAV_FAIL',
              result.data.favouriteCreate.error.message || result.data.favouriteCreate.error.code,
            );
          } else {
            this.apollo
              .use(this.favouriteCreateGQL.client)
              .getClient()
              .reFetchObservableQueries()
              .then(() => {
                this.favOperations[auctionId] = false;
                this.notificationService.renderSuccess('LOTS.FAV_SUCCESS');
                this.router.navigate([], {
                  queryParamsHandling: 'merge',
                });
              });
          }
        },
        (error) => {
          this.favOperations[auctionId] = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
    return false;
  }

  unfav(auctionId: string, event: Event): boolean {
    event.stopPropagation();

    this.favOperations[auctionId] = true;
    this.favouriteDeleteGQL
      .mutate({
        input: {
          id: this.getFavId(auctionId),
        },
      })
      .subscribe(
        (result) => {
          console.log('Favourite delete response', result);

          if (!result || !result.data) {
            this.favOperations[auctionId] = false;
            this.notificationService.renderError('Empty response');
            return;
          }

          if (result.data.favouriteDelete.error) {
            this.favOperations[auctionId] = false;
            this.notificationService.renderError(
              'LOTS.UNFAV_FAIL',
              result.data.favouriteDelete.error.message || result.data.favouriteDelete.error.code,
            );
          } else {
            this.apollo
              .use(this.favouriteDeleteGQL.client)
              .getClient()
              .reFetchObservableQueries()
              .then(() => {
                this.favOperations[auctionId] = false;
                this.notificationService.renderSuccess('LOTS.UNFAV_SUCCESS');
                this.router.navigate([], {
                  queryParamsHandling: 'merge',
                });
              });
          }
        },
        (error) => {
          this.favOperations[auctionId] = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
    return false;
  }
}
