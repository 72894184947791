<nz-spin [nzSpinning]="loading" nzSize="large">
  <section class="section breadcrumbs">
    <nz-breadcrumb>
      <ng-container>
        <nz-breadcrumb-item>
          <a routerLink="/rakuten">Rakuten</a>
        </nz-breadcrumb-item>
      </ng-container>
    </nz-breadcrumb>
  </section>

  <div class="container skiptranslate">
    <div nz-row nzType="flex" [nzGutter]="[15, 15]">
      <ng-container *ngFor="let category of categories">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12" [nzLg]="8" [nzXl]="6">
          <nz-card [nzTitle]="title" [nzBodyStyle]="{heigth: '100%'}">
            <ng-template #title>
              <a
                *ngIf="category.code && !category.searchQuery"
                routerLink="/rakuten/search"
                [queryParams]="{ category: category.code }"
              >
                {{ category.title }}
              </a>
              <a
                *ngIf="category.useSearch"
                routerLink="{{getPathname(category.searchQuery)}}"
                [queryParams]="getQueryParams(category.searchQuery)"
              >
                {{ category.title }}
              </a>
              <span *ngIf="!category.code && !category.searchQuery"> {{ category.title }} </span>
            </ng-template>

            <div class="body">
              <ng-container *ngFor="let subcat of category.children">
                <div class="tag">
                  <a
                    *ngIf="subcat.code && !subcat.searchQuery"
                    routerLink="/rakuten/search"
                    [queryParams]="{ category: subcat.code }"
                  >
                    {{ subcat.title }}
                  </a>
                  <a
                    *ngIf="subcat.useSearch"
                    routerLink="{{getPathname(subcat.searchQuery)}}"
                    [queryParams]="getQueryParams(subcat.searchQuery)"
                  >
                    {{ subcat.title }}
                  </a>
                </div>
              </ng-container>
            </div>
          </nz-card>
        </div>
      </ng-container>
    </div>
  </div>
</nz-spin>
