import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopCatalogStore } from 'src/app/graphql/user-service';

@Component({
  selector: 'app-top-filter',
  templateUrl: './top-filter.component.html',
  styleUrls: ['./top-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopFilterComponent {
  @Input() shopCatalogStores: ShopCatalogStore[];

  currentStoreId: string;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.currentStoreId = this.route.snapshot.queryParams.storeId;
  }

  filterStore(storeId: string) {
    if (this.currentStoreId == storeId) {
      this.currentStoreId = null;
    } else {
      this.currentStoreId = storeId;
    }
    this.router.navigate([], {
      queryParams: {
        storeId: this.currentStoreId,
      },
      queryParamsHandling: 'merge',
    });
  }
}
