<nz-layout *ngIf="headerDataRef.data | async as header">
  <nz-header class="skiptranslate">
    <div
      nz-row
      nzType="flex"
      nzJustify="space-between"
      nzAlign="middle"
      [nzGutter]="{ xs: 0, sm: 0, md: 10, lg: 10, xl: 10, xxl: 10 }"
    >
      <div nz-col [nzXs]="12" [nzSm]="7" [nzMd]="5" [nzXl]="4">
        <a routerLink="/" class="logo">
          <img src="/assets/logo.png" alt="logo" />
        </a>
      </div>
      <div nz-col [nzXs]="0" [nzSm]="10" [nzMd]="13" [nzXl]="16" style="line-height: normal">
        <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
          <input
            nz-input
            type="search"
            [(ngModel)]="searchValue"
            [placeholder]="'HEADER.SEARCH' | translate"
            (keyup.enter)="find()"
            style="width: calc(70%)"
          />
          <nz-select
            [(ngModel)]="searchCategoryCode"
            nzDropdownMatchSelectWidth="false"
            [nzDropdownStyle]="{ width: '300px' }"
            nzDropdownClassName="skiptranslate"
            nzSize="large"
            style="width: calc(30%)"
          >
            <nz-option-group nzLabel="Yahoo Auctions">
              <nz-option [nzValue]="'yahoo'" [nzLabel]="'Yahoo Auctions'"></nz-option>
              <nz-option
                *ngIf="currentSeller"
                [nzValue]="'yahooSellerCurrent'"
                [nzLabel]="'HEADER.CURRENT_SELLER' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="'currentYahoo'"
                [nzLabel]="'HEADER.CURRENT_CATEGORY' | translate"
              ></nz-option>
              <ng-container *ngFor="let category of header.yahooAuctionsCategories">
                <nz-option
                  *ngIf="category.code"
                  [nzValue]="category.code"
                  [nzLabel]="category.title"
                ></nz-option>
              </ng-container>
            </nz-option-group>
            <nz-option nzValue="croober" nzLabel="Croooober.com"></nz-option>
            <nz-option nzValue="shopping" nzLabel="Yahoo Shopping"></nz-option>
            <nz-option nzValue="rakuten" nzLabel="Rakuten"></nz-option>
            <nz-option nzValue="parts" [nzLabel]="'ORIGINAL_AUTO_PARTS' | translate"></nz-option>
            <nz-option nzValue="catalog" nzLabel="Shop Catalogs"></nz-option>
            <!-- <nz-option nzValue="mercari" nzLabel="Mercari"></nz-option> -->
          </nz-select>
        </nz-input-group>
        <ng-template #suffixButton>
          <button nzSize="large" nz-button (click)="find()" nzSearch>
            <i nz-icon nzType="search" nzTheme="outline"></i>
          </button>
        </ng-template>
      </div>

      <div nz-col [nzXs]="12" [nzSm]="7" [nzMd]="6" [nzXl]="4">
        <div nz-row nzType="flex" nzJustify="end" [nzGutter]="{ xs: 2, sm: 2, lg: 5 }">
          <div nz-col>
            <!-- <a href="https://www.instagram.com/akebono.shop" class="insta">
              <i nz-icon class="insta-logo" nzType="instagram" nzTheme="outline"></i>
            </a> -->
          </div>
          <div *ngIf="!header.currentUser" nz-col class="login">
            <a
              class="login-button"
              href="//id.{{ domain }}/?referrer=//shop.{{ domain }}{{ router.url }}"
              nz-button
              nzType="primary"
              nzSize="default"
            >
              {{ 'HEADER.LOGIN' | translate }}
            </a>
          </div>
          <div *ngIf="header.currentUser" nz-col>
            <nz-badge
              [nzCount]="getTotalCartItems((cartItemsRef.data | async)?.currentUser.cartItems)"
            >
              <a routerLink="/cart" class="insta">
                <i nz-icon nzType="shopping-cart" nzTheme="outline" class="insta-logo"></i>
              </a>
            </nz-badge>
          </div>
          <div *ngIf="header.currentUser" nz-col class="menu">
            <div
              class="menu-button"
              style="margin-left: 10px"
              nz-popover
              [nzPopoverContent]="menu"
              [nzPopoverTrigger]="null"
              nzPopoverPlacement="bottomRight"
              [(nzPopoverVisible)]="isPopoverMenuOpen"
              (click)="toggleMenu()"
            >
              <div class="icon">
                <img src="/assets/user-empty.png" alt="user-icon" />
              </div>
              <div class="balance">
                {{ header.currentUser.totalBalance?.toLocaleString() || 0 }} ¥
              </div>
            </div>
            <nz-drawer
              [nzClosable]="false"
              [nzMaskClosable]="true"
              [nzCloseOnNavigation]="true"
              [nzContent]="menu"
              nzWidth="300px"
              [nzBodyStyle]="{ padding: 0 }"
              [(nzVisible)]="isDrawerMenuOpen"
              nzPlacement="right"
              [nzZIndex]="999"
              (nzOnClose)="isDrawerMenuOpen = false"
              (click)="toggleMenu()"
            ></nz-drawer>
          </div>
        </div>
      </div>
    </div>
  </nz-header>
  <nz-header nz-col [nzXl]="0" [nzMd]="0" [nzSm]="0">
    <div
      nz-row
      nzType="flex"
      style="height: 4rem"
      nzJustify="space-between"
      nzAlign="middle"
      nzGutter="{ xs: 0, sm: 0, md: 10, lg: 10, xl: 10, xxl: 10 }"
    >
      <div nz-col [nzXl]="24" [nzMd]="24" [nzSm]="24" [nzXs]="24" style="line-height: normal">
        <nz-input-group nzSearch [nzAddOnAfter]="suffixButton" nzSize="large">
          <nz-select
            [(ngModel)]="searchCategoryCode"
            nzDropdownClassName="skiptranslate"
            nzDropdownMatchSelectWidth="false"
            [nzDropdownStyle]="{ width: '300px' }"
            nzSize="large"
            style="width: calc(40%)"
          >
            <nz-option-group nzLabel="Yahoo Auctions">
              <nz-option
                [nzValue]="'yahoo'"
                [nzLabel]="'HEADER.EVERYWHERE' | translate"
              ></nz-option>
              <nz-option
                [nzValue]="'currentYahoo'"
                [nzLabel]="'HEADER.CURRENT_CATEGORY' | translate"
              ></nz-option>
              <ng-container *ngFor="let category of header.yahooAuctionsCategories">
                <nz-option [nzValue]="category.code" [nzLabel]="category.title"></nz-option>
              </ng-container>
            </nz-option-group>
            <nz-option nzValue="croober" nzLabel="Croooober.com"></nz-option>
            <nz-option nzValue="shopping" nzLabel="Yahoo Shopping"></nz-option>
            <nz-option nzValue="rakuten" nzLabel="Rakuten"></nz-option>
            <nz-option nzValue="parts" [nzLabel]="'ORIGINAL_AUTO_PARTS' | translate"></nz-option>
            <nz-option nzValue="catalog" nzLabel="Shop Catalogs"></nz-option>
            <!-- <nz-option nzValue="mercari" nzLabel="Mercari"></nz-option> -->
          </nz-select>
          <input
            nz-input
            type="search"
            [(ngModel)]="searchValue"
            [placeholder]="'HEADER.SEARCH' | translate"
            (keyup.enter)="find()"
            style="width: calc(60%)"
          />
        </nz-input-group>
        <ng-template #suffixButton>
          <button nzSize="large" nz-button (click)="find()" nzSearch>
            <i nz-icon nzType="search" nzTheme="outline"></i>
          </button>
        </ng-template>
      </div>
    </div>
  </nz-header>

  <nz-content style="min-height: 800px">
    <router-outlet *ngIf="!header.countryBlocked" (activate)="onActivate($event)"></router-outlet>
  </nz-content>

  <nz-footer>
    <nz-divider></nz-divider>
    <footer class="footer skiptranslate">
      <div nz-row nzType="flex" nzGutter="15">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8">
          <h2 nz-typography>{{ 'AKEBONO' }}</h2>
          <nz-spin [nzSpinning]="articlesRef.loading | async">
            <ng-container *ngFor="let article of (articlesRef.data | async)?.articles.items">
              <div class="news ellipsis">
                <a [routerLink]="['support', article.page.slice(1)]">{{
                  translateService.currentLang === 'ru' ? article.title.ru : article.title.en
                }}</a>
              </div>
            </ng-container>
            <div class="news ellipsis">
              <a [routerLink]="['/news']">{{ 'FOOTER.NEWS' | translate }}</a>
            </div>
          </nz-spin>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8">
          <h2 nz-typography>{{ 'FOOTER.CONTACTS' | translate }}</h2>
          <div class="descriptin-row">
            <span class="title">{{ 'FOOTER.ADDRESS' | translate }}:</span>
            <span class="desctiption">
              429 Kusajima, Toyama city, Toyama prefecture, 930-2201, Japan
            </span>
          </div>
          <div class="descriptin-row">
            <span class="desctiption">
              <a href="https://my.akebono.world/shop/information-center?tab=1" target="_blank">
                {{ 'FOOTER.MESSAGE' | translate }}
              </a>
            </span>
          </div>
          <div class="descriptin-row">
            <!-- <span class="title">{{ 'Skype' }}:</span>
            <span class="desctiption">
              <a href="skype:srg_akebono?chat">{{ 'Sergio' | translate }}</a>
              <br /> -->
            <!-- <a href="skype:live:akebono_anna?chat">{{ 'FOOTER.ANNA' | translate }}</a> -->
            <!-- <br> -->
            <!-- <a href="skype:live:.cid.8d2184428b4811ef?chat">{{ 'FOOTER.PAVEL' | translate }}</a> -->
            <!-- </span> -->
          </div>
          <div class="descriptin-row">
            <span class="title">{{ 'FOOTER.WORK_SCHEDULE_TITLE' | translate }}:</span>
            <span class="desctiption">
              {{ 'FOOTER.WORK_SCHEDULE' | translate }}
            </span>
          </div>
          <div class="descriptin-row">
            <span class="title">{{ 'FOOTER.VLADIVOSTOK_ADDRESS_TITLE' | translate }}:</span>
            <span class="desctiption" [outerHTML]="'FOOTER.VLADIVOSTOK_ADDRESS' | translate">
            </span>
          </div>
        </div>
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8">
          <h2 nz-typography>{{ 'FOOTER.ACCEPT' | translate }}</h2>
          <img src="/assets/payment-visa.png" alt="visa" />
          <img src="/assets/payment-mc.png" alt="mc" />
          <img src="/assets/payment-pp.png" alt="pp" />
        </div>
      </div>
    </footer>
  </nz-footer>
  <ng-template #menu>
    <div
      *ngIf="header.currentUser"
      class="dropdown skiptranslate"
      (click)="isPopoverMenuOpen = false"
    >
      <section class="info">
        <div>
          {{ header.currentUser.fullName || header.currentUser.firstName }}
        </div>
        <div class="email">{{ header.currentUser.username }} ({{ header.currentUser.email }})</div>
        <div>
          {{ 'HEADER.BALANCE' | translate }}:
          {{ header.currentUser.totalBalance?.toLocaleString() || 0 }} ¥
        </div>
        <div>
          {{ 'HEADER.UNPAID_SHIPMENTS' | translate }}:
          {{ header.currentUser.shipments.nodes.length }}
          {{ 'HEADER.ON_SUM' | translate }}
          {{ calcTotal(header.currentUser.shipments.nodes) }} ¥
        </div>
      </section>
      <section class="menu" (click)="toggleMenu()">
        <hr />
        <ul nz-menu nzMode="vertical">
          <li nz-menu-item>
            <a (click)="forceOpenInNewTab(personalAccountOrigin + '/shop/favorites')">
              {{ 'MENU.FAVORITES' | translate }}
            </a>
          </li>
          <li nz-menu-item>
            <a (click)="forceOpenInNewTab(personalAccountOrigin + '/shop/bids')">
              {{ 'MENU.BIDS' | translate }}
            </a>
          </li>
          <hr />
          <li nz-menu-item>
            <a (click)="forceOpenInNewTab(personalAccountOrigin + '/shop/orders')">
              {{ 'MENU.ORDERS' | translate }}
            </a>
          </li>
          <li nz-menu-item>
            <a (click)="forceOpenInNewTab(personalAccountOrigin + '/shop/finance')">
              {{ 'MENU.BALANCE' | translate }}
            </a>
          </li>
          <li nz-menu-item>
            <a
              (click)="forceOpenInNewTab(personalAccountOrigin + '/shop/information-center?tab=1')"
            >
              {{ 'MENU.MESSAGES' | translate }}
            </a>
          </li>
          <hr />
          <li nz-menu-item>
            <a (click)="forceOpenInNewTab(personalAccountOrigin + '/shop/orders?tab=1')">
              {{ 'MENU.SHIPMENTS' | translate }}
            </a>
          </li>
          <li nz-menu-item>
            <a (click)="forceOpenInNewTab(personalAccountOrigin + '/shop/addresses')">
              {{ 'MENU.ADDRESSES' | translate }}
            </a>
          </li>
          <!-- <li nz-menu-item>
            <a routerLink="/shop/customs-addresses">{{ 'MENU.CUSTOMS_ADDRESSES' | translate }}</a>
          </li> -->
          <hr />
          <li nz-menu-item>
            <a (click)="forceOpenInNewTab(personalAccountOrigin + '/cars/profile')">
              {{ 'MENU.PROFILE' | translate }}
            </a>
          </li>
          <li nz-menu-item>
            <a (click)="forceOpenInNewTab(personalAccountOrigin + '/cars/profile?tab=1')">{{
              'MENU.NOTIFICATIONS' | translate
            }}</a>
          </li>
          <li nz-menu-item>
            <a (click)="forceOpenInNewTab(personalAccountOrigin + '/cars/profile?tab=2')">{{
              'MENU.SECURITY' | translate
            }}</a>
          </li>
          <hr />
          <li nz-menu-item (click)="changeLang('ru')">Русский</li>
          <li nz-menu-item (click)="changeLang('en')">English</li>
          <hr />
          <li nz-menu-item (click)="logOut()">
            {{ 'MENU.LOGOUT' | translate }}
          </li>
        </ul>
      </section>
    </div>
  </ng-template>
</nz-layout>
