import { NotificationService } from '@akebono/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CurrentUser, ExchangeRate, SellerGQL } from 'src/app/graphql/user-service';

import { SearchService, SearchServiceEnum } from '../../../../services/search.service';
import { LotsFilter, LotsFilterComponent } from '../lots-filter/lots-filter.component';

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html',
  styleUrls: ['./seller.component.scss'],
})
export class SellerComponent implements OnInit, OnDestroy {
  sellerId: string | null = null;

  items: any = null;
  currentUser: CurrentUser = null;
  exchangeRate: ExchangeRate = null;
  currentTime = null;

  loading = false;

  filter: LotsFilter = null;

  subscription: Subscription = null;

  page = 1;
  total = 0;
  pageSize = 20;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    searchService: SearchService,
    private notificationService: NotificationService,
    private sellerGQL: SellerGQL,
  ) {
    this.sellerId = this.route.snapshot.paramMap.get('id');
    searchService.currentService.next(SearchServiceEnum.YahooSellerCurrent);
    searchService.currentSeller.next(this.sellerId);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.page = Number(params.page) || 1;
      this.pageSize = Number(params.pageSize) || this.pageSize;

      this.filter = LotsFilterComponent.getFilterFromParams(params);

      this.unsub();
      this.fetchSeller();
    });
  }

  fetchSeller(): void {
    this.loading = true;
    this.subscription = this.sellerGQL
      .watch({
        sellerId: this.sellerId,
        pageSize: this.pageSize,
        offset: (this.page - 1) * this.pageSize || 1,
        ...this.filter,
      })
      .valueChanges.subscribe(
        (result) => {
          this.loading = false;
          console.log('Seller response', result);

          if (result && result.data && result.data.auctions) {
            this.items = result.data.auctions.sellingList.ResultSet?.Result?.Item || [];
            this.exchangeRate = result.data.exchangeRate;
            this.currentUser = result.data.currentUser as unknown as CurrentUser;
            this.currentTime = result.data.currentTime;

            this.total = Number(
              result.data.auctions.sellingList.ResultSet['@attributes']?.totalResultsAvailable,
            );

            this.title.setTitle(this.sellerId);
          } else {
            this.notificationService.renderError('Empty response');
          }
        },
        (error) => {
          this.loading = false;
          console.log(error);
          this.notificationService.renderError('Error', error);
        },
      );
  }

  unsub(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsub();
  }
}
