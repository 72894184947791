import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info-row',
  templateUrl: './info-row.component.html',
  styleUrls: ['./info-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoRowComponent implements OnInit {
  @Input() label = '';
  @Input() text = '';
  @Input() useTranslate = true;
  @Input() href: string = null;
  @Input() routerLink: string = null;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (this.useTranslate) {
      this.label = this.translate.instant(this.label);
    }
  }
}
