import { MutationHandlingServiceV2 } from '@akebono/core';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { of } from 'rxjs';
import {
  CartItemCreateGQL,
  CartItemDeleteGQL,
  CartItemTypeEnum,
  CartItemUpdateGQL,
  CartItemsQuery,
  ShopCatalogProduct,
} from 'src/app/graphql/user-service';

@Component({
  selector: 'app-add-to-cart',
  templateUrl: './add-to-cart.component.html',
  styleUrls: ['./add-to-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToCartComponent implements OnInit {
  @Input() item: ShopCatalogProduct;
  @Input() cartItems: CartItemsQuery['currentUser']['cartItems'];

  adding$ = of(false);
  increasing$ = of(false);
  decreasing$ = of(false);

  constructor(
    private mhs: MutationHandlingServiceV2,
    private cartItemCreate: CartItemCreateGQL,
    private cartItemUpdate: CartItemUpdateGQL,
    private cartItemDelete: CartItemDeleteGQL,
  ) {}

  ngOnInit(): void {}

  get isInCart() {
    return !!this.cartItem;
  }

  get cartItem() {
    return (
      this.cartItems &&
      this.cartItems.find(
        (it) => it.subject.__typename == 'ShopCatalogProduct' && it.subject.id == this.item.id,
      )
    );
  }

  add() {
    this.adding$ = this.mhs.mutate(
      this.cartItemCreate,
      {
        input: {
          item: {
            code: this.item.code,
            type: CartItemTypeEnum.ShopCatalogProduct,
          },
          quantity: 1,
        },
      },
      {
        refetch: true,
        renderSuccess: false,
      },
    ).loading;
  }

  increase() {
    this.increasing$ = this.update(this.cartItem.quantity + 1);
  }

  decrease() {
    const q = this.cartItem.quantity - 1;
    this.decreasing$ = q > 0 ? this.update(q) : this.delete();
  }

  update(quantity: number) {
    return this.mhs.mutate(
      this.cartItemUpdate,
      {
        input: {
          id: this.cartItem.id,
          quantity: quantity,
        },
      },
      {
        refetch: true,
        renderSuccess: false,
      },
    ).loading;
  }

  delete() {
    return this.mhs.mutate(
      this.cartItemDelete,
      {
        input: {
          id: this.cartItem.id,
        },
      },
      {
        refetch: true,
        renderSuccess: false,
      },
    ).loading;
  }
}
