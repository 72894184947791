<nz-spin [nzSpinning]="loading | async" nzSize="large">
  <section *ngIf="categoryData | async as data" class="section breadcrumb">
    <nz-breadcrumb>
      <ng-container *ngFor="let crumb of breadcrumbs">
        <nz-breadcrumb-item>
          <a [routerLink]="crumb.path">{{ crumb.name }}</a>
        </nz-breadcrumb-item>
      </ng-container>
    </nz-breadcrumb>
  </section>

  <section class="section filter skiptranslate">
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="'CATEGORY.FILTER' | translate">
        <app-lots-filter [filter]="filter"></app-lots-filter>
      </nz-collapse-panel>
    </nz-collapse>
  </section>

  <section class="section subcategories skiptranslate" *ngIf="categoryData | async as data">
    <div class="desktop">
      <ng-container *ngFor="let subcat of data.category?.children">
        <span *ngIf="subcat.title.trim() !== ''" class="tag">
          <nz-tag>
            <a [routerLink]="['/category', subcat.code]">
              {{ subcat.title }}
            </a>
          </nz-tag>
        </span>
      </ng-container>
    </div>
    <nz-collapse class="mobile">
      <nz-collapse-panel [nzHeader]="'CATEGORY.SUBCATS' | translate">
        <ng-container *ngFor="let subcat of data.category?.children">
          <span *ngIf="subcat.title.trim() !== ''" class="tag">
            <nz-tag>
              <a [routerLink]="['/category', subcat.code]">
                {{ subcat.title }}
              </a>
            </nz-tag>
          </span>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
  </section>

  <app-lots
    *ngIf="categoryData | async as data"
    [items]="data.auctions?.Item"
    [favourites]="data.currentUser?.favourites.nodes"
    [exchangeRate]="data.exchangeRate"
    [currentTime]="data.currentTime"
    [page]="page"
    [pageSize]="pageSize"
    [total]="data.total"
  ></app-lots>
</nz-spin>
