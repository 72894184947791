import {
  AuthenticationService,
  ConfigService,
  NotificationService,
  QueryHandlingService,
  WatchQueryRef,
} from '@akebono/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { scrollToTop } from '../../const';
import {
  ArticlesGQL,
  ArticlesQuery,
  ArticlesQueryVariables,
  SortEnumType,
} from '../../graphql/content-service';
import {
  CartItemsGQL,
  CartItemsQuery,
  CartItemsQueryVariables,
  Category,
  CurrentUser,
  HeaderGQL,
  HeaderQuery,
  HeaderQueryVariables,
  Shipment,
} from '../../graphql/user-service';
import { SearchService, SearchServiceEnum } from '../../services/search.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit, OnDestroy {
  categories: Category[] = [];
  currentUser: CurrentUser | null = null;
  articlesRef: WatchQueryRef<ArticlesQuery, ArticlesQueryVariables>;
  cartItemsRef: WatchQueryRef<CartItemsQuery, CartItemsQueryVariables>;
  headerDataRef: WatchQueryRef<HeaderQuery, HeaderQueryVariables>;

  searchValue = null;
  searchCategoryCode: SearchServiceEnum | null = SearchServiceEnum.Yahoo;

  showSearchPanel = false;
  isPopoverMenuOpen = false;
  isDrawerMenuOpen = false;
  currentYahooCategory: string | null = '';
  currentSeller: string | null = '';

  countryRestricted = false;

  domain = environment.domain;

  subcription: Subscription = Subscription.EMPTY;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private notificationService: NotificationService,
    public translateService: TranslateService,
    private configService: ConfigService,
    private authenticationService: AuthenticationService,
    private headerGQL: HeaderGQL,
    private articlesGQL: ArticlesGQL,
    private search: SearchService,
    private cartItemsGQL: CartItemsGQL,
    private qhs: QueryHandlingService,
  ) {
    this.search.currentYahooCategory.subscribe((category) => {
      this.currentYahooCategory = category;
      this.searchCategoryCode = SearchServiceEnum.YahooCurrent;
    });

    this.search.currentService.subscribe((category) => {
      this.searchCategoryCode = category;
    });

    this.search.currentSeller.subscribe((seller) => (this.currentSeller = seller));
  }

  ngOnInit(): void {
    this.fetchHeaderData();
    this.fetchArticles();
    this.fetchCartItems();

    this.route.queryParams.subscribe((params) => {
      this.searchValue = params.q || null;
      if (params.category) {
        this.currentYahooCategory = params.category;
      }
      if (params.seller) {
        this.currentSeller = params.seller;
      }
    });

    if (window.location.pathname.includes('/croooober')) {
      this.searchCategoryCode = SearchServiceEnum.Croober;
    }
    if (window.location.pathname.includes('/shop-catalog')) {
      this.searchCategoryCode = SearchServiceEnum.Shopping;
    }
    if (window.location.pathname.includes('/mercari')) {
      this.searchCategoryCode = SearchServiceEnum.Mercari;
    }
    if (window.location.pathname.includes('/mercari')) {
      this.searchCategoryCode = SearchServiceEnum.Mercari;
    }
  }

  fetchCartItems() {
    this.cartItemsRef = this.qhs.watch<CartItemsQuery, CartItemsQueryVariables>(this.cartItemsGQL);
  }

  getTotalCartItems(cartItems?: CartItemsQuery['currentUser']['cartItems']) {
    return cartItems?.map((c) => c.quantity).reduce((c, i) => c + i, 0);
  }

  fetchHeaderData(): void {
    this.headerDataRef = this.qhs.watch<HeaderQuery, HeaderQueryVariables>(
      this.headerGQL,
      {
        lang: this.translateService.currentLang,
      },
      'cache-first',
    );
  }

  fetchArticles(): void {
    this.articlesRef = this.qhs.watch<ArticlesQuery, ArticlesQueryVariables>(
      this.articlesGQL,
      {
        take: 20,
        where: { site: { domain: { eq: `shop.${this.domain}` } } },
        order: { createdAt: SortEnumType.Desc },
      },
      'cache-first',
    );
  }

  ngOnDestroy(): void {
    if (this.subcription) {
      this.subcription.unsubscribe();
    }
  }

  calcTotal(shipments: Shipment[]): number {
    let amountSum = 0;
    shipments?.forEach((shipment) => {
      shipment?.invoices?.forEach((invoice) => {
        amountSum += invoice?.amount?.amount;
      });
    });

    return amountSum;
  }

  logOut(): void {
    this.authenticationService.signOut();
    window.location.reload();
  }

  showSearch() {
    this.showSearchPanel = true;
  }

  changeLang(lang: string) {
    this.configService.setCurrentLanguage({
      localeId: lang,
      imagePath: '',
      titleTranslateKey: 'LANGUAGE.EN',
    });
    window.location.reload();
  }

  find(): void {
    if (!this.searchValue) {
      this.notificationService.renderError('Empty query');
      return;
    }

    let matches = null;
    if (this.searchCategoryCode === SearchServiceEnum.Parts) {
      if (window.location.pathname.includes('/original-parts')) {
        this.router.navigate([], {
          queryParams: {
            q: this.searchValue,
            page: 1,
          },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigateByUrl(`/original-parts?q=${this.searchValue}`);
      }
      return;
    } else if (
      (matches = this.searchValue.match('https://page.auctions.yahoo.co.jp/jp/auction/([a-z0-9]+)'))
    ) {
      this.router.navigate(['/auction', matches[1]]);
    } else if (this.searchCategoryCode === SearchServiceEnum.Croober) {
      if (window.location.pathname.includes('/upgarage/')) {
        this.router.navigate([], {
          queryParams: {
            q: this.searchValue,
          },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate(['/croooober/cparts/search'], {
          queryParams: {
            q: this.searchValue,
          },
          queryParamsHandling: 'merge',
        });
      }
      return;
    } else if (
      (matches = this.searchValue.match('^([a-z][0-9]{6,})$')) ||
      (!this.searchCategoryCode?.startsWith('/') &&
        (matches = this.searchValue.match('^([0-9]{6,})$')))
    ) {
      this.router.navigate(['/auction', matches[1]]);
    } else if ((matches = this.searchValue.match('https://www.upgarage.com/ec/.+/([0-9]+)'))) {
      this.router.navigate(['/croooober/item', matches[1]]);
    } else if (
      (matches = this.searchValue.match('https://store.shopping.yahoo.co.jp/(.+)/(.+).html'))
    ) {
      this.router.navigate(['/shopping/item/', `${matches[1]}_${matches[2]}`]);
    } else if (
      (matches = this.searchValue.match(
        'https://paypaymall.yahoo.co.jp/store/(.+)/item/([A-z0-9-_]+)/?',
      ))
    ) {
      this.router.navigate(['/shopping/item/', `${matches[1]}_${matches[2]}`]);
    } else if (this.searchCategoryCode === SearchServiceEnum.Shopping) {
      if (window.location.pathname.includes('/shopping/search')) {
        this.router.navigate([], {
          queryParams: {
            q: this.searchValue,
          },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigateByUrl(`/shopping/search?q=${this.searchValue}`);
      }
      return;
    } else if (this.searchCategoryCode === SearchServiceEnum.Rakuten) {
      if (window.location.pathname.includes('/rakuten/search')) {
        this.router.navigate([], {
          queryParams: {
            q: this.searchValue,
            page: 1,
          },
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigateByUrl(`/rakuten/search?q=${this.searchValue}`);
      }
      return;
    } else if (this.searchCategoryCode === SearchServiceEnum.Yahoo) {
      this.router.navigate(['/search'], {
        queryParams: {
          q: this.searchValue,
          page: 1,
        },
        queryParamsHandling: 'merge',
      });
    } else if (this.searchCategoryCode === SearchServiceEnum.YahooCurrent) {
      this.router.navigate(['/search'], {
        queryParams: {
          q: this.searchValue,
          category: this.currentYahooCategory,
        },
      });
    } else if (this.searchCategoryCode === SearchServiceEnum.YahooSellerCurrent) {
      this.router.navigate(['/search'], {
        queryParams: {
          q: this.searchValue,
          seller: this.currentSeller,
        },
      });
    } else if (this.searchCategoryCode === SearchServiceEnum.Catalog) {
      this.router.navigate(['/shop-catalog'], {
        queryParams: {
          q: this.searchValue,
        },
      });
    } else if (this.searchCategoryCode === SearchServiceEnum.Mercari) {
      this.router.navigate(['/mercari', 'search'], {
        queryParams: {
          q: this.searchValue,
        },
      });
    } else {
      this.router.navigate(['/search'], {
        queryParams: {
          q: this.searchValue,
          category: this.searchCategoryCode,
        },
      });
    }
  }

  onActivate(event: any): void {
    scrollToTop();
  }

  forceOpenInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  get personalAccountOrigin(): string {
    return `https://my.${this.domain}`;
  }

  toggleMenu() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      this.isDrawerMenuOpen = !this.isDrawerMenuOpen;
    } else {
      this.isPopoverMenuOpen = !this.isPopoverMenuOpen;
    }
  }
}
