import { MutationHandlingServiceV2, NotificationService } from '@akebono/core';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { validateAllFormFields } from 'src/app/const';

import {
  YahooBid,
  YahooBidCreateGQL,
  YahooBidCreateInput,
  YahooBidUpdateGQL,
  YahooBidUpdateInput,
} from '../../../../../../../graphql/user-service';

interface IModalData {
  amount: number | string;
  isBuying: boolean;
  isBlocked: boolean;
  auctionId: string;
  deferred?: boolean;
  activeBid?: YahooBid;
}

@Component({
  selector: 'app-bid-or-buy-confirmation-modal',
  templateUrl: './bid-or-buy-confirmation-modal.component.html',
  styleUrls: ['./bid-or-buy-confirmation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidOrBuyConfirmationModalComponent implements OnInit {
  readonly data: IModalData = inject(NZ_MODAL_DATA);

  form: FormGroup;

  loading = of(false);

  constructor(
    private fb: FormBuilder,
    private modal: NzModalRef,
    private notification: NotificationService,
    private yahooBidCreateGQL: YahooBidCreateGQL,
    private yahooBidUpdateGQL: YahooBidUpdateGQL,
    private mutation: MutationHandlingServiceV2,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      price: this.fb.control(this.data.amount || 0, [Validators.required]),
      deferred: this.fb.control(this.data.deferred ?? false),
      quantity: this.fb.control(1, [Validators.required]),
      message: this.fb.control(null),
      agree: this.fb.control(null),
    });
  }

  trade(): void {
    validateAllFormFields(this.form);

    if (this.form.valid) {
      if (this.data.isBuying) {
        const input: YahooBidCreateInput = {
          auctionId: this.data.auctionId,
          amount: Number(this.form.value.price),
          comment: this.form.value.message,
          isBuyNow: true,
          quantity: this.form.value.quantity,
          isDeferred: false,
          quantityPolicy: '',
        };

        this.yahooBidCreate(input);
      } else {
        if (this.data.activeBid) {
          const input: YahooBidUpdateInput = {
            id: this.data.activeBid.id,
            amount: Number(this.form.value.price),
          };

          this.yahooBidUpdate(input);
        } else {
          const input: YahooBidCreateInput = {
            auctionId: this.data.auctionId,
            amount: Number(this.form.value.price),
            comment: this.form.value.message,
            isBuyNow: false,
            quantity: this.form.value.quantity,
            isDeferred: this.form.value.deferred,
            quantityPolicy: '',
          };

          this.yahooBidCreate(input);
        }
      }
    } else {
      this.notification.renderError('AUCTION.VALIDATION_FAIL');
    }
  }

  private yahooBidCreate(input: YahooBidCreateInput): void {
    const ref = this.mutation.mutate(
      this.yahooBidCreateGQL,
      { input },
      {
        failureTranslationKey: input.isBuyNow ? 'AUCTION.LOT_BUY_FAIL' : 'AUCTION.BID_CREATE_FAIL',
        successTranslationKey: input.isBuyNow
          ? 'AUCTION.LOT_BUY_SUCCESS'
          : 'AUCTION.BID_CREATE_SUCCESS',
        refetch: true,
      },
    );

    this.loading = ref.loading;
    ref.data.subscribe({
      error: () => this.modal.destroy(),
      complete: () => this.modal.destroy(),
    });
  }

  private yahooBidUpdate(input: YahooBidUpdateInput): void {
    const ref = this.mutation.mutate(
      this.yahooBidUpdateGQL,
      { input },
      {
        failureTranslationKey: 'AUCTION.BID_UPDATE_FAIL',
        successTranslationKey: 'AUCTION.BID_UPDATE_SUCCESS',
        refetch: true,
      },
    );

    this.loading = ref.loading;
    ref.data.subscribe({
      error: () => this.modal.destroy(),
      complete: () => this.modal.destroy(),
    });
  }
}
