<div nz-row nzJustify="space-between" class="container">
  <div nz-col class="label">{{ label }}:</div>
  <div *ngIf="!href && !routerLink" nz-col class="text">
    {{ text }}
  </div>
  <div *ngIf="href || routerLink" nz-col class="text">
    <a *ngIf="href" target="_blank" [href]="href">
      {{ text }}
    </a>
    <a *ngIf="routerLink" [routerLink]="routerLink">
      {{ text }}
    </a>
  </div>
</div>
