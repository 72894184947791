import {
  MutationHandlingServiceV2,
  NotificationService,
  QueryHandlingService,
} from '@akebono/core';
import { WatchQueryRef } from '@akebono/core/lib/services/handling-services/query-handling-service/query-refs/watch.query-ref';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewChecked, ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import {
  AuctionGQL,
  AuctionQuery,
  AuctionQueryVariables,
  Auctions,
  ExchangeRate,
  FavouriteCreateGQL,
  FavouriteCreateMutation,
  FavouriteCreateMutationVariables,
  FavouriteDeleteGQL,
  MessageTemplate,
  MessageTemplateLanguageEnum,
  YahooLot,
} from 'src/app/graphql/user-service';
import { GoogleTranslateService } from 'src/app/services/google-translate.service';
import { SearchService, SearchServiceEnum } from 'src/app/services/search.service';

interface Breadcrumb {
  name: string;
  path: string;
}

@Component({
  selector: 'app-auction',
  templateUrl: './auction.component.html',
  styleUrls: ['./auction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuctionComponent implements OnDestroy, AfterViewChecked {
  auctionId: string = null;

  lot: AuctionQuery['yahooLot'] = null;
  auctions: Auctions = null;
  messageTemplates: MessageTemplate[] = [];
  currentUser: AuctionQuery['currentUser'] = null;
  exchangeRate: ExchangeRate = null;
  services: AuctionQuery['services']['nodes'] = [];
  favourites: AuctionQuery['currentUser']['favourites']['nodes'] = [];
  breadcrumbs: Breadcrumb[] = [];
  shipmentPrice = null;
  currentTime = null;

  loading: Observable<boolean>;
  faving: Observable<boolean>;
  isAuth = false;
  favId: string;
  mobile = false;
  untranslated = false;

  amount = null;

  queryRef: WatchQueryRef<AuctionQuery, AuctionQueryVariables>;
  subscription = Subscription.EMPTY;

  constructor(
    private notification: NotificationService,
    private googleTranslate: GoogleTranslateService,
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private auctionGQL: AuctionGQL,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver,
    private favouriteCreateGQL: FavouriteCreateGQL,
    private favouriteDeleteGQL: FavouriteDeleteGQL,
    private searchService: SearchService,
    private query: QueryHandlingService,
    private mutation: MutationHandlingServiceV2,
  ) {
    this.auctionId = this.route.snapshot.paramMap.get('auctionId');
    this.searchService.currentService.next(SearchServiceEnum.Yahoo);

    this.breakpointObserver.observe(['(max-width: 900px)']).subscribe((state: BreakpointState) => {
      this.mobile = state.matches;
    });

    this.queryRef = this.query.watch<AuctionQuery, AuctionQueryVariables>(this.auctionGQL, {
      auctionId: this.auctionId,
      secureAuctionId: this.auctionId,
      withShipmentPrice: true,
      messageTemplateLanguage: this.translate.currentLang as MessageTemplateLanguageEnum,
    });

    this.loading = this.queryRef.loading;
    this.subscription = this.queryRef.data.subscribe((data) => {
      if (data && data.yahooLot && data.auctions && data.services) {
        if (!data.yahooLot.lastPayload) {
          this.router.navigate(['404']);
          return;
        }
        this.lot = data.yahooLot as unknown as YahooLot;
        this.amount = this.lot.lastPayload?.Price;
        this.auctions = data.auctions;
        this.messageTemplates = data.messageTemplates;
        this.currentUser = data.currentUser;
        this.exchangeRate = data.exchangeRate;
        this.services = data.services.nodes;
        this.currentTime = data.currentTime;
        this.shipmentPrice = data.yahooLot.shipmentPrice;

        this.isAuth = Boolean(this.currentUser);
        this.favourites = this.currentUser?.favourites?.nodes || null;
        this.title.setTitle(this.lot.lastPayload.Title);
        this.breadcrumbs = this.getBreadcrumbs(this.lot.lastPayload);
        this.searchService.currentYahooCategory.next(
          this.lot.lastPayload?.CategoryIdPath?.split(',')?.pop(),
        );

        this.favId = this.favourites?.find(
          (fav) => fav.yahooLot.id === this.lot.lastPayload.AuctionID,
        )?.id;
        this.untranslated = true;
      } else {
        this.notification.renderError('Empty response');
      }
    });
  }

  ngAfterViewChecked() {
    if (this.untranslated) {
      this.untranslated = false;
      this.googleTranslate.translate();
    }
  }

  getBreadcrumbs(payload: any): Breadcrumb[] {
    const crumbs: Breadcrumb[] = [];
    const names = payload.CategoryPath.split('>');
    const ids = payload.CategoryIdPath.split(',');

    for (let i = 1; i < names.length && i < ids.length; i++) {
      crumbs.push({
        name: names[i],
        path: `/category/${ids[i]}`,
      });
    }

    return crumbs;
  }

  isAllowBid(): boolean {
    return (
      !this.lot.lastPayload.Bidorbuy || this.lot.lastPayload.Price !== this.lot.lastPayload.Bidorbuy
    );
  }

  update(): void {
    console.log('updating!');
    this.queryRef.refetch();
  }

  onAmountUpdate(amount: number) {
    this.amount = amount;
  }

  fav(): void {
    const ref = this.mutation.mutate<FavouriteCreateMutation, FavouriteCreateMutationVariables>(
      this.favouriteCreateGQL,
      {
        input: {
          auctionId: this.lot.lastPayload.AuctionID,
        },
      },
      {
        successTranslationKey: 'LOTS.FAV_SUCCESS',
        failureTranslationKey: 'LOTS.FAV_FAIL',
      },
    );

    this.faving = ref.loading;
    ref.data.subscribe((data) => {
      this.favId = data?.favouriteCreate?.favourite?.id;
    });
  }

  unfav(): void {
    const ref = this.mutation.mutate(
      this.favouriteDeleteGQL,
      {
        input: {
          id: this.favId,
        },
      },
      {
        successTranslationKey: 'LOTS.UNFAV_SUCCESS',
        failureTranslationKey: 'LOTS.UNFAV_FAIL',
      },
    );

    this.faving = ref.loading;
    ref.data.subscribe(() => {
      this.favId = null;
    });
  }

  get isShop(): boolean {
    return !!this.lot.lastPayload.Option?.StoreIcon;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
