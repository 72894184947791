import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CrooberCategoriesComponent } from './components/croober-categories/croober-categories.component';
import { CrooberItemComponent } from './components/croober-item/croober-item.component';
import { CrooberSearchComponent } from './components/croober-search/croober-search.component';

const routes: Routes = [
  { path: '', component: CrooberCategoriesComponent },
  { path: 'item/:id', component: CrooberItemComponent },
  { path: '**', component: CrooberSearchComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CrooberRoutingModule {}
