import { Component, OnInit } from '@angular/core';
import { NewsEntryGQL, News } from 'src/app/graphql/content-service';
import { NotificationService } from '@akebono/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-news-entry',
  templateUrl: './news-entry.component.html',
  styleUrls: ['./news-entry.component.scss'],
})
export class NewsEntryComponent implements OnInit {
  id = '';
  news: News['title' | 'country' | 'image' | 'createdAt'] = null;
  currentLanguage;
  newsTitle;
  newsContent;

  loading = false;

  constructor(
    private notification: NotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private title: Title,
    private newsEntryGQL: NewsEntryGQL,
    private translate: TranslateService,
  ) {
    this.id = this.route.snapshot.paramMap.get('id');
  }

  ngOnInit(): void {
    this.loading = true;
    this.currentLanguage = this.translate.currentLang;
    const sub = this.newsEntryGQL.fetch({ id: Number(this.id) }).subscribe(
      (result) => {
        sub.unsubscribe();
        this.loading = false;

        if (result && result.data && result.data.newsEntry) {
          this.news = result.data.newsEntry;
          this.newsTitle = result.data.newsEntry.title[this.currentLanguage];
          this.newsContent = result.data.newsEntry.content[this.currentLanguage];
          this.title.setTitle(this.news.title[this.currentLanguage]);
        } else {
          this.notification.renderError('Empty response');
        }
      },
      (error) => {
        this.loading = false;
        console.log(error);
        this.notification.renderError('Error', error);
        sub.unsubscribe();
      },
    );
  }

  goToNews(): void {
    this.router.navigate(['/news']);
  }
}
