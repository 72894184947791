import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';

export enum ShoppingSearchSort {
  ScoreDsc = '-score',
  ReviewDsc = '-review_count',
  PriceDsc = '-price',
  PriceAsc = '+price',
}

export enum ShoppingSearchConfition {
  New = 'new',
  Used = 'used',
}

export interface ShoppingSearchFilter {
  priceFrom: number | null;
  priceTo: number | null;
  query: string | null;
  genreCategoryId: number | null;
  isDiscounted: boolean | null;
  condition: string | null;
  sort: string | null;
  inStock: boolean | null;
  sellerId: string | null;
}

@Component({
  selector: 'app-shopping-search-filter',
  templateUrl: './shopping-search-filter.component.html',
  styleUrls: ['./shopping-search-filter.component.scss'],
})
export class ShoppingSearchFilterComponent implements OnInit {
  @Input() initFilter: ShoppingSearchFilter = null;

  form: FormGroup = null;

  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder) {
    this.initFilterForm();
  }

  public static getFilter(params: Params): ShoppingSearchFilter {
    return {
      query: params.q || undefined,
      condition: params.condition || undefined,
      sort: params.sort || undefined,
      sellerId: params.seller || undefined,
      genreCategoryId: this.paramToNumber(params.category),
      priceFrom: this.paramToNumber(params.priceFrom),
      priceTo: this.paramToNumber(params.priceTo),
      isDiscounted: this.paramToBoolean(params.discounted),
      inStock: this.paramToBoolean(params.inStock),
    };
  }

  private static paramToBoolean(param: string | null): boolean | null {
    if (param) {
      return param === 'true';
    }

    return null;
  }

  private static paramToNumber(value: string, defaultValue: number | null = null): number | null {
    const result = Number(value);
    return !isNaN(result) ? result : defaultValue;
  }

  ngOnInit(): void {
    this.initFilterForm(this.initFilter);
  }

  initFilterForm(filter?: ShoppingSearchFilter): void {
    this.form = this.fb.group({
      condition: this.fb.control(filter?.condition || 'default'),
      sort: this.fb.control(filter?.sort || ShoppingSearchSort.ScoreDsc),
      priceFrom: this.fb.control(filter?.priceFrom || null),
      priceTo: this.fb.control(filter?.priceTo || null),
      discounted: this.fb.control(filter?.isDiscounted || 'default'),
      inStock: this.fb.control(filter?.inStock || 'default'),
    });
  }

  reset(): void {
    const mainFilters = this.getMainFilters();
    this.form.reset();
    this.initFilterForm();

    this.router.navigate([], {
      queryParams: {
        ...mainFilters,
      },
    });
  }

  apply(): void {
    const query = this.compileQury();
    const mainFilters = this.getMainFilters();

    this.router.navigate([], {
      queryParams: {
        ...query,
        ...mainFilters,
      },
    });
  }

  getMainFilters(): any {
    return {
      q: this.route.snapshot.queryParams.q,
      seller: this.route.snapshot.queryParams.seller,
      category: this.route.snapshot.queryParams.category,
      pageSize: this.route.snapshot.queryParams.pageSize,
    };
  }

  compileQury(): any {
    const query = {};
    const value = this.form.value;

    Object.keys(value)
      .filter((key) => value[key] !== 'default' && value[key] !== '-score')
      .forEach((key) => (query[key] = value[key]));

    return query;
  }
}
